import { Avatar, Box, Button, capitalize, CircularProgress, ImageList, Input, List, ListItem, ListItemText, MenuItem, Select, Slide, Slider, TextField, Typography, useTheme } from "@mui/material";
import { tokens } from "../../theme";
import React, { useMemo, useState } from "react";
import { AddCircleOutline, CloseRounded, FormatBoldRounded, FormatItalicRounded, FormatUnderlined } from "@mui/icons-material";
import { useRef } from "react";
import NewImage from "./newImage";
import { storage, writedb } from "../../firebase-config"
import { ref, uploadBytes } from "firebase/storage"
import { ref as dbref, set as dbset, child, push } from "firebase/database";
import * as htmlToImage from 'html-to-image';
import { toPng, toJpeg, toBlob, toPixelData, toSvg } from 'html-to-image';
import html2canvas from 'html2canvas';
import { async } from "@firebase/util";
import NewText from "./newText";
import { current } from "@reduxjs/toolkit";


const AddMultipleVideos = (props) => { //Cat display is used to close add category window from close button
    const catDis = props.catDis;
    const myKey = props.myKey;
    const myName = props.myName;
    const catCus = props.address;
    const theme = useTheme();
    const colors = tokens(theme.palette.mode);
    const [selectImage, setSeletImage] = useState(false);
    const [coverSelect, setCoverSelect] = useState(true);
    const fileInput = useRef(null);
    const bgInput = useRef(null);
    const coverInput = useRef(null);
    const [imageList, setImageList] = useState([]);
    const [mainBoxArray, setmainBoxArray] = useState([]); // This array is used to store movable images inside server
    const [textMainArray, setTextMainArray] = useState([]); // This array is used to store editable text in server...
    const [background, setBackground] = useState([]);
    const [categoryName, setCatName] = useState(props.myName);
    const [currenSelection, setCurrSelection] = useState({ height: 50, width: 50, left: 1, top: 1 });
    const [height, setHeight] = useState(0);
    const [width, setWidth] = useState(0);
    const [top, setTop] = useState(0);
    const [left, setLeft] = useState(0);
    const [catNameSel, setCatNameSel] = useState(false);
    const [showAddText, setShowAddText] = useState(false);
    const [addTextData, setAddTextData] = useState("");  // This is the text data we want to add in our main editing box
    const [fontFamily, setFontFamily] = useState('Montserrat, sans-serif'); //This is the subpart of text data
    const [fontSize, setFontSize] = useState(16); // ||
    const [fontWeight, setFontWeight] = useState(300); // ||
    const [fontItalic, setFontItalic] = useState(false); // ||
    const [fontUnderline, setFontUnderline] = useState(false); // ||
    const [fontColor, setFontColor] = useState("#ffffff"); // ||
    const [isEditText, setIsEditText] = useState(false);
    const [editTextIndex, setEditTextIndex] = useState(); // Index of array where this text is located
    const [letsupload, setletsupload] = useState(false)
    const [vegNonVeg, setVegNonVeg] = useState("");
    const [foodTypeSel, setFoodTypeSel] = useState(false);
    const [subCatName, setSubCatName] = useState("");
    const [previewImage, setPreviewImage] = useState("");
    const [showNewSubcatName, setShowNewSubCatName] = useState(false)
    const [subCatList, setSubCatList] = useState([]);
    const [searchLabels, setSearchLables] = useState(false);
    const [searchList, setSearchList] = useState([]);
    const [labelName, setLabelName] = useState("");
    const [imagesUploading, setImagesUploading] = useState(false);
    const [currentVideo, setCurrentVideo] = useState("");
    const [currentVideoSelcted, setCurrentVideoSelected] = useState(false);
    const [thumbList, setThumbList] = useState([]);
    const [selectVideo, setSelectVideo] = useState(false);
    const imageEditingBoxRef = useRef(null);
    const [videoStatus, setVideoStatus] = useState("Generating Thumbnails")



    const handleFileSelect = (event) => {
        if (event.target.files[0]) {
            setSeletImage(true);
            setCatNameSel(true);
            const files = event.target.files;
            setImageList(Array.from(files));
            // setFoodTypeSel(true);
            // setImagesUploading(true);
            let arr = [];
            Array.from(files).forEach(async image => {
                document.getElementById("backgroundImage").src = URL.createObjectURL(image);
                let video = document.getElementById("backgroundImage");
                video.load();
                video.onloadeddata = () => {
                    html2canvas(document.getElementById("backgroundImage"))
                        .then(async canvas => {
                            const dataUrl = canvas.toDataURL('image/png');
                            const blob = await fetch(dataUrl).then(res => res.blob());
                            console.info(blob);
                            thumbList.push(blob);
                        })
                        .catch(error => {
                            console.log('Error:', error);
                        });
                }


            })
            setImagesUploading(false);
            setVideoStatus("Uploading Videos")
            setFoodTypeSel(true);
        } else {
            alert("Video Not selected")
        }
    };




    const addImageToServer = async (dataUrl, dbref, storageRef1) => {
        try {
            let i = 1;
            imageList.forEach(async (image, index) => {
                dbref = writedb.ref(`videos/${catCus}`);
                storageRef1 = storage.ref(`videos/${catCus}`);
                const pushref = dbref.push();
                const uniqueKey = pushref.key;
                const storageRef = storageRef1.child(uniqueKey);
                let path = `video/${catCus}/${myKey}/${categoryName}/${vegNonVeg}`
                let pushRef = writedb.ref(path).push();
                let imageCatKey = pushRef.key;
                const task = await storageRef.child("background").put(image).then(
                    () => {
                        // complete function
                        // storageRef.child("background").getDownloadURL().then((url) => {
                        storageRef.child("background").getDownloadURL().then((url) => {
                            const finalPath = dbref.child(uniqueKey);
                            const addBg = finalPath.child("background")
                            addBg.set({ video: url }).then(async () => {
                                try {


                                    await storageRef.child("cover").put(thumbList[index]).then(() => {
                                        storageRef.child("cover").getDownloadURL().then((url) => {
                                            const addCover = finalPath.child("cover");
                                            addCover.set({ image: url }).then(() => {

                                            })
                                        })
                                    })
                                    await writedb.ref(path).child(imageCatKey).set({ video: `videos/${catCus}/${uniqueKey}` }).then(() => {
                                        finalPath.child("paths").child(myKey).set({ key: myKey }).then(() => {
                                            searchList.forEach(async txt => {
                                                await writedb.ref(`search/${txt}/${uniqueKey}`).set({ image: `videos/${catCus}/${uniqueKey}` }).then(() => {
                                                    finalPath.child("labels").child(txt).set({ name: txt });
                                                })
                                            })
                                        })
                                    })


                                } catch (error) {
                                    console.error(error.toString());
                                }
                                if (i == imageList.length) {
                                    // resetAll();
                                    alert("All Videos uploaded")
                                    catDis();
                                }
                                i++;
                            })
                        })
                    }
                );

            })


        } catch (error) {
            console.error(error)
        }
    }


    const uploadToServer = async () => {
        setImagesUploading(true);
        const dbref = writedb.ref(`video/${catCus}/${myKey}/${categoryName}/${vegNonVeg}/${subCatName}`);
        const storageRef = storage.ref(`video/${catCus}/${myKey}/${categoryName}/${vegNonVeg}/${subCatName}`);
        addImageToServer(previewImage, dbref, storageRef)

    }




    const inputStyle = {
        '& label.Mui-focused': {
            color: 'aqua',
        },
        '& .MuiInput-underline:after': {
            borderBottomColor: 'white',
        },
        '& .MuiOutlinedInput-root': {
            '& fieldset': {
                borderColor: 'gray',
            },
            '&:hover fieldset': {
                borderColor: 'white',
            },
            '&.Mui-focused fieldset': {
                borderColor: 'rgb(155,155,255)',
            }, '& .MuiInputBase-input': {
                color: 'black',
                fontSize: "16px",

            },
        }
    }






    const handleClickOfInput = (input) => {
        if (input.current) {
            input.current.click();
        }
    };

    const addSearch = () => {
        searchList.push(labelName);
        setLabelName("");
    }



    return (
        <Box display="flex" position="absolute" top="20%" bottom="20%" left="30%" right="30%" height="400px" width="700px" backgroundColor="#727681" zIndex="20" borderRadius='10px' justifyContent="center" >


            <Box display="flex"  >
                <Box id="imageEditingBoxInAddCategory" display="flex" flexDirection='column' position="absolute" width="290px" height="300px" color="white" overflow="hidden">
                    <video id="backgroundImage" src={selectVideo > 0 ? URL.createObjectURL(currentVideo) : ""} style={{
                        width: "290px", height: "300px",
                        objectFit: 'fill',
                        width: '100%',
                        height: '100%',
                    }} />

                </Box>
            </Box>

            {/* ############## category setup Screen 1 starts here ################ */}
            {/* This is close button of add category */}
            <Box position="absolute" right="0" top="0" display="flex" zIndex="100">
                <Button onClick={catDis} ><CloseRounded sx={{
                    color: colors.primary[100], '&:hover': {
                        color: 'lightgreen'
                    }
                }} /></Button>
            </Box>
            {/* This is close button of add category */}




            {/* This is for select image from files */}
            <Box display={selectImage ? "none" : "flex"} height="400px" width="700px" alignItems="center" flexDirection="column" >

                <Typography fontSize="35px" fontWeight="700" marginTop="100px" marginBottom="20px">Add Videos</Typography>

                <input accept="video/*" type="file" onChange={handleFileSelect} multiple ref={fileInput} style={{ display: "none" }} />
                <Box display="flex" justifyContent="center" alignItems="center" >
                    <Button onClick={() => {
                        handleClickOfInput(fileInput)
                    }}
                        sx={{ backgroundColor: "blue", borderRadius: "20px" }}><Typography fontSize="16px" textTransform="none" color="white" padding="5px" textAlign="center" justifyContent="center" display="flex" alignItems="center"><AddCircleOutline sx={{ marginRight: "8px" }} />Select Videos</Typography></Button>
                </Box>
            </Box>
            {/* File select ends here */}








            <Box display={foodTypeSel ? "flex" : "none"} height="100%" width="100%" justifyContent="center" alignItems="Center">
                <Box display="flex" bgcolor="blue" borderRadius="10px" mr="40px">
                    <Button
                        onClick={() => { <> {setFoodTypeSel(false)} {setVegNonVeg("veg")}  {setSearchLables(true)}</> }}>
                        <Typography textTransform="none" color="white">Veg</Typography>
                    </Button>
                </Box>
                <Box display="flex" bgcolor="blue" borderRadius="10px">
                    <Button
                        onClick={() => { <> {setFoodTypeSel(false)} {setVegNonVeg("nonVeg")}  {setSearchLables(true)}</> }}>
                        <Typography textTransform="none" color="white">Non-Veg</Typography>
                    </Button>
                </Box>
            </Box>




            <Box display={searchLabels && !imagesUploading ? "flex" : "none"} height="100%" width="100%" justifyContent="center" alignItems="center" flexDirection='column'>
                <TextField autoFocus autoComplete="off" label="Enter Label?" value={labelName} onChange={(event) => {
                    setLabelName(event.target.value.charAt(0).toUpperCase() + event.target.value.slice(1))
                }} sx={inputStyle} />
                <Box display="flex">
                    <Box display="flex" mt="20px" bgcolor={labelName.length > 0 ? "blue" : "rgb(255,255,255,0.23)"} borderRadius="10px">
                        <Button disabled={labelName.length > 0 ? false : true} onClick={() => { addSearch() }}>
                            <Typography textTransform="none" color="white">Add Search Label</Typography>
                        </Button>
                    </Box>

                    <Box display="flex" ml="10px" mt="20px" bgcolor={searchList.length > 0 ? "blue" : "rgb(255,255,255,0.23)"} borderRadius="10px">
                        <Button disabled={searchList.length > 0 ? false : true} onClick={() => { uploadToServer() }}>
                            <Typography textTransform="none" color="white">Upload Videos</Typography>
                        </Button>
                    </Box>
                </Box>

                <Box display={searchList.length > 0 ? "none" : "flex"} position="absolute" bottom="0" mb="10px">Add one label to continue</Box>
            </Box>

            <Box display={imagesUploading ? "flex" : "none"} justifyContent="center" flexDirection="column" alignItems="center">
                <Typography >{videoStatus}</Typography>
                <CircularProgress sx={{ padding: "7px", mt: "10px" }} />
            </Box>


        </Box>
    );



};

export default AddMultipleVideos;
