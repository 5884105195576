import React, { useRef, useState } from 'react'
import axios from 'axios';
import { Alert, Box, Button, List, ListItem, ListItemText, TextField, Typography, useTheme } from '@mui/material';
import Header from '../../components/Header';
import { tokens } from '../../theme';
import { AddCircleOutlineRounded, CloseRounded } from '@mui/icons-material';
import { onValue, ref } from 'firebase/database';
import { database, storage, writedb } from '../../firebase-config';

const Notification = () => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);

  const [title, setTitle] = useState("");
  const [message, setMesaage] = useState("");
  const [topic, setTopic] = useState("")
  const imageInput = useRef(null);
  const [image, setImage] = useState("");
  const [imageSelected, setImageSelected] = useState(false);
  const [listNames, setListNames] = useState([]);
  const [targetedUser, selectTargetedUser] = useState("all");
  const [showTarget, setShowTarget] = useState(false);
  const [showTriggers,setShowTriggers] = useState(true);
  const [triggerName,setTriggerName] = useState("");
  const [targetSelected,setTargetSelected] = useState("");
  const [targetKeyName,setTargetKeyName] = useState({key:"",name:""});

  const handleImageSelect = (event) => {
    if (event.target.files[0]) {
      const files = event.target.files;
      setImage(Array.from(files)[0]);
      setImageSelected(true);

    } else {
      alert("Image not selected")
    }

  }

  const settargetName = (val) => {
      setTargetKeyName(val)
      setShowTarget(false);
  }


  const SERVER_KEY = 'AAAAspmmHNU:APA91bGSSmGb11I7pusmNHN5lPfOM2bDDDl5IXJzc5LxcxjkfObjcloUyCCOfdeEalXV6t3Pk-u0MAPhb2iKsSqChN2_PkZclVKIe753szMwVPE76z3WNgiUlN5-OsNboJQbHqBAiwSB'; // Replace with your Firebase Server Key

  function sendNotification(data){
    
    
    const headers = {
      'Content-Type': 'application/json',
      'Authorization': `key=${SERVER_KEY}`
    };

    axios.post('https://fcm.googleapis.com/fcm/send', data, {
      headers: headers
    })
      .then(response => {
        alert("Notification sent successfully")
        // console.log('Notification sent successfully:', response.data);
      })
      .catch(error => {
        alert('Error sending notification:')
        // console.error('Error sending notification:', error);
      });
  }

  function sendPushNotificationToTopic() {
    let key;
    const topic = targetedUser;
    writedb.ref("analytics/notificationClicks").remove();
    if (imageSelected){
      let pathToPush = writedb.ref("notification");
      key = pathToPush.push().key;
      storage.ref("notification/" + key + "/image").put(image).then(() => {
          storage.ref("notification/" + key+"/image").getDownloadURL().then((url) => {
            writedb.ref("notification/" + key).set({title:title,message:message,image:url,triggerName:triggerName,userType:targetedUser,key:targetKeyName.key,name:targetKeyName.name}).then(() =>{
              const data = {
                to: `/topics/${topic}`,
                notification: {
                  title: title,
                  body: message,
                  image: url,
                  click_action: "OPEN_ACTIVITY"
                },
                data: {
                  type: triggerName,
                  key: targetKeyName.key,
                }
              };
              sendNotification(data)
            });
          })
          
      })
    }
    else{
      writedb.ref("notification").push().set({title:title,message:message,triggerName:triggerName,userType:targetedUser,key:targetKeyName.key,name:targetKeyName.name}).then(() =>{
        const data = {
          to: `/topics/${topic}`,
          notification: {
            title: title,
            body: message,
            click_action: "OPEN_ACTIVITY"
          },
          data: {
            type: triggerName,
            key: targetKeyName.key,
          }
        };
        sendNotification(data)
      })
      
    }

    

    
  }

  const inputStyle = {
    '& label.Mui-focused': {
      color: 'aqua',
    },
    '& .MuiInput-underline:after': {
      borderBottomColor: 'white',
    },
    '& .MuiOutlinedInput-root': {
      '& fieldset': {
        borderColor: 'gray',
      },
      '&:hover fieldset': {
        borderColor: 'white',
      },
      '&.Mui-focused fieldset': {
        borderColor: 'rgb(155,155,255)',
      }, '& .MuiInputBase-input': {
        color: 'white',
        fontSize: "16px",

      },
    },
    width: "30%"
  }


  const loadData = (name) => {
    setShowTriggers(false);
    var path;
    if (name == "Category") {
      setTriggerName("category");
      path = "homeUniquekeys/category"
    } else if (name == "Outlet") {
      setTriggerName("outlet");
      path = "homeUniquekeys/outlet"
    }else if (name == "Video") {
      setTriggerName("categoryvideo");
      path = "videoUniquekeys/category"
    } else if (name == "General") {
      setTriggerName("General");
      path = "generalUniquekeys/categoryGeneral"
    } else {
      setTriggerName("Custom");
      path = "homeUniquekeys/custom"
    }
    onValue(ref(database, path), (snapshot) => {
      listNames.length = 0;
      let names = [];

      snapshot.forEach(snapshot => {
        const data = snapshot.val();
        const name = snapshot.key;
        const key = Object.values(data)[0];
        names.push({ key: key, name: name });
      });
      setTargetSelected(true)
      setListNames(names);
    })
  }

  function pushNotification(){
    if (title.length==0 && message.length == 0){
      alert("Title and Message should not be empty");
      return;
    }
    sendPushNotificationToTopic();
  }

  function resetAndShowTarget(){
    setShowTarget(!showTarget)
    setShowTriggers(true);
    setTargetSelected(false)
  }

  function setHomeScreenData(){
    let val = {key:"HomeScreen",name:"HomeScreen"}
    settargetName(val);
    setTriggerName("HomeScreen");
  }


  return (
    <div>
      <Box display="flex" flexDirection="column" m="20px" marginBottom="20px">
        {/* HEADER */}
        <Box display="flex" justifyContent="space-between" alignItems="center">
          <Header title="Notification" subtitle="Brand Bite" />
        </Box>

        <Box display={showTarget ? "flex" : "none"} position="fixed" margin="auto" backgroundColor={colors.primary[500]} width="500px" height="300px" borderRadius="10px" top="20%" left="35%" zIndex="1" justifyContent="center" alignItems="center">

          <Box position="absolute" right="0" top="0" display="flex" zIndex="100">
            <Button onClick={() => { setShowTarget(!showTarget) }}><CloseRounded sx={{
              color: colors.primary[100], '&:hover': {
                color: 'lightgreen'
              }
            }} /></Button>
          </Box>

          <Box display={showTriggers?"inline-flex":"none"} >
            <Box display="flex" bgcolor="blue" borderRadius="20px" p="5px" mr="10px" className='thisisbtn' onClick={()=>{loadData("Category")}}>
              <Typography>Category</Typography>
            </Box>
            <Box display="flex" bgcolor="blue" borderRadius="20px" p="5px" mr="10px" className='thisisbtn' onClick={()=>{loadData("Outlet")}}>
              <Typography>Outlet</Typography>
            </Box>
            <Box display="flex" bgcolor="blue" borderRadius="20px" p="5px" mr="10px" className='thisisbtn' onClick={()=>{loadData("Custom")}}>
              <Typography>Custom</Typography>
            </Box>
            <Box display="flex" bgcolor="blue" borderRadius="20px" p="5px" mr="10px" className='thisisbtn' onClick={()=>{loadData("Video")}}>
              <Typography>Video</Typography>
            </Box>
            <Box display="flex" bgcolor="blue" borderRadius="20px" p="5px" mr="10px" className='thisisbtn' onClick={()=>{loadData("General")}}>
              <Typography>General</Typography>
            </Box>
            <Box display="flex" bgcolor="blue" borderRadius="20px" p="5px" mr="10px" className='thisisbtn' onClick={setHomeScreenData}>
              <Typography>Home Screen</Typography>
            </Box>
          </Box>

          <Box display={targetSelected ? "flex" : "none"} width="490px" height="290px"  justifyContent="center" alignItems="center" flexDirection="column" backgroundColor={colors.primary[500]} borderRadius="20px">
                <Box display="flex" marginBottom="20px">
                    <Typography color="white">Trigger {triggerName}</Typography>
                </Box>
                <Box width="350px" height="250px" overflow="auto" >
                <List >
                    {
                        listNames.map((val, index) => (
                            <ListItem key={val.key} sx={{ '&:hover': { background: "#3b9a8c" ,userSelect:"none"} }} onClick={() => settargetName(val)}>
                                <ListItemText primary={val.name} sx={{color:"white"}}/>
                            </ListItem>
                        ))
                    }
                </List>
                </Box>
            </Box>

        </Box>


        <input accept="image/*" type="file" onChange={handleImageSelect} ref={imageInput} style={{ display: "none" }} />

        <Box display="flex" flexDirection="column" mt="50px" >
          <Box display="flex" >
            <TextField autoFocus autoComplete="off" label="Title" value={title} onChange={(event) => {
              setTitle(event.target.value.charAt(0).toUpperCase() + event.target.value.slice(1))
            }} sx={inputStyle} />
          </Box>
          <Box display="flex" mt="20px">
            <TextField multiline  autoComplete="off" label="Message" value={message} onChange={(event) => {
              setMesaage(event.target.value.charAt(0).toUpperCase() + event.target.value.slice(1))
            }} sx={inputStyle} />
          </Box>

          <Box display="flex" mt="20px" className='thisisbtn'>
            <Box display="inline-flex" bgcolor="blue" borderRadius="20px" onClick={() => {
              imageInput.current.click();
            }}>
              <Typography display="flex" justifyContent="center" alignItems="center" p="10px" color="white"><AddCircleOutlineRounded sx={{ mr: "5px" }} />Select Image (Optional) </Typography>
            </Box>
            <Box display="flex" bgcolor="blue" borderRadius="20px" p="10px" ml="10px" onClick={() => { resetAndShowTarget() }}>
              <Typography color="white">Select Target</Typography>
            </Box>
          </Box>

          <Box display="flex" mt="20px">
            <Box display="inline-flex" justifyContent="center" alignItems="center">
              <Box display="inline-flex" bgcolor={targetedUser == "all" ? "blue" : "rgb(80,84,104)"} borderRadius="20px" p="5px 15px" mr="5px" className='thisisbtn' onClick={() => { selectTargetedUser("all") }}>
                <Typography color="white">All </Typography>
              </Box>
              <Box display="inline-flex" bgcolor={targetedUser == "free" ? "blue" : "rgb(80,84,104)"} borderRadius="20px" p="5px 15px" mr="5px" className='thisisbtn' onClick={() => { selectTargetedUser("free") }}>
                <Typography color="white">Free </Typography>
              </Box>
              <Box display="inline-flex" bgcolor={targetedUser == "premium" ? "blue" : "rgb(80,84,104)"} borderRadius="20px" p="5px 15px" mr="5px" className='thisisbtn' onClick={() => { selectTargetedUser("premium") }}>
                <Typography color="white">Premium </Typography>
              </Box>
            </Box>
          </Box>
            
          <Box display="flex" >
            <Box display="inline-flex" bgcolor="blue" borderRadius="20px" p="5px 10px" mt="20px" className='thisisbtn' onClick={()=>{pushNotification()}}>
              <Typography color="white">Push Notification</Typography>
            </Box>
          </Box>

        </Box>
      </Box>
    </div>
  )
}

export default Notification
