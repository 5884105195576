import { Avatar, Box, makeStyles, TextField, Typography } from "@mui/material";
import React, { useEffect, useMemo, useRef } from "react";
import { useState } from "react";



const NewText = (props) => {
    const [position, setPosition] = useState({ x:100, y:100 });
    const [posX, setPosX] = useState(150);
    const [posY, setPosY] = useState(150);
    const [cor, setCor] = useState({X:0,y:0 });
    const [isDragging, setIsDragging] = useState(false);
    const text = props.element[props.index];





    async function update() {
      setTimeout(() =>{
          props.onTextChange(text);
      },1)
  }

  const handleMouseDown = (event) => {
      document.addEventListener('mousemove', handleMouseMove);
      update();
      event.preventDefault();
      setIsDragging(true);
      document.addEventListener('mouseup', handleMouseUp);
      update();
  }

  

  const handleMouseMove = async (event) => {
      if (isDragging) {
          
          const x = parseInt(posX + event.movementX * 1.6);
          const y = parseInt(posY + event.movementY * 1.6);
              setPosX(x);
              text.left = x;
              setPosY(y);
              text.top = y;
      }
  }

  const handleMouseUp = (event) => {      
      update();
      event.preventDefault();
      setIsDragging(false)
      document.removeEventListener('mouseup', handleMouseUp);
      document.removeEventListener('mousemove', handleMouseMove);
      update();
  }
  
    return (
    
       <Box key={text.text+text.index} border={isDragging?"2px solid white":"none"} onMouseDown={handleMouseDown} onMouseMove={handleMouseMove}  onMouseUp={handleMouseUp} sx={{userSelect:"none",cursor:"pointer",position:"absolute",left:text.left + "px",top: text.top + "px"}} display="flex" justifyContent="center" alignItems="center" >
        <Typography textAlign={text.fontGravity} color={text.fontColor} noWrap sx={{fontFamily:text.fontFamily,fontSize:text.fontSize+"px",fontWeight:text.fontWeight,textDecoration:text.fontUnderline?"underline":"",fontStyle:text.fontItalic?'italic':"none"}} onDoubleClick={() => {props.editText(text)}} >
        {text.text.split("\n").map((line, index) => {
          return (
            <React.Fragment key={index}>
              {line}
              <br />
            </React.Fragment>
          );
        })}
      </Typography>
       </Box>
     
    );
  };

export default NewText;
