import React, { useEffect, useMemo, useRef, useState } from "react";
import axios from "axios";
import {
  Alert,
  Avatar,
  Box,
  Button,
  CircularProgress,
  List,
  ListItem,
  ListItemText,
  TextField,
  Typography,
  useTheme,
} from "@mui/material";
import Header from "../../components/Header";
import { tokens } from "../../theme";
import { AddCircleOutlineRounded, CloseRounded } from "@mui/icons-material";
import { onValue, ref } from "firebase/database";
import { database, storage, writedb } from "../../firebase-config";

const PopUpScreen = () => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);

  const [title, setTitle] = useState("");
  const [message, setMesaage] = useState("");
  const [topic, setTopic] = useState("");
  const imageInput = useRef(null);
  const [image, setImage] = useState("");
  const [imageSelected, setImageSelected] = useState(false);
  const [listNames, setListNames] = useState([]);
  const [targetedUser, selectTargetedUser] = useState("all");
  const [showTarget, setShowTarget] = useState(false);
  const [showTriggers, setShowTriggers] = useState(true);
  const [triggerName, setTriggerName] = useState("");
  const [targetSelected, setTargetSelected] = useState("");
  const [targetKeyName, setTargetKeyName] = useState({ key: "", name: "" });
  const [uploading, setUploading] = useState(false);
  const [imageUrl, setImageurl] = useState("");
  const handleImageSelect = (event) => {
    if (event.target.files[0]) {
      const files = event.target.files;
      setImage(Array.from(files)[0]);
      setImageSelected(true);
    } else {
      alert("Image not selected");
    }
  };

  useEffect(() => {
    writedb
      .ref("popUpData/image")
      .once("value")
      .then((snapshot) => {
        if (snapshot.exists()) {
          setImageurl(snapshot.val());
        }
      });
  });

  const settargetName = (val) => {
    setTargetKeyName(val);
    setShowTarget(false);
  };

  const loadData = (name) => {
    setShowTriggers(false);
    var path;
    if (name == "Category") {
      setTriggerName("category");
      path = "homeUniquekeys/category";
    } else if (name == "Outlet") {
      setTriggerName("outlet");
      path = "homeUniquekeys/outlet";
    } else if (name == "Video") {
      setTriggerName("categoryvideo");
      path = "videoUniquekeys/category";
    } else if (name == "General") {
      setTriggerName("General");
      path = "generalUniquekeys/categoryGeneral";
    } else {
      setTriggerName("Custom");
      path = "homeUniquekeys/custom";
    }
    onValue(ref(database, path), (snapshot) => {
      listNames.length = 0;
      let names = [];

      snapshot.forEach((snapshot) => {
        const data = snapshot.val();
        const name = snapshot.key;
        const key = Object.values(data)[0];
        names.push({ key: key, name: name });
      });
      setTargetSelected(true);
      setListNames(names);
    });
  };

  function pushpopup() {
    if (!imageSelected) {
      alert("Image not selected");
      return;
    }
    if (targetKeyName.key == "" && targetKeyName.name == "") {
      alert("Please select a Target");
      return;
    }
    setUploading(true);
    let storageRef = storage.ref(`popUpImageDialogBox`);
    const task = storageRef.child("image").put(image);
    task.then(() => {
      storageRef
        .child("image")
        .getDownloadURL()
        .then((url) => {
          writedb
            .ref("popUpData")
            .set({
              image: url,
              targetKey: targetKeyName.key,
              target: targetKeyName.name,
              triggerName: triggerName,
            })
            .then(() => {
              setUploading(false);
            });
        });
    });
  }

  function resetAndShowTarget() {
    setShowTarget(!showTarget);
    setShowTriggers(true);
    setTargetSelected(false);
  }

  function setHomeScreenData() {
    let val = { key: "HomeScreen", name: "HomeScreen" };
    settargetName(val);
    setTriggerName("HomeScreen");
  }

  function setPremiumScreenData() {
    let val = { key: "PremiumScreen", name: "PremiumScreen" };
    settargetName(val);
    setTriggerName("PremiumScreen");
  }

  return (
    <div>
      <Box display="flex" flexDirection="column" m="20px" marginBottom="20px">
        {/* HEADER */}
        <Box display="flex" justifyContent="space-between" alignItems="center">
          <Header title="Pop Up Image" subtitle="Brand Bite" />
        </Box>

        <Box
          display={showTarget ? "flex" : "none"}
          position="fixed"
          margin="auto"
          backgroundColor={colors.primary[500]}
          width="500px"
          height="300px"
          borderRadius="10px"
          top="20%"
          left="35%"
          zIndex="1"
          justifyContent="center"
          alignItems="center"
        >
          <Box
            position="absolute"
            right="0"
            top="0"
            display="flex"
            zIndex="100"
          >
            <Button
              onClick={() => {
                setShowTarget(!showTarget);
              }}
            >
              <CloseRounded
                sx={{
                  color: colors.primary[100],
                  "&:hover": {
                    color: "lightgreen",
                  },
                }}
              />
            </Button>
          </Box>

          <Box display={showTriggers ? "inline-flex" : "none"}>
            <Box
              display="flex"
              bgcolor="blue"
              borderRadius="20px"
              p="5px"
              mr="10px"
              className="thisisbtn"
              onClick={() => {
                loadData("Category");
              }}
            >
              <Typography>Category</Typography>
            </Box>
            <Box
              display="flex"
              bgcolor="blue"
              borderRadius="20px"
              p="5px"
              mr="10px"
              className="thisisbtn"
              onClick={() => {
                loadData("Outlet");
              }}
            >
              <Typography>Outlet</Typography>
            </Box>
            <Box
              display="flex"
              bgcolor="blue"
              borderRadius="20px"
              p="5px"
              mr="10px"
              className="thisisbtn"
              onClick={() => {
                loadData("Custom");
              }}
            >
              <Typography>Custom</Typography>
            </Box>
            <Box
              display="flex"
              bgcolor="blue"
              borderRadius="20px"
              p="5px"
              mr="10px"
              className="thisisbtn"
              onClick={() => {
                loadData("Video");
              }}
            >
              <Typography>Video</Typography>
            </Box>
            <Box
              display="flex"
              bgcolor="blue"
              borderRadius="20px"
              p="5px"
              mr="10px"
              className="thisisbtn"
              onClick={() => {
                loadData("General");
              }}
            >
              <Typography>General</Typography>
            </Box>
            {/* <Box
              display="flex"
              bgcolor="blue"
              borderRadius="20px"
              p="5px"
              mr="10px"
              className="thisisbtn"
              onClick={setHomeScreenData}
            >
              <Typography>Home Screen</Typography>
            </Box> */}
            <Box
              display="flex"
              bgcolor="blue"
              borderRadius="20px"
              p="5px"
              mr="10px"
              className="thisisbtn"
              onClick={setPremiumScreenData}
            >
              <Typography>Premium Screen</Typography>
            </Box>
          </Box>

          <Box
            display={targetSelected ? "flex" : "none"}
            width="490px"
            height="290px"
            justifyContent="center"
            alignItems="center"
            flexDirection="column"
            backgroundColor={colors.primary[500]}
            borderRadius="20px"
          >
            <Box display="flex" marginBottom="20px">
              <Typography color="white">Trigger {triggerName}</Typography>
            </Box>
            <Box width="350px" height="250px" overflow="auto">
              <List>
                {listNames.map((val, index) => (
                  <ListItem
                    key={val.key}
                    sx={{
                      "&:hover": { background: "#3b9a8c", userSelect: "none" },
                    }}
                    onClick={() => settargetName(val)}
                  >
                    <ListItemText primary={val.name} sx={{ color: "white" }} />
                  </ListItem>
                ))}
              </List>
            </Box>
          </Box>
        </Box>

        <input
          accept="image/*"
          type="file"
          onChange={handleImageSelect}
          ref={imageInput}
          style={{ display: "none" }}
        />

        <Box display="flex" flexDirection="column" mt="20px">
          <Box display="flex" className="thisisbtn">
            <Box
              display="inline-flex"
              bgcolor="blue"
              borderRadius="20px"
              onClick={() => {
                imageInput.current.click();
              }}
            >
              <Typography
                display="flex"
                justifyContent="center"
                alignItems="center"
                p="10px"
                color="white"
              >
                <AddCircleOutlineRounded sx={{ mr: "5px" }} />
                Select Image
              </Typography>
            </Box>
            <Box
              display="flex"
              bgcolor="blue"
              borderRadius="20px"
              p="10px"
              ml="10px"
              onClick={() => {
                resetAndShowTarget();
              }}
            >
              <Typography color="white">Select Target</Typography>
            </Box>
          </Box>

          <Box display={imageUrl.length > 0
                  ? "flex"
                  : imageSelected
                  ? "flex"
                  : "none"} mt="20px">
            <Avatar
              src={
                imageSelected 
                  ?  URL.createObjectURL(image)
                  : imageUrl.length > 0
                  ? imageUrl
                  : ""
              }
              sx={{
                width: 300,
                height: 400,
                marginRight: "15px",
                marginBottom: "10px",
                borderRadius: "2%",
                cursor: "pointer",
                "& > img": {
                  objectFit: "fill",
                  width: "100%",
                  height: "100%",
                },
                ":hover": { bgcolor: "white" },
              }}
            ></Avatar>
          </Box>

          <Box display="flex" mt="10px">
            <Box
              display="inline-flex"
              justifyContent="center"
              alignItems="center"
            >
              {!uploading ? (
                <Box
                  display="inline-flex"
                  bgcolor="blue"
                  borderRadius="20px"
                  p="5px 10px"
                  className="thisisbtn"
                  onClick={() => {
                    pushpopup();
                  }}
                >
                  <Typography color="white">Push Pop Up</Typography>
                </Box>
              ) : (
                <Box
                  display="inline-flex"
                  bgcolor="blue"
                  borderRadius="20px"
                  p="5px 10px"
                  className="thisisbtn"
                >
                  <CircularProgress
                    style={{
                      height: "25px",
                      width: "25px",
                      padding: "2px",
                      color: "white",
                    }}
                  />
                </Box>
              )}
              {!uploading && imageUrl.length > 0 ? (
                <Box
                  display="inline-flex"
                  bgcolor="blue"
                  borderRadius="20px"
                  p="5px 10px"
                  ml="10px"
                  className="thisisbtn"
                  onClick={async () => {
                    const confirmed = window.confirm(
                      `Are you sure to delete this pop up?`
                    );
                    if (confirmed) {
                      await storage.refFromURL(imageUrl).delete();
                      writedb.ref("popUpData").remove();
                      setImageurl("");
                    }
                  }}
                >
                  <Typography color="white">Delete Pop Up</Typography>
                </Box>
              ) : (
                <Box></Box>
              )}
            </Box>
          </Box>
        </Box>
      </Box>
    </div>
  );
};

export default PopUpScreen;
