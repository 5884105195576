import { useTheme } from "@emotion/react";
import React from "react";
import { tokens } from "../../theme";
import {
  Box,
  Button,
  Grid,
  Input,
  Modal,
  TextField,
  Typography,
} from "@mui/material";
import Header from "../../components/Header";
import { AddCircleOutline, South } from "@mui/icons-material";
import { useState } from "react";
import { database, writedb } from "../../firebase-config";
import { useMemo } from "react";
import { onValue, ref } from "firebase/database";
import { number } from "yup";

const Coupons = () => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);

  const [open, setOpen] = useState(false);
  const [showData, setShowData] = useState(false);
  const [couponPrice, setCouponPrice] = useState("");
  const [couCode, setRefCode] = useState("");
  const [couponList, setSalesManList] = useState([]);
  const [salesDataArr, setSalesDataArr] = useState([]);
  const [isCopied, setIsCopied] = useState(false);
  const [arr1Month, setArr1Month] = useState([]);
  const [arr6Month, setArr6Month] = useState([]);
  const [arr12Month, setArr12Month] = useState([]);
  useMemo(() => {
    onValue(ref(database, `couponCodes`), (snapshot) => {
      if (snapshot.exists()) {
        const snapVal = snapshot.val();
        let keys = Object.keys(snapVal);
        let values = Object.values(snapVal);
        let arr = [];
        if (snapshot.child("data").exists()) {
        }
        values.forEach((val, index) => {
          if (val.hasOwnProperty("data")) {
            let price = val.price;
            let key = keys[index];
            let total = 0;
            let arr1 = [];
            let arr6 = [];
            let arr12 = [];
            if (val.data.hasOwnProperty("month1")) {
              total = total + Object.keys(val.data.month1).length;
              arr1 = Object.keys(val.data.month1);
            }
            if (val.data.hasOwnProperty("month6")) {
              total = total + Object.keys(val.data.month6).length;
              arr6 = Object.keys(val.data.month6);
            }
            if (val.data.hasOwnProperty("month12")) {
              total = total + Object.keys(val.data.month12).length;
              arr12 = Object.keys(val.data.month12);
            }
            let newItem = {
              price: price,
              ref: key,
              total: total,
              month1: arr1,
              month6: arr6,
              month12: arr12,
            };
            arr.push(newItem);
          } else if (val.hasOwnProperty("price")) {
            let price = val.price;
            let key = keys[index];
            let newItem = {
              price: price,
              ref: key,
              total: 0,
              month1: [],
              month6: [],
              month12: [],
            };
            arr.push(newItem);
          }
        });
        setSalesManList(arr.reverse());
      }
    });
  }, [couponList.length]);

  const handleClose = () => {
    setOpen(false);
    setShowData(false);
  };

  const inputStyle = {
    "& label.Mui-focused": {
      color: "aqua",
    },
    "& .MuiInput-underline:after": {
      borderBottomColor: "white",
    },
    "& .MuiOutlinedInput-root": {
      "& fieldset": {
        borderColor: "gray",
        height: "45px",
      },
      "&:hover fieldset": {
        borderColor: "white",
      },
      "&.Mui-focused fieldset": {
        borderColor: "rgb(155,155,255)",
      },
      "& .MuiInputBase-input": {
        color: "white",
        fontSize: "16px",
        height: "7px",
      },
    },
  };

  const generateReferCode = () => {
    const referCodeLength = 6;
    const characters = "ABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789";
    let referCode = "";

    for (let i = 0; i < referCodeLength; i++) {
      const randomIndex = Math.floor(Math.random() * characters.length);
      referCode += characters.charAt(randomIndex);
    }

    return referCode;
  };

  const handelOpen = () => {
    setOpen(!open);
    setRefCode(generateReferCode);
  };

  async function addCouponCode() {
    if (couponPrice.length > 0) {
      let check = await writedb.ref(`couponCodes/${couCode}`).once("value");
      if (check.exists()) {
        alert("Coupon Code Already Exist");
      } else {
        var finalPrice = parseInt(couponPrice, 10);
        writedb
          .ref(`couponCodes/${couCode}`)
          .set({ price: finalPrice })
          .then(() => {
            handleClose();
            setCouponPrice("");
          });
      }
    } else {
      alert("Please Enter coupon price");
    }
  }

  function deleteCoupon(key) {
    let check = window.confirm("Are you sure to delete this coupon?");
    if (check) {
      writedb.ref(`couponCodes/${key}`).remove();
    }
  }

  function showSalesData(val) {
    setArr1Month(val.month1);
    setArr6Month(val.month6);
    setArr12Month(val.month12);
    setShowData(!showData);
  }

  const copyToClipboard = (val) => {
    try {
      navigator.clipboard.writeText(val);
      setIsCopied(true);

      // Reset the isCopied state after 2 seconds to hide the animation
      setTimeout(() => {
        setIsCopied(false);
      }, 2000);
    } catch (error) {
      alert("Copying to clipboard failed. Please try again.");
    }
  };

  return (
    <Box display="flex" flexDirection="column" m="20px" marginBottom="20px">
      <Box display="flex" justifyContent="space-between" alignItems="center">
        <Header title="Coupons" subtitle="Welcome to Brand Bite" />
      </Box>

      <Box display="flex" borderRadius="10px">
        <Box>
          <Button
            onClick={handelOpen}
            sx={{
              marginRight: "10px",
              backgroundColor: "blue",
              borderRadius: "20px",
              pr: "10px",
            }}
          >
            <Typography
              display="flex"
              textAlign="center"
              fontSize="18px"
              textTransform="none"
              color="white"
              alignItems="center"
            >
              <AddCircleOutline sx={{ marginRight: "8px" }} />
              Add new Coupon
            </Typography>
          </Button>
        </Box>
      </Box>

      <Modal open={open} onClose={handleClose}>
        <Box
          display="flex"
          flexDirection="column"
          justifyContent="center"
          alignItems="center"
          bgcolor="#727681"
          p={3}
        >
          <Box display="flex">
            <TextField
              autoFocus
              autoComplete="off"
              label="Price"
              type="number"
              value={couponPrice}
              onChange={(event) => {
                if (event.target.value.length < 6) {
                  setCouponPrice(event.target.value);
                }
              }}
            />
            <Box display="flex" ml="10px">
              <TextField
                autoComplete="off"
                label="Coupon Code"
                value={couCode}
                onChange={(event) => {
                  if (event.target.value.length < 7) {
                    setRefCode(event.target.value.toUpperCase());
                  }
                }}
              />
            </Box>
          </Box>

          <Box mt="15px">
            <Button
              variant="contained"
              color="primary"
              sx={{ mr: "10px" }}
              onClick={addCouponCode}
            >
              Add
            </Button>
            <Button variant="contained" color="primary" onClick={handleClose}>
              Close
            </Button>
          </Box>
        </Box>
      </Modal>

      <Modal open={showData} onClose={handleClose}>
        <Box
          display="flex"
          flexDirection="column"
          justifyContent="center"
          alignItems="center"
          bgcolor="#727681"
          minHeight="200px" // Use minHeight to prevent the modal from collapsing too much
          maxHeight="400px"
          p={3}
        >
          <Box
            display="flex"
            flexDirection="row"
            overflow="auto" // Add scrollable container to handle overflow
            pr="10px"
          >
            <Typography display={arr1Month.length > 0 || arr6Month.length > 0 || arr12Month.length > 0 ? "none" : "flex"} fontSize="20px">No Record found</Typography>
            <Box display={arr1Month.length > 0 ? "flex" : "none"} flexDirection="column" justifyContent="center" alignItems="center">
            <Typography fontSize="17px">1 Month</Typography>
              {arr1Month.map((val, index) => (
                <Box
                  key={index}
                  display="flex"
                  flexDirection="column"
                  onClick={() => copyToClipboard(val)}
                >
                  <Button>
                    <Typography fontSize="18px" color="white">
                      {val}
                    </Typography>
                  </Button>
                </Box>
              ))}
            </Box>
            <Box display={arr6Month.length > 0 ? "flex" : "none"} flexDirection="column" justifyContent="center" alignItems="center">
            <Typography fontSize="17px">6 Months</Typography>
              {arr6Month.map((val, index) => (
                <Box
                  key={index}
                  display="flex"
                  flexDirection="column"
                  onClick={() => copyToClipboard(val)}
                >
                  <Button>
                    <Typography fontSize="18px" color="white">
                      {val}
                    </Typography>
                  </Button>
                </Box>
              ))}
            </Box>
            <Box display={arr12Month.length > 0 ? "flex" : "none"} flexDirection="column" justifyContent="center" alignItems="center">
              <Typography fontSize="17px">12 Months</Typography>
              {arr12Month.map((val, index) => (
                <Box
                  key={index}
                  display="flex"
                  flexDirection="column"
                  onClick={() => copyToClipboard(val)}
                >
                  <Button>
                    <Typography fontSize="18px" color="white">
                      {val}
                    </Typography>
                  </Button>
                </Box>
              ))}
            </Box>
          </Box>

          {isCopied && <div className="copy-animation">Copied!</div>}

          <Box mt="15px" pr="10px">
            <Button variant="contained" color="primary" onClick={handleClose}>
              Close
            </Button>
          </Box>
        </Box>
      </Modal>

      <Box display="flex" mt="20px">
        <Grid container spacing={4} justifyContent="start">
          {couponList.map((val, index) => (
            <Grid item key={val.ref}>
              <Box>
                <Button
                  variant="contained"
                  color="primary"
                  sx={{
                    background: colors.primary[400],
                    "&:hover": {
                      backgroundColor: colors.grey[500],
                    },
                  }}
                  onClick={() => {
                    showSalesData(val);
                  }}
                >
                  <Box
                    display="flex"
                    flexDirection="column"
                    height="230px"
                    width="230px"
                    justifyContent="start"
                    alignItems="center"
                  >
                    <Box color={colors.bwTextColor[100]}>
                      <Typography mt="5px" fontSize="20px">
                        {val.ref}
                      </Typography>
                      <Typography fontSize="20px">{val.price + "₹"}</Typography>
                      <Typography fontSize="30px" mt="25px">
                        {val.total}
                      </Typography>
                      <Typography mt="10px">Times Applied</Typography>
                    </Box>
                  </Box>
                </Button>
                <Box display="flex" justifyContent="center" mt="-32px">
                  <Button
                    onClick={() => {
                      deleteCoupon(val.ref);
                    }}
                    sx={{ color: "white" }}
                  >
                    Delete
                  </Button>
                </Box>
              </Box>
            </Grid>
          ))}
        </Grid>
      </Box>
    </Box>
  );
};

export default Coupons;
