import { useTheme } from '@emotion/react';
import React, { useEffect } from 'react'
import { tokens } from '../../theme';
import { Box, Button, IconButton, TextField, Typography } from '@mui/material';
import { useState } from 'react';
import Header from '../../components/Header';
import { database, writedb } from '../../firebase-config';
import { AddCircleOutlineOutlined, DeleteOutline, DeleteRounded, LineAxisOutlined, LineAxisSharp, LineStyle, LineStyleOutlined, LineStyleRounded, LineWeightRounded } from '@mui/icons-material';
import { onValue, ref } from 'firebase/database';
import { useMemo } from 'react';
import { LineCanvas } from '@nivo/line';






function TreeNode({ nodeId, childNodes, level = 0 }) {
    const [expanded, setExpanded] = useState(false);
    const [hoveredId, setHoveredId] = useState(null);

    const toggleExpanded = () => {
        setExpanded((prevExpanded) => !prevExpanded);
    };

    const handleMouseEnter = (id) => {
        setHoveredId(id);
    };

    const handleMouseLeave = () => {
        setHoveredId(null);
    };

    function deleteSearch(path) {
        writedb.ref("search").child(path).remove();
    }

    return (
        <Box>
            <Box display="inline-flex">
                <Typography
                    onClick={toggleExpanded}
                    style={{
                        marginLeft: level * 40 + "px",
                        cursor: "pointer",
                        userSelect: "none",
                        fontSize: "20px",
                    }}
                >
                    {expanded ? "▼" : "▶"} {nodeId}
                </Typography>
            </Box>
            {expanded && (
                <ul>
                    {childNodes &&
                        Object.entries(childNodes).map(([key, value]) => (
                            <li key={key} style={{ listStyleType: "none" }}>
                                {typeof value === "object" ? (
                                    <TreeNode
                                        nodeId={key}
                                        childNodes={value}
                                        level={level + 1}
                                    />
                                ) : (
                                    <Box
                                        display="flex"
                                        alignItems="center"
                                        onMouseEnter={() => handleMouseEnter(key)}
                                        onMouseLeave={handleMouseLeave}
                                        paddingRight="20px"
                                    >
                                        <Typography
                                            style={{
                                                marginLeft: (level + 1) * 40 + "px",
                                                fontSize: "17px",
                                            }}
                                        >
                                            ⎯&nbsp;&nbsp; {value}
                                        </Typography>
                                        {hoveredId === key && (
                                            <IconButton onClick={() => { deleteSearch(nodeId + "/" + key) }}>
                                                <DeleteOutline />
                                            </IconButton>
                                        )}
                                    </Box>
                                )}
                            </li>
                        ))}
                </ul>
            )}
        </Box>
    );
}









const Search = () => {
    const theme = useTheme();
    const colors = tokens(theme.palette.mode);

    const [showAddSearch, setShowAddSearch] = useState(false);
    const [textName, setTextName] = useState("");
    const [inputs, setInputs] = useState([{ value: "" }]);
    const [searchData, setSearchData] = useState(null)
    const [searchAv, setSearchAV] = useState(0);
    const [expanded, setExpanded] = useState(false);

    const toggleExpanded = () => {
        setExpanded((prevExpanded) => !prevExpanded);
    };

    const inputStyle = {
        '& label.Mui-focused': {
            color: 'aqua',
        },
        '& .MuiInput-underline:after': {
            borderBottomColor: 'white',
        },
        '& .MuiOutlinedInput-root': {
            '& fieldset': {
                borderColor: 'gray',
            },
            '&:hover fieldset': {
                borderColor: 'white',
            },
            '&.Mui-focused fieldset': {
                borderColor: 'rgb(155,155,255)',
            }, '& .MuiInputBase-input': {

                width: "220px",
                color: 'white',
                fontSize: "16px",

            },
        }
    }


    const addSearchToServer = (list) => {
        writedb.ref("search").child(textName).set(list).then(() => {
            alert("search added")
        })
    }

    const checkAllInputs = () => {
        const allFieldsFilled = inputs.every((input) => input.value.trim() !== '');
        if (allFieldsFilled) {
            // perform action
            const values = inputs.map((input) => input.value.toString());
            addSearchToServer(values);
        } else {
            // show error message
            alert("Please fill all the inputs")
        }
        // addSearchToServer();
    }



    const handleInputChange = (index, event) => {
        const values = [...inputs];
        values[index].value = event.target.value;
        setInputs(values);
    };

    const handleAddInput = () => {
        const values = [...inputs];
        values.push({ value: "" });
        setInputs(values);
    };

    const handleRemoveInput = (index) => {
        const values = [...inputs];
        values.splice(index, 1);
        setInputs(values);
    };


    useMemo(() => {


        onValue(ref(database, 'search'), (snapshot) => {
            if (snapshot.exists()) {
                const snapVal = snapshot.val();
                setSearchData(snapVal);
                setShowAddSearch(false)
            } else {
                setSearchData(null);
                setShowAddSearch(true);
            }
        })

    }, [searchAv])


    return (
        <Box height="100%" display="flex" flexDirection="column">
            <Box className="flex-col hide-600px m20" justifyContent="space-between" alignItems="start" >
                {/* <Header title="DASHBOARD" subtitle="Welcome to your dashboard" /> */}
                <Header title="SEARCH" subtitle="Welcome to Brand Bite" /></Box>
            <Box className="hide show-600px m20">
                <Typography fontSize="30px" fontWeight="700">BrandBite</Typography>

            </Box>

            {/* <Box>
                {searchData.map(val => {
                    {console.info(val)}
                    {searchData && <TreeNode nodeId="root" childNodes={val} />}
                })}
            </Box> */}

            <Box display="inline-flex" ml="30px" mt="-10px" mb="10px" onClick={()=>{setShowAddSearch(!showAddSearch)}}>
                <Box  display="inline-flex" bgcolor="blue" borderRadius="15px">
                <Button>
                    <AddCircleOutlineOutlined  style={{ fontSize: "20px",color:"white",display:showAddSearch ? "none" : "flex" }} />
                    <LineWeightRounded  style={{ fontSize: "20px",color:"white",display:showAddSearch ? "flex" : "none" }} />
                </Button>
                
                </Box>
            </Box>

            <Box display={showAddSearch ? "none" : "flex"} height="600px"  ml="10px" >

                <Box display="inline-flex"  >
                    {searchData != null && (
                        <TreeNode nodeId="Search Tree" childNodes={searchData} />
                    )}
                </Box>

            </Box>




            <Box display={showAddSearch ? "flex" : "none"} height="100%" width="100%" justifyContent="center" alignItems="center"    >
                <Box display="flex" flexDirection="column" overflow="auto" maxHeight="600px" p="20px">
                    <TextField autoFocus autoComplete="off" label="Text" value={textName} onChange={(event) => {
                        setTextName(event.target.value.charAt(0).toUpperCase() + event.target.value.slice(1))
                    }} sx={inputStyle} />


                    {inputs.map((input, index) => (
                        <Box key={index + "searchLabel"} mt="20px" display="flex" alignItems="center">
                            <TextField autoFocus autoComplete="off" label="Replace With" value={input.value} onChange={(event) => handleInputChange(index, event)}
                                sx={inputStyle} />

                            <Box display={index == 0 ? "none" : "flex"} ml="10px" onClick={() => handleRemoveInput(index)}>
                                <Typography fontSize="20px" display="flex" justifyContent="center" color="white">
                                    <DeleteRounded style={{ fontSize: 20, cursor: 'pointer' }} />
                                </Typography>
                            </Box>
                        </Box>
                    ))}
                    <Box display="flex" mt="15px" justifyContent="center">
                        <Box display="flex" p="5px">
                            <Button onClick={handleAddInput} sx={{ color: "white", bgcolor: "blue", width: "100%", borderRadius: "15px" }}>
                                <Typography fontSize="20px" display="flex" justifyContent="center" color="white">
                                    <AddCircleOutlineOutlined style={{ fontSize: 20 }} />
                                </Typography>
                            </Button>

                        </Box>
                    </Box>
                    <Box display="flex" justifyContent="center" mt="15px" >
                        <Button sx={{ color: "white", bgcolor: "blue", width: "100%", borderRadius: "15px" }} onClick={checkAllInputs} >
                            Add
                        </Button>
                    </Box>
                </Box>
            </Box>
        </Box>
    )
}

export default Search
