import { useMemo, useState } from "react";
import { ProSidebar, Menu, MenuItem } from "react-pro-sidebar";
import { Box, IconButton, Popover, Popper, Typography, useTheme } from "@mui/material";
import { Link, useLocation } from "react-router-dom";
import "react-pro-sidebar/dist/css/styles.css";
import { tokens } from "../../theme";
import HomeRoundedIcon from "@mui/icons-material/HomeRounded";
import PersonRoundedIcon from "@mui/icons-material/PersonRounded";
import PieChartOutlineOutlinedIcon from "@mui/icons-material/PieChartOutlineOutlined";
import MenuOutlinedIcon from "@mui/icons-material/MenuOutlined";
import CategoryIcon from "@mui/icons-material/Category";
import SignalCellularAltIcon from "@mui/icons-material/SignalCellularAlt";
import RectangleRoundedIcon from "@mui/icons-material/RectangleRounded";
import AccountBalanceRoundedIcon from "@mui/icons-material/AccountBalanceRounded";
import NotificationsIcon from "@mui/icons-material/Notifications";
import SentimentSatisfiedAltIcon from "@mui/icons-material/SentimentSatisfiedAlt";
import {
  Analytics,
  AnalyticsOutlined,
  AnalyticsSharp,
  BarChartOutlined,
  CropDinOutlined,
  CurrencyBitcoin,
  CurrencyRupee,
  Discount,
  ImageOutlined,
  LanguageOutlined,
  LocalOffer,
  LocalOfferSharp,
  Money,
  MoreHorizOutlined,
  RestaurantMenuOutlined,
  SearchOffOutlined,
  SearchOutlined,
  SquareFootOutlined,
  TrendingUp,
  VideoCameraBackRounded,
  VideocamRounded,
  VideocamTwoTone,
  WallpaperRounded,
} from "@mui/icons-material";

const Item = ({ title, to, icon, selected, setSelected }) => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);

  return (
    <MenuItem
      active={selected === title}
      style={{
        color: colors.grey[100],
      }}
      onClick={() => setSelected(title)}
      icon={icon}
    >
      <Typography>{title}</Typography>
      <Link to={to} />
    </MenuItem>
  );
};

const Sidebar = () => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const [isCollapsed, setIsCollapsed] = useState(false);
  const [selected, setSelected] = useState("Dashboard");
  const location = useLocation().pathname;

  useMemo(() => {
    if (location.includes("/home")) {
      setSelected("Home");
    } else if (
      location.includes("/menu") &&
      !location.includes("/menubanner")
    ) {
      setSelected("Menu");
    } 
    // else if (location.includes("/offers")) {
    //   setSelected("Offers");
    // } 
    else if (
      location.includes("/general") ||
      location.includes("/stickers") ||
      location.includes("/background") ||
      location.includes("/foodImages") ||
      location.includes("/frame") ||
      location.includes("/more") ||
      location.includes("/testimonials") ||
      location.includes("/wallposter") ||
      location.includes("/combos") ||
      location.includes("/events") ||
      location.includes("/brands") ||
      location.includes("/sales") ||
      location.includes("/flyer") ||
      location.includes("filter") ||
      location.includes("Offers")
    ) {
      setSelected("More");
    }
    else if (location.includes("/popup")) {
        setSelected("Pop Up");
      }
    //  else if (location.includes("/sales")) {
    //   setSelected("Sales");
    // }
    // else if (location.includes("/general")) {
    //   setSelected("General")
    // }
    else if (
      location.includes("/analytics") ||
      location.includes("/downloads") ||
      location.includes("/videodownloads")
    ) {
      setSelected("Analytics");
    } else if (location.includes("/video")) {
      setSelected("Video");
    } else if (location.includes("/transactions")) {
      setSelected("Transactions");
    } else if (location === "/users") {
      setSelected("Users");
    } else if (location === "/subscription") {
      setSelected("Subscription");
    } else if (location === "/notification") {
      setSelected("Notification");
    }  else if (location === "/coupons") {
      setSelected("Coupons");
    } else {
      setSelected("Dashboard");
    }
  }, [location]);

  return (
    <div className="main">
      <Box
        sx={{
          height: "200vh",
          position: "fixed",
          zIndex: "10",
          left: 0,
          top: 0,
          "& .pro-sidebar": {
            transition: "all 0.3s ease",
          },
          "& .pro-sidebar-inner": {
            background: `${colors.primary[400]} !important`,
          },
          "& .pro-icon-wrapper": {
            backgroundColor: "transparent !important",
          },
          "& .pro-inner-item": {
            padding: "5px 35px 5px 20px !important",
          },
          "& .pro-inner-item:hover": {
            color: "#868dfb !important",
          },
          "& .pro-menu-item.active": {
            color: "#6870fa !important",
          },
        }}
      >
        <ProSidebar collapsed={isCollapsed} width="18vw" height="100vh">
          <Menu iconShape="square">
            {/* LOGO AND MENU ICON */}
            <MenuItem
              onClick={() => setIsCollapsed(!isCollapsed)}
              icon={isCollapsed ? <MenuOutlinedIcon /> : undefined}
              style={{
                margin: "10px 0 20px 0",
                color: colors.grey[100],
              }}
            >
              {!isCollapsed && (
                <Box
                  display="flex"
                  justifyContent="space-between"
                  alignItems="center"
                  ml="15px"
                >
                  <Typography variant="h3" color={colors.grey[100]}>
                    ADMINS
                  </Typography>
                  <IconButton onClick={() => setIsCollapsed(!isCollapsed)}>
                    <MenuOutlinedIcon />
                  </IconButton>
                </Box>
              )}
            </MenuItem>

            {!isCollapsed && (
              <Box mb="25px">
                <Box textAlign="center">
                  <Typography
                    variant="h2"
                    color={colors.grey[100]}
                    fontWeight="bold"
                    sx={{ m: "10px 0 0 0" }}
                  >
                    Brand Bite
                  </Typography>
                </Box>
              </Box>
            )}

            <Box paddingLeft={isCollapsed ? undefined : "10%"}>
              <Item
                title="Dashboard"
                to="/"
                icon={<CategoryIcon />}
                selected={selected}
                setSelected={setSelected}
              />
              <Typography
                variant="h6"
                color={colors.grey[300]}
                sx={{ m: "15px 0 5px 20px" }}
              >
                Pages
              </Typography>
              <Item
                title="Transactions"
                to="/transactions"
                icon={<Money />}
                selected={selected}
                setSelected={setSelected}
              />
              <Item
                title="Home"
                to="/home"
                icon={<HomeRoundedIcon />}
                selected={selected}
                setSelected={setSelected}
              />
              <Item
                title="Video"
                to="/video"
                icon={<VideocamRounded />}
                selected={selected}
                setSelected={setSelected}
              />
              
              <Item
                title="Analytics"
                to="/analytics"
                icon={<BarChartOutlined />}
                selected={selected}
                setSelected={setSelected}
              />
              {/* <Item
                title="General"
                to="/general"
                icon={<LanguageOutlined />}
                selected={selected}
                setSelected={setSelected}
              /> */}

              {/* <Item
                title="Stickers"
                to="/stickers"
                icon={<SentimentSatisfiedAltIcon />}
                selected={selected}
                setSelected={setSelected}
              />
              <Item
                title="Background"
                to="/background"
                icon={<WallpaperRounded />}
                selected={selected}
                setSelected={setSelected}
              /> */}

              <Item
                title="Pop Up"
                to="/popup"
                icon={<ImageOutlined />}
                selected={selected}
                setSelected={setSelected}
              />

              <Item
                title="Coupons"
                to="/coupons"
                icon={<CurrencyRupee />}
                selected={selected}
                setSelected={setSelected}
              />

              <Item
                title="Menu"
                to="/menu"
                icon={<RestaurantMenuOutlined />}
                selected={selected}
                setSelected={setSelected}
              />
              {/* <Item
                title="Sales"
                to="/sales"
                icon={<TrendingUp />}
                selected={selected}
                setSelected={setSelected}
              /> */}
              <Item
                title="More"
                to="/more"
                icon={<MoreHorizOutlined />}
                selected={selected}
                setSelected={setSelected}
              />
              {/* <Item
                title="Frames"
                to="/frame"
                icon={<CropDinOutlined />}
                selected={selected}
                setSelected={setSelected}
              /> */}
              {/* <Item
                title="Search"
                to="/search"
                icon={<SearchOutlined />}
                selected={selected}
                setSelected={setSelected}
              /> */}

              <Item
                title="Users"
                to="/users"
                icon={<PersonRoundedIcon />}
                selected={selected}
                setSelected={setSelected}
              />

              <Item
                title="Notification"
                to="/notification"
                icon={<NotificationsIcon />}
                selected={selected}
                setSelected={setSelected}
              />

              {/* <Typography
              variant="h6"
              color={colors.grey[300]}
              sx={{ m: "15px 0 5px 20px" }}
            >
              Soon
            </Typography>
            <Item
              title="Subscription"
              to="/subscription"
              icon={<AccountBalanceRoundedIcon />}
              selected={selected}
              setSelected={setSelected}
            /> */}
            </Box>
          </Menu>
        </ProSidebar>
      </Box>
      <Box position="sticky" top="0">
        <ProSidebar
          collapsed={isCollapsed}
          width="18vw"
          height="100vh"
        ></ProSidebar>
      </Box>
    </div>
  );
};

export default Sidebar;
