import { Avatar, Box, Button, Tooltip, Typography, useTheme } from "@mui/material";
import { DataGrid } from "@mui/x-data-grid";
import { tokens } from "../../theme";
import { mockDataTeam } from "../../data/mockData";
import AdminPanelSettingsOutlinedIcon from "@mui/icons-material/AdminPanelSettingsOutlined";
import LockOpenOutlinedIcon from "@mui/icons-material/LockOpenOutlined";
import SecurityOutlinedIcon from "@mui/icons-material/SecurityOutlined";
import Header from "../../components/Header";
import { useState } from "react";
import { onValue, ref } from "firebase/database";
import { useMemo } from "react";
import { database, writedb } from "../../firebase-config";
import { useHistory } from "react-router-dom";
import { CloseRounded } from "@mui/icons-material";
import ArrayToCsvConverter from "../csv Report";
import ArrayToCsvUploader from "../csv Report";
import { blue } from "@mui/material/colors";


const Users = () => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const [userData, setUserData] = useState([]);
  const [userNumber, setUserNumbers] = useState([]);
  const [userkeys, setUserKeys] = useState([]);
  const [snapOfUser, setSnapOfUser] = useState([]);
  const [showProfile, setShowProfile] = useState(false);
  const [userMobile, setUserMobile] = useState("")
  const [phoneUser, setPhoneUser] = useState(true)
  const [userLinkedMobile, setUserLinkedMobile] = useState("")
  const [userMobileLinkedOrnot, setMobileLinkedOrNot] = useState(false)
  const [uniqueKey, setUniqueKey] = useState("");
  const [userSnapshot, setUserSnapshot] = useState("");
  const [displayBox, setDisplayBox] = useState("details");
  const [allPremium, setAllPremium] = useState(false);

  // Variables used for show user details 
  const [userProfile, setUserProfile] = useState("")
  const [userName, setUserName] = useState("")
  const [userEmail, setUserEmail] = useState("")
  const [userGender, setUserGender] = useState("")
  const [userCity, setUserCity] = useState("")
  const [userDesignation, setUserDesignation] = useState("")
  const [userBirth, setUserBirth] = useState("")
  const [userType, setUserType] = useState("")
  const [billDetailsAv, setBillDetailsAv] = useState(false);
  const [businessDetailsAv, setBusinessDetailsAv] = useState(false);
  const [expiredPlansAv, setexpiredPlansAv] = useState(false);
  const [paymentHistoryAv, setpaymentHistoryAv] = useState(false);
  const [plasDetailsAv, setPlasDetailsAv] = useState(false);
  // Billing details variables
  const [billingDetails, setBillingDetails] = useState({ address: "", brandName: "", country: "India", legalName: "", mobile: "", state: "", gst: "" });
  const [business, setBusiness] = useState({
    address: "", city: "",
    email: "", foodOrderingNumber: "",
    legalName: "",
    logo: "", mobile: "",
    name: "", website: ""
  })

  const [plan, setPlan] = useState({ name: "", price: "", date: "", time: "", expiryDate: "", status: "" });
  const [blockUnblock, setBlockUnblock] = useState("Block");
  const [premiumFree, setPremiumFree] = useState("Give Premium Access");

  const [paymentHistory, setPaymentHistory] = useState([]);
  const [expiredPlans, setExpiredPlans] = useState([]);
  const profileClick = (id) => {
    let index = id - 1;
    let mobile = userNumber[index];
    setUniqueKey(userkeys[index]);
    setUserMobile(mobile);
    if (!mobile.startsWith("+")){
      setPhoneUser(false);
    }else{
      setPhoneUser(true);
    }
    console.info(userkeys[index]);
    setShowProfile(true);
    loadProfile(mobile);
    // console.info(userkeys[index])
  }

  const columns = [
    { field: "id", headerName: "ID" },
    {
      field: "name",
      headerName: "Name",
      flex: 1,
      cellClassName: "name-column--cell",
    },
    // {
    //   field: "age",
    //   headerName: "Age",
    //   type: "number",
    //   headerAlign: "left",
    //   align: "left",
    // },
    {
      field: "mobile",
      headerName: "Phone Number",
      flex: 1,
    },
    {
      field: "email",
      headerName: "Email",
      flex: 1,
    },
    {
      field: "type",
      headerName: "User Type",
      flex: 1,
      renderCell: ({ row: { type } }) => {
        return (
          <Box>
            <Typography color={type == "Premium" ? colors.greenAccent[500] : type == "Premium - Admin" ? "orange" : colors.redAccent[500]} >
              {type}
            </Typography>
          </Box>
        );
      },
    },
    {
      field: "accessLevel",
      headerName: "Profile",
      flex: 1,
      renderCell: ({ row: { id } }) => {
        return (
          <Box
            width="60%"
            m="0 auto"
            p="5px"
            display="flex"
            justifyContent="center"
            backgroundColor="green"
            borderRadius="4px"
            onClick={() => profileClick(id)}
          >
            <Typography color="white" sx={{ ml: "5px", userSelect: "none", cursor: "pointer" }}>
              View Profile
            </Typography>
          </Box>
        );
      },
    },
  ];


  function getUserDetails(arr, uniqueKey) {

    onValue(ref(database, "users"), (snapshot) => {
      if (snapshot.exists()) {
        setSnapOfUser(snapshot);
        let userArray = [];
        arr.forEach(async (mobile, index) => {
          let checkNo = snapshot.child(mobile);
          if (checkNo.exists()) {
            let mobileUser = true;
            let userVal = checkNo.val();
            let details = userVal.details;
            let name = details.name;
            let email = details.email;
            let userMEmail = details.userMainEmail;
            let type = "free";
            let designation = details.designation;
            let state = details.state;
            let city = details.city;
            let gender = details.gender;
            let businessName = "Business not added";
            let address = "No address added";
            let planName = "No plan";
            let expiry = "null";
            if (!mobile.startsWith("+")){
              mobileUser = false;
            }
            let mobileLinkedOrNot = false;
            let linkedMobile = mobile;
            if (userVal.hasOwnProperty("business")) {
              let business = userVal.business;
              if (business.hasOwnProperty("name")) {
                businessName = business.name;
              }
              if (business.hasOwnProperty("address")){
                address = business.address;
              }
            }
            
            if (userVal.hasOwnProperty("premiumPlanDetail")){
              let plan = userVal.premiumPlanDetail;
              if (plan.hasOwnProperty("selectedPlan")){
                  planName = plan.selectedPlan;
                  if (planName == "Brand Bite trial plan"){
                    planName = "Trial activated";
                  }
              }
              if (plan.hasOwnProperty("expiryDate")){
                expiry = plan.expiryDate;
              }
            }

            if (details.hasOwnProperty('type')) {
              type = details.type;
            }
            if (details.hasOwnProperty("linkedMobile")){
              mobileLinkedOrNot = true;
              linkedMobile = details.linkedMobile;
            }
            let joinDate = "Date Error";
            try {
              joinDate = await details.joinDate;
              joinDate = joinDate.substring(0, 12);
            } catch (ignored) {
              joinDate = "Date Error";
            }
            if (checkNo.child("adminPremium").exists()) {
              type = "premium - Admin"
            }
            var profile = { id: index + 1, name: name, mobile: mobileUser ? mobile : mobileLinkedOrNot ? linkedMobile + " (Linked)" : "Not Linked (Email User)", key: uniqueKey[index], email: mobileUser ? email : userMEmail, type: type.charAt(0).toUpperCase() + type.slice(1), joinDate: joinDate, designation: designation, state: state, city: city, gender: gender,businessName: businessName,planName:planName,expiryDate:expiry,address:address }
            
            userArray.push(profile);
            setUserData(userArray);

            // if (checkNo.child("premiumPlanDetail").child("selectedPlan").exists()){
            //   let plan = checkNo.child("premiumPlanDetail").child("selectedPlan").val()
            //   if (checkNo.child("premiumPlanDetail").child("price").exists()){
            //     let price = checkNo.child("premiumPlanDetail").child("price").val();
            //     if (plan == "Brand Bite trial plan" && price == "0"){
            //       type = "trial"
            //     }               
            //   }
            // }
          }

        });


        
      }
    })
  }

  useMemo(() => {
    onValue(ref(database, `userUniqueKeys`), (snapshot) => {
      if (snapshot.exists()) {
        const data = snapshot.val();
        let value = Object.values(data);
        let keys = Object.keys(data);
        let arr = [];
        let arrUniqueKeys = []; //unique key for each user;
        value.forEach((val, index) => {
          let mobile = Object.keys(val)[0]
          // console.info(`users/${mobile}`)
          let key = keys[index];
          arr.push(mobile);
          arrUniqueKeys.push(key);
        }) 
        setUserKeys(arrUniqueKeys);
        setUserNumbers(arr);
        getUserDetails(arr, arrUniqueKeys);
      }
    })
    writedb.ref("allPremium").once('value').then((snapshot) => {
      if (snapshot.exists()) {
        setAllPremium(true);
      }
    })

    
  }, [userNumber.length])




  async function blockUser() {
    let checkSnap = await writedb.ref("users").child(userMobile).child("details").child("block").once('value');
    if (checkSnap.exists()) {
      await writedb.ref("users").child(userMobile).child("details").child("block").set("true");
    }
    if (blockUnblock == "Block") {
      let confirm = window.confirm("Are you sure to block this user");

      if (confirm) {
        writedb.ref("users").child(userMobile).child("details").update({ block: "true" }).then(() => {
          setBlockUnblock("Unblock");
          alert("User Blocked")
        });
      }
    }
    else {
      writedb.ref("users").child(userMobile).child("details").update({ block: "false" }).then(() => {
        setBlockUnblock("Block");
        alert("User Unblocked")
      });
    }
  }

  function premiumAccess() {
    if (premiumFree == "Give Premium Access") {
      writedb.ref("users").child(userMobile).child("adminPremium").set({ acess: "true" }).then(() => {
        setPremiumFree("Take Premium Access")
      })
    } else {
      writedb.ref("users").child(userMobile).child("adminPremium").remove().then(() => {
        setPremiumFree("Give Premium Access")
      })
    }
  }

  function loadProfile(mobile) {
    console.info(mobile);
    writedb.ref("users").child(mobile).once('value').then((snapshot) => {
      if (snapshot.exists()) {
        setUserSnapshot(snapshot.val());
        const sval = snapshot.val();
        const details = sval.details;

        setUserProfile(details.profile);
        setUserName(details.name);
        setUserEmail(details.email);
        setUserGender(details.gender.charAt(0).toUpperCase() + details.gender.slice(1));
        setUserCity(details.city);
        setUserDesignation(details.designation);
        setUserBirth(details.birth);
        let type = "Free";
        if (details.hasOwnProperty('type')) {
          type = details.type.charAt(0).toUpperCase() + details.type.slice(1);
        }
        if (!phoneUser){
          if (details.hasOwnProperty("linkedMobile")){
            console.info(mobile);
            setUserLinkedMobile(details.linkedMobile);
            setMobileLinkedOrNot(true)
          }
        }

        if (sval.hasOwnProperty("adminPremium")) {
          setPremiumFree("Take Premium Access")
        } else {
          setPremiumFree("Give Premium Access")
        }

        if (details.hasOwnProperty("block")) {
          let block = details.block;
          if (block == "true") {
            setBlockUnblock("Unblock")
          } else {
            setBlockUnblock("Block")
          }
        }

        setUserType(type);
        if (sval.hasOwnProperty('billDetails')) {
          setBillDetailsAv(true);
          let bill = sval.billDetails;
          if (bill.hasOwnProperty("address")) {
            billingDetails.address = bill.address;
          }
          if (bill.hasOwnProperty("legalName")) {
            billingDetails.legalName = bill.legalName;
          }
          if (bill.hasOwnProperty("mobile")) {
            billingDetails.mobile = bill.mobile;
          } if (bill.hasOwnProperty("gstNo")) {
            billingDetails.gst = bill.gstNo;
          }
          billingDetails.state = bill.state;
          billingDetails.brandName = bill.brandName;
        }
        if (sval.hasOwnProperty('business')) {
          setBusinessDetailsAv(true);
          let busi = sval.business;
          if (busi.hasOwnProperty("address")) {
            business.address = busi.address;
          }
          if (busi.hasOwnProperty("city")) {
            business.legalName = busi.city;
          }
          if (busi.hasOwnProperty("email")) {
            business.email = busi.email;
          } if (busi.hasOwnProperty("foodOrderingNumber")) {
            business.foodOrderingNumber = busi.foodOrderingNumber;
          }
          if (busi.hasOwnProperty("logo")) {
            business.logo = busi.logo;
          }
          if (busi.hasOwnProperty("foodOrderingNumber")) {
            business.foodOrderingNumber = busi.foodOrderingNumber;
          }
          if (busi.hasOwnProperty("mobile")) {
            business.mobile = busi.mobile;
          }
          if (busi.hasOwnProperty("name")) {
            business.name = busi.name;
          }
          if (busi.hasOwnProperty("website")) {
            business.website = busi.website;
          }
        }

        if (sval.hasOwnProperty("paymentHistory")) {
          setpaymentHistoryAv(true);
          let pay = sval.paymentHistory;
          let values = Object.values(pay);
          let arr = [];
          values.forEach(val => {
            let expiryDate = "";
            if (val.hasOwnProperty("expiryDate")) {
              expiryDate = val.expiryDate
            } else {
              val.expiryDate = expiryDate;
            }
            arr.push(val);
          })
          setPaymentHistory(arr);
        }

        if (sval.hasOwnProperty("premiumPlanDetail")) {
          setPlasDetailsAv(true)
          let pl = sval.premiumPlanDetail;
          plan.name = pl.selectedPlan;
          plan.expiryDate = pl.expiryDate;
          plan.price = pl.price;
          plan.date = pl.date;
          plan.time = pl.time;
          plan.status = pl.paymentStatus;
        }

        if (sval.hasOwnProperty("expiredPlans")) {
          setexpiredPlansAv(true);
          let ex = sval.expiredPlans;
          let exval = Object.values(ex);
          setExpiredPlans(exval);
        }


      } else {
        alert("User Not Found")
      }
    })
  }


  function openUrl(val) {
    const url = new URL(val);
    window.open(url.href, "_blank");
  }

  function deleteUser() {
    let confirm = window.confirm("Confirm?");
    if (confirm) {

      writedb.ref(`deletedUsers/${uniqueKey}/${userMobile}`).set(userSnapshot).then(() => {
        writedb.ref(`users/${userMobile}`).remove().then(() => {
          writedb.ref(`userUniqueKeys/${uniqueKey}`).remove().then(() => {
            alert("User Deleted");
          })
        })

      })
    }
  }

  function resetAll() {
    setShowProfile(!showProfile)
    setDisplayBox("details")
    setUserProfile("");
    setUserName("");
    setUserEmail("");
    setUserGender("");
    setUserCity("");
    setUserDesignation("");
    setUserBirth("");
    setUserType("");
    setBillDetailsAv(false);
    setBusinessDetailsAv(false);
    setexpiredPlansAv(false);
    setExpiredPlans([]);
    setpaymentHistoryAv(false);
    setPlasDetailsAv(false);
    setPremiumFree("Give Premium Access")
  }



  const headers = {
    id: "Index",
    name: "User Name",
    mobile: "Mobile",
    email: "Email",
    planName: "Selected Plan",
    expiryDate: "Expiry Date",  
    businessName: "Business Name",
    address:"Address",
    type: "User type",
    designation: "Designation",
    city: "City",
    state: "State",
    gender: "Gender",
    joinDate: "Join Date"
  }

  // const csvReport = {
  //   data: userData,
  //   headers: headers,
  //   filename: 'User_Data.csv'
  // };




  const download = (data, fileName) => {
    const blob = new Blob([data], { type: 'text/csv' });
    const url = window.URL.createObjectURL(blob);
    const a = document.createElement('a');
    a.setAttribute('hidden', '');
    a.setAttribute('href', url);
    a.setAttribute('download', fileName + '.csv');
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
  };
  const spreadsheetId = 'YOUR_SPREADSHEET_ID';

  const makeAllPremium = () => {
    if (allPremium){
      writedb.ref("allPremium").remove();
      setAllPremium(false);
    }else{
      writedb.ref("allPremium").set({value:"true"});
      setAllPremium(true);
    }
  }

  return (
    <Box>


      <Box display={showProfile ? "flex" : "none"} m="40px" mt="60px" width="100%" height="100%" justifyContent="center" alignItems="center">
        <Box display="flex" width="700px" height="590px" flexDirection="column" sx={{ background: colors.primary[400] }} borderRadius="20px" alignItems="center" position="relative">

          <Box position="absolute" right="0px" top="0" display="flex" zIndex="100">
            <Button onClick={() => { resetAll() }} ><CloseRounded sx={{
              color: colors.primary[100], '&:hover': {
                color: 'lightgreen'
              }
            }} /></Button>
          </Box>

          <Box display="flex" flexDirection="column" justifyContent='center' alignItems="center" >
            <Box display="flex" width="100%" justifyContent="center" mt="5px">
              <Avatar src={userProfile} sx={{
                width: 151, height: 150, cursor: "pointer", '& > img': {
                  objectFit: 'fill',
                  padding: "5px"
                }, ":hover": { bgcolor: "white" }
              }}></Avatar>
            </Box>
            <Typography fontSize="17px" mt="10px">{userName}</Typography>
            <Typography fontSize="17px" mt="15px">{phoneUser ?userMobile:userMobileLinkedOrnot?userLinkedMobile+" (Linked)":"No Mobile Linked"}</Typography>
            {userEmail.length > 0 ? <Typography fontSize="17px" mt="15px">{userEmail}</Typography> : ""}

            <Box display={displayBox == "details" ? "flex" : "none"} flexDirection="column" alignItems="center">

              {userDesignation ? <Typography fontSize="17px" mt="15px">Designation: {userDesignation}</Typography> : ""}
              {userCity ?  <Typography fontSize="17px" mt="15px">City: {userCity}</Typography> : ""}
              <Typography fontSize="17px" mt="15px">Gender: {userGender}</Typography>
              <Typography fontSize="17px" mt="15px">Join Date: {userBirth}</Typography>
              <Typography fontSize="20px" mt="20px">{userType}</Typography>
            </Box>


            <Box display={displayBox == "bill" ? "" : "none"} border="2px solid white" mt="25px" width="100%" justifyContent="center"
              flexDirection="column" padding="10px">
              <Box display={billDetailsAv ? "" : "none"}>
                {billingDetails.gst.length > 0 ? <Typography fontSize="15px" >GST No: {billingDetails.gst}</Typography> : ""}
                {billingDetails.legalName.length > 0 ? <Typography fontSize="15px" >Legal Name: {billingDetails.legalName}</Typography> : ""}
                {billingDetails.brandName.length > 0 ? <Typography fontSize="15px" >Brand Name: {billingDetails.brandName}</Typography> : ""}
                {billingDetails.address.length > 0 ? <Typography fontSize="15px" >Address: {billingDetails.address}</Typography> : ""}
                {billingDetails.state.length > 0 ? <Typography fontSize="15px" >State: {billingDetails.state}</Typography> : ""}
                <Typography fontSize="15px" mt="5px">Country: India</Typography>
              </Box>
              <Box display={billDetailsAv ? "none" : "flex"} justifyContent="center">
                <Typography fontSize="15px" m="5px 0">No Bills Found</Typography>
              </Box>
            </Box>


            <Box display={displayBox == "business" ? "" : "none"} border="2px solid white" mt="25px" width="100%" justifyContent="center"
              flexDirection="column" padding="10px">
              <Box display={businessDetailsAv ? "" : "none"}>
                {business.name.length > 0 ? <Typography fontSize="15px" >Name: {business.name}</Typography> : ""}
                {business.mobile.length > 0 ? <Typography fontSize="15px" >Mobile: {business.mobile}</Typography> : ""}
                {business.foodOrderingNumber.length > 0 ? <Typography fontSize="15px" >Food Or.. No: {business.foodOrderingNumber}</Typography> : ""}
                {business.email.length > 0 ? <Typography fontSize="15px" >Email: {business.email}</Typography> : ""}
                {business.website.length > 0 ? <Typography fontSize="15px" >Website: {business.website}</Typography> : ""}
                {business.address.length > 0 ? <Typography fontSize="15px" >Address: {business.address}</Typography> : ""}
                {business.city.length > 0 ? <Typography fontSize="15px" >City: {business.city}</Typography> : ""}
                <Box width="100%" display="flex" justifyContent="center">
                  <Button sx={{ color: "white", bgcolor: "blue", borderRadius: "10px", padding: "2px 7px", mt: "2px" }} onClick={() => { openUrl(business.logo) }}>
                    View Logo
                  </Button>
                </Box>
              </Box>
              <Box display={businessDetailsAv ? "none" : "flex"} justifyContent="center">
                <Typography fontSize="15px" m="5px 0">Business Not Added</Typography>
              </Box>

            </Box>

            <Box display={displayBox == "payHistory" ? "" : "none"} border="2px solid white" mt="25px" width="100%" justifyContent="center"
              flexDirection="column" padding="10px">
              <Box >
                <Box display={paymentHistoryAv ? "" : "none"} flexDirection="column" maxHeight="150px" overflow="auto">
                  {paymentHistory.map((val, index) => (
                    <Box key={"ref" + index} display="flex" borderRadius="2px" mt="10px" pl="5px" flexDirection="column" border="2px solid #f2f2f2">
                      <Typography fontSize="15px" mt="3px">Ref: {val.ref}</Typography>
                      <Typography fontSize="15px" mt="3px">Date: {val.date}</Typography>
                      {val.expiryDate.length > 0 ? <Typography fontSize="15px" >Expires On: {val.expiryDate}</Typography> : ""}
                      <Typography fontSize="15px" mt="3px">Selected Plan: {val.selectedPlan}</Typography>
                      <Typography fontSize="15px" mt="3px">Price: {val.price}</Typography>
                      <Typography fontSize="15px" mt="3px">Status: {val.status}</Typography>
                    </Box>
                  ))}
                </Box>
              </Box>
              <Box display={paymentHistoryAv ? "none" : "flex"} justifyContent="center">
                <Typography fontSize="15px" m="5px 0">No payments Done By User</Typography>
              </Box>
            </Box>


            <Box display={displayBox == "plan" ? "" : "none"} border="2px solid white" mt="25px" width="100%" justifyContent="center">
              <Box display={plasDetailsAv ? "" : "none"}>
                <Typography fontSize="17px" mt="3px">Name: {plan.name}</Typography>
                <Typography fontSize="15px" mt="3px">Price: {plan.price}</Typography>
                <Typography fontSize="15px" mt="3px">Date: {plan.date}</Typography>
                <Typography fontSize="15px" mt="3px">Expiry Date: {plan.expiryDate}</Typography>
                <Typography fontSize="15px" mt="3px">Time: {plan.time}</Typography>
                <Typography fontSize="15px" mt="3px">Status: {plan.status}</Typography>
              </Box>
              <Box display={plasDetailsAv ? "none" : "flex"} justifyContent="center">
                <Typography fontSize="15px" m="5px 0">No Plan selected By User</Typography>
              </Box>
            </Box>


            <Box display={displayBox == "exPlans" ? "" : "none"} border="2px solid white" mt="25px" width="100%" justifyContent="center" maxHeight="200px" overflow="auto" padding="0 15px">
              <Box display={expiredPlansAv ? "" : "none"}>

                {expiredPlans.map((val, index) => (
                  <Box display="flex" m="7px 0" key={index + "expiredPlans"}>
                    <Box border="2px solid white" width="100%" justifyContent="center" alignItems="center" pl="4px">
                      <Box display="flex" width="100%" justifyContent="center">
                        <Typography fontSize="17px" mt="3px" color="yellow">Bill Details</Typography>
                      </Box>
                      {val.billDetails.hasOwnProperty("gstNo") ? <Typography fontSize="15px" mt="3px">GST No: {val.billDetails.gstNo}</Typography> : ""}
                      {val.billDetails.hasOwnProperty("legalName") ? <Typography fontSize="15px" mt="3px">Legal Name: {val.billDetails.legalName}</Typography> : ""}
                      {val.billDetails.hasOwnProperty("brandName") ? <Typography fontSize="15px" mt="3px">Brand Name: {val.billDetails.brandName}</Typography> : ""}
                      {val.billDetails.hasOwnProperty("address") ? <Typography fontSize="15px" mt="3px">Address: {val.billDetails.address}</Typography> : ""}
                      {val.billDetails.hasOwnProperty("state") ? <Typography fontSize="15px" mt="3px">State: {val.billDetails.state}</Typography> : ""}
                      <Typography fontSize="15px" mt="5px">Country: India</Typography>
                    </Box>

                    <Box ml="20px" border="2px solid white" width="100%" pl="4px">
                      <Box display="flex" width="100%" justifyContent="center">
                        <Typography fontSize="17px" mt="3px" color="yellow">Plan Details</Typography>
                      </Box>
                      <Typography fontSize="15px" mt="3px">Name: {val.planDetails.name}</Typography>
                      <Typography fontSize="15px" mt="3px">Price: {val.planDetails.price}</Typography>
                      <Typography fontSize="15px" mt="3px">Date: {val.planDetails.date}</Typography>
                      <Typography fontSize="15px" mt="3px">Expiry Date: {val.planDetails.expiryDate}</Typography>
                      <Typography fontSize="15px" mt="3px">Time: {val.planDetails.time}</Typography>
                      <Typography fontSize="15px" mt="3px">Status: {val.planDetails.paymentStatus}</Typography>
                    </Box>
                  </Box>
                ))}

              </Box>

              <Box display={expiredPlansAv ? "none" : "flex"} justifyContent="center">
                <Typography fontSize="15px" m="5px 0">No Expired Plans Found</Typography>
              </Box>

            </Box>




            <Box display="flex" mt="30px" position="absolute" bottom="0" mb="60px" >
              <Box display="flex" whiteSpace="nowrap">
                <Tooltip title="User Details">
                  <Button sx={{ color: "white", bgcolor: "blue", borderRadius: "10px", padding: "5px 10px" }} onClick={() => { setDisplayBox("details") }} >
                    Details
                  </Button>
                </Tooltip>
                <Tooltip title="User Bill Details">
                  <Button sx={{ color: "white", bgcolor: "blue", borderRadius: "10px", padding: "5px  3px", ml: "20px" }} onClick={() => { setDisplayBox("bill") }}>
                    Bill
                  </Button>
                </Tooltip>
                <Tooltip title="User Business Details">
                  <Button sx={{ color: "white", bgcolor: "blue", borderRadius: "10px", padding: "5px 7px", ml: "20px" }} onClick={() => { setDisplayBox("business") }}>
                    Business
                  </Button>
                </Tooltip>
                <Tooltip title="Payments Of User">
                  <Button sx={{ color: "white", bgcolor: "blue", borderRadius: "10px", padding: "5px 7px", ml: "20px", }} onClick={() => { setDisplayBox("payHistory") }}>
                    Transactions
                  </Button>
                </Tooltip>


              </Box>
            </Box>

            <Box display="flex" mt="30px" position="absolute" bottom="0" mb="10px" >
              <Box display="flex" whiteSpace="nowrap">
                <Tooltip title="Selected Plan">
                  <Button sx={{ color: "white", bgcolor: "blue", borderRadius: "10px", }} onClick={() => { setDisplayBox("plan") }}>
                    plan
                  </Button>
                </Tooltip>
                <Tooltip title="Expired Plan">
                  <Button sx={{ color: "white", bgcolor: "blue", borderRadius: "10px", ml: "20px", }} onClick={() => { setDisplayBox("exPlans") }}>
                    Ex Plans
                  </Button>
                </Tooltip>
                <Tooltip title="Block The User">
                  <Button sx={{ color: "white", bgcolor: "blue", borderRadius: "10px", ml: "20px", }} onClick={blockUser}>
                    {blockUnblock}
                  </Button>
                </Tooltip>
                <Tooltip title="Give Take Access">
                  <Button sx={{ color: "white", bgcolor: "blue", borderRadius: "10px", ml: "20px", }} onClick={premiumAccess}>
                    {premiumFree}
                  </Button>
                </Tooltip>
                <Button sx={{ color: "white", bgcolor: "blue", borderRadius: "10px", ml: "20px", }} onClick={deleteUser}>
                  Delete
                </Button>
              </Box>
            </Box>

          </Box>





        </Box>
      </Box>

      <Box display={showProfile ? "none" : ""} m="20px" overflow="auto">
        <Header title="Users" subtitle="Managing the Users Of Brand Bite" />
        {/* <Button onClick={() => {download(userData,"vinay soni")}} style={{ color: "white", }}>CSV Report</Button> 
        */}
        <Box
          display="flex"
          justifyContent="space-between"
        >
           <Box display="inline-flex">
            <Box backgroundColor={blue[700]} borderRadius="20px" paddingRight="5px">
                <Button onClick={makeAllPremium}>
                    <Typography display="flex" textAlign="center" fontSize="15px" textTransform="none" color="white" alignItems="center">
                    {!allPremium ? "Give premium access to all" : "Take premium access from all"}
                    </Typography>
                </Button>
            </Box>
        </Box>
          <Box>
            {/* Use ArrayToCsvConverter here */}
            
            <ArrayToCsvConverter data={userData} columnMap={headers} filename="User Data.csv" />
          </Box>
        </Box>
        <Box
          m="10px 0 0 0"
          height="75vh"
          sx={{
            "& .MuiDataGrid-root": {
              border: "none",
            },
            "& .MuiDataGrid-cell": {
              borderBottom: "none",
            },
            "& .name-column--cell": {
              color: colors.greenAccent[300],
            },
            "& .free-column--cell": {
              color: colors.redAccent[500],
            },
            "& .premium-column--cell": {
              color: colors.greenAccent[500],
            },
            "& .MuiDataGrid-columnHeaders": {
              backgroundColor: colors.blueAccent[700],
              borderBottom: "none",
            },
            "& .MuiDataGrid-virtualScroller": {
              backgroundColor: colors.primary[400],
            },
            "& .MuiDataGrid-footerContainer": {
              borderTop: "none",
              backgroundColor: colors.blueAccent[700],
            },
            "& .MuiCheckbox-root": {
              color: `${colors.greenAccent[200]} !important`,
            },
          }}
        >
          <DataGrid checkboxSelection rows={userData.length > 0 ? userData : []} columns={columns} />
        </Box>
      </Box>
    </Box>
  );
};

export default Users;



