import {
    Avatar,
    Box,
    Button,
    Grid,
    Tooltip,
    Typography,
    useTheme,
  } from "@mui/material";
  import { tokens } from "../../theme";
  import Header from "../../components/Header";
  import { useMemo, useState } from "react";
  import { Link } from "react-router-dom";
  import { database, writedb } from "../../firebase-config";
  import { onValue, ref } from "firebase/database";
  import axios from "axios";
  import {
    CropDinRounded,
    RestaurantMenuOutlined,
    SentimentSatisfiedAltOutlined,
    WallpaperRounded,
  } from "@mui/icons-material";
  
  const TeamDashboard = () => {
    const theme = useTheme();
    const colors = tokens(theme.palette.mode);
    const [users, setUsers] = useState(0);
    const [preUser, setPreUsers] = useState(0);
    const [freeUser, setFreeUsers] = useState();
    const [blockUser, setBlockUsers] = useState("0");
    const [appOnOff, setAppOnOff] = useState(true);
    const [suggestions, setSuggestions] = useState([]);
    const [year, setYear] = useState(new Date().getFullYear().toString());
    const [earningList, setEarningList] = useState([]);
    const [totalEarnings, setTotalEarning] = useState("");
    const [snapOfUser, setSnapOfUser] = useState("");
  
    // ======================     Server Database Management starts  ===================================
  
   
  
    // Getting user details from server
    
  
   
  
  
 
  
    // Fetch Trending Images From Server
    const [trendingImages, setTrendingImages] = useState([]);
    const [moreCuisines, setMoreCuisines] = useState([]);
    const trendingImagesRef = ref(database, "home/trending");
    const MoreCuisinesRef = ref(database, "home/moreCuisines");
    const GenMoreCuisinesRef = ref(database, "general/moreCuisines");
    useMemo(() => {
      onValue(trendingImagesRef, (snapshot) => {
        let urls = [];
        snapshot.forEach((childSnapshot) => {
          let key = childSnapshot.val();
          urls.push(key.image);
        });
        urls.reverse();
        setTrendingImages(urls);
      });
  
      onValue(MoreCuisinesRef, (snapshot) => {
        let urls = [];
        snapshot.forEach((childSnapshot) => {
          let key = childSnapshot.val();
          urls.push(key.image);
        });
        urls.reverse();
        setMoreCuisines(urls);
      });
    }, [trendingImages.size]);
  
    const imageElements = trendingImages.map((imageUrl, index) => (
      <Link key={imageUrl + index} to="/trending">
        <Avatar
          src={imageUrl}
          sx={{
            width: 70,
            height: 70,
            margin: "0 10px",
            marginBottom: "10px",
            cursor: "pointer",
          }}
        ></Avatar>
      </Link>
    ));
  
    
  
    const imageElementsForHomeCuisines = moreCuisines.map((imageUrl, index) => (
      <Link key={imageUrl + index} to="/morecuisines">
        <Avatar
          src={imageUrl}
          sx={{
            width: 70,
            height: 70,
            margin: "0 10px",
            marginBottom: "10px",
            cursor: "pointer",
          }}
        ></Avatar>
      </Link>
    ));
  
    // Fetch Banner Images From Server
    const [bannerImages, setBannerImages] = useState([]);
    const [genBannerImages, setGenBannerImages] = useState([]);
    const [menuBannerImages, setMenuBannerImages] = useState([]);
    const [videoBannerImages, setVideoBannerImages] = useState([]);
    const bannerImagesRef = ref(database, "home/banner");
    const videoBannerImagesRef = ref(database, "video/banner");
    const genbannerImagesRef = ref(database, "general/banner");
    const menubannerImagesRef = ref(database, "menu/banner");
    useMemo(() => {
      onValue(bannerImagesRef, (snapshot) => {
        let urls = [];
        snapshot.forEach((childSnapshot) => {
          let key = childSnapshot.val();
          urls.push(key.image);
        });
        urls.reverse();
        setBannerImages(urls);
      });
      onValue(videoBannerImagesRef, (snapshot) => {
        let urls = [];
        snapshot.forEach((childSnapshot) => {
          let key = childSnapshot.val();
          urls.push(key.image);
        });
        urls.reverse();
        setVideoBannerImages(urls);
      });
      onValue(genbannerImagesRef, (snapshot) => {
        let urls = [];
        snapshot.forEach((childSnapshot) => {
          let key = childSnapshot.val();
          urls.push(key.image);
        });
        urls.reverse();
        setGenBannerImages(urls);
      });
  
      onValue(menubannerImagesRef, (snapshot) => {
        let urls = [];
        snapshot.forEach((childSnapshot) => {
          let key = childSnapshot.val();
          urls.push(key.image);
        });
        urls.reverse();
        setMenuBannerImages(urls);
      });
  
      
    }, [bannerImages.size]);
  
    const bannerimageElements = bannerImages.map((imageUrl, index) => (
      <Link key={imageUrl + index} to="/banner">
        <Avatar
          src={imageUrl}
          sx={{
            width: 130,
            height: 100,
            margin: "0 10px",
            marginBottom: "10px",
            borderRadius: "10%",
            cursor: "pointer",
          }}
        ></Avatar>
      </Link>
    ));
  
    const bannerimageElementsForVideo = videoBannerImages.map(
      (imageUrl, index) => (
        <Link key={imageUrl + index} to="/videobanner">
          <Avatar
            src={imageUrl}
            sx={{
              width: 130,
              height: 100,
              margin: "0 10px",
              marginBottom: "10px",
              borderRadius: "10%",
              cursor: "pointer",
            }}
          ></Avatar>
        </Link>
      )
    );
  
    const bannerimageElementsForGeneral = genBannerImages.map(
      (imageUrl, index) => (
        <Link key={imageUrl + index} to="/genbanner">
          <Avatar
            src={imageUrl}
            sx={{
              width: 130,
              height: 100,
              margin: "0 10px",
              marginBottom: "10px",
              borderRadius: "10%",
              cursor: "pointer",
            }}
          ></Avatar>
        </Link>
      )
    );
 
  
    const bannerimageElementsForMenu = menuBannerImages.map((imageUrl, index) => (
      <Link key={imageUrl + index} to="/menubanner">
        <Avatar
          src={imageUrl}
          sx={{
            width: 130,
            height: 100,
            margin: "0 10px",
            marginBottom: "10px",
            borderRadius: "10%",
            cursor: "pointer",
          }}
        ></Avatar>
      </Link>
    ));
  
   
  
    // This is for update database to turn app status on off
    
  
  
  
    // ======================     Server Database Management Ends  ===================================
  
    
  
    return (
      <Box maxWidth="100vw" className="hide-overflow-600px" paddingRight="5px">
        <Box
          display="flex"
          flexDirection="column"
          className="m20"
          marginBottom="20px"
          maxWidth="100%"
        >
          {/* HEADER */}
          <Box className="hide show-600px">
            <Typography fontSize="30px" fontWeight="700">
              BrandBite
            </Typography>
          </Box>
          <Box
            className="flex-col hide-600px"
            justifyContent="space-between"
            alignItems="start"
          >
            {/* <Header title="DASHBOARD" subtitle="Welcome to your dashboard" /> */}
            <Header title="DASHBOARD" subtitle="Welcome to Brand Bite" />
            {/* Download Report BTn */}
          </Box>
  
          {/* GRID & CHARTS */}
  
          {/* Row 1 - Dashboard */}
  
          
  
          {/* Row 2 - Dashboard */}
  
          <Box className="flex-col-600px flex-row">
            {/* Home Trending starts here*/}
  
            <Box
              className="mb10-600px width50 setwidth100"
              display="flex"
              flexDirection="column"
              alignItems="center"
              overflow="auto"
              sx={{
                background: colors.primary[400],
                padding: 2,
                cursor: "pointer",
                maxWidth: "100%", // Set maximum width to 50%
                maxHeight: "320px",
              }}
              borderRadius="10px"
            >
              <Link
                to="/trending"
                overflow="auto"
                style={{ color: "inherit", textDecoration: "none" }}
              >
                <Box display="flex" flexDirection="column">
                  <Typography fontSize="20px" fontWeight={700} textAlign="center">
                    Trending
                  </Typography>
                </Box>
              </Link>
  
              {/* Image List */}
              {trendingImages.length > 0 ? (
                <Box
                  display="flex"
                  flexDirection="row"
                  justifyContent="center"
                  alignItems="center"
                  flexWrap="wrap"
                >
                  {imageElements}
                </Box>
              ) : (
                <Box
                  display="flex"
                  flexDirection="row"
                  justifyContent="center"
                  alignItems="center"
                  height="100%"
                >
                  <Link
                    to="/trending"
                    overflow="auto"
                    style={{ color: "inherit", textDecoration: "none" }}
                  >
                    <Box display="flex" flexDirection="column">
                      <Typography
                        fontSize="20px"
                        fontWeight={700}
                        textAlign="center"
                      >
                        No images
                      </Typography>
                    </Box>
                  </Link>
                </Box>
              )}
            </Box>
            {/* Home Trending ends here*/}
            {/* More Cuisines starts here*/}
            <Box
              className="mb10-600px width50 setwidth100 ml20 ml0"
              display="flex"
              flexDirection="column"
              alignItems="center"
              overflow="auto"
              sx={{
                background: colors.primary[400],
                padding: 2,
                cursor: "pointer",
                maxWidth: "100%", // Set maximum width to 50%
                maxHeight: "320px",
              }}
              borderRadius="10px"
            >
              <Link
                to="/morecuisines"
                overflow="auto"
                style={{ color: "inherit", textDecoration: "none" }}
              >
                <Box display="flex" flexDirection="column">
                  <Typography fontSize="20px" fontWeight={700} textAlign="center">
                    Explore More Cuisines
                  </Typography>
                  <Typography
                    fontSize="20px"
                    fontWeight={700}
                    textAlign="center"
                    marginBottom="10px"
                  >
                    Home
                  </Typography>
                </Box>
              </Link>
  
              {/* Image List */}
              <Box
                display="flex"
                flexDirection="row"
                justifyContent="center"
                alignItems="center"
                flexWrap="wrap"
              >
                {imageElementsForHomeCuisines}
              </Box>
            </Box>
            {/* More Cuisines ends here*/}
            
             
          </Box>
  
         
  
          <Box className="flex-col-600px flex-row" mt="20px" mb="100px">
            <Box
              className="mb10-600px width50 setwidth100"
              display="flex"
              flexDirection="column"
              alignItems="center"
              overflow="auto"
              maxHeight="200px"
              sx={{
                background: colors.primary[400],
                padding: 2,
                cursor: "pointer",
                maxWidth: "100%", // Set maximum width to 50%
              }}
              borderRadius="10px"
            >
              <Link
                to="/trending"
                style={{ color: "inherit", textDecoration: "none" }}
              >
                <Typography
                  fontSize="30px"
                  fontWeight={700}
                  textAlign="center"
                  marginBottom="10px"
                >
                  Home
                </Typography>
              </Link>
              {/* Image List */}
              <Box
                display="flex"
                flexDirection="row"
                justifyContent="center"
                alignItems="center"
                flexWrap="wrap"
              >
                {bannerimageElements}
              </Box>
            </Box>
  
            <Box
              className="mb10-600px width50 setwidth100 ml20 ml0"
              display="flex"
              flexDirection="column"
              alignItems="center"
              overflow="auto"
              maxHeight="200px"
              sx={{
                background: colors.primary[400],
                padding: 2,
                cursor: "pointer",
                maxWidth: "100%", // Set maximum width to 50%
              }}
              borderRadius="10px"
            >
              <Link
                to="/videobanner"
                style={{ color: "inherit", textDecoration: "none" }}
              >
                <Typography
                  fontSize="30px"
                  fontWeight={700}
                  textAlign="center"
                  marginBottom="10px"
                >
                  Video
                </Typography>
              </Link>
              {/* Image List */}
              <Box
                display="flex"
                flexDirection="row"
                justifyContent="center"
                alignItems="center"
                flexWrap="wrap"
              >
                {bannerimageElementsForVideo}
              </Box>
            </Box>
  
            <Box
              className="mb10-600px width50 setwidth100 ml20 ml0"
              display="flex"
              flexDirection="column"
              alignItems="center"
              overflow="auto"
              maxHeight="200px"
              sx={{
                background: colors.primary[400],
                padding: 2,
                cursor: "pointer",
                maxWidth: "100%", // Set maximum width to 50%
              }}
              borderRadius="10px"
            >
              <Link
                to="/genbanner"
                style={{ color: "inherit", textDecoration: "none" }}
              >
                <Typography
                  fontSize="30px"
                  fontWeight={700}
                  textAlign="center"
                  marginBottom="10px"
                >
                  General
                </Typography>
              </Link>
              {/* Image List */}
              <Box
                display="flex"
                flexDirection="row"
                justifyContent="center"
                alignItems="center"
                flexWrap="wrap"
              >
                {bannerimageElementsForGeneral}
              </Box>
            </Box>
  
            <Box
              className="mb10-600px width50 setwidth100 ml20 ml0"
              display="flex"
              flexDirection="column"
              alignItems="center"
              overflow="auto"
              maxHeight="200px"
              sx={{
                background: colors.primary[400],
                padding: 2,
                cursor: "pointer",
                maxWidth: "100%", // Set maximum width to 50%
              }}
              borderRadius="10px"
            >
              <Link
                to="/menubanner"
                style={{ color: "inherit", textDecoration: "none" }}
              >
                <Typography
                  fontSize="30px"
                  fontWeight={700}
                  textAlign="center"
                  marginBottom="10px"
                >
                  Menu
                </Typography>
              </Link>
              {/* Image List */}
              <Box
                display="flex"
                flexDirection="row"
                justifyContent="center"
                alignItems="center"
                flexWrap="wrap"
              >
                {bannerimageElementsForMenu}
              </Box>
            </Box>
          </Box>
  
          {/* Feedback */}
        </Box>
      </Box>
    );
  };
  
  export default TeamDashboard;
  