import { Avatar, Box, Button, Grid, Input, Modal, Typography, useTheme } from "@mui/material";
import { tokens } from "../../theme";
import Header from "../../components/Header";
import React, { useMemo, useState } from "react";
import { database, writedb } from "../../firebase-config";
import { onValue, ref } from "firebase/database";
import { AddCircleOutline} from "@mui/icons-material";
import Category from "../addCategory";
import Outlet from "../addOutlet";
import { Link } from "react-router-dom";
import { useEffect } from "react";

import AddNewImage from "../manageOutlet/addNewImage";


const Combos = () => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const [imageVideo, setImageVideo] = useState("Image")
  const [showCategory, setShowCategory] = useState(false);
  const [showAddImage, setShowAddImage] = useState(false);
  const [showCustom, setShowCustom] = useState(false);
  const [showOutlet, setShowOutlet] = useState(false);
  const [vegOrNonVeg, setVegOrNonVeg] = useState("veg");
  const [avatarWidth, setAvatarWidth] = useState(200);
  const [avatarHeight, setAvatarHeight] = useState(200);


  useEffect(() => {
    function handleResize() {
      if (window.innerWidth >= 1200) {
        setAvatarWidth(200);
        setAvatarHeight(200);
      } else if (window.innerWidth >= 900) {
        setAvatarWidth(200);
        setAvatarHeight(200);
      } else if (window.innerWidth >= 770) {
        setAvatarWidth(280);
        setAvatarHeight(280);
      }

      else if (window.innerWidth <= 770 && window.innerWidth >= 700) {
        setAvatarWidth(300);
        setAvatarHeight(300);
      }
      else if (window.innerWidth >= 620) {
        setAvatarWidth(280);
        setAvatarHeight(280);
      }
      else if (window.innerWidth >= 600) {
        setAvatarWidth(250);
        setAvatarHeight(250);
      }
      else if (window.innerWidth >= 550) {
        setAvatarWidth(230);
        setAvatarHeight(230);
      }
      else if (window.innerWidth >= 500) {
        setAvatarWidth(210);
        setAvatarHeight(210);
      }
      else if (window.innerWidth >= 450) {
        setAvatarWidth(180);
        setAvatarHeight(180);
      }
      else if (window.innerWidth >= 400) {
        setAvatarWidth(160);
        setAvatarHeight(160);
      }
      else if (window.innerWidth >= 350) {
        setAvatarWidth(140);
        setAvatarHeight(140);
      }

      else {
        setAvatarWidth(120);
        setAvatarHeight(120);
      }
    }
    window.addEventListener('resize', handleResize);
    handleResize();
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  // ======================     Server Database Management starts  ===================================

  const [vegCategory, setVegCategory] = useState([]);
  const [nonVegCategory, setNonVegCategory] = useState([]);

  const [vegCustom, setVegCustom] = useState([]);
  const [nonVegCustom, setNonVegCustom] = useState([]);

  const [outlet, setOutlet] = useState([]);
  const [showMoveBox, setShowMoveBox] = useState(false); //this is used to get index where we want to move image;
  const [dropLocationOfImage, setDropLocationOfImage] = useState("");
  const [selectedImageIndexNumber, setSelectedImageIndexNumber] = useState("");
  useMemo(() => {
    
      // Category Data Fetch
      onValue(ref(database, "combos/categoryCombos"), (snapshot) => {
        vegCategory.size = 0;
        var vegImages = [];
        var nonVegImages = [];
        vegImages.length = 0;
        nonVegImages.length = 0;
        snapshot.forEach(childSnapshot => {
          var categoryKey = childSnapshot.key;
          var data = childSnapshot.val(); //All data of category is here
          var keys = Object.keys(data)[0]; // name of the category is the key value of childsnapshot
          var v3 = Object.values(data)[0]; // this is used to get images from specific category wrapped with unique id like -NZ...hux
          var keyinv3 = Object.keys(v3);
          keyinv3.forEach(key => {
            if (key == "veg") {
              var veg = Object.values(v3.veg)
              var arr = [];
              veg.forEach(val => {
                if (arr.length < 10) {
                  let value = Object.values(val)
                  if (val.hasOwnProperty("thumbnailImage")) {
                    // let imageKey = val.image.slice(16);
                    // if (snapshotImages.child(imageKey).exists()) {
                    //   let image = snapshotImages.child(imageKey).val();
                    //   if (image.hasOwnProperty("cover")) {
                        arr.push({ image: val.thumbnailImage });
                    //   }
                    // }
                  }
                }
             
              })

              var newItem = { key: categoryKey, value: arr, category: keys }
              vegImages.push(newItem);
            } else if (key == "nonVeg") {
              var nonVeg = Object.values(v3.nonVeg)
              var arr = [];
              nonVeg.forEach(val => {
                if (arr.length < 10) {
                  let value = Object.values(val)
                  if (val.hasOwnProperty("thumbnailImage")) {
                    // let imageKey = val.image.slice(16);
                    // if (snapshotImages.child(imageKey).exists()) {
                    //   let image = snapshotImages.child(imageKey).val();
                    //   if (image.hasOwnProperty("cover")) {
                        arr.push({ image: val.thumbnailImage });
                    //   }
                    // }
                  }
                }
              })
              var newItem = { key: categoryKey, value: arr, category: keys }
              nonVegImages.push(newItem); 
            }
          })




        })

        
        setVegCategory(vegImages);
        setNonVegCategory(nonVegImages);
      })

      
    

  }, [vegCategory.size])










  // ======================     Server Database Management Ends  ===================================

  const catDisplay = () => {
    setShowCategory(showCategory ? false : true);
  }
  const addImageDisplay = () => {
    setShowAddImage(showAddImage ? false : true);
  }
  const customDisplay = () => {
    setShowCustom(!showCustom);
  }
  const outDisplay = () => {
    setShowOutlet(showOutlet ? false : true);
  }

  const handleLoad = (lazyLoadRef) => {

    if (lazyLoadRef.current) {
      lazyLoadRef.current.load();
    }
  };

  const setSelectedImageDataToBeMove = (index) => {

    setSelectedImageIndexNumber(index);
    setShowMoveBox(true)
  }

  const inputStyle = {
    '& label.Mui-focused': {
      color: 'aqua',
    },
    '& .MuiInput-underline:after': {
      borderBottomColor: 'white',
    },
    '& .MuiOutlinedInput-root': {
      '& fieldset': {
        borderColor: 'gray',
        height: '45px'
      },
      '&:hover fieldset': {
        borderColor: 'white',
      },
      '&.Mui-focused fieldset': {
        borderColor: 'rgb(155,155,255)',
      }, '& .MuiInputBase-input': {
        color: 'white',
        fontSize: "16px",
        height: "7px"

      },
    }
  }

  const swapCategory = async () => {
    let arr = vegCategory;
    if (vegOrNonVeg == "nonVeg"){
      arr = nonVegCategory;
    }
    if (dropLocationOfImage.length == 0 || dropLocationOfImage < 1 || dropLocationOfImage > arr.length || selectedImageIndexNumber + 1 == dropLocationOfImage) {
      alert("Invalid");
      return;
    }
    
    



    let firstKey = arr[selectedImageIndexNumber].key
    let secondKey = arr[dropLocationOfImage - 1].key

    let firstName = arr[selectedImageIndexNumber].category
    let secondName = arr[dropLocationOfImage - 1].category

    let firstData;
    let secondData;

    
    await writedb.ref(`combos/categoryCombos/${firstKey}`).once('value').then((snapshot) => {
      firstData = snapshot.val();
      writedb.ref(`combos/categoryCombos/${secondKey}`).once('value').then((snapshot) => {
        secondData = snapshot.val();
        writedb.ref(`combosUniquekeys/categoryCombos/${firstName}`).set({ key: secondKey }).then(() => {
          writedb.ref(`combosUniquekeys/categoryCombos/${secondName}`).set({ key: firstKey }).then(() => {
            writedb.ref(`combos/categoryCombos/${firstKey}`).set(secondData).then(() => {
              writedb.ref(`combos/categoryCombos/${secondKey}`).set(firstData).then(async () => {
                setSelectedImageIndexNumber(dropLocationOfImage - 1);
                alert("Swapped")
              })
            })
          })
        })

      })
    })
  }

  return (
    <Box display="flex" flexDirection="column" m="20px" marginBottom="20px">
      {/* HEADER */}
      <Box display="flex" justifyContent="space-between" alignItems="center">
        <Header title="Combos" subtitle="Welcome to Brand Bite" />
      </Box>
 
      <Modal open={showMoveBox} >
        <Box
          display="flex"
          flexDirection="column"
          justifyContent="center"
          alignItems="center"
          bgcolor="#727681"
          p={3}
        >
          <Box display="flex" flexDirection="column" height="45px" ml="10px">
            <Input autoFocus autoComplete="off" type="number" label="Name" value={dropLocationOfImage} onChange={(event) => {
              setDropLocationOfImage(parseInt(event.target.value))
            }} sx={inputStyle} />
          </Box>

          <Box mt="10px">
            <Button variant="contained" color="primary" sx={{ mr: "10px" }} onClick={swapCategory} >
              Move
            </Button>
            <Button variant="contained" color="primary" onClick={() => { setShowMoveBox(!showMoveBox) }}>
              Close
            </Button>
          </Box>
        </Box>
      </Modal>

      {/* For add new category and outlet system is here */}
      <Box display="flex" borderRadius="10px" marginBottom={imageVideo === "Image" || imageVideo === "Video" ? "0px" : "20px"}>
        <Box marginRight="10px" backgroundColor="blue" borderRadius="20px" paddingRight="5px">
          <Button onClick={addImageDisplay}><Typography display="flex" textAlign="center" fontSize="18px" textTransform="none" color="white" alignItems="center"><AddCircleOutline sx={{ marginRight: "8px" }} />Add New Image</Typography></Button>
        </Box>
        <Box marginRight="10px" backgroundColor="blue" borderRadius="20px" paddingRight="5px">
          <Button onClick={catDisplay}><Typography display="flex" textAlign="center" fontSize="18px" textTransform="none" color="white" alignItems="center"><AddCircleOutline sx={{ marginRight: "8px" }} />Add New Category</Typography></Button>
        </Box>
        
        {/* Category display box starts from here */}
        {showAddImage ? <AddNewImage catDis={addImageDisplay} catGen={"categoryCombos"} parent={"combos"} method="nocat"  myName=" " myKey=" "/> : ""}
        {showCategory ? <Category parent={"combos"} catDis={catDisplay} catOut={"categoryCombos"} /> : ""}
        
        {/* Category display box ends here */}

        {/* outlet display box starts from here */}
        {showOutlet ? <Outlet outDis={outDisplay} /> : ""} 
        {/* outlet display box ends here */}
      </Box>

      {/* Display all categories from server using below code */}


      <Box display={imageVideo === "Image" || imageVideo === "Custom" ? "flex" : "none"}  >
        <Box display="flex" borderRadius="10px" >
          <Button onClick={() => { setVegOrNonVeg("veg") }}><Typography textTransform="none" fontSize="20px" fontWeight="700" color={vegOrNonVeg === "veg" ? colors.greenAccent[500] : colors.grey[100]}>Veg</Typography> </Button>

          <Button onClick={() => { setVegOrNonVeg("nonVeg") }}> <Typography textTransform="none" fontSize="20px" fontWeight="700" color={vegOrNonVeg === "nonVeg" ? colors.greenAccent[500] : colors.grey[100]}>Non-veg</Typography> </Button>
        </Box>
      </Box>

      <Box display={imageVideo === "Image" ? vegOrNonVeg == "veg" ? "flex" : "none" : "none"} flexDirection="column">
        {vegCategory.map((imageUrl, index) => (

          <Box key={imageUrl.category + index} display="flex" flexDirection="column" width="100%" sx={{ background: colors.primary[400] }} marginBottom="20px" paddingLeft="10px" borderRadius="10px">
            <Box display="flex" width="100%">
              <Box flex="1">
                <Typography fontSize="25px" fontWeight="700" margin="10px 0" >{imageUrl.category}</Typography>
              </Box>
              <Box margin="10px" >
                <Button variant="contained" sx={{ background: "green" }} onClick={() => { setSelectedImageDataToBeMove(index) }} >
                  Move
                </Button>
              </Box>
            </Box>
            <Box display="flex" overflow="auto"  >
              <Grid container maxHeight="300px" overflow="auto">
                {
                  imageUrl.value.reverse().map((vinay, index) => (
                    <Grid item key={index + "nonVeg"} xs={6} sm={6} md={4} lg={3} xl={2.4} >
                      <Box key={vinay.image + index} >
                        <Link to={"edit/category/" + imageUrl.key}>

                          <Avatar src={vinay.image} sx={{
                            width: avatarWidth, height: avatarHeight, marginRight: "15px", marginBottom: "10px", borderRadius: "5%", cursor: "pointer", '& > img': {
                              objectFit: 'fill',
                              width: '100%',
                              height: '100%',
                            }, ":hover": { bgcolor: "white" }
                          }}>
                          </Avatar>
                        </Link>
                      </Box>
                    </Grid>
                  ))
                }
              </Grid>
            </Box>
          </Box>
        ))}
      </Box>


      <Box display={imageVideo === "Image" ? vegOrNonVeg == "nonVeg" ? "flex" : "none" : "none"} flexDirection="column">
        {nonVegCategory.map((imageUrl, index) => (

          <Box key={imageUrl.category + index} display="flex" flexDirection="column" width="100%" sx={{ background: colors.primary[400] }} marginBottom="20px" paddingLeft="10px" borderRadius="10px">
            
            <Box display="flex" width="100%">
              <Box flex="1">
                <Typography fontSize="25px" fontWeight="700" margin="10px 0" >{imageUrl.category}</Typography>
              </Box>
              <Box margin="10px" >
                <Button variant="contained" sx={{ background: "green" }}  onClick={() => { setSelectedImageDataToBeMove(index) }}>
                  Move
                </Button>
              </Box>
            </Box>
            <Box display="flex" overflow="auto"  >
              <Grid container maxHeight="300px" overflow="auto">
                {
                  imageUrl.value.reverse().map((vinay, index) => (
                    <Grid item key={index + "nonVeg"} xs={6} sm={6} md={4} lg={3} xl={2.4} >
                      <Box key={vinay.image + index} >
                        <Link to={"edit/category/" + imageUrl.key}>

                          <Avatar src={vinay.image} sx={{
                            width: avatarWidth, height: avatarHeight, marginRight: "15px", marginBottom: "10px", borderRadius: "5%", cursor: "pointer", '& > img': {
                              objectFit: 'fill',
                              width: '100%',
                              height: '100%',
                            }, ":hover": { bgcolor: "white" }
                          }}></Avatar>

                        </Link>
                      </Box>
                    </Grid>
                  ))
                }
              </Grid>
            </Box>
          </Box>))}

      </Box>




    </Box>

  );
};

export default Combos;
