import { Avatar, Box, Button, Grid, Input, Modal, Typography, useTheme } from "@mui/material";
import { tokens } from "../../theme";
import Header from "../../components/Header";
import React, { useMemo, useState } from "react";
import { database, writedb } from "../../firebase-config";
import { onValue, ref } from "firebase/database";
import { AddCircleOutline, CloseRounded } from "@mui/icons-material";
import Category from "../addCategory";
import Outlet from "../addOutlet";
import { Link } from "react-router-dom";
import { useEffect } from "react";
import LazyLoad from "react-lazyload";
import AddNewImage from "../manageOutlet/addNewImage";


const Menu = () => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const [imageVideo, setImageVideo] = useState("Outlet")
  const [showCategory, setShowCategory] = useState(false);
  const [showAddImage, setShowAddImage] = useState(false);
  const [showCustom, setShowCustom] = useState(false);
  const [showOutlet, setShowOutlet] = useState(false);
  const [avatarWidth, setAvatarWidth] = useState(200);
  const [avatarHeight, setAvatarHeight] = useState(300);
  const [selectedImageIndexNumber, setSelectedImageIndexNumber] = useState("");
  const [showMoveBox, setShowMoveBox] = useState(false); //this is used to get index where we want to move image;
  const [dropLocationOfImage, setDropLocationOfImage] = useState("");
  const [close, setClose] = useState(false);
  useEffect(() => {
    function handleResize() {
      if (window.innerWidth >= 1200) {
        setAvatarWidth(200);
        setAvatarHeight(300);
      } else if (window.innerWidth >= 900) {
        setAvatarWidth(200);
        setAvatarHeight(300);
      } else if (window.innerWidth >= 770) {
        setAvatarWidth(280);
        setAvatarHeight(380);
      }

      else if (window.innerWidth <= 770 && window.innerWidth >= 700) {
        setAvatarWidth(300);
        setAvatarHeight(400);
      }
      else if (window.innerWidth >= 620) {
        setAvatarWidth(280);
        setAvatarHeight(380);
      }
      else if (window.innerWidth >= 600) {
        setAvatarWidth(250);
        setAvatarHeight(350);
      }
      else if (window.innerWidth >= 550) {
        setAvatarWidth(230);
        setAvatarHeight(330);
      }
      else if (window.innerWidth >= 500) {
        setAvatarWidth(210);
        setAvatarHeight(310);
      }
      else if (window.innerWidth >= 450) {
        setAvatarWidth(180);
        setAvatarHeight(280);
      }
      else if (window.innerWidth >= 400) {
        setAvatarWidth(160);
        setAvatarHeight(260);
      }
      else if (window.innerWidth >= 350) {
        setAvatarWidth(140);
        setAvatarHeight(240);
      }

      else {
        setAvatarWidth(120);
        setAvatarHeight(220);
      }
    }
    window.addEventListener('resize', handleResize);
    handleResize();
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  // ======================     Server Database Management starts  ===================================

  const [vegCategory, setVegCategory] = useState([]);
  const [nonVegCategory, setNonVegCategory] = useState([]);

  const [vegCustom, setVegCustom] = useState([]);
  const [nonVegCustom, setNonVegCustom] = useState([]);

  const [outlet, setOutlet] = useState([]);

  useMemo(() => {
    onValue(ref(database, "images"), (snapshotImages) => {
      // Category Data Fetch
      // outlet data fetch
      onValue(ref(database, "menu/categoryMenu"), (snapshot) => {
        outlet.size = 0;
        let urls = [];
        snapshot.forEach(childSnapshot => {
          var outletKey = childSnapshot.key;
          const data = childSnapshot.val(); //All data of outlet is here
          const keys = Object.keys(data)[0]; // name of the outlet is the key value of childsnapshot
          var v3 = Object.values(data)[0]; // this is used to get images from specific outlet wrapped with unique id like -NZ...hux
          var v4 = Object.values(v3).reverse(); // This is used to extract images from unique id of server
          var arr = [];
          v4.forEach(val => { // there is multiple images in our system but here we need cover image or thumbnail of that so this is used to get just cover imag...
            if (arr.length < 10) {

              if (val.hasOwnProperty("image")) {
                let imageKey = val.image.slice(20);

                let imageEx = snapshotImages.child("categoryMenu").child(imageKey);
                if (imageEx.exists()) {

                  if (imageEx.child("cover").exists()) {
                    let image = imageEx.child("cover").child("image").val();
                    arr.push({ image: image });
                  }
                }

              }
            }


          })

          const newItem = { key: outletKey, outlet: keys, value: arr }
          
          urls.push(newItem);

        })
        urls.reverse()

        setOutlet(urls);

      })
    })

  }, [vegCategory.size])










  // ======================     Server Database Management Ends  ===================================

  const catDisplay = () => {
    setShowCategory(showCategory ? false : true);
  }
  const addImageDisplay = () => {
    setShowAddImage(showAddImage ? false : true);
  }
  const customDisplay = () => {
    setShowCustom(!showCustom);
  }
  const outDisplay = () => {
    setShowOutlet(showOutlet ? false : true);
  }

  const handleLoad = (lazyLoadRef) => {

    if (lazyLoadRef.current) {
      lazyLoadRef.current.load();
    }
  };

  const setSelectedImageDataToBeMove = (index) => {

    setSelectedImageIndexNumber(index);
    setShowMoveBox(true)
  }

  const handleClose = () => {
    setClose(false)
  };

  const inputStyle = {
    '& label.Mui-focused': {
      color: 'aqua',
    },
    '& .MuiInput-underline:after': {
      borderBottomColor: 'white',
    },
    '& .MuiOutlinedInput-root': {
      '& fieldset': {
        borderColor: 'gray',
        height: '45px'
      },
      '&:hover fieldset': {
        borderColor: 'white',
      },
      '&.Mui-focused fieldset': {
        borderColor: 'rgb(155,155,255)',
      }, '& .MuiInputBase-input': {
        color: 'white',
        fontSize: "16px",
        height: "7px"

      },
    }
  }


  const swapCategory = async () => {
    if (dropLocationOfImage.length == 0 || dropLocationOfImage < 1 || dropLocationOfImage > outlet.length || selectedImageIndexNumber + 1 == dropLocationOfImage) {
      alert("Invalid");
      return;
    }
    let arr = outlet;



    let firstKey = arr[selectedImageIndexNumber].key
    let secondKey = arr[dropLocationOfImage - 1].key

    let firstName = arr[selectedImageIndexNumber].outlet
    let secondName = arr[dropLocationOfImage - 1].outlet

    let firstData;
    let secondData;

    


    await writedb.ref(`menu/categoryMenu/${firstKey}`).once('value').then((snapshot) => {
      firstData = snapshot.val();
      writedb.ref(`menu/categoryMenu/${secondKey}`).once('value').then((snapshot) => {
        secondData = snapshot.val();
        writedb.ref(`menuUniquekeys/categoryMenu/${firstName}`).set({ key: secondKey }).then(() => {
          writedb.ref(`menuUniquekeys/categoryMenu/${secondName}`).set({ key: firstKey }).then(() => {
            writedb.ref(`menu/categoryMenu/${firstKey}`).set(secondData).then(() => {
              writedb.ref(`menu/categoryMenu/${secondKey}`).set(firstData).then(async () => {
                setSelectedImageIndexNumber(dropLocationOfImage - 1);
                alert("Category Swapped")
              })
            })
          })
        })

      })
    })
  }




  return (
    <Box display="flex" flexDirection="column" m="20px" marginBottom="20px">
      {/* HEADER */}
      <Box display="flex" justifyContent="space-between" alignItems="center">
        <Header title="Menu" subtitle="Welcome to Brand Bite" />
      </Box>


      <Modal open={showMoveBox} onClose={handleClose}>
        <Box
          display="flex"
          flexDirection="column"
          justifyContent="center"
          alignItems="center"
          bgcolor="#727681"
          p={3}
        >
          <Box display="flex" flexDirection="column" height="45px" ml="10px">
            <Input autoFocus autoComplete="off" type="number" label="Name" value={dropLocationOfImage} onChange={(event) => {
              setDropLocationOfImage(parseInt(event.target.value))
            }} sx={inputStyle} />
          </Box>

          <Box mt="10px">
            <Button variant="contained" color="primary" sx={{ mr: "10px" }} onClick={swapCategory}>
              Move
            </Button>
            <Button variant="contained" color="primary" onClick={() => { setShowMoveBox(!showMoveBox) }}>
              Close
            </Button>
          </Box>
        </Box>
      </Modal>


      {/* For add new category and outlet system is here */}
      <Box display="flex" borderRadius="10px" marginBottom={imageVideo === "Image" || imageVideo === "Video" ? "0px" : "20px"}>
        <Box marginRight="10px" backgroundColor="blue" borderRadius="20px" paddingRight="5px">
          <Button onClick={addImageDisplay}><Typography display="flex" textAlign="center" fontSize="18px" textTransform="none" color="white" alignItems="center"><AddCircleOutline sx={{ marginRight: "8px" }} />Add New Image</Typography></Button>
        </Box>
        <Box marginRight="10px" backgroundColor="blue" borderRadius="20px" paddingRight="5px">
          <Button onClick={catDisplay}><Typography display="flex" textAlign="center" fontSize="18px" textTransform="none" color="white" alignItems="center"><AddCircleOutline sx={{ marginRight: "8px" }} />Add New Category</Typography></Button>
        </Box>

        {/* Category display box starts from here */}
        {showAddImage ? <AddNewImage method="nocat" catDis={addImageDisplay} catGen={"categoryMenu"} parent={"menu"}  myName=" " myKey=" "/> : ""}
        {showCategory ? <Category parent={"menu"} catDis={catDisplay} catOut={"categoryMenu"} /> : ""}
        {/* Category display box ends here */}
      </Box>

      {/* Display all general from server using below code */}

      <Box display="flex" flexDirection="column">
        {outlet.map((imageUrl, index) => (

          <Box key={imageUrl.outlet + index} display="flex" flexDirection="column" sx={{ background: colors.primary[400] }} marginBottom="20px" paddingLeft="10px" borderRadius="10px">

            <Box display="flex" width="100%">
              <Box flex="1">
                <Typography fontSize="25px" fontWeight="700" margin="10px 0" >{imageUrl.outlet}</Typography>
              </Box>
              <Box margin="10px" >
                <Button variant="contained" sx={{ background: "green" }} onClick={() => { setSelectedImageDataToBeMove(index) }} >
                  Move
                </Button>
              </Box>
            </Box>
            <Box display="flex" overflow="auto"  >
              <Grid container maxHeight="400px" overflow="auto">
                {
                  imageUrl.value.reverse().map((vinay, index) => (
                    <Grid item key={index + "nonVeg"} xs={12} sm={6} md={4} lg={3} xl={3} >
                      <Box key={vinay.image + index} >

                        <Link to={"edit/category/" + imageUrl.key}><Avatar src={vinay.image} sx={{
                          width: avatarWidth, height: avatarHeight, marginRight: "15px", marginBottom: "10px", borderRadius: "5%", cursor: "pointer", '& > img': {
                            objectFit: 'fill',
                            width: '100%',
                            height: '100%',
                          }, ":hover": { bgcolor: "white" }
                        }}></Avatar></Link>
                      </Box>
                    </Grid>
                  ))
                }
              </Grid>
            </Box>
          </Box>))}
      </Box>

    </Box>

  );
};

export default Menu;
