import { Avatar, Box, Button, CircularProgress, MenuItem, Select, TextField, Typography, useTheme } from "@mui/material";
import { tokens } from "../../theme";
import React, { useState } from "react";
import { AddCircleOutline, CloseRounded, FormatAlignCenterRounded, FormatAlignLeftRounded, FormatAlignRightRounded, FormatBoldRounded, FormatItalicRounded, FormatUnderlined, Grid4x4Outlined } from "@mui/icons-material";
import { useRef } from "react";
import NewImage from "./newImage";
import { storage, writedb } from "../../firebase-config"
import html2canvas from 'html2canvas';
import NewText from "./newText";
import { useEffect } from "react";
import * as htmlToImage from 'html-to-image';
import fontFamilies from "../tools/fontFamilies";

const Outlet = ({ outDis }) => { //Out display is used to close add outlet window from close button
    const theme = useTheme();
    const colors = tokens(theme.palette.mode);
    const [selectImage, setSeletImage] = useState(false);
    const [coverSelect, setCoverSelect] = useState(false);
    const fileInput = useRef(null);
    const bgInput = useRef(null);
    const coverInput = useRef(null);
    const [imageList, setImageList] = useState([]);
    const [mainBoxArray, setmainBoxArray] = useState([]); // This array is used to store movable images inside server
    const [textMainArray, setTextMainArray] = useState([]); // This array is used to store editable text in server...
    const [background, setBackground] = useState([]);
    const [outletName, setOutName] = useState("");
    const [currenSelection, setCurrSelection] = useState({ height: 50, width: 50, left: 1, top: 1 });
    const [height, setHeight] = useState(0);
    const [width, setWidth] = useState(0);
    const [top, setTop] = useState(0);
    const [left, setLeft] = useState(0);
    const [outNameSel, setOutNameSel] = useState(false);
    const [showAddText, setShowAddText] = useState(false);
    const [addTextData, setAddTextData] = useState("");  // This is the text data we want to add in our main editing box
    const [fontFamily, setFontFamily] = useState('Montserrat'); //This is the subpart of text data
    const [fontSize, setFontSize] = useState(16); // ||
    const [fontWeight, setFontWeight] = useState(300); // ||
    const [fontItalic, setFontItalic] = useState(false); // ||
    const [fontGravity, setFontGravity] = useState("start"); // ||
    const [fontUnderline, setFontUnderline] = useState(false); // ||
    const [fontColor, setFontColor] = useState("#ffffff"); // ||
    const [isEditText, setIsEditText] = useState(false);
    const [editTextIndex, setEditTextIndex] = useState(); // Index of array where this text is located
    const [outCover, setOutCover] = useState("");
    const [searchLabels, setSearchLables] = useState(false);
    const [uploadImage, setUploadImage] = useState(false);
    const [labelName, setLabelName] = useState("");
    const [searchAdding, setSearchAdding] = useState(false);
    const [imageKey, setImageKey] = useState("");
    const [generatingPreview,setGeneratingPreview] = useState(false);
    const [showGuide,setShowGuide] = useState(true);


    const generateImage = async (element) => {
        const dataUrl = await htmlToImage.toPng(element);
        uploadToServer(dataUrl) 

    }

    const captureImage = () => {
        setGeneratingPreview(true);
        const element = document.getElementById('imageEditingBoxInAddOutlet');
        generateImage(element)
        // html2canvas(document.getElementById("imageEditingBoxInAddOutlet"))
        //     .then(canvas => {
        //         const dataUrl = canvas.toDataURL('image/png');
        //         uploadToServer(dataUrl)

        //     })
        //     .catch(error => {
        //         console.log('Error:', error);
        //     });



    };

    const handleCoverSelect = (event) => {
        if (event.target.files[0]) {
            const files = event.target.files;
            setOutCover(Array.from(files)[0]);
            setCoverSelect(true)
        }
    }

    const handleFileSelect = (event) => {
        if (event.target.files[0]) {
            setSeletImage(true);
            setOutNameSel(true);
            const files = event.target.files;
            setImageList(Array.from(files));
            setBackground(Array.from(files)[0]);
        } else {
            alert("Image Not selected")
        }
    };

    const bgSelect = (event) => {
        if (event.target.files[0]) {
            const files = event.target.files;
            setBackground(Array.from(files)[0]);
        } else {
            alert("Image Not selected")
        }
    };



    function addImageToMain(index) {
        setmainBoxArray([...mainBoxArray, { file: imageList[index], top: 1, left: 1, height: 50, width: 50, index: mainBoxArray.length }]);
    }

    function resetText() {
        setAddTextData("");
        setFontFamily("Montserrat, sans-serif");
        setFontSize(16);
        setFontWeight(300);
        setFontItalic(false);
        setFontUnderline(false);
        setFontGravity("start")
        setFontColor("#ffffff");

    }

    function deleteText() {
        const newItems = textMainArray.filter((item, i) => i !== editTextIndex);
        setTextMainArray(newItems);
        resetEditText();
    }

    function addTextToMain() {
        if (isEditText) {
            const data = textMainArray[editTextIndex];
            data.text = addTextData;
            data.fontFamily = fontFamily;
            data.fontSize = fontSize;
            data.fontWeight = fontWeight;
            data.fontItalic = fontItalic;
            data.fontUnderline = fontUnderline;
            data.fontColor = fontColor;
            data.fontGravity = fontGravity;
            setTimeout(() => {
                setShowAddText(true)
            }, 5)
            setShowAddText(false)

        }
        else {
            if (addTextData.trim().length > 0) {
                setTextMainArray([...textMainArray, { fontGravity: fontGravity, text: addTextData, top: 150, left: 150, height: 0, width: 0, index: textMainArray.length, fontFamily: fontFamily, fontSize: fontSize, fontWeight: fontWeight, fontItalic: fontItalic, fontUnderline: fontUnderline, fontColor: fontColor }]);
                resetText();
            }
        }

    }


    const addImageToServer = async (dataUrl, dbref, storageRef, outletKey) => {
        try {
            let dbrefPath = dbref;
            dbref = writedb.ref(`images/outlet`);
            storageRef = storage.ref(`images/outlet`);
            const pushref = dbref.push();
            const uniqueKey = pushref.key;
            setImageKey(uniqueKey);
            storageRef = storageRef.child(uniqueKey);
            const task = storageRef.child("background").put(background);
            const blob = await fetch(dataUrl).then(res => res.blob()); // This is for convert image generated from html to blob for upload in firebase
            task.then(
                () => {
                    // complete function
                    storageRef.child("background").getDownloadURL().then((url) => {
                        const finalPath = dbref.child(uniqueKey);
                        const addBg = finalPath.child("background")
                        addBg.set({ image: url }).then(() => {
                            try {

                                storageRef.child("cover").put(blob).then(() => {
                                    storageRef.child("cover").getDownloadURL().then((url) => {
                                        const addCover = finalPath.child("cover");
                                        addCover.set({ image: url, outlet: outletName }).then(async () => {
                                            for (let i = 0; i < mainBoxArray.length; i++) {
                                                const data = mainBoxArray[i];
                                                const imageRef = storageRef.child(`i${i}`);
                                                const imageUpload = imageRef.put(data.file).then(() => {
                                                    imageRef.getDownloadURL().then((url) => {
                                                        const addImage = finalPath.child(`/i${i}`);
                                                        addImage.set({ image: url, height: parseInt(data.height), width: parseInt(data.width), top: parseInt(data.top), left: parseInt(data.left) });

                                                    })
                                                })
                                            }
                                            for (let i = 0; i < textMainArray.length; i++) {
                                                const data = textMainArray[i];
                                                let text = "";
                                                data.text.toString().split("\n").map((line, index) => {
                                                    text = text + line + "\\n"
                                                })
                                                const addText = finalPath.child(`t${i}`);
                                                addText.set({ fontGravity: data.fontGravity.toString(), text: text, fontFamily: data.fontFamily.toString(), fontWeight: parseInt(data.fontWeight), fontItalic: data.fontItalic.toString(), fontUnderline: data.fontUnderline.toString(), fontSize: parseInt(data.fontSize), fontColor: data.fontColor.toString(), top: parseInt(data.top), left: parseInt(data.left) })
                                            }

                                            await dbrefPath.push().set({ image: `images/outlet/${uniqueKey}`,thumbnailImage:url }).then(async () => {
                                                finalPath.child("paths").child(outletKey).set({ key: outletKey }).then(() => {
                                                    setSearchLables(true);
                                                });
                                            });
                                        })
                                    })

                                })

                            } catch (error) {
                                console.error(error.toString());
                            }
                        })
                    })
                }
            );
        } catch (error) {
            console.error(error)
        }
    }




    const uploadToServer = async (dataUrl) => {
        setUploadImage(true);
        const checkOutletExistence = writedb.ref(`homeUniquekeys/outlet/${outletName}`).once('value').then((snapshot) => {
            let checkOut = snapshot.exists().toString();
            let dbref;

            if (checkOut == "true") {
                dbref = writedb.ref(`home/outlet/${snapshot.val().key}/${outletName}`);
                const storageRef = storage.ref(`home/outlet/${snapshot.val().key}/${outletName}`);
                addImageToServer(dataUrl, dbref, storageRef, snapshot.val().key)
            } else {
                let pathToPush = writedb.ref('home/outlet');
                let pushOnPath = pathToPush.push().key;
                writedb.ref(`homeUniquekeys/outlet/${outletName}`).set({ key: pushOnPath.toString() }).then(() => {
                    dbref = writedb.ref(`home/outlet/${pushOnPath}/${outletName}`);
                    const storageRef = storage.ref(`home/outlet/${pushOnPath}/${outletName}`);
                    storage.ref(`home/outlet/${pushOnPath}`).child("coverPhoto").put(outCover).then(() => {
                        storage.ref(`home/outlet/${pushOnPath}`).child("coverPhoto").getDownloadURL().then((url) => {
                            writedb.ref(`home/outlet/${pushOnPath}/cover`).set({ image: url }).then(() => {
                                addImageToServer(dataUrl, dbref, storageRef, pushOnPath);
                            })
                        })
                    })

                });
            }
        })


    }








    function resetAll() {
        setmainBoxArray([]);
        setImageList([]);
        setSeletImage(false);
        setTextMainArray([])
        setBackground("");
    }

    const inputStyle = {
        '& label.Mui-focused': {
            color: 'aqua',
        },
        '& .MuiInput-underline:after': {
            borderBottomColor: 'white',
        },
        '& .MuiOutlinedInput-root': {
            '& fieldset': {
                borderColor: 'gray',
            },
            '&:hover fieldset': {
                borderColor: 'white',
            },
            '&.Mui-focused fieldset': {
                borderColor: 'rgb(155,155,255)',
            }, '& .MuiInputBase-input': {
                color: 'black',
                fontSize: "16px",

            },
        }
    }

    const inputStyleForproperties = {
        width: "80%", marginBottom: "10px", color: "white", '& .MuiInputBase-input': { color: 'white' }, '& label': { color: 'white' },
    }

    // ********* made changes of image such as Height width top left using this functions (Starts) 

    const heightValue = (event) => {
        setHeight(event.target.value)
        if (!currenSelection.fontColor) {
            mainBoxArray[currenSelection.index].height = event.target.value;
        }

    }


    const widthValue = (event) => {
        setWidth(event.target.value)
        if (!currenSelection.fontColor) {
            mainBoxArray[currenSelection.index].width = event.target.value;
        }
    }



    const topValue = (event) => {
        setTop(event.target.value)
        if (currenSelection.fontColor) {
            textMainArray[currenSelection.index].top = event.target.value;
        } else {
            mainBoxArray[currenSelection.index].top = event.target.value;
        }
    }



    const leftValue = (event) => {
        setLeft(event.target.value)
        if (currenSelection.fontColor) {
            textMainArray[currenSelection.index].left = event.target.value;
        } else {
            mainBoxArray[currenSelection.index].left = event.target.value;
        }
    }

    // ========== made changes of image such as Height width top left using this functions (Ends) 

    // ********* This Function call when we drag image inside mainbox using mouse listener attached with that image Starts
    const handleTextChange = async (newText) => {
        await Promise.all([
            setCurrSelection(newText),
        ]);
        setHeight(newText.height);
        setWidth(newText.width);
        setTop(newText.top);
        setLeft(newText.left);

    };
    // ========== This Function call when we drag image inside mainbox using mouse listener attached with that image End 

    const handleTextChangeForText = async (newText) => {
        await Promise.all([
            setCurrSelection(newText),
        ]);

        setHeight(newText.height);
        setWidth(newText.width);

        setTop(newText.top);
        setLeft(newText.left);
    };


    const handleFontFamilyChange = (event) => {
        setFontFamily(event.target.value);
    };

    const editText = (data) => {
        setEditTextIndex(data.index);
        setCurrSelection(data);
        setAddTextData(data.text);
        setFontFamily(data.fontFamily);
        setFontSize(data.fontSize);
        setFontWeight(data.fontWeight);
        setFontGravity(data.fontGravity)
        setFontItalic(data.fontItalic);
        setFontUnderline(data.fontUnderline);
        setFontColor(data.fontColor);
        setIsEditText(true);
        setShowAddText(true);


    }

    const TextDataForAddTextInMain = (event) => {
        setAddTextData(event.target.value);
    }

    const displayAddText = () => {
        if (showAddText) {
            setShowAddText(false)

        } else {
            setAddTextData("");
            setFontFamily("Montserrat, sans-serif");
            setFontSize(16);
            setFontWeight(300);
            setFontItalic(false);
            setFontUnderline(false);
            setFontColor("#ffffff");
            setShowAddText(true);
            setIsEditText(false)
        }

    }

    const resetEditText = () => {
        resetText();
        setIsEditText(false)
    }

    const handleClickOfInput = (input) => {
        if (input.current) {
            input.current.click();
        }
    };

    const addSearch = async () => {
        setSearchAdding(true);


        if (labelName.includes(",")) {
            let arr = labelName.split(",");
            arr.forEach(val => {
                let labelName = val.trim();
                writedb.ref(`search/${labelName}/${imageKey}`).set({ image: `images/outlet/${imageKey}` }).then(() => {
                    writedb.ref(`images/outlet/${imageKey}/labels`).child(labelName).set({ name: labelName }).then(() => {
                        setLabelName("");
                        setSearchAdding(false);
                    })
                });
            })
        } else {
            writedb.ref(`search/${labelName}/${imageKey}`).set({ image: `images/outlet/${imageKey}` }).then(() => {
                writedb.ref(`images/outlet/${imageKey}/labels`).child(labelName).set({ name: labelName }).then(() => {
                    setLabelName("");
                    setSearchAdding(false);
                })
            });
        }
    }


    const handleKeyDown = (event) => {
        if (event.key === "w") {

            currenSelection.height += 5;
            currenSelection.width += 5;
        }
        if (event.key === "s") {

            currenSelection.height -= 5;
            currenSelection.width -= 5;
        }
        if (event.key === "a") {

            currenSelection.height -= 5;

        }
        if (event.key === "d") {

            currenSelection.width -= 5;

        }
        if (event.key === "z") {

            currenSelection.height += 5;

        }
        if (event.key === "x") {

            currenSelection.width += 5;

        }

        setTimeout(() => {
            setHeight(height + 5);
            setWidth(width + 5);

        }, 1)

    };

    useEffect(() => {
        document.addEventListener("keydown", handleKeyDown);
        return () => {
            document.removeEventListener("keydown", handleKeyDown);
        };
    }, [height]);

    return (
        <Box display="flex" position="fixed" top="20%" bottom="20%" left="30%" right="30%" height="400px" width="700px" backgroundColor="#727681" zIndex="20" borderRadius='10px' justifyContent="center" >

            {/* ############## outlet setup Screen 1 starts here ################ */}
            {/* This is close button of add outlet */}
            <Box position="absolute" right="-10px" top="0" display="flex" zIndex="100">
                <Button onClick={outDis} ><CloseRounded sx={{
                    color: colors.primary[100], '&:hover': {
                        color: 'lightgreen'
                    }
                }} /></Button>
            </Box>
            {/* This is close button of add outlet */}
            {/* Enter outlet name here */}
            <Box display={outNameSel ? "none" : "flex"} flexDirection="column" width="700px" height="400px" borderRadius="10px" justifyContent="center" alignItems="center">
                <TextField autoFocus autoComplete="off" label="Outlet Name?" value={outletName} onChange={(event) => {
                    setOutName(event.target.value.charAt(0).toUpperCase() + event.target.value.slice(1))
                }} sx={inputStyle} />
                <input accept="image/*" type="file" onChange={handleCoverSelect} ref={coverInput} style={{ display: "none" }} />
                <Box display={coverSelect ? "none" : "flex"} justifyContent="center" alignItems="center" marginTop="20px" >
                    <Button disabled={outletName.length > 0 ? false : true} onClick={() => {
                        handleClickOfInput(coverInput)
                    }}
                        sx={{ backgroundColor: outletName.length > 0 ? "blue" : "rgb(255,255,255,0.23)", borderRadius: "20px" }}><Typography fontSize="16px" textTransform="none" color="white" padding="5px" textAlign="center" justifyContent="center" display="flex" alignItems="center"><AddCircleOutline sx={{ marginRight: "8px" }} />Cover</Typography></Button>
                </Box>
                <Box display={coverSelect ? "flex" : "none"} marginTop="20px">
                    <Avatar src={coverSelect ? URL.createObjectURL(outCover) : ""} sx={{
                        width: "100px", height: "80px", borderRadius: "5%", border: "2px solid white", '& > img': {
                            objectFit: 'fill',
                            width: '100%',
                            height: '100%',
                        }
                    }} />
                </Box>
            </Box>
            {/* Enter outlet name ends */}
            {/* This is for select image from files */}
            <Box display={selectImage ? "none" : "flex"} height="400px" width="700px" alignItems="center" flexDirection="column" >

                <Typography fontSize="35px" fontWeight="700" marginTop="100px" marginBottom="20px">Add Image</Typography>

                <input accept="image/*" type="file" onChange={handleFileSelect} multiple ref={fileInput} style={{ display: "none" }} />
                <Box display="flex" justifyContent="center" alignItems="center" >
                    <Button disabled={coverSelect ? false : outletName.length > 0 ? true : false} onClick={() => {
                        handleClickOfInput(fileInput)
                    }}
                        sx={{ backgroundColor: coverSelect ? outletName.length > 0 ? "blue" : "rgb(255,255,255,0.23)" : "rgb(255,255,255,0.23)", borderRadius: "20px" }}><Typography fontSize="16px" textTransform="none" color="white" padding="5px" textAlign="center" justifyContent="center" display="flex" alignItems="center"><AddCircleOutline sx={{ marginRight: "8px" }} />Select Images</Typography></Button>
                </Box>
            </Box>
            {/* File select ends here */}

            {/* ############## outlet setup  Screen 2 starts here ################ */}


            {/* ############## editor Screen 2 starts here ################ */}

            {/* This box works after image selection complete starts */}

            <Box display={selectImage && !uploadImage ? "inline-flex" : "none"} height="100%" width="100%">
                {/* Display outlet name eneterd by user starts */}
                <Box display="flex" position="absolute" top="0" paddingLeft="20%" width="100%" justifyContent="center" marginTop="10px">
                    <Typography fontSize="20px" fontWeight="700" >{outletName}</Typography>
                </Box>
                {/* Display outlet name eneterd by user ends */}
                {/* This is for list selected images in editor */}
                <Box display="inline-flex" height="100%" width="25%" >
                    <Box display="flex" flexDirection="column" height="100%" width="100%" justifyContent="start" alignItems="center" overflow="auto" paddingTop="10px" borderRight="2px solid black" className="hideScroll" backgroundColor="#727681" borderRadius="10px">
                        {imageList.map((image, index) => (
                            <Avatar key={image + index} src={URL.createObjectURL(image)} sx={{ width: "80%", height: 80, marginBottom: "10px", borderRadius: "5%", border: "2px solid white" }} onContextMenu={() => { setBackground(image) }} onClick={() => { addImageToMain(index) }} />
                        ))}
                    </Box>
                </Box>
                {/* This is for list selected images in editor ends */}
                <Box display="inline-flex" width="100%" height="100%" justifyContent="center" alignItems="center" >
                    {/* When You add image inside 300 * 300 editor then this trigger  */}
                    <Box display="flex" width="400px" height="300px" backgroundColor={colors.primary[500]} >
                        <Box width="400px" height="300px"  >
                            {/* This is fixed background image of editor */}
                            <Box position="absolute" width="400px" height="300px" zIndex="0">
                                <Box display="flex" position="absolute" >
                                    <Box id="imageEditingBoxInAddOutlet" display="flex" flexDirection='column' position="absolute" width="300px" height="300px" color="white" zIndex="20" overflow="hidden">
                                        <Avatar id="backgroundImage" src={imageList.length > 0 ? URL.createObjectURL(background) : ""} variant="square" sx={{
                                            width: "300px", height: "300px", position: "absolute", '& > img': {
                                                objectFit: 'fill',
                                                width: '100%',
                                                height: '100%',
                                            }
                                        }} />
                                        {mainBoxArray.map((item, index) => (
                                            <Box key={item.file.name + index} >

                                                <NewImage element={mainBoxArray} onTextChange={handleTextChange} index={index} onMouseDown={() => { setCurrSelection(mainBoxArray[index]) }} />
                                            </Box>
                                        ))}
                                        {textMainArray.map((item, index) => (
                                            <Box key={item.text + index} >
                                                <NewText key={item + index} element={textMainArray} onTextChange={handleTextChangeForText} index={index} editText={editText} />
                                            </Box>
                                        ))}
                                    </Box>

                                    <Box display={showGuide?"flex":"none"} >
                                        <Box id="horizontalline1" display="flex" position="absolute" top="150px" width="290px" height="1px" zIndex="50" bgcolor="red"></Box>



                                        <Box id="verticalline1" display="flex" position="absolute" left="145px" width="1px" height="300px" zIndex="50" bgcolor="red"></Box>

                                    </Box>
                                </Box>
                            </Box>

                            {/* Fixed background image of editor ends here */}

                            {/* Height and width of the selected image inside main box editor detais */}
                            <Box display="flex" flexDirection="column" margin="20px 0" marginLeft="300px" justifyContent="center" alignItems="center">
                                <Box display="flex" flexDirection="column" alignItems="center">
                                    <TextField autoComplete="off" type="number" variant="standard" color="secondary" label="Height" value={height} sx={inputStyleForproperties} onChange={(e) => { heightValue(e) }} />

                                    <TextField autoComplete="off" type="number" variant="standard" color="secondary" label="Width" value={width} sx={inputStyleForproperties} onChange={(e) => { widthValue(e) }} />

                                    <TextField autoComplete="off" type="number" variant="standard" color="secondary" label="Top" value={top} sx={inputStyleForproperties} onChange={(e) => { topValue(e) }} />

                                    <TextField autoComplete="off" type="number" variant="standard" color="secondary" label="Left" value={left} sx={inputStyleForproperties} onChange={(e) => { leftValue(e) }} />
                                </Box>



                            </Box>
                            {/* Height and width of the selected image inside main box editor detais ends */}

                        </Box>
                    </Box>

                    {/* Add Image to Main Editing box Ends here   */}

                    {/* For change background and add Text */}
                    <Box display="flex" position="absolute" bottom="0" marginBottom="5px" left="0" borderRadius="10px" marginLeft="22%" padding="5px">
                        <input accept="image/*" type="file" onChange={bgSelect} ref={bgInput} style={{ display: "none" }} />
                        <Box backgroundColor="yellow" marginRight="10px" display="flex" alignItems="center" padding="3px" borderRadius="5px">
                            <Typography color="black" sx={{ userSelect: "none", cursor: "pointer" }} onClick={() => {
                                handleClickOfInput(bgInput)
                            }}>Change Background</Typography>
                        </Box>
                        <Box backgroundColor="yellow" padding="3px" display="flex" alignItems="center" borderRadius="5px">
                            <Typography onClick={() => { displayAddText() }} color="black" sx={{ userSelect: "none", cursor: "pointer" }}>Add Text</Typography>
                        </Box>
                        <Box backgroundColor="yellow" padding="2px"  display="flex" justifyContent="center" alignItems="center" borderRadius="5px" ml="10px">
                            <Typography onClick={() => {setShowGuide(!showGuide) }} color="black" sx={{ userSelect: "none", cursor: "pointer" }}><Grid4x4Outlined sx={{pt:"5px"}}/></Typography>
                        </Box>
                    </Box>
                    {/* For change background and add Text */}

                    {/* For upload imaege to server */}
                    <Box display="flex" position="absolute" bottom="0" marginBottom="10px" backgroundColor="yellow" right="0" borderRadius="10px" marginRight="20px" >
                        <Box display={generatingPreview ? "none" : "flex"}>
                            <Button onClick={captureImage}>Upload Image to server</Button>
                        </Box>

                        <Box width="100px" display={generatingPreview ? "flex" : "none"} justifyContent="center" >
                            <Box display="flex" >
                                <CircularProgress style={{ height: "30px", width: "30px", padding: "5px" }} />
                            </Box>
                        </Box>

                        {/* Here i use capture image first and then upload to server because i need to create final image of all editor and then uplod. */}
                    </Box>
                    {/* For upload imaege to server */}

                </Box>

            </Box>


            {/* This box works after image selection complete ends*/}

            {/* ############## editor Screen 2 ends here ################ */}

            <Box display="flex" width="250px" overflow="auto" height="100%" position="fixed" top="0" right={showAddText ? "0" : "-250px"} sx={{ background: colors.primary[400], transition: "0.3s all ease-in-out" }} justifyContent="start" flexDirection="column" padding="0px 20px" paddingTop="100px">
                <Box position="fixed" right="-10px" top="0" display={showAddText ? "flex" : "none"} zIndex="100">
                    <Button onClick={() => { showAddText ? setShowAddText(false) : setShowAddText(true) }} ><CloseRounded sx={{
                        color: colors.primary[100], '&:hover': {
                            color: 'blue'
                        }
                    }} /></Button>
                </Box>

                <TextField autoComplete="off" label="Enter Text" multiline value={addTextData} onChange={(event) => { TextDataForAddTextInMain(event) }}
                    sx={{ inputStyle }} />

                <Typography color={fontColor} sx={{ textAlign: fontGravity, fontFamily: fontFamily, margin: "20px 0", fontSize: fontSize + "px", fontWeight: fontWeight, fontStyle: fontItalic ? "italic" : "", textDecoration: fontUnderline ? "underline" : "" }} >
                    {addTextData.split("\n").map((line, index) => {
                        return (
                            <React.Fragment key={index}>
                                {line}
                                <br />
                            </React.Fragment>
                        );
                    })}
                </Typography>

                <Select value={fontFamily} onChange={handleFontFamilyChange} >
                    {fontFamilies.map((option) => (
                        <MenuItem key={option.value} value={option.value}>
                            <Typography sx={{ fontFamily: option.value }}>{option.label}</Typography>
                        </MenuItem>
                    ))}
                </Select>

                <Box display="flex" marginTop="20px" border="2px solid black" width="100%">
                    <Box display="flex" justifyContent="center" alignItems="center" width="20%" height="35px" borderRight="2px solid black" onClick={() => { fontWeight == 700 ? setFontWeight(300) : setFontWeight(700) }} backgroundColor={fontWeight == 700 ? "white" : ""} color={fontWeight == 700 ? "black" : "white"} sx={{ cursor: "pointer" }} >
                        <FormatBoldRounded />
                    </Box>
                    <Box display="flex" justifyContent="center" alignItems="center" width="20%" height="35px" borderRight="2px solid black" onClick={() => { fontItalic ? setFontItalic(false) : setFontItalic(true) }} backgroundColor={fontItalic ? "white" : ""} color={fontItalic ? "black" : "white"} sx={{ cursor: "pointer" }} >
                        <FormatItalicRounded />
                    </Box>
                    <Box display="flex" justifyContent="center" alignItems="center" width="20%" height="35px" borderRight="2px solid black" onClick={() => { fontUnderline ? setFontUnderline(false) : setFontUnderline(true) }} backgroundColor={fontUnderline ? "white" : ""} color={fontUnderline ? "black" : "white"} sx={{ cursor: "pointer" }} >
                        <FormatUnderlined />
                    </Box>
                    <Box display="flex" width="40%" height="35px" alignItems="center">
                        <TextField placeholder="size" type="number" inputProps={{ max: 40, min: 5 }} value={fontSize} onChange={(event) => { setFontSize(event.target.value) }}
                            sx={{
                                '& label.Mui-focused': {
                                    color: 'aqua',
                                },
                                '& .MuiInput-underline:after': {
                                    borderBottomColor: 'white',
                                },
                                '& .MuiOutlinedInput-root': {
                                    '& fieldset': {
                                        border: "none",

                                    },
                                    '&:hover fieldset': {
                                        border: "none",

                                    },
                                    '&.Mui-focused fieldset': {
                                        border: "none",

                                    }, '& .MuiInputBase-input': {
                                        color: 'white',
                                        fontSize: "15px",
                                        border: "none"

                                    }
                                }
                            }} />
                    </Box>
                </Box>




                <Box display="inline-flex" marginTop="20px"  >
                    <Box display='inline-flex' border="2px solid black">
                        <Box display="flex" justifyContent="center" alignItems="center" width="40px" height="35px" borderRight="2px solid black" onClick={() => { setFontGravity("start") }} backgroundColor={fontGravity == "start" ? "white" : ""} color={fontGravity == "start" ? "black" : "white"} sx={{ cursor: "pointer" }} >
                            <FormatAlignLeftRounded />
                        </Box>

                        <Box display="flex" justifyContent="center" alignItems="center" width="40px" height="35px" borderRight="2px solid black" onClick={() => { setFontGravity("center") }} backgroundColor={fontGravity == "center" ? "white" : ""} color={fontGravity == "center" ? "black" : "white"} sx={{ cursor: "pointer" }} >
                            <FormatAlignCenterRounded />
                        </Box>

                        <Box display="flex" justifyContent="center" alignItems="center" width="40px" height="35px" onClick={() => { setFontGravity("end") }} backgroundColor={fontGravity == "end" ? "white" : ""} color={fontGravity == "end" ? "black" : "white"} sx={{ cursor: "pointer" }} >
                            <FormatAlignRightRounded />
                        </Box>

                    </Box>
                </Box>



                <Box display="flex" marginTop="20px" width="100%" >
                    <TextField
                        label="color"
                        type="color"
                        value={fontColor}
                        onChange={(event) => { setFontColor(event.target.value) }}
                        InputLabelProps={{
                            shrink: true,
                        }}
                        sx={{ width: "50%", height: "50%" }}
                    />
                </Box>

                <Box display="flex" marginTop="20px">
                    <Box display="flex" backgroundColor="blue" padding="8px" borderRadius="18px" alignItems="center">
                        <Typography onClick={() => {
                            addTextToMain()
                        }} color="white" sx={{ userSelect: "none" }} fontSize="15px">{isEditText ? "Edit Text" : "Add Text"}</Typography>
                    </Box>

                    <Box display={isEditText ? "flex" : "none"} ml="10px" backgroundColor="blue" padding="8px" borderRadius="18px" alignItems="center">
                        <Typography onClick={() => {
                            deleteText()
                        }} color="white" sx={{ userSelect: "none" }} fontSize="15px">Delete</Typography>
                    </Box>

                    <Box display={isEditText ? "flex" : "none"} marginLeft="10px">
                        <Box display="flex" backgroundColor="blue" padding="8px" borderRadius="18px" alignItems="center">

                            <Typography onClick={() => {
                                resetEditText()
                            }} color="white" sx={{ userSelect: "none" }} fontSize="15px">Reset</Typography>
                        </Box>
                    </Box>
                </Box>



            </Box>

            <Box display="flex" position="absolute" bottom="0" marginBottom="20px">
                <Typography display={outletName.length > 0 ? "none" : "flex"}>
                    Enter Outlet Name to continue
                </Typography>
                <Typography display={coverSelect ? "none" : outletName.length > 0 ? "flex" : "none"}>
                    Select cover photo to continue
                </Typography>
                <Typography display={coverSelect > 0 ? outletName.length > 0 ? selectImage ? "none" : "flex" : "none" : "none"}>
                    Select Images to continue
                </Typography>
            </Box>

            <Box display={uploadImage && !searchLabels ? "flex" : "none"} justifyContent="center" flexDirection="column" alignItems="center">
                <Typography >Image Uploading</Typography>
                <CircularProgress sx={{ padding: "7px", mt: "10px" }} />
            </Box>


            <Box display={searchLabels ? "flex" : "none"} height="100%" width="100%" justifyContent="center" alignItems="center" flexDirection='column'>
                <TextField autoFocus autoComplete="off" label="Enter Label?" value={labelName} onChange={(event) => {
                    setLabelName(event.target.value.charAt(0).toUpperCase() + event.target.value.slice(1))
                }} sx={inputStyle} />
                <Box display={searchAdding ? "none" : "flex"} mt="20px" bgcolor={labelName.length > 0 ? "blue" : "rgb(255,255,255,0.23)"} borderRadius="10px">
                    <Button disabled={labelName.length > 0 ? false : true} onClick={() => { addSearch() }}>
                        <Typography textTransform="none" color="white">Add Search Label</Typography>
                    </Button>
                </Box>
                <Box display={searchAdding ? "flex" : "none"} mt="20px" borderRadius="10px">
                    <CircularProgress />
                </Box>
                <Box display="flex" position="absolute" bottom="0" mb="10px">At least one label is required otherwise image not view in Search</Box>
            </Box>


        </Box>
    );







};

export default Outlet;
