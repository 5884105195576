import { useTheme } from '@emotion/react';
import React from 'react'
import { tokens } from '../../theme';
import { Box, Button, Grid, Input, Modal, TextField, Typography } from '@mui/material';
import Header from '../../components/Header';
import { AddCircleOutline, South } from '@mui/icons-material';
import { useState } from 'react';
import { database, writedb } from '../../firebase-config';
import { useMemo } from 'react';
import { onValue, ref } from 'firebase/database';
import { number } from 'yup';

const Sales = () => {
    const theme = useTheme();
    const colors = tokens(theme.palette.mode);

    const [open, setOpen] = useState(false);
    const [salesData, setShowSalesData] = useState(false);
    const [personName, setPersonName] = useState('');
    const [refCode, setRefCode] = useState('');
    const [salesManList, setSalesManList] = useState([]);
    const [salesDataArr, setSalesDataArr] = useState([]);
    const [isCopied, setIsCopied] = useState(false);
    useMemo(() => {
        onValue(ref(database, `salesMan`), (snapshot) => {
            if (snapshot.exists()) {
                const snapVal = snapshot.val();
                let keys = Object.keys(snapVal);
                let values = Object.values(snapVal);
                let arr = [];
                values.forEach((val, index) => {
                    if (val.hasOwnProperty("details")) {
                        let name = val.details.name;
                        let key = keys[index];
                        let salesCount = 0;
                        if (val.hasOwnProperty("salesCount")) {
                            salesCount = Object.keys(val.salesCount).length;
                        }
                        let newItem = { name: name, ref: key, salesCount: salesCount };
                        arr.push(newItem);
                    }
                })
                setSalesManList(arr.reverse());

            }
        })
    }, [salesManList.length])


    const handleClose = () => {
        setOpen(false);
        setShowSalesData(false);
    }

    const inputStyle = {
        '& label.Mui-focused': {
            color: 'aqua',
        },
        '& .MuiInput-underline:after': {
            borderBottomColor: 'white',
        },
        '& .MuiOutlinedInput-root': {
            '& fieldset': {
                borderColor: 'gray',
                height: '45px'
            },
            '&:hover fieldset': {
                borderColor: 'white',
            },
            '&.Mui-focused fieldset': {
                borderColor: 'rgb(155,155,255)',
            }, '& .MuiInputBase-input': {
                color: 'white',
                fontSize: "16px",
                height: "7px"

            },
        }
    }

    const generateReferCode = () => {
        const referCodeLength = 6;
        const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789';
        let referCode = '';

        for (let i = 0; i < referCodeLength; i++) {
            const randomIndex = Math.floor(Math.random() * characters.length);
            referCode += characters.charAt(randomIndex);
        }

        return referCode;
    };

    const handelOpen = () => {
        setOpen(!open);
        setRefCode(generateReferCode);
    }

    async function addSalesPerson() {
        if (personName.length > 0) {
            let check = await writedb.ref(`salesMan/${refCode}`).once('value');
            if (check.exists()) {
                alert("Refer Code Already Exist")
            } else {
                writedb.ref(`salesMan/${refCode}/details`).set({ name: personName }).then(() => {

                    handleClose();
                    setPersonName("");
                })
            }
        } else {
            alert("Please Enter Person Name")
        }
    }

    function deletePerson(key) {
        let check = window.confirm("Are you sure to delete this user?");
        if (check) {
            writedb.ref(`salesMan/${key}`).remove();
        }
    }

    function showSalesData(refcode) {
        writedb.ref(`salesMan/${refcode}/salesCount`).once('value').then((snapshot) => {
            if (snapshot ?? false) {
                // snapshot exists, continue with your logic
                if (snapshot.exists()) {
                    let numbers = Object.keys(snapshot.val());
                    //   let doubledNumbers = [];
                    //   for (let i = 0; i < 20; i++) {
                    //     doubledNumbers = [...doubledNumbers, ...numbers];
                    //   }
                    setSalesDataArr(numbers);
                    setShowSalesData(true);
                } else {
                    alert("User Found, but data not found.");
                }
            } else {
                // snapshot is null or undefined, handle the error
                alert("An error occurred while fetching data.");
            }
        }).catch((error) => {
            // Catch any error that might occur during the data retrieval
            alert("An error occurred while fetching data.");
        });
    }

    const copyToClipboard = (val) => {
        try {
            navigator.clipboard.writeText(val);
            setIsCopied(true);

            // Reset the isCopied state after 2 seconds to hide the animation
            setTimeout(() => {
                setIsCopied(false);
            }, 2000);
        } catch (error) {
            alert('Copying to clipboard failed. Please try again.');
        }
    };


    return (
        <Box display="flex" flexDirection="column" m="20px" marginBottom="20px">
            <Box display="flex" justifyContent="space-between" alignItems="center">
                <Header title="Sales" subtitle="Welcome to Brand Bite" />
            </Box>

            <Box display="flex" borderRadius="10px" >
                <Box >
                    <Button onClick={handelOpen} sx={{ marginRight: "10px", backgroundColor: "blue", borderRadius: "20px", pr: "10px" }}><Typography display="flex" textAlign="center" fontSize="18px" textTransform="none" color="white" alignItems="center"><AddCircleOutline sx={{ marginRight: "8px" }} />Add New Person</Typography></Button>
                </Box>
            </Box>

            <Modal open={open} onClose={handleClose}>
                <Box
                    display="flex"
                    flexDirection="column"
                    justifyContent="center"
                    alignItems="center"
                    bgcolor="#727681"
                    p={3}
                >
                    <Box display="flex">
                        <TextField autoFocus autoComplete="off" label="Name" value={personName} onChange={(event) => {
                            setPersonName(event.target.value)
                        }} />
                        <Box display="flex" ml="10px">
                            <TextField autoComplete="off" label="Reference Code" value={refCode} onChange={(event) => {
                                setRefCode(event.target.value.toUpperCase())
                            }} />
                        </Box>
                    </Box>



                    <Box mt="15px">
                        <Button variant="contained" color="primary" sx={{ mr: "10px" }} onClick={addSalesPerson} >
                            Add
                        </Button>
                        <Button variant="contained" color="primary" onClick={handleClose}>
                            Close
                        </Button>
                    </Box>
                </Box>
            </Modal>


            <Modal open={salesData} onClose={handleClose}>
                <Box
                    display="flex"
                    flexDirection="column"
                    justifyContent="center"
                    alignItems="center"
                    bgcolor="#727681"
                    minHeight="200px" // Use minHeight to prevent the modal from collapsing too much
                    maxHeight="400px"
                    p={3}
                >
                    <Box
                        display="flex"
                        flexDirection="column"
                        overflow="auto" // Add scrollable container to handle overflow
                        pr="10px"
                    >
                        {salesDataArr.map((val, index) => (
                            <Box key={index} display="flex" flexDirection="column" onClick={() => copyToClipboard(val)}>
                                <Button>
                                    <Typography fontSize="18px" color="white" >
                                        {val}
                                    </Typography>
                                </Button>
                            </Box>
                        ))}
                    </Box>

                    {isCopied && (
                        <div className="copy-animation">Copied!</div>
                    )} 

                    <Box mt="15px" pr="10px">
                        <Button variant="contained" color="primary" onClick={handleClose}>
                            Close
                        </Button>
                    </Box>
                </Box>
            </Modal>


            <Box display="flex" mt="20px">
                <Grid container spacing={4} justifyContent="start">
                    {salesManList.map((val, index) => (
                        <Grid item key={val.ref}>
                            <Box >
                                <Button variant="contained" color="primary" sx={{ background: colors.primary[400],"&:hover": {
      backgroundColor: colors.grey[500],} }} onClick={() => { showSalesData(val.ref) }}>
                                    <Box display="flex" flexDirection="column" height="230px" width="230px" justifyContent="start" alignItems="center">
                                        <Box color={colors.bwTextColor[100]}>
                                            <Typography mt="5px" fontSize="20px">{val.name}</Typography>
                                            <Typography>{val.ref}</Typography>
                                            <Typography fontSize="30px" mt="50px" sx={{ color: "orange" }}>{val.salesCount}</Typography>
                                            <Typography sx={{ color: "orange" }} >Account Created</Typography>
                                        </Box>

                                    </Box>
                                </Button>
                                <Box display="flex" justifyContent="center" mt="-32px">
                                    <Button onClick={() => { deletePerson(val.ref) }} sx={{ color: "white" }}>Delete</Button>
                                </Box>
                            </Box>
                        </Grid>
                    ))}

                </Grid>
            </Box>
        </Box>
    )
}

export default Sales
