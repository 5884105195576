import { async } from "@firebase/util";
import { Avatar, Box, makeStyles, TextField } from "@mui/material";
import React, { useEffect, useMemo, useRef } from "react";
import { useState } from "react";



const NewImage = (props) => {
    const [posX, setPosX] = useState(1);
    const [posY, setPosY] = useState(1);
    const [isDragging, setIsDragging] = useState(false);
    const element = props.element[props.index];
    const [height, setHeight] = useState(50)
    const [width, setWidth] = useState(50)
    var image = URL.createObjectURL(element.file);
    
    useMemo(() => {
        setTimeout(()=>{
            setHeight(element.height);
            setWidth(element.width);
        },1)        
    }, [element.height, element.width])




    async function update() {
        setTimeout(() =>{
            // element.left = posX;
            // element.top = posY;
            props.onTextChange(element);
        },1)
        
    }

    const handleMouseDown = (event) => {
        update();
        event.preventDefault();
        setIsDragging(true);
        document.addEventListener('mouseup', handleMouseUp);
        document.addEventListener('mousemove', handleMouseMove);
        update();
    }



    const handleMouseMove = async (event) => {
        if (isDragging) {
            
            const x = parseInt(posX + event.movementX * 1.6);
            const y = parseInt(posY + event.movementY * 1.6);
            if (x < 301 - width * 3 && x >= 0) {
                setPosX(x);
                element.left = x;
            } if (y < 301 - height * 3 && y >= 0) {
                setPosY(y);
                element.top = y;
            }
            
        }
    }

    const handleMouseUp = (event) => {      
        update();
        event.preventDefault();
        setIsDragging(false)
        document.removeEventListener('mouseup', handleMouseUp);
        document.removeEventListener('mousemove', handleMouseMove);
        update();
    }


    return (

        <Avatar key={element.file.name + element.index} variant="square" src={image}  onMouseDown={handleMouseDown} onMouseUp={handleMouseUp} onMouseMove={(handleMouseMove)} sx={{
            width: width + "%", height: height + "%", position: "absolute", top: element.top + "px", left: element.left + "px", cursor: isDragging ? 'grabbing' : 'grab',border:isDragging?"2px solid white":"none", '& > img': {
                objectFit: 'contain',
                width: '100%',
                height: '100%',
            }
        }}
        />
    )
};

export default NewImage;
