import { Box, Button, Grid, Modal, Typography, useTheme } from "@mui/material";
import React from "react";
import { tokens } from "../../theme";
import Header from "../../components/Header";
import { Link } from "react-router-dom";
import {
  AddCircleOutline,
  AutoAwesome,
  Celebration,
  CropDinRounded,
  DesignServices,
  DoneAll,
  FastfoodOutlined,
  LanguageOutlined,
  LocalOffer,
  Lock,
  LockOpen,
  RestaurantMenuOutlined,
  SentimentSatisfiedAltOutlined,
  TrendingUp,
  TuneOutlined,
  WallpaperRounded,
  WebStories,
} from "@mui/icons-material";
import { database, writedb } from "../../firebase-config";
import { useState } from "react";
import { useMemo } from "react";
import { onValue, ref } from "firebase/database";

const More = () => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const [lock, setLock] = useState(true); // this is for lock unlock testimonial, wallposter and other graphics
  const [lockFlyer, setLockFlyer] = useState(false);
  const [lockTestimonials, setLockTestimonials] = useState(false);
  const [lockwallPoster, setLockWallPoster] = useState(false);
  const [lockCombos, setLockCombos] = useState(false);
  const [lockEvents, setLockEvents] = useState(false);
  const [bgRemoveAv, setBgRemoveAv] = useState(true); // Background remover availability status
  const [categoryList, setCategoryList] = useState([]);
  const [showCategory, setShowCategory] = useState(false);

  const StickersButton = ({ to, label, icon }) => (
    <Grid item>
      <Link to={to}>
        <Button
          variant="contained"
          color="primary"
          sx={{
            background: colors.primary[400],
            "&:hover": {
              backgroundColor: colors.grey[500],
            },
          }}
        >
          <Box
            display="flex"
            color={colors.bwTextColor[100]}
            flexDirection="column"
            height="170px"
            width="170px"
            justifyContent="center"
            alignItems="center"
          >
            <Typography>{label}</Typography>
            {icon}
          </Box>
        </Button>
      </Link>
    </Grid>
  );

  useMemo(() => {
    writedb
      .ref("graphicsLock")
      .once("value")
      .then((snapshot) => {
        if (snapshot.exists()) {
          if (snapshot.child("flyer").exists()) {
            setLockFlyer(true);
          }
          if (snapshot.child("testimonials").exists()) {
            setLockTestimonials(true);
          }
          if (snapshot.child("wallposter").exists()) {
            setLockWallPoster(true);
          }
          if (snapshot.child("combos").exists()) {
            setLockCombos(true);
          }
          if (snapshot.child("events").exists()) {
            setLockEvents(true);
          }
        }
      });
    writedb
      .ref("bgRemove/status")
      .once("value")
      .then((snapshot) => {
        if (snapshot.exists()) {
          setBgRemoveAv(true);
        } else {
          setBgRemoveAv(false);
        }
      });
  }, [!lock]);

  function loadCat() {
    let path = "homeUniquekeys/category";
    onValue(ref(database, path), (snapshot) => {
      let names = [];

      snapshot.forEach((snapshot) => {
        const data = snapshot.val();
        const name = snapshot.key;
        const key = Object.values(data)[0];
        names.push({ key: key, name: name });
      });
      setShowCategory(true);
      setCategoryList(names);
    });
  }

  function graphicsLock() {
    if (lock) {
      writedb
        .ref("graphicsLock/status")
        .remove()
        .then(() => {
          setLock(false);
        });
    } else {
      writedb
        .ref("graphicsLock/status")
        .set("locked")
        .then(() => {
          setLock(true);
        });
    }
  }

  const graphicsLockUsingval = (val, value, booleanVal) => {
    if (booleanVal) {
      writedb
        .ref(`graphicsLock/${val}`)
        .remove()
        .then(() => {
          value(false);
        });
    } else {
      writedb
        .ref(`graphicsLock/${val}/status`)
        .set("locked")
        .then(() => {
          value(true);
        });
    }
  };

  function bgremoveActivateDeactivate() {
    if (bgRemoveAv) {
      writedb
        .ref("bgRemove/status")
        .remove()
        .then(() => {
          setBgRemoveAv(false);
        });
    } else {
      writedb
        .ref("bgRemove/status")
        .set("locked")
        .then(() => {
          setBgRemoveAv(true);
        });
    }
  }

  const StickersButtonNoLink = ({ label, icon, click }) => (
    <Grid item>
      <Button
        variant="contained"
        color="primary"
        sx={{
          background: colors.primary[400],
          "&:hover": {
            backgroundColor: colors.grey[500],
          },
        }}
        onClick={click}
      >
        <Box
          display="flex"
          color={colors.bwTextColor[100]}
          flexDirection="column"
          height="170px"
          width="170px"
          justifyContent="center"
          alignItems="center"
        >
          <Typography>{label}</Typography>
          {icon}
        </Box>
      </Button>
    </Grid>
  );

  const handleClose = () => {
    setShowCategory(false);
  };

  return (
    <Box
      display="flex"
      flexDirection="column"
      className="m20"
      marginBottom="20px"
      maxWidth="100%"
    >
      <Box maxWidth="100vw" className="hide-overflow-600px" paddingRight="5px">
        <Box
          className="flex-col hide-600px"
          justifyContent="space-between"
          alignItems="start"
        >
          {/* <Header title="DASHBOARD" subtitle="Welcome to your dashboard" /> */}
          <Header title="MORE" subtitle="Welcome to Brand Bite" />
        </Box>
      </Box>

      <Box display="flex" mt="20px" mb="20px">
        <Grid container spacing={4} justifyContent="start">
          <StickersButton
            to="/sales"
            label="Sales"
            icon={<TrendingUp sx={{ mt: "5px" }} />}
          />

          <StickersButton
            to="/Offers"
            label="Offers"
            icon={<LocalOffer sx={{ mt: "5px" }} />}
          />
          <StickersButton
            to="/general"
            label="General"
            icon={<LanguageOutlined sx={{ mt: "5px" }} />}
          />
          <StickersButton
            to="/stickers"
            label="Stickers"
            icon={<SentimentSatisfiedAltOutlined sx={{ mt: "5px" }} />}
          />
          <StickersButton
            to="/background"
            label="Background"
            icon={<WallpaperRounded sx={{ mt: "5px" }} />}
          />
          <StickersButton
            to="/foodImages"
            label="Food Images"
            icon={<FastfoodOutlined sx={{ mt: "5px" }} />}
          />
          <StickersButton
            to="/frame"
            label="Frame"
            icon={<CropDinRounded sx={{ mt: "5px" }} />}
          />
          {/* <StickersButton to="/framevideo" label="Video Frame" icon={<CropDinRounded sx={{ mt: "5px" }} />} />  */}
          <StickersButton
            to="/testimonials"
            label="Testimonials"
            icon={<AutoAwesome sx={{ mt: "5px" }} />}
          />
          <StickersButton
            to="/wallposter"
            label="Wall Poster"
            icon={<WebStories sx={{ mt: "5px" }} />}
          />
          <StickersButton
            to="/flyer"
            label="Flyer"
            icon={<DesignServices sx={{ mt: "5px" }} />}
          />
          <StickersButton
            to="/combos"
            label="Combos"
            icon={<DoneAll sx={{ mt: "5px" }} />}
          />
          <StickersButton
            to="/events"
            label="Events"
            icon={<Celebration sx={{ mt: "5px" }} />}
          />
          <StickersButton
            to="/brands"
            label="Swiggy Zomato"
            icon={<AddCircleOutline sx={{ mt: "5px" }} />}
          />
          {/* <StickersButtonNoLink click={graphicsLock} label={lock ? "Graphics Locked" : "Graphics Unlocked"} icon={lock ? <Lock sx={{ mt: "5px" }} /> : <LockOpen sx={{ mt: "5px" }} />} /> */}

          <StickersButtonNoLink
            click={bgremoveActivateDeactivate}
            label="BG Remove"
            icon={
              !bgRemoveAv ? (
                <Lock sx={{ mt: "5px" }} />
              ) : (
                <LockOpen sx={{ mt: "5px" }} />
              )
            }
          />
          <StickersButtonNoLink
            click={loadCat}
            label="Filter"
            icon={<TuneOutlined sx={{ mt: "5px" }} />}
          />

          <StickersButtonNoLink
            click={() => {
              graphicsLockUsingval("flyer", setLockFlyer, lockFlyer);
            }}
            label={lockFlyer ? "Flyer Locked" : "Flyer Unlocked"}
            icon={
              lockFlyer ? (
                <Lock sx={{ mt: "5px" }} />
              ) : (
                <LockOpen sx={{ mt: "5px" }} />
              )
            }
          />
          <StickersButtonNoLink
            click={() => {
              graphicsLockUsingval(
                "testimonials",
                setLockTestimonials,
                lockTestimonials
              );
            }}
            label={
              lockTestimonials ? "Testimonials Locked" : "Testimonials Unlocked"
            }
            icon={
              lockTestimonials ? (
                <Lock sx={{ mt: "5px" }} />
              ) : (
                <LockOpen sx={{ mt: "5px" }} />
              )
            }
          />

          <StickersButtonNoLink
            click={() => {
              graphicsLockUsingval(
                "wallposter",
                setLockWallPoster,
                lockwallPoster
              );
            }}
            label={
              lockwallPoster ? "Wall Poster Locked" : "Wall Poster Unlocked"
            }
            icon={
              lockwallPoster ? (
                <Lock sx={{ mt: "5px" }} />
              ) : (
                <LockOpen sx={{ mt: "5px" }} />
              )
            }
          />

          <StickersButtonNoLink
            click={() => {
              graphicsLockUsingval("combos", setLockCombos, lockCombos);
            }}
            label={lockCombos ? "Combos Locked" : "Combos Unlocked"}
            icon={
              lockCombos ? (
                <Lock sx={{ mt: "5px" }} />
              ) : (
                <LockOpen sx={{ mt: "5px" }} />
              )
            }
          />

          <StickersButtonNoLink
            click={() => {
              graphicsLockUsingval("events", setLockEvents, lockEvents);
            }}
            label={lockEvents ? "Events Locked" : "Events Unlocked"}
            icon={
              lockEvents ? (
                <Lock sx={{ mt: "5px" }} />
              ) : (
                <LockOpen sx={{ mt: "5px" }} />
              )
            }
          />
        </Grid>
      </Box>

      <Modal open={showCategory} onClose={handleClose}>
        <Box
          display="flex"
          flexDirection="column"
          justifyContent="center"
          alignItems="center"
          bgcolor="#727681"
          minHeight="200px" // Use minHeight to prevent the modal from collapsing too much
          maxHeight="350px"
          p={3}
        >
          <Typography
            display="flex"
            justifyContent="center"
            fontSize="18px"
            color="#000000"
            textTransform="none"
          >
            Select Category
          </Typography>
          <Box
            display="flex"
            flexDirection="column"
            overflow="auto" // Add scrollable container to handle overflow
            pr="10px"
            mt="5px"
          >
            {categoryList.map((val, index) => (
              <Box
                key={index}
                display="flex"
                flexDirection="column"
                alignItems="center"
              >
                <Link to={`/filter/category/${val.key}`}>
                  <Button>
                    <Typography
                      fontSize="18px"
                      color="white"
                      textTransform="none"
                    >
                      {val.name}
                    </Typography>
                  </Button>
                </Link>
              </Box>
            ))}
          </Box>
        </Box>
      </Modal>
    </Box>
  );
};

export default More;
