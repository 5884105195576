
import { Avatar, Box, Button, Grid, Input, Modal, Typography, useTheme } from "@mui/material";
import { tokens } from "../../theme";
import Header from "../../components/Header";
import React, { useMemo, useState } from "react";
import { database, storage, writedb } from "../../firebase-config";
import { onValue, ref } from "firebase/database";
import { AddCircleOutline, CloseRounded } from "@mui/icons-material";
import Category from "../addCategory";
import Outlet from "../addOutlet";
import { Link, useLocation } from "react-router-dom";
import { useEffect } from "react";
import LazyLoad from "react-lazyload";
import AddNewImage from "../addNewImage";
import MultipleImagesUpload from "../addMultipleImages";


const TestiComboEventsWallPos = () => {
    const theme = useTheme();
    const colors = tokens(theme.palette.mode);
    const [showImageUpload, setShowImageUpload] = useState(false);
    const [avatarWidth, setAvatarWidth] = useState(200);
    const [avatarHeight, setAvatarHeight] = useState(200);
    const [title, setTitle] = useState("");
    const location = useLocation().pathname;
    const [imageList, setImageList] = useState([]);
    const [showMoveBox, setShowMoveBox] = useState(false); //this is used to get index where we want to move image;
    const [close, setClose] = useState(false);
    const [dropLocationOfImage, setDropLocationOfImage] = useState("");
    const [selectedImageIndexNumber, setSelectedImageIndexNumber] = useState(0);
    useMemo(() => {
        if (location.includes("testimonials")) {
            setTitle("testimonials")
            loadData("testimonials")
        } else if (location.includes("wallposter")) {
            setTitle("wallPoster")
            setAvatarHeight(270)
            loadData("wallPoster")
        } else if (location.includes("combos")) {
            setTitle("combos")
            loadData("combos")
        }
        else if (location.includes("events")) {
            setTitle("events")
            loadData("events")
        }
        else if (location.includes("stickers")) {
            setTitle("stickers")
            loadData("stickers")
        }
        else if (location.includes("background")) {
            setTitle("background")
            loadData("background")
        }
        else if (location.includes("brands")) {
            setTitle("brands")
            loadData("brands")
        }
        else if (location.includes("foodImages")) {
            setTitle("foodImages")
            loadData("foodImages")
        }
    }, [location.length])



    async function loadData(address) {

        onValue(ref(database, address), (snapshot) => {

            let urls = [];
            snapshot.forEach(childSnapshot => {
                var outletKey = childSnapshot.key;

                const data = childSnapshot.val(); //All data of outlet is here
                const keys = Object.keys(data)[0]; // name of the outlet is the key value of childsnapshot
                var v3 = Object.values(data)[0]; // this is used to get images from specific outlet wrapped with unique id like -NZ...hux
                var v4 = Object.values(v3).reverse(); // This is used to extract images from unique id of server
                var arr = [];

                v4.forEach(val => { // there is multiple images in our system but here we need cover image or thumbnail of that so this is used to get just cover imag...

                    if (arr.length < 10) {

                        if (val.hasOwnProperty("image")) {

                            let image = val.image
                            arr.push({ image: image });
                        }
                    }


                })

                const newItem = { key: outletKey, outlet: keys, value: arr }
                urls.push(newItem);

            })
            urls.reverse()
            setImageList(urls);

        })
    }

    const swapCategory = async () => {
        if (dropLocationOfImage.length == 0 || dropLocationOfImage < 1 || dropLocationOfImage > imageList.length || selectedImageIndexNumber + 1 == dropLocationOfImage) {
            alert("Invalid");
            return;
        }
        let arr = imageList;



        let firstKey = arr[selectedImageIndexNumber].key
        let secondKey = arr[dropLocationOfImage - 1].key

        let firstName = arr[selectedImageIndexNumber].outlet
        let secondName = arr[dropLocationOfImage - 1].outlet

        let firstData;
        let secondData;


        await writedb.ref(`${title}/${firstKey}`).once('value').then((snapshot) => {
            firstData = snapshot.val();
            writedb.ref(`${title}/${secondKey}`).once('value').then((snapshot) => {
                secondData = snapshot.val();
                writedb.ref(`${title}UniqueKeys/${firstName}`).set({ key: secondKey }).then(() => {
                    writedb.ref(`${title}UniqueKeys/${secondName}`).set({ key: firstKey }).then(() => {
                        writedb.ref(`${title}/${firstKey}`).set(secondData).then(() => {
                            writedb.ref(`${title}/${secondKey}`).set(firstData).then(async () => {
                                setSelectedImageIndexNumber(dropLocationOfImage - 1);
                                alert("Category Swapped")
                            })
                        })
                    })
                })

            })
        })
    }

    const handleClose = () => {
        setClose(false)
    };

    const setSelectedImageDataToBeMove = (index) => {

        setSelectedImageIndexNumber(index);
        setShowMoveBox(true)
    }

    const inputStyle = {
        '& label.Mui-focused': {
            color: 'aqua',
        },
        '& .MuiInput-underline:after': {
            borderBottomColor: 'white',
        },
        '& .MuiOutlinedInput-root': {
            '& fieldset': {
                borderColor: 'gray',
                height: '45px'
            },
            '&:hover fieldset': {
                borderColor: 'white',
            },
            '&.Mui-focused fieldset': {
                borderColor: 'rgb(155,155,255)',
            }, '& .MuiInputBase-input': {
                color: 'white',
                fontSize: "16px",
                height: "7px"

            },
        }
    }

    return (
        <Box display="flex" flexDirection="column" m="20px" marginBottom="20px">
            {/* HEADER */}
            <Box display="flex" justifyContent="space-between" alignItems="center">
                <Header title={title.charAt(0).toUpperCase() + title.slice(1)} subtitle="Welcome to Brand Bite" />
            </Box>

            {showImageUpload ? <MultipleImagesUpload path="" boxDisplay={() => setShowImageUpload(!showImageUpload)} parent={title} /> : ""}

            <Box display="flex" borderRadius="10px" marginBottom="20px">
                <Box >
                    <Button onClick={() => setShowImageUpload(!showImageUpload)} sx={{ marginRight: "10px", backgroundColor: "blue", borderRadius: "20px", paddingRight: "10px" }}><Typography display="flex" textAlign="center" fontSize="18px" textTransform="none" color="white" alignItems="center"><AddCircleOutline sx={{ marginRight: "8px" }} />Add Images</Typography></Button>
                </Box>

            </Box>

            <Modal open={showMoveBox} onClose={handleClose}>
                <Box
                    display="flex"
                    flexDirection="column"
                    justifyContent="center"
                    alignItems="center"
                    bgcolor="#727681"
                    p={3}
                >
                    <Box display="flex" flexDirection="column" height="45px" ml="10px">
                        <Input autoFocus autoComplete="off" type="number" label="Name" value={dropLocationOfImage} onChange={(event) => {
                            setDropLocationOfImage(parseInt(event.target.value))
                        }} sx={inputStyle} />
                    </Box>

                    <Box mt="10px">
                        <Button variant="contained" color="primary" sx={{ mr: "10px" }} onClick={swapCategory}>
                            Move
                        </Button>
                        <Button variant="contained" color="primary" onClick={() => { setShowMoveBox(!showMoveBox) }}>
                            Close
                        </Button>
                    </Box>
                </Box>
            </Modal>


            <Box display="flex" flexDirection="column">
                {imageList.map((imageUrl, index) => (

                    <Box key={imageUrl.outlet + index} display="flex" flexDirection="column" sx={{ background: colors.primary[400] }} marginBottom="20px" paddingLeft="10px" borderRadius="10px">
                        <Box display="flex" width="100%">
                            <Box flex="1">
                                <Typography fontSize="25px" fontWeight="700" margin="10px 0">
                                    {imageUrl.outlet}
                                </Typography>
                            </Box>
                            <Box margin="10px" >
                                <Button variant="contained" sx={{ background: "green" }} onClick={() => { setSelectedImageDataToBeMove(index) }} >
                                    Move
                                </Button>
                            </Box>
                        </Box>


                        <Box display="flex" overflow="auto"  >
                            <Grid container maxHeight="300px" overflow="auto">
                                {
                                    imageUrl.value.reverse().map((vinay, index) => (
                                        <Grid item key={index + "nonVeg"} xs={12} sm={6} md={4} lg={3} xl={3} >
                                            <Box key={vinay.image + index} >

                                                <Link to={"edit/" + imageUrl.key}><Avatar src={vinay.image} sx={{
                                                    width: avatarWidth, height: avatarHeight, marginRight: "15px", marginBottom: "10px", borderRadius: "5%", cursor: "pointer", '& > img': {
                                                        objectFit: 'fill',
                                                        width: '100%',
                                                        height: '100%',
                                                    }, ":hover": { bgcolor: "white" }
                                                }}></Avatar></Link>
                                            </Box>
                                        </Grid>
                                    ))
                                }
                            </Grid>
                        </Box>
                    </Box>))}
            </Box>




        </Box>
    )
}

export default TestiComboEventsWallPos
