import { Box, Button, Grid, Modal, Typography, useTheme } from '@mui/material';
import React from 'react'
import { tokens } from '../../theme';
import Header from '../../components/Header';
import { Link } from 'react-router-dom';
import { AddCircleOutline, AutoAwesome, Celebration, CropDinRounded, DesignServices, DoneAll, FastfoodOutlined, LanguageOutlined, Lock, LockOpen, RestaurantMenuOutlined, SentimentSatisfiedAltOutlined, TuneOutlined, WallpaperRounded, WebStories } from '@mui/icons-material';
import { database, writedb } from '../../firebase-config';
import { useState } from 'react';
import { useMemo } from 'react';
import { onValue, ref } from 'firebase/database';

const MoreTeam = () => {
    const theme = useTheme();
    const colors = tokens(theme.palette.mode);
    const [lock, setLock] = useState(true); // this is for lock unlock testimonial, wallposter and other graphics
    const [bgRemoveAv, setBgRemoveAv] = useState(true); // Background remover availability status
    const [categoryList, setCategoryList] = useState([]);
    const [showCategory, setShowCategory] = useState(false);

    const StickersButton = ({ to, label, icon }) => (
        <Grid item>
            <Link to={to}>
            <Button variant="contained" color="primary" sx={{ background: colors.primary[400],"&:hover": {
      backgroundColor: colors.grey[500],
    }, }} >
                    <Box display="flex" color={colors.bwTextColor[100] } flexDirection="column" height="170px" width="170px" justifyContent="center" alignItems="center">
                        <Typography>{label}</Typography>
                        {icon}
                    </Box>
                </Button>
            </Link>
        </Grid>
    );

    useMemo(() => {
        writedb.ref("graphicsLock/status").once('value').then((snapshot) => {
            if (snapshot.exists()) {
                setLock(true)
            } else {
                setLock(false)
            }
        })
        writedb.ref("bgRemove/status").once('value').then((snapshot) => {
            if (snapshot.exists()) {
                setBgRemoveAv(true)
            } else {
                setBgRemoveAv(false)
            }
        })
    }, [!lock])


    function loadCat() {
        let path = "homeUniquekeys/category"
        onValue(ref(database, path), (snapshot) => {

            let names = [];

            snapshot.forEach(snapshot => {
                const data = snapshot.val();
                const name = snapshot.key;
                const key = Object.values(data)[0];
                names.push({ key: key, name: name });
            });
            setShowCategory(true)
            setCategoryList(names);
        })
    }


   


    const StickersButtonNoLink = ({ label, icon, click }) => (
        <Grid item>

            <Button variant="contained" color="primary" sx={{ background: colors.primary[400],"&:hover": {
      backgroundColor: colors.grey[500],
    }, }} onClick={click}>
                <Box display="flex" color={colors.bwTextColor[100] } flexDirection="column" height="170px" width="170px" justifyContent="center" alignItems="center">
                    <Typography>{label}</Typography>
                    {icon}
                </Box>
            </Button>

        </Grid>
    );


    const handleClose = () => {
        setShowCategory(false);
    }

    return (
        <Box display="flex" flexDirection="column" className="m20" marginBottom="20px" maxWidth="100%" >
            <Box maxWidth="100vw" className="hide-overflow-600px" paddingRight="5px">
                <Box className="flex-col hide-600px" justifyContent="space-between" alignItems="start" >
                    {/* <Header title="DASHBOARD" subtitle="Welcome to your dashboard" /> */}
                    <Header title="MORE" subtitle="Welcome to Brand Bite" />
                </Box>
            </Box>

            <Box display="flex" mt="20px" mb="20px">
                <Grid container spacing={4} justifyContent="start">

                    <StickersButton to="/general" label="General" icon={<LanguageOutlined sx={{ mt: "5px" }} />} />
                    <StickersButton to="/flyer" label="Flyer" icon={<DesignServices sx={{ mt: "5px" }} />} />
                    <StickersButton to="/stickers" label="Stickers" icon={<SentimentSatisfiedAltOutlined sx={{ mt: "5px" }} />} />
                    <StickersButton to="/background" label="Background" icon={<WallpaperRounded sx={{ mt: "5px" }} />} />
                    <StickersButton to="/foodImages" label="Food Images" icon={<FastfoodOutlined sx={{ mt: "5px" }} />} />
                    <StickersButton to="/frame" label="Frame" icon={<CropDinRounded sx={{ mt: "5px" }} />} />
                    <StickersButton to="/framevideo" label="Video Frame" icon={<CropDinRounded sx={{ mt: "5px" }} />} /> 
                   
                    <StickersButton to="/brands" label="Swiggy Zomato" icon={<AddCircleOutline sx={{ mt: "5px" }} />} />
                    <StickersButtonNoLink to="/brands" click={loadCat} label="Filter" icon={<TuneOutlined sx={{ mt: "5px" }} />} />



                </Grid>
            </Box>



            <Modal open={showCategory} onClose={handleClose}>
                <Box
                    display="flex"
                    flexDirection="column"
                    justifyContent="center"
                    alignItems="center"
                    bgcolor="#727681"
                    minHeight="200px" // Use minHeight to prevent the modal from collapsing too much
                    maxHeight="350px"
                    p={3}
                >
                    <Typography display="flex" justifyContent="center" fontSize="18px" color="#000000" textTransform='none'>
                        Select Category
                    </Typography>
                    <Box
                        display="flex"
                        flexDirection="column"
                        overflow="auto" // Add scrollable container to handle overflow
                        pr="10px"
                        mt="5px"
                    >

                        {categoryList.map((val, index) => (
                            <Box key={index} display="flex" flexDirection="column" alignItems="center" >
                                <Link to={`/filter/category/${val.key}`}>
                                    <Button >
                                        <Typography fontSize="18px" color="white" textTransform='none'>
                                            {val.name}
                                        </Typography>
                                    </Button>
                                </Link>
                            </Box>
                        ))}
                    </Box>
                </Box>
            </Modal>


        </Box>


    )
}

export default MoreTeam
