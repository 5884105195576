import { Box, useTheme } from "@mui/material";
import React, { useMemo } from "react";
import ReactApexChart from "react-apexcharts";
import { tokens } from "../../theme";
import { onValue, ref } from "firebase/database";
import { database } from "../../firebase-config";
import { useState } from "react";

const CuisineChart = () => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const textColor = theme.palette.mode === "dark" ? "white" : "black"
  const [categoryList, setCategoryList] = useState([]);
  const [usageList, setUsageList] = useState([]);

  useMemo(() => {
    onValue(ref(database,"homeUniquekeys/category"),(snapshot) => {
      let cuisineNameSnap = snapshot.val();
      onValue(ref(database,"analytics/cuisineUsage"),(snapshot) => {
        let cuisineUsageSnap = snapshot.val();
        let cuisineUsageKeys = Object.keys(cuisineUsageSnap);
        let cuisineUsageValues = Object.values(cuisineUsageSnap); 
        let cuisineNameKeys = Object.keys(cuisineNameSnap) // list of category names
        let cuisineNameValues = Object.values(cuisineNameSnap); // list of category unique keys
        let categoryArr = [];
        let usageArr = [];
        cuisineNameValues.forEach((key1,index1) => {
          cuisineUsageKeys.forEach((key2,index2) => {
            if (key1.key === key2){
              categoryArr.push(cuisineNameKeys[index1]);
              usageArr.push(cuisineUsageValues[index2].count);
            }
          })
          
        });
        setCategoryList(categoryArr);
        setUsageList(usageArr);
      })
    })
  },[usageList.length == 0])


  const options = {
    chart: {
      id: "basic-bar",
      toolbar: {
        show: false,
      },
    },
    xaxis: {
      categories: categoryList,
      labels: {
        style: {
          colors: textColor, // Change the color here
        },
      },
    },
    yaxis: {
        labels: {
          style: {
            colors: textColor, // Change the color of Y-axis labels here
          },
        },
      },
      tooltip: {
        theme: "dark", // Change the tooltip theme (e.g., "light" or "dark")
      },
  };
  

  const series = [
    {
      name: "Usage",
      data: usageList,
      
    },
  ];

  return (
    <Box id="chart" minWidth="400px" >
      <ReactApexChart
        options={options}
        series={series}
        type="bar"
        height="360"
      />
    </Box>
  );
};

export default CuisineChart;





