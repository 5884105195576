import { Avatar, Box, Button, Grid, Input, Modal, TextField, Tooltip, Typography, useTheme } from "@mui/material";
import { Link, useLocation, useParams } from "react-router-dom";
import Header from "../../components/Header";
import { tokens } from "../../theme";
import { useEffect, useState } from "react";
import { useMemo } from "react";
import { onValue, ref } from "firebase/database";
import { database, storage, writedb } from "../../firebase-config";
import { AddCircleOutlineRounded, DeleteRounded, AbcRounded, CloseRounded, AddCircleOutline } from "@mui/icons-material";
import React from "react";
import { ar } from "date-fns/locale";


const Filter = () => {
    const theme = useTheme();
    const colors = tokens(theme.palette.mode);

    const location = useLocation().pathname;
    const [catCus, setCatCus] = useState("");

    useEffect(() => {
        if (location.includes("home/edit/category") || location.includes("filter/category")) {
            setCatCus("category");
            loadData("category")
        } else if (location.includes("home/edit/custom")) {
            setCatCus("custom");
            loadData("custom")
        }
    }, [catCus.length])

    const categoryKey = useParams().name;

    const [name, setName] = useState();  // this is the name of the category
    const [vegNonVeg, setVegNonVeg] = useState("veg");
    const [myKey, setMyKey] = useState();
    const [showaddImage, setShowAddimage] = useState(false);
    const [showMoveBox, setShowMoveBox] = useState(false); //this is used to get index where we want to move image;
    const [newFilterData, setNewFilterData] = useState(false);
    const [dropLocationOfCategory, setDropLocationOfCategory] = useState("");
    const [imageSnapshot, setImageSnapshot] = useState("");
    const [newFilterName, setNewFilterName] = useState("");
    const [avatarWidth, setAvatarWidth] = useState(200);
    const [avatarHeight, setAvatarHeight] = useState(200);
    const [vegFilterList, setVegFilterList] = useState([])
    const [nonVegFilterList, setNonVegFilterList] = useState([])
    const [selectedCategoryIndexNumber, setSelectedCateogoryIndexNumber] = useState("");
    const [categoryFound, setCategoryFound] = useState(true);


    // const addImageLink = (array) => {
    //     array.forEach(async (data, index) => {
    //         data.arr.forEach(async (data,index) => {
    //             // console.info(data.tempPath);
    //             await writedb.ref(data.tempPath).update({ thumbnailImage: data.image });
    //             console.info(index)
    //         })
            
    //     })
    // }

    function loadData(address) {

        
            onValue(ref(database, `images/${address}`), (snapshotAllImages) => {
            setImageSnapshot(snapshotAllImages);
            
                onValue(ref(database, `home/${address}/` + categoryKey), (snapshot) => {

                if (snapshot.exists()) {
                    let catName = Object.keys(snapshot.val())[0]
                    setMyKey(categoryKey)
                    setName(catName + " - Filter")
                }
                
                    onValue(ref(database, `home/${address}/${categoryKey}/cover/filter/vegFilter`), (snapshot) => {
                    if (snapshot.exists()) {
                        let mainArr = [];
                        let snapVal = Object.values(snapshot.val());
                        let catKeys = Object.keys(snapshot.val());
                        snapVal.forEach((val, index) => {
                            let name = Object.keys(val)[0]
                            let catKey = catKeys[index];
                            let keyOfImages = Object.values(val);
                            let arr = [];
                            keyOfImages.forEach(val => {
                                let keys = Object.keys(val);
                                let values = Object.values(val);
                                keys.forEach((val, index) => {
                                    let key = val; // this is key for provide unique path in the filter image section 
                                    let imageKey = values[index].image.slice(7).slice(catCus.length + 1);
                                    let checkImage = snapshotAllImages.child(imageKey);
                                    if (checkImage.exists()) {
                                        let coverCheck = checkImage.child("cover")
                                        if (coverCheck.exists()) {
                                            let link = coverCheck.child("image").val()
                                            // let path = `home/${address}/${categoryKey}/cover/filter/vegFilter/${catKey}/${name}/${key}`
                                            let item = { key: key, image: link };
                                            if (arr.length < 10){
                                            arr.push(item);
                                            }

                                        }
                                    }
                                })
                            })
                            let item = { name: name, arr: arr, key: catKey };
                            mainArr.push(item);
                        })
                        setVegFilterList(mainArr);
                    }
                });
                
                    onValue(ref(database, `home/${address}/${categoryKey}/cover/filter/nonVegFilter`), (snapshot) => {
                    if (snapshot.exists()) {
                        let mainArr = [];
                        let snapVal = Object.values(snapshot.val());
                        let catKeys = Object.keys(snapshot.val());
                        snapVal.forEach((val, index) => {
                            let name = Object.keys(val)[0]
                            let catKey = catKeys[index];
                            let keyOfImages = Object.values(val);
                            let arr = [];
                            keyOfImages.forEach(val => {
                                let keys = Object.keys(val);
                                let values = Object.values(val);
                                keys.forEach((val, index) => {
                                    let key = val; // this is key for provide unique path in the filter image section 

                                    let imageKey = values[index].image.slice(7).slice(catCus.length + 1);
                                    let checkImage = snapshotAllImages.child(imageKey);
                                    if (checkImage.exists()) {
                                        let coverCheck = checkImage.child("cover")
                                        if (coverCheck.exists()) {
                                            let link = coverCheck.child("image").val()
                                            // let path = `home/${address}/${categoryKey}/cover/filter/nonVegFilter/${catKey}/${name}/${key}`
                                            let item = { key: key, image: link };
                                            if (arr.length < 10) {
                                                arr.push(item);
                                            }

                                        }
                                    }
                                })
                            })
                            let item = { name: name, arr: arr, key: catKey };
                            mainArr.push(item);
                        })
                        setNonVegFilterList(mainArr);
                    }
                });


            })

        });

    }


    const catDisplay = () => {

        setShowAddimage(!showaddImage);
    }



    const inputStyle = {
        '& label.Mui-focused': {
            color: 'aqua',
        },
        '& .MuiInput-underline:after': {
            borderBottomColor: 'white',
        },
        '& .MuiOutlinedInput-root': {
            '& fieldset': {
                borderColor: 'gray',
                height: '45px'
            },
            '&:hover fieldset': {
                borderColor: 'white',
            },
            '&.Mui-focused fieldset': {
                borderColor: 'rgb(155,155,255)',
            }, '& .MuiInputBase-input': {
                color: 'white',
                fontSize: "16px",
                height: "7px"

            },
        }
    }




    const handleClose = () => {
        setNewFilterData(false)
        setNewFilterName("");
    }



    function checkAndNaviagetoNewFilter(type) {
        let name = newFilterName;

        if (name.length == 0) {
            alert("Please enter name")
        }
        else {
            window.location.pathname = `filter/new/category/${type}/${categoryKey}/${newFilterName}`
        }

    }

    const setSelectedCategoryToBeMove = (index) => {
        setSelectedCateogoryIndexNumber(index);
        setShowMoveBox(true)
    }


    const swapCategory = async () => {
        let arr = vegFilterList
        let type = "vegFilter"
        if (vegNonVeg === "nonVeg") {
            arr = nonVegFilterList
            type = "nonVegFilter"
        }



        if (dropLocationOfCategory.length == 0 || dropLocationOfCategory < 1 || dropLocationOfCategory > arr.length || selectedCategoryIndexNumber + 1 == dropLocationOfCategory) {
            alert("Invalid");
            return;
        }




        let firstKey = arr[selectedCategoryIndexNumber].key
        let secondKey = arr[dropLocationOfCategory - 1].key

        let firstName = arr[selectedCategoryIndexNumber].name
        let secondName = arr[dropLocationOfCategory - 1].name

        let firstData;
        let secondData;

        await writedb.ref(`home/${catCus}/${categoryKey}/cover/filter/${type}/${firstKey}`).once('value').then((snapshot) => {
            firstData = snapshot.val();
            writedb.ref(`home/${catCus}/${categoryKey}/cover/filter/${type}/${secondKey}`).once('value').then((snapshot) => {
                secondData = snapshot.val();
                writedb.ref(`home/${catCus}/${categoryKey}/cover/filter/uniqueKeys/${type}/${firstName}`).set({ key: secondKey }).then(() => {
                    writedb.ref(`home/${catCus}/${categoryKey}/cover/filter/uniqueKeys/${type}/${secondName}`).set({ key: firstKey }).then(() => {
                        writedb.ref(`home/${catCus}/${categoryKey}/cover/filter/${type}/${firstKey}`).set(secondData).then(() => {
                            writedb.ref(`home/${catCus}/${categoryKey}/cover/filter/${type}/${secondKey}`).set(firstData).then(async () => {
                                setSelectedCateogoryIndexNumber(dropLocationOfCategory - 1);
                                alert("Category Swapped")
                            })
                        })
                    })
                })

            })
        })
    }





    return (
        <Box>

            <Box display={categoryFound ? "flex" : "none"} flexDirection="column" m="20px" marginBottom="20px">
                {/* HEADER */}
                <Box display="flex" justifyContent="space-between" alignItems="center">
                    <Header title={name} subtitle="Brand Bite" />
                </Box>

                {/* Category Options starts here */}
                <Box display="flex" flexDirection="column">
                    <Box display="flex" >
                        <Box display="inline-flex" bgcolor="blue" borderRadius="20px" p="5px 10px" className="thisisbtn" onClick={() => { setNewFilterData(!newFilterData) }} >
                            <Typography display="flex" justifyContent="center" alignItems="center" color="white"><AddCircleOutlineRounded sx={{ mr: "5px" }} />New Filter</Typography>
                        </Box>

                       




                    </Box>
                    <Box display="flex" borderRadius="10px" mb="-15px" mt="5px">
                        <Button onClick={() => { setVegNonVeg("veg") }}><Typography textTransform="none" fontSize="20px" fontWeight="700" color={vegNonVeg === "veg" ? colors.greenAccent[500] : colors.grey[100]}>Veg</Typography> </Button>

                        <Button onClick={() => { setVegNonVeg("nonVeg") }}> <Typography textTransform="none" fontSize="20px" fontWeight="700" color={vegNonVeg === "nonVeg" ? colors.greenAccent[500] : colors.grey[100]}>Non-veg</Typography> </Button>
                    </Box>
                </Box>
                {/* Category Options ends here */}


                <Modal open={newFilterData} onClose={handleClose}>
                    <Box
                        display="flex"
                        flexDirection="column"
                        justifyContent="center"
                        alignItems="center"
                        bgcolor="#727681"
                        p={3}
                    >
                        <Box display="flex">
                            <TextField autoFocus autoComplete="off" label="Name" value={newFilterName} onChange={(event) => {
                                setNewFilterName(event.target.value.charAt(0).toUpperCase() + event.target.value.slice(1))
                            }} />
                        </Box>



                        <Box mt="15px">
                            <Button variant="contained" color="primary" sx={{ mr: "10px" }} onClick={() => { checkAndNaviagetoNewFilter("veg") }} >
                                Veg
                            </Button>
                            <Button variant="contained" color="primary" onClick={() => { checkAndNaviagetoNewFilter("nonveg") }}>
                                Non-Veg
                            </Button>
                        </Box>

                        <Box mt="20px" display="flex" alignItems="center">
                            <Button sx={{ bgcolor: "black", borderRadius: "50%", width: "55px", height: "60px" }} onClick={handleClose}>
                                <CloseRounded sx={{ color: "white" }} />
                            </Button>
                        </Box>
                    </Box>
                </Modal>


                <Modal open={showMoveBox} onClose={handleClose}>
                    <Box
                        display="flex"
                        flexDirection="column"
                        justifyContent="center"
                        alignItems="center"
                        bgcolor="#727681"
                        p={3}
                    >
                        <Box display="flex" flexDirection="column" height="45px" ml="10px">
                            <Input autoFocus autoComplete="off" type="number" label="Name" value={dropLocationOfCategory} onChange={(event) => {
                                setDropLocationOfCategory(parseInt(event.target.value))
                            }} sx={inputStyle} />
                        </Box>

                        <Box mt="10px">
                            <Button variant="contained" color="primary" sx={{ mr: "10px" }} onClick={swapCategory}>
                                Move
                            </Button>
                            <Button variant="contained" color="primary" onClick={() => { setShowMoveBox(!showMoveBox) }}>
                                Close
                            </Button>
                        </Box>
                    </Box>
                </Modal>


                <Box display={vegNonVeg == "veg" ? "flex" : "none"} flexDirection="column" mt="30px">
                    {vegFilterList.map((imageUrl, index) => (

                        <Box key={imageUrl.name + index} display="flex" flexDirection="column" sx={{ background: colors.primary[400] }} marginBottom="20px" paddingLeft="10px" borderRadius="10px">

                            <Box display="flex" width="100%">
                                <Box flex="1">
                                    <Typography fontSize="25px" fontWeight="700" margin="10px 0" >{imageUrl.name}</Typography>
                                </Box>
                                <Box margin="10px" >
                                    <Button variant="contained" sx={{ background: "green" }}
                                        onClick={() => { setSelectedCategoryToBeMove(index) }}
                                    >
                                        Move
                                    </Button>
                                </Box>
                            </Box>
                            <Box display="flex" overflow="auto"  >
                                <Grid container maxHeight="400px" overflow="auto">
                                    {
                                        imageUrl.arr.reverse().map((vinay, index) => (
                                            <Grid item key={index + "nonVeg"} xs={12} sm={6} md={4} lg={3} xl={3} >
                                                <Box key={vinay.image + index} >

                                                    <Link to={`/filter/edit/category/${categoryKey}/veg/${imageUrl.key}`}>
                                                        <Avatar src={vinay.image} sx={{
                                                            width: avatarWidth, height: avatarHeight, marginRight: "15px", marginBottom: "10px", borderRadius: "5%", cursor: "pointer", '& > img': {
                                                                objectFit: 'fill',
                                                                width: '100%',
                                                                height: '100%',
                                                            }, ":hover": { bgcolor: "white" }
                                                        }}></Avatar></Link>
                                                </Box>
                                            </Grid>
                                        ))
                                    }
                                </Grid>
                            </Box>
                        </Box>))}
                </Box>


                <Box display={vegNonVeg == "nonVeg" ? "flex" : "none"} flexDirection="column" mt="30px">
                    {nonVegFilterList.map((imageUrl, index) => (

                        <Box key={imageUrl.name + index} display="flex" flexDirection="column" sx={{ background: colors.primary[400] }} marginBottom="20px" paddingLeft="10px" borderRadius="10px">

                            <Box display="flex" width="100%">
                                <Box flex="1">
                                    <Typography fontSize="25px" fontWeight="700" margin="10px 0" >{imageUrl.name}</Typography>
                                </Box>
                                <Box margin="10px" >
                                    <Button variant="contained" sx={{ background: "green" }}
                                        onClick={() => { setSelectedCategoryToBeMove(index) }}
                                    >
                                        Move
                                    </Button>
                                </Box>
                            </Box>
                            <Box display="flex" overflow="auto"  >
                                <Grid container maxHeight="400px" overflow="auto">
                                    {
                                        imageUrl.arr.reverse().map((vinay, index) => (
                                            <Grid item key={index + "nonVeg"} xs={12} sm={6} md={4} lg={3} xl={3} >
                                                <Box key={vinay.image + index} >

                                                    <Link to={`/filter/edit/category/${categoryKey}/nonveg/${imageUrl.key}`}>
                                                        <Avatar src={vinay.image} sx={{
                                                            width: avatarWidth, height: avatarHeight, marginRight: "15px", marginBottom: "10px", borderRadius: "5%", cursor: "pointer", '& > img': {
                                                                objectFit: 'fill',
                                                                width: '100%',
                                                                height: '100%',
                                                            }, ":hover": { bgcolor: "white" }
                                                        }}></Avatar></Link>
                                                </Box>
                                            </Grid>
                                        ))
                                    }
                                </Grid>
                            </Box>
                        </Box>))}
                </Box>





                {/* <Box display={vegNonVeg == "veg" ? "flex" : "none"} width="100%" flexWrap="wrap" mt="20px" >
                    <Grid container justify="center" spacing={1} wrap="wrap"  >
                        {vegCategory.map((val, index) => (


                            <Grid item key={index + "veg"} xs={12} sm={6} md={4} lg={3} xl={2.4} >
                                <Box display="flex" p="10px" flexDirection="column" key={val.key} width={200} position="relative">
                                    <Avatar src={val.image} variant="square" sx={{
                                        width: 200, height: 200, borderStartStartRadius: "10px", borderStartEndRadius: "10px", cursor: "pointer", '& > img': {
                                            objectFit: 'fill',
                                            width: '100%',
                                            height: '100%',
                                        }, ":hover": { bgcolor: "white" }
                                    }}></Avatar>

                                    <Typography display="flex" color="white" ml="180px" position="absolute" mt="170px">{index + 1}</Typography>

                                    <Box display="flex" bgcolor="white" sx={{ borderEndEndRadius: "10px", borderEndStartRadius: "10px" }} width="200px" justifyContent="center">


                                    </Box>
                                </Box>
                            </Grid>


                        ))}
                    </Grid>
                </Box>



                <Box display={vegNonVeg == "nonVeg" ? "flex" : "none"} width="100%" flexWrap={true} flexDirection="column" mt="20px">
                    <Grid container justify="center" spacing={1} wrap="wrap"  >
                        {nonVegCategory.map((val, index) => (


                            <Grid item key={index + "nonVeg"} xs={12} sm={6} md={4} lg={3} xl={2.4} >
                                <Box display="flex" p="10px" flexDirection="column" key={val.key} width={200} position="relative">
                                    <Avatar src={val.image} variant="square" sx={{
                                        width: 200, height: 200, borderStartStartRadius: "10px", borderStartEndRadius: "10px", cursor: "pointer", '& > img': {
                                            objectFit: 'fill',
                                            width: '100%',
                                            height: '100%',
                                        }, ":hover": { bgcolor: "white" }
                                    }}></Avatar>

                                    <Typography display="flex" color="white" ml="180px" position="absolute" mt="170px">{index + 1}</Typography>

                                    <Box display="flex" bgcolor="white" sx={{ borderEndEndRadius: "10px", borderEndStartRadius: "10px" }} width="200px" justifyContent="center">

                                    </Box>
                                </Box>
                            </Grid>


                        ))}
                    </Grid>
                </Box> */}

            </Box>

            <Box width="100%" height="100vh" display={categoryFound ? "none" : "flex"} justifyContent="Center" alignItems="center" >

                <Typography fontSize="20px">
                    Category Not Found
                </Typography>
            </Box>

        </Box>


    );
}

export default Filter;