import { Box, Button, Typography } from '@mui/material';
import { blue } from '@mui/material/colors';
import React from 'react';

function ArrayToCsvConverter({ data, columnMap, filename }) {
    const convertArrayToCsv = (array) => {
        if (array.length === 0) return '';

        const headers = Object.keys(columnMap);
        const headerRow = headers.map((key) => `"${columnMap[key]}"`).join(',') + '\n';

        const dataRows = array.map((item) => {
            const values = headers.map((key) => `"${item[key]}"`);
            return values.join(',');
        }).join('\n');

        return headerRow + dataRows;
    };

    const handleDownloadClick = () => {
        const csvString = convertArrayToCsv(data);
        const blob = new Blob([csvString], { type: 'text/csv' });
        const url = URL.createObjectURL(blob);

        const a = document.createElement('a');
        a.href = url;
        a.download = filename || 'data.csv';
        document.body.appendChild(a);
        a.click();

        document.body.removeChild(a);
        URL.revokeObjectURL(url);
    };

    return (
        <Box display="inline-flex">
            <Box backgroundColor={blue[700]} borderRadius="20px" paddingRight="5px">
                <Button onClick={handleDownloadClick}>
                    <Typography display="flex" textAlign="center" fontSize="15px" textTransform="none" color="white" alignItems="center">
                        Download CSV Report
                    </Typography>
                </Button>
            </Box>
        </Box>
    );
}


export default ArrayToCsvConverter;





