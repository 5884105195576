import {
  Avatar,
  Box,
  Button,
  capitalize,
  CircularProgress,
  debounce,
  ImageList,
  Input,
  List,
  ListItem,
  ListItemText,
  MenuItem,
  Select,
  Slide,
  Slider,
  TextField,
  Typography,
  useTheme,
} from "@mui/material";
import { tokens } from "../../theme";
import React, { useCallback, useMemo, useState } from "react";
import {
  AddCircleOutline,
  CloseRounded,
  FormatAlignCenterRounded,
  FormatAlignLeft,
  FormatAlignLeftRounded,
  FormatAlignRightRounded,
  FormatBoldRounded,
  FormatItalicRounded,
  FormatUnderlined,
  Grid4x4Outlined,
} from "@mui/icons-material";
import { useRef } from "react";
import { storage, writedb } from "../../firebase-config";
import * as htmlToImage from "html-to-image";
import { useEffect } from "react";
import fontFamilies from "../tools/fontFamilies";
import { useDrag } from "react-use-gesture";
import NewImage from "../manageOutlet/newImage";
import NewText from "../manageOutlet/newText";
import html2canvas from "html2canvas";
import { getDownloadURL } from "firebase/storage";

const EditImageFromServer = (props) => {
  //Cat display is used to close add outlet window from close button
  const catDis = props.catDis;
  const catPath = props.catPath;
  const imagePath = props.imagePath;
  const [catList, setCatList] = useState([]);
  const parent = props.parent;
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const [heightAvatar, setHeightAvatar] = useState(300);
  const [selectImage, setSeletImage] = useState(false);
  const [coverSelect, setCoverSelect] = useState(true);
  const fileInput = useRef(null);
  const bgInput = useRef(null);
  const [imageList, setImageList] = useState([]);
  const [mainBoxArray, setmainBoxArray] = useState([]); // This array is used to store movable images inside server
  const [deletedImageLinks, setDeletedImageLinks] = useState([]);
  const [textMainArray, setTextMainArray] = useState([]); // This array is used to store editable text in server...
  const [background, setBackground] = useState([]);
  const [backgroundFile, setBackgroundFile] = useState([]);
  const [outletName, setOutName] = useState(props.myName);
  const [currenSelection, setCurrSelection] = useState({
    height: 50,
    width: 50,
    left: 1,
    top: 1,
    index: 0,
  });
  const [catGen, setCatGen] = useState(
    !props.catCus ? props.catGen : props.catCus
  );
  const [selectedCatList, setSelctedCatList] = useState([]);
  const [showCategoryList, setShowCategoryList] = useState(false);
  const [height, setHeight] = useState(0);
  const [width, setWidth] = useState(0);
  const [top, setTop] = useState(0);
  const [left, setLeft] = useState(0);
  const [catNameSel, setCatNameSel] = useState(false);
  const [showAddText, setShowAddText] = useState(false);
  const [addTextData, setAddTextData] = useState(""); // This is the text data we want to add in our main editing box
  const [fontFamily, setFontFamily] = useState("Montserrat"); //This is the subpart of text data
  const [fontSize, setFontSize] = useState(16); // ||
  const [fontWeight, setFontWeight] = useState(300); // ||
  const [fontGravity, setFontGravity] = useState("start"); // ||
  const [fontItalic, setFontItalic] = useState(false); // ||
  const [fontUnderline, setFontUnderline] = useState(false); // ||
  const [fontColor, setFontColor] = useState("#ffffff"); // ||
  const [isEditText, setIsEditText] = useState(false);
  const [editTextIndex, setEditTextIndex] = useState(); // Index of array where this text is located
  const [letsupload, setletsupload] = useState(false);
  const [vegNonVeg, setVegNonVeg] = useState("");
  const [foodTypeSel, setFoodTypeSel] = useState(false);
  const [subCatName, setSubCatName] = useState("");
  const [previewImage, setPreviewImage] = useState("");
  const [subCatList, setSubCatList] = useState([]);
  const [uploadImage, setUploadImage] = useState(false);
  const [searchLabels, setSearchLables] = useState(false);
  const [labelName, setLabelName] = useState("");
  const [imageKey, setImageKey] = useState("");
  const [searchAdding, setSearchAdding] = useState(false);
  const [generatingPreview, setGeneratingPreview] = useState(false);
  const [showGuide, setShowGuide] = useState(true);
  const hLineRef = useRef(null);
  const vLineRef = useRef(null);
  const v1LineRef = useRef(null);
  const h1LineRef = useRef(null);
  let vLineY = 137;
  const [lockLines, setLockLines] = useState(false);
  const textInputRef = useRef(null);
  const [initializing, setInitializing] = useState(true);

  const generateImage = async (element) => {
    const dataUrl = await htmlToImage.toPng(element);
    setPreviewImage(dataUrl);
    const blob = await fetch(dataUrl).then((res) => res.blob());
    const storageRef = storage.ref(imagePath);
    await storageRef.child("cover").put(blob);
    let url = await getDownloadURL(storageRef.child("cover"));
    writedb
      .ref(imagePath)
      .child("cover")
      .set({ image: url })
      .then(() => {
        writedb
          .ref(catPath)
          .update({
            thumbnailImage: url,
          })
          .then(() => {
            setGeneratingPreview(false);
            saveImage();
          });
      });
  };

  const captureImage = () => {
    setGeneratingPreview(true);
    const element = document.getElementById("imageEditingBoxInAddCategory");
    generateImage(element);
  };

  useMemo(() => {
    if (parent === "menu" || parent === "wallposter") {
      setHeightAvatar(400);
    }
    if (props.a4) {
      setHeightAvatar(400);
    }
    initializeData();
  }, [parent]);

  function replaceNewLines(inputString) {
    let f = inputString;
    if (inputString.endsWith("\\n")){
      f = inputString.slice(0,-2);
    }
    
    return f.replace(/\\n/g, "\n");
  }

  // Use the downloadFile function as needed in your React component

  function initializeData() {
    writedb
      .ref(catPath)
      .once("value")
      .then((snapshotCat) => {
        if (snapshotCat.exists()) {
          writedb
            .ref(imagePath)
            .once("value")
            .then((snapshot) => {
              if (snapshot.exists()) {
                let data = snapshot.val();
                let keys = Object.keys(data);
                let values = Object.values(data);
                let iarr = [];
                let tarr = [];
                if (data.hasOwnProperty("background")) {
                  setBackground(data.background.image);
                } else {
                  setBackground(data.cover.image);
                }

                keys.map((val, index) => {
                  if (val.startsWith("i")) {
                    let item = values[index];
                    let finalItem = {
                      file: item.image,
                      top: item.top,
                      left: item.left,
                      height: item.height,
                      width: item.width,
                      index: iarr.length,
                    };
                    iarr.push(finalItem);
                  } else if (val.startsWith("t")) {
                    let item = values[index];

                    let finalItem = {
                      fontGravity: item.fontGravity,
                      text: replaceNewLines(item.text),
                      top: item.top,
                      left: item.left,
                      height: item.hasOwnProperty("height") ? item.height : 0,
                      width: item.hasOwnProperty("width") ? item.width : 0,
                      index: tarr.length,
                      fontFamily: item.fontFamily,
                      fontSize: item.fontSize,
                      fontWeight: item.fontWeight,
                      fontItalic: item.fontItalic === "true" ? true : false,
                      fontUnderline:
                        item.fontUnderline === "true" ? true : false,
                      fontColor: item.fontColor,
                    };
                    tarr.push(finalItem);
                  }
                });
                setmainBoxArray(iarr);
                setTextMainArray(tarr);
                setInitializing(false);
                setSeletImage(true);
                setCatNameSel(true);
              }
            });
        }
      });
  }

  const handleFileSelect = (event) => {
    if (imageList.length > 0) {
      const files = event.target.files;
      const ilist = Array.from(files);
      let arr = [...imageList, ...ilist];
      setImageList(arr);
    } else {
      if (event.target.files[0]) {
        setSeletImage(true);
        setCatNameSel(true);
        const files = event.target.files;
        setImageList(Array.from(files));
        if (background.length == 0) {
          setBackground(URL.createObjectURL(Array.from(files)[0]));
          setBackgroundFile(Array.from(files)[0]);
        }
      } else {
        alert("Image Not selected");
      }
    }
  };

  const bgSelect = (event) => {
    if (event.target.files[0]) {
      const files = event.target.files;
      setBackground(URL.createObjectURL(Array.from(files)[0]));
      setBackgroundFile(Array.from(files)[0]);
    } else {
      alert("Image Not selected");
    }
  };

  function addImageToMain(index) {
    setmainBoxArray([
      ...mainBoxArray,
      {
        file: imageList[index],
        top: 1,
        left: 1,
        height: 50,
        width: 50,
        index: mainBoxArray.length,
      },
    ]);
  }

  function resetText() {
    setAddTextData("");
    setFontFamily("Montserrat, sans-serif");
    setFontSize(16);
    setFontWeight(300);
    setFontItalic(false);
    setFontUnderline(false);
    setFontColor("#ffffff");
    setFontGravity("start");
  }

  function deleteText() {
    const newItems = textMainArray.filter((item, i) => i !== editTextIndex);
    setTextMainArray(newItems);
    resetEditText();
  }

  function addTextToMain() {
    if (isEditText) {
      let finalText = addTextData;
      finalText = finalText.replace(/\n+$/, "");
      if (finalText.endsWith("\\n")){
        finalText = finalText.slice(0,-2);
      }
      setAddTextData(finalText)
      const data = textMainArray[editTextIndex];
      data.text = finalText;
      data.fontFamily = fontFamily;
      data.fontSize = fontSize;
      data.fontWeight = fontWeight;
      data.fontItalic = fontItalic;
      data.fontUnderline = fontUnderline;
      data.fontColor = fontColor;
      data.fontGravity = fontGravity;
      setTimeout(() => {
        setShowAddText(true);
      }, 5);
      setShowAddText(false);
    } else {
      if (addTextData.trim().length > 0) {
        setTextMainArray([
          ...textMainArray,
          {
            fontGravity: fontGravity,
            text: addTextData,
            top: 150,
            left: 150,
            height: 0,
            width: 0,
            index: textMainArray.length,
            fontFamily: fontFamily,
            fontSize: fontSize,
            fontWeight: fontWeight,
            fontItalic: fontItalic,
            fontUnderline: fontUnderline,
            fontColor: fontColor,
          },
        ]);
        resetText();
      }
    }
  }

  function removeQuotes(inputString) {
    let input = JSON.stringify(inputString);
    if (input.startsWith('"') && input.endsWith('"')) {
      return input.slice(1, -1);
    }
    return inputString;
  }

  function checkImageType(input) {
    if (typeof input === "string") {
      // If the input is a string, check if it is a link or a file path
      if (input.startsWith("http") || input.startsWith("https")) {
        return "link";
      }
      return "file";
    } else if (input instanceof File) {
      // If the input is a File object, it's a file selected from a device
      return "device";
    }

    // If the input is neither a string nor a File object, return null or handle accordingly
    return null;
  }

  async function saveImage() {
    setInitializing(true);
    let path = imagePath;
    let imageType = checkImageType(background);
    let bgLink = "";
    if (imageType === "file") {
      let storageRef = storage.ref(path);
      await storageRef.child("background").put(backgroundFile);
      storageRef
        .child("background")
        .getDownloadURL()
        .then((url) => {
          bgLink = url;
        });
    } else if (imageType === "link") {
      bgLink = removeQuotes(background);
    }
    let cover;
    let labels;
    let paths;
    let pathSnapshot;
    let coverEx = false;
    let labelsEx = false;
    let pathsEx = false;
    await writedb
      .ref(imagePath)
      .once("value")
      .then((snapshot) => {
        if (snapshot.exists()) {
          pathSnapshot = snapshot;
          let data = snapshot.val();
          if (data.hasOwnProperty("cover")) {
            cover = data.cover;
            coverEx = true;
          }
          if (data.hasOwnProperty("labels")) {
            labels = data.labels;
            labelsEx = true;
          }
          if (data.hasOwnProperty("paths")) {
            paths = data.paths;
            pathsEx = true;
          }
          deletedImageLinks.forEach((val, index) => {
            deleteImageFromLink(val);
          });
          setDeletedImageLinks([]);
        }
      });
    if (
      pathSnapshot.val().hasOwnProperty("background") &&
      pathSnapshot.val().hasOwnProperty("cover")
    ) {
      await writedb.ref(imagePath).remove();
    }
    let storageRef = storage.ref(path);
    let finalPath = writedb.ref(path);
    await finalPath.child("background").set({ image: bgLink });
    for (let i = 0; i < mainBoxArray.length; i++) {
      const data = mainBoxArray[i];
      let imageType = checkImageType(data.file);
      if (imageType === "file" || imageType === "device") {
        const imageRef = storageRef.child(`i${i}`);
        const imageUpload = await imageRef.put(data.file).then(() => {
          imageRef.getDownloadURL().then((link) => {
            const addImage = finalPath.child(`/i${i}`);
            addImage.set({
              image: link,
              height: parseInt(data.height),
              width: parseInt(data.width),
              top: parseInt(data.top),
              left: parseInt(data.left),
            }).then(()=>{
              mainBoxArray[i].file = link;
            });
          });
        });
      } else if (imageType === "link") {
        const addImage = finalPath.child(`/i${i}`);
        addImage.set({
          image: data.file,
          height: parseInt(data.height),
          width: parseInt(data.width),
          top: parseInt(data.top),
          left: parseInt(data.left),
        });
      }
    }
    for (let i = 0; i < textMainArray.length; i++) {
      const data = textMainArray[i];
      let text = "";
      data.text
        .toString()
        .split("\n")
        .map((line, index) => {
          text = text + line + "\\n";
        });
      const addText = finalPath.child(`t${i}`);
      addText.set({
        fontGravity: data.fontGravity,
        text: text,
        fontFamily: data.fontFamily.toString(),
        fontWeight: parseInt(data.fontWeight),
        fontItalic: data.fontItalic.toString(),
        fontUnderline: data.fontUnderline.toString(),
        fontSize: parseInt(data.fontSize),
        fontColor: data.fontColor.toString(),
        top: parseInt(data.top),
        left: parseInt(data.left),
      });
    }
    if (coverEx) {
      await finalPath.child("cover").set(cover);
    }
    if (labelsEx) {
      await finalPath.child("labels").set(labels);
    }
    if (pathsEx) {
      await finalPath.child("paths").set(paths);
    }
    // setDeletedImageLinks([]);
    // initializeData();
    setInitializing(false)
    setBackground(bgLink);
    // catDis();
  }

  const deleteImageFromLink = async (link) => {
    try {
      await storage.refFromURL(link).delete();
    } catch (e) {}
  };

  const inputStyle = {
    "& label.Mui-focused": {
      color: "aqua",
    },
    "& .MuiInput-underline:after": {
      borderBottomColor: "white",
    },
    "& .MuiOutlinedInput-root": {
      "& fieldset": {
        borderColor: "gray",
      },
      "&:hover fieldset": {
        borderColor: "white",
      },
      "&.Mui-focused fieldset": {
        borderColor: "rgb(155,155,255)",
      },
      "& .MuiInputBase-input": {
        color: "black",
        fontSize: "16px",
      },
    },
  };

  const inputStyleForproperties = {
    width: "80%",
    marginBottom: "10px",
    color: "white",
    "& .MuiInputBase-input": { color: "white" },
    "& label": { color: "white" },
  };

  // ********* made changes of image such as Height width top left using this functions (Starts)

  const heightValue = (event) => {
    const inputValue = event.target.value;
    if (!isNaN(inputValue)) {
      setHeight(event.target.value);
      if (!currenSelection.fontColor) {
        currenSelection.height = inputValue;
      }
    }
  };

  const widthValue = (event) => {
    const inputValue = event.target.value;

    if (!isNaN(inputValue)) {
      setWidth(inputValue);

      if (!currenSelection.fontColor) {
        currenSelection.width = inputValue;
      }
    }
  };

  const topValue = (event) => {
    setTop(event.target.value);
    if (currenSelection.fontColor) {
      currenSelection.top = event.target.value;
    } else {
      currenSelection.top = event.target.value;
    }
  };

  const leftValue = (event) => {
    setLeft(event.target.value);
    if (currenSelection.fontColor) {
      currenSelection.left = event.target.value;
    } else {
      currenSelection.left = event.target.value;
    }
  };

  // ========== made changes of image such as Height width top left using this functions (Ends)

  // ********* This Function call when we drag image inside mainbox using mouse listener attached with that image Starts
  const handleChangeForImage = async (newText) => {
    await Promise.all([
      setCurrSelection(newText),
      setHeight(newText.height),
      setWidth(newText.width),
    ]);
  };
  // ========== This Function call when we drag image inside mainbox using mouse listener attached with that image End

  const handleTextChangeForText = async (index, top, left) => {
    await Promise.all([
      setCurrSelection(textMainArray[index]),
      (textMainArray[index].top = top),
      (textMainArray[index].left = left),
      setTop(top),
      setLeft(left),
    ]);
  };

  const handleFontFamilyChange = (event) => {
    setFontFamily(event.target.value);
  };

  const editText = (data) => {
    setEditTextIndex(data.index);
    setCurrSelection(data);
    setAddTextData(data.text);
    setFontFamily(data.fontFamily);
    setFontSize(data.fontSize);
    setFontWeight(data.fontWeight);
    setFontItalic(data.fontItalic);
    setFontUnderline(data.fontUnderline);
    setFontColor(data.fontColor);
    setFontGravity(data.fontGravity);
    setIsEditText(true);
    setShowAddText(true);
  };

  const TextDataForAddTextInMain = (event) => {
    setAddTextData(event.target.value);
  };

  const displayAddText = () => {
    if (showAddText) {
      setShowAddText(false);
    } else {
      setAddTextData("");
      setFontFamily("Montserrat, sans-serif");
      setFontSize(16);
      setFontWeight(300);
      setFontItalic(false);
      setFontUnderline(false);
      setFontColor("#ffffff");
      setShowAddText(true);
      setIsEditText(false);
    }
  };

  const resetEditText = () => {
    setCurrSelection({});
    resetText();
    setIsEditText(false);
  };

  const handleClickOfInput = (input) => {
    if (input.current) {
      input.current.click();
    }
  };

  let isKeyDown = false;

  const handleKeyDown = (event) => {
    if (isKeyDown) {
      return; // Skip handling if a key is already down
    }
    isKeyDown = true;

    if (!currenSelection.fontColor) {
      if (event.key === "w") {
        currenSelection.height += 5;
        currenSelection.width += 5;
      } else if (event.key === "s") {
        currenSelection.height -= 5;
        currenSelection.width -= 5;
      } else if (event.key === "a") {
        currenSelection.height -= 5;
      } else if (event.key === "d") {
        currenSelection.width -= 5;
      } else if (event.key === "z") {
        currenSelection.height += 5;
      } else if (event.key === "x") {
        currenSelection.width += 5;
      }
    }
  };

  const handleKeyUp = (event) => {
    setHeight(currenSelection.height);
    setWidth(currenSelection.width);
    isKeyDown = false;
  };

  useEffect(() => {
    document.addEventListener("keydown", handleKeyDown);
    document.addEventListener("keyup", handleKeyUp);
    return () => {
      document.removeEventListener("keydown", handleKeyDown);
      document.removeEventListener("keyup", handleKeyUp);
    };
  }, [height]);

  const dragFunctionForHorizontalLine = useDrag(
    ({ offset: [dx, dy], down }) => {
      if (down && !lockLines) {
        // Calculate new position within the bounds of the box
        const newTop = Math.min(
          Math.max(150 + dy, 0),
          400 - hLineRef.current.clientHeight
        );
        hLineRef.current.style.top = `${newTop}px`;
        h1LineRef.current.style.top = `${newTop}px`;
      }
    },
    { domTarget: !lockLines ? hLineRef : "", eventOptions: { passive: false } }
  );

  const dragFunctionForVerticallLine = useDrag(
    ({ offset: [dx, dy], down }) => {
      if (down && !lockLines) {
        // Calculate new position
        const newLeft = Math.min(
          Math.max(150 + dx, 0),
          290 - vLineRef.current.clientWidth
        );
        vLineRef.current.style.left = `${newLeft}px`;
        v1LineRef.current.style.left = `${newLeft}px`;
      }
    },
    { domTarget: vLineRef, eventOptions: { passive: false } }
  );

  async function setTopLeft(index, top1, left1) {
    await Promise.all([
      setTop(parseInt(top1, 10)),
      setLeft(parseInt(left1, 10)),
    ]);
    mainBoxArray[index].top = top1;
    mainBoxArray[index].left = left1;
  }
  const debouncedSetFontColor = useCallback(
    debounce((color) => {
      setFontColor(color);
    }, 50), // Adjust the debounce delay as needed
    []
  );
  const handleFontColorChange = useCallback((event) => {
    const newColor = event.target.value;
    debouncedSetFontColor(newColor);
  }, []);

  const makeCategoryList = (index) => {
    let key = catList[index].key;
    let name = catList[index].name;
    let color = catList[index].color;
    if (color === "white") {
      selectedCatList.push({ key: key, name: name });
      catList[index].color = "black";
      setCatList([...catList]);
    } else {
      if (selectedCatList.length === 1) {
        setSelctedCatList([]);
        catList[index].color = "white";
        setCatList([...catList]);
      } else {
        let arr = [];
        selectedCatList.forEach((val) => {
          if (val.key != key) {
            arr.push(val);
          }
        });
        setSelctedCatList(arr);
        catList[index].color = "white";
        setCatList([...catList]);
      }
    }
  };

  return (
    <Box
      display="flex"
      position="absolute"
      top="20%"
      bottom="20%"
      left="30%"
      right="30%"
      height={`${heightAvatar + 100}px`}
      width="700px"
      backgroundColor="#727681"
      zIndex="20"
      borderRadius="10px"
      justifyContent="center"
    >
      <Box
        display={initializing ? "flex" : "none"}
        justifyContent="center"
        alignItems="center"
      >
        <Box
          position="absolute"
          right="0px"
          top="0"
          display="flex"
          zIndex="100"
        >
          <Button onClick={catDis}>
            <CloseRounded
              sx={{
                color: colors.primary[100],
                "&:hover": {
                  color: "lightgreen",
                },
              }}
            />
          </Button>
        </Box>
        <Box
          display="flex"
          flexDirection="column"
          justifyContent="center"
          alignItems="center"
        >
          <Typography mb="10px" fontSize="20px">
            Please wait...
          </Typography>
          <CircularProgress
            style={{ height: "70px", width: "70px", padding: "5px" }}
          />
        </Box>
      </Box>
      <Box
        display={initializing ? "none" : "flex"}
        justifyContent="center"
        width="700px"
      >
        {/* ############## category setup Screen 1 starts here ################ */}
        {/* This is close button of add category */}
        <Box
          position="absolute"
          right="0px"
          top="0"
          display="flex"
          zIndex="100"
        >
          <Button onClick={catDis}>
            <CloseRounded
              sx={{
                color: colors.primary[100],
                "&:hover": {
                  color: "lightgreen",
                },
              }}
            />
          </Button>
        </Box>
        {/* This is close button of add category */}

        {/* This is for select image from files */}
        <Box
          display={selectImage ? "none" : "flex"}
          height="400px"
          width="700px"
          alignItems="center"
          flexDirection="column"
        >
          <Typography
            fontSize="35px"
            fontWeight="700"
            marginTop="100px"
            marginBottom="20px"
          >
            Add Image
          </Typography>

          <input
            accept="image/*"
            type="file"
            onChange={handleFileSelect}
            multiple
            ref={fileInput}
            style={{ display: "none" }}
          />
          <Box display="flex" justifyContent="center" alignItems="center">
            <Button
              onClick={() => {
                handleClickOfInput(fileInput);
              }}
              sx={{ backgroundColor: "blue", borderRadius: "20px" }}
            >
              <Typography
                fontSize="16px"
                textTransform="none"
                color="white"
                padding="5px"
                textAlign="center"
                justifyContent="center"
                display="flex"
                alignItems="center"
              >
                <AddCircleOutline sx={{ marginRight: "8px" }} />
                Select Images
              </Typography>
            </Button>
          </Box>
        </Box>
        {/* File select ends here */}

        {/* ############## category setup  Screen 2 starts here ################ */}

        {/* ############## editor Screen 2 starts here ################ */}

        {/* This box works after image selection complete starts */}

        <Box
          display={
            selectImage && !uploadImage
              ? letsupload || uploadImage
                ? "none"
                : !foodTypeSel
                ? "inline-flex"
                : "none"
              : "none"
          }
          height="100%"
          width="100%"
        >
          {/* Display Category name eneterd by user starts */}
          <Box
            display="flex"
            position="absolute"
            top="0"
            paddingLeft="20%"
            width="100%"
            justifyContent="center"
            marginTop="10px"
          >
            <Typography fontSize="20px" fontWeight="700" color="white">
              {outletName}
            </Typography>
          </Box>
          {/* Display Category name eneterd by user ends */}
          {/* This is for list selected images in editor */}
          <Box
            display={
              letsupload ? "none" : !foodTypeSel ? "inline-flex" : "none"
            }
            height="100%"
            width="25%"
          >
            <Box
              display="flex"
              flexDirection="column"
              height="100%"
              width="100%"
              justifyContent="start"
              alignItems="center"
              overflow="auto"
              paddingTop="10px"
              borderRight="2px solid black"
              className="hideScroll"
              backgroundColor="#727681"
              borderRadius="10px"
            >
              {imageList.map((image, index) => (
                <Avatar
                  key={image + index}
                  src={
                    JSON.stringify(image).startsWith('"https')
                      ? image
                      : URL.createObjectURL(image)
                  }
                  sx={{
                    width: "80%",
                    height: 80,
                    marginBottom: "10px",
                    borderRadius: "5%",
                    border: "2px solid white",
                  }}
                  onContextMenu={() => {
                    setBackground(URL.createObjectURL(image));
                    setBackgroundFile(image);
                  }}
                  onClick={() => {
                    addImageToMain(index);
                  }}
                />
              ))}
            </Box>
          </Box>
          {/* This is for list selected images in editor ends */}
          <Box
            display={
              letsupload ? "none" : !foodTypeSel ? "inline-flex" : "none"
            }
            width="100%"
            height="100%"
            justifyContent="center"
            alignItems="center"
          >
            {/* When You add image inside 300 * 300 editor then this trigger  */}
            <Box
              display="flex"
              width="400px"
              height={`${heightAvatar}px`}
              backgroundColor={colors.primary[500]}
            >
              <Box width="390px" height="300px">
                {/* This is fixed background image of editor */}
                <Box
                  position="absolute"
                  width="400px"
                  height={`${heightAvatar}px`}
                  zIndex="0"
                >
                  <Box display="flex" position="absolute">
                    <Box
                      id="imageEditingBoxInAddCategory"
                      display="flex"
                      flexDirection="column"
                      position="absolute"
                      width="290px"
                      height={`${heightAvatar}px`}
                      color="white"
                      zIndex="20"
                      overflow="hidden"                    >
                      <Avatar
                        id="backgroundImage"
                        src={background.length > 0 ? background : ""}
                        variant="square"
                        sx={{
                          width: "290px",
                          height: `${heightAvatar}px`,
                          position: "absolute",
                          "& > img": {
                            objectFit: "fill",
                            width: "100%",
                            height: "100%",
                            crossOrigin: "anonymous",
                          },
                        }}
                      />
                      {mainBoxArray.map((item, index) => (
                        <Box
                          key={JSON.stringify(item.file) + index}
                          onMouseDown={() => {
                            handleChangeForImage(mainBoxArray[index]);
                          }}
                        >
                          <NewImage
                            element={mainBoxArray}
                            onTextChange={handleChangeForImage}
                            topLeft={setTopLeft}
                            index={index}
                            left={item.left}
                            top={item.top}
                            myheight={heightAvatar}
                            onMouseDown={() => {
                              setCurrSelection(mainBoxArray[index]);
                            }}
                          />
                        </Box>
                      ))}
                      {textMainArray.map((item, index) => (
                        <Box
                          key={item.text + index}
                          onMouseDown={() => {
                            setCurrSelection(textMainArray[index]);
                          }}
                        >
                          <NewText
                            key={item + index}
                            element={textMainArray}
                            onTextChange={handleTextChangeForText}
                            index={index}
                            left={item.left}
                            top={item.top}
                            myheight={heightAvatar}
                            editText={editText}
                          />
                        </Box>
                      ))}
                    </Box>

                    <Box display={showGuide && !lockLines ? "flex" : "none"}>
                      <Box
                        ref={hLineRef}
                        id="horizontalline1"
                        display="flex"
                        position="absolute"
                        top={`${heightAvatar / 2 - 8}px`}
                        width="290px"
                        height="15px"
                        zIndex="50"
                        {...dragFunctionForHorizontalLine}
                        alignItems="center"
                      >
                        <Box width="290px" bgcolor="red" height="1.5px"></Box>
                      </Box>

                      <Box
                        ref={vLineRef}
                        id="verticalline1"
                        display="flex"
                        position="absolute"
                        left="137px"
                        width="15px"
                        height={`${heightAvatar}px`}
                        zIndex="50"
                        justifyContent="center"
                        cursor={lockLines ? "default" : "row-resize"}
                        {...dragFunctionForVerticallLine}
                      >
                        <Box
                          width="1.5px"
                          bgcolor="red"
                          height={`${heightAvatar}px`}
                        ></Box>
                      </Box>
                    </Box>

                    <Box display={showGuide && lockLines ? "flex" : "none"}>
                      <Box
                        ref={h1LineRef}
                        display="flex"
                        position="absolute"
                        top={`${heightAvatar / 2 - 8}px`}
                        width="290px"
                        height="15px"
                        zIndex="50"
                        alignItems="center"
                      >
                        <Box width="290px" bgcolor="red" height="1.5px"></Box>
                      </Box>

                      <Box
                        ref={v1LineRef}
                        display="flex"
                        position="absolute"
                        left={`${vLineY}px`}
                        width="15px"
                        height={`${heightAvatar}px`}
                        zIndex="50"
                        justifyContent="center"
                        cursor={lockLines ? "default" : "row-resize"}
                      >
                        <Box
                          width="1.5px"
                          bgcolor="red"
                          height={`${heightAvatar}px`}
                        ></Box>
                      </Box>
                    </Box>
                  </Box>
                </Box>

                {/* Fixed background image of editor ends here */}

                {/* Height and width of the selected image inside main box editor detais */}
                <Box
                  display="flex"
                  flexDirection="column"
                  margin="20px 0"
                  marginLeft="300px"
                  justifyContent="center"
                  alignItems="center"
                  maxHeight={heightAvatar - 50}
                  overflow="auto"
                >
                  <Box
                    display="flex"
                    flexDirection="column"
                    alignItems="center"
                    mt={heightAvatar < 400 ? "110px" : "0px"}
                  >
                    <TextField
                      autoComplete="off"
                      type="number"
                      variant="standard"
                      color="secondary"
                      label="Height"
                      value={height}
                      sx={inputStyleForproperties}
                      onChange={(e) => {
                        heightValue(e);
                      }}
                    />

                    <TextField
                      autoComplete="off"
                      type="number"
                      variant="standard"
                      color="secondary"
                      label="Width"
                      value={width}
                      sx={inputStyleForproperties}
                      onChange={(e) => {
                        widthValue(e);
                      }}
                    />

                    <TextField
                      autoComplete="off"
                      type="number"
                      variant="standard"
                      color="secondary"
                      label="Top"
                      value={top}
                      sx={inputStyleForproperties}
                      onChange={(e) => {
                        topValue(e);
                      }}
                    />

                    <TextField
                      autoComplete="off"
                      type="number"
                      variant="standard"
                      color="secondary"
                      label="Left"
                      value={left}
                      sx={inputStyleForproperties}
                      onChange={(e) => {
                        leftValue(e);
                      }}
                    />

                    <Box display="flex">
                      <Button
                        sx={{ color: "white" }}
                        onClick={() => {
                          hLineRef.current.style.top = `${
                            heightAvatar / 2 - 8
                          }px`;
                          h1LineRef.current.style.top = `${
                            heightAvatar / 2 - 8
                          }px`;
                        }}
                      >
                        Center H
                      </Button>
                    </Box>
                    <Box display="flex">
                      <Button
                        sx={{ color: "white" }}
                        onClick={() => {
                          vLineRef.current.style.left = "137px";
                          v1LineRef.current.style.left = "137px";
                        }}
                      >
                        Center V
                      </Button>
                    </Box>

                    <Box display="flex">
                      <Button
                        sx={{ color: "white" }}
                        onClick={() => {
                          setLockLines(!lockLines);
                        }}
                      >
                        {lockLines ? "Unlock" : "Lock"}
                      </Button>
                    </Box>
                    <Box display="flex">
                      <Button
                        sx={{ color: "white" }}
                        onClick={() => {
                          if (!currenSelection.fontColor) {
                            if (mainBoxArray.length == 1) {
                              let type = checkImageType(mainBoxArray[0].file);
                              if (type === "link") {
                                let arr = deletedImageLinks;
                                arr.push(mainBoxArray[0].file);
                                setDeletedImageLinks(arr);
                              }
                              setmainBoxArray([]);
                            } else {
                              let type = checkImageType(
                                mainBoxArray[currenSelection.index].file
                              );
                              if (type === "link") {
                                let arr = deletedImageLinks;
                                arr.push(
                                  mainBoxArray[currenSelection.index].file
                                );
                                setDeletedImageLinks(arr);
                              }
                              mainBoxArray.splice(currenSelection.index, 1);
                              setmainBoxArray([...mainBoxArray]);
                            }
                          } else if (currenSelection.fontColor) {
                            if (textMainArray.length == 1) {
                              setTextMainArray([]);
                            } else {
                              const newItems = textMainArray.filter(
                                (item, i) => i !== currenSelection.index
                              );
                              setTextMainArray(newItems);
                            }

                            resetEditText();
                          }
                        }}
                      >
                        Delete
                      </Button>
                    </Box>
                  </Box>
                </Box>
                {/* Height and width of the selected image inside main box editor detais ends */}
              </Box>
            </Box>

            {/* Add Image to Main Editing box Ends here   */}

            {/* For change background and add Text */}
            <Box
              display="flex"
              position="absolute"
              bottom="0"
              marginBottom="5px"
              left="0"
              borderRadius="10px"
              marginLeft="22%"
              padding="5px"
            >
              <input
                accept="image/*"
                type="file"
                onChange={bgSelect}
                ref={bgInput}
                style={{ display: "none" }}
              />
              <Box
                backgroundColor="yellow"
                marginRight="10px"
                display="flex"
                alignItems="center"
                padding="3px"
                borderRadius="5px"
                pl="5px"
                pr="5px"
              >
                <Typography
                  color="black"
                  sx={{ userSelect: "none", cursor: "pointer" }}
                  onClick={() => {
                    handleClickOfInput(bgInput);
                    // console.info(mainBoxArray);
                  }}
                >
                  Change Background
                </Typography>
              </Box>
              <Box
                backgroundColor="yellow"
                padding="3px"
                display="flex"
                alignItems="center"
                borderRadius="5px"
                pl="5px"
                pr="5px"
              >
                <Typography
                  onClick={() => {
                    displayAddText();
                  }}
                  color="black"
                  sx={{ userSelect: "none", cursor: "pointer" }}
                >
                  Add Text
                </Typography>
              </Box>
              <Box
                backgroundColor="yellow"
                padding="2px"
                display="flex"
                justifyContent="center"
                alignItems="center"
                borderRadius="5px"
                ml="10px"
              >
                <Typography
                  onClick={() => {
                    setShowGuide(!showGuide);
                  }}
                  color="black"
                  sx={{ userSelect: "none", cursor: "pointer" }}
                >
                  <Grid4x4Outlined sx={{ pt: "5px" }} />
                </Typography>
              </Box>
              <Box
                backgroundColor="yellow"
                padding="2px"
                display="flex"
                justifyContent="center"
                alignItems="center"
                borderRadius="5px"
                ml="10px"
                pl="5px"
                pr="5px"
              >
                <Typography
                  onClick={() => {
                    handleClickOfInput(fileInput);
                  }}
                  color="black"
                  sx={{ userSelect: "none", cursor: "pointer" }}
                >
                  Add Images
                </Typography>
              </Box>

              <Box
                backgroundColor="yellow"
                padding="2px"
                display="flex"
                justifyContent="center"
                alignItems="center"
                borderRadius="5px"
                ml="10px"
                pl="5px"
                pr="5px"
              >
                <Box display={generatingPreview ? "none" : "flex"}>
                  <Typography
                    onClick={() => {
                      saveImage();
                    }}
                    color="black"
                    sx={{ userSelect: "none", cursor: "pointer" }}
                  >
                    Save
                  </Typography>
                </Box>
                <Box
                  width="50px"
                  display={generatingPreview ? "flex" : "none"}
                  justifyContent="center"
                >
                  <Box display="flex">
                    <CircularProgress
                      style={{ height: "30px", width: "30px", padding: "5px" }}
                    />
                  </Box>
                </Box>
              </Box>
              <Box
                backgroundColor="yellow"
                padding="2px"
                display="flex"
                justifyContent="center"
                alignItems="center"
                borderRadius="5px"
                ml="10px"
                pl="5px"
                pr="5px"
              >
                <Box display={generatingPreview ? "none" : "flex"}>
                  <Typography
                    onClick={captureImage}
                    color="black"
                    sx={{ userSelect: "none", cursor: "pointer" }}
                  >
                    Save With Preview
                  </Typography>
                </Box>
                <Box
                  width="100px"
                  display={generatingPreview ? "flex" : "none"}
                  justifyContent="center"
                >
                  <Box display="flex">
                    <CircularProgress
                      style={{ height: "30px", width: "30px", padding: "5px" }}
                    />
                  </Box>
                </Box>
              </Box>
            </Box>
          </Box>
        </Box>

        {/* #############   This is for add text inside ediitor #################### */}

        <Box
          display="flex"
          width="250px"
          overflow="auto"
          height="100%"
          position="fixed"
          top="0"
          right={showAddText ? "0" : "-250px"}
          sx={{
            background: colors.primary[400],
            transition: "0.3s all ease-in-out",
          }}
          justifyContent="start"
          flexDirection="column"
          padding="0px 20px"
          paddingTop="100px"
          zIndex="105"
        >
          <Box
            position="fixed"
            right="-10px"
            top="0"
            display={showAddText ? "flex" : "none"}
            zIndex="100"
          >
            <Button
              onClick={() => {
                showAddText ? setShowAddText(false) : setShowAddText(true);
              }}
            >
              <CloseRounded
                sx={{
                  color: colors.primary[100],
                  "&:hover": {
                    color: "blue",
                  },
                }}
              />
            </Button>
          </Box>

          <TextField
            ref={textInputRef}
            autoComplete="off"
            label="Enter Text"
            multiline
            value={addTextData}
            onChange={(event) => {
              TextDataForAddTextInMain(event);
            }}
            onMouseDown={() => {
              setCurrSelection({});
            }}
            sx={{ inputStyle }}
          />

          <Typography
            color={fontColor}
            sx={{
              textAlign: fontGravity,
              fontFamily: fontFamily,
              margin: "20px 0",
              fontSize: fontSize + "px",
              fontWeight: fontWeight,
              fontStyle: fontItalic ? "italic" : "",
              textDecoration: fontUnderline ? "underline" : "",
            }}
          >
            {addTextData.split("\n").map((line, index) => {
              return (
                <React.Fragment key={index}>
                  {line}
                  <br />
                </React.Fragment>
              );
            })}
          </Typography>

          <Select value={fontFamily} onChange={handleFontFamilyChange}>
            {fontFamilies.map((option) => (
              <MenuItem key={option.value} value={option.value}>
                <Typography sx={{ fontFamily: option.value }}>
                  {option.label}
                </Typography>
              </MenuItem>
            ))}
          </Select>

          <Box
            display="flex"
            marginTop="20px"
            border="2px solid black"
            width="100%"
          >
            <Box
              display="flex"
              justifyContent="center"
              alignItems="center"
              width="20%"
              height="35px"
              borderRight="2px solid black"
              onClick={() => {
                fontWeight == 700 ? setFontWeight(300) : setFontWeight(700);
              }}
              backgroundColor={fontWeight == 700 ? "white" : ""}
              color={fontWeight == 700 ? "black" : "white"}
              sx={{ cursor: "pointer" }}
            >
              <FormatBoldRounded />
            </Box>
            <Box
              display="flex"
              justifyContent="center"
              alignItems="center"
              width="20%"
              height="35px"
              borderRight="2px solid black"
              onClick={() => {
                fontItalic ? setFontItalic(false) : setFontItalic(true);
              }}
              backgroundColor={fontItalic ? "white" : ""}
              color={fontItalic ? "black" : "white"}
              sx={{ cursor: "pointer" }}
            >
              <FormatItalicRounded />
            </Box>
            <Box
              display="flex"
              justifyContent="center"
              alignItems="center"
              width="20%"
              height="35px"
              borderRight="2px solid black"
              onClick={() => {
                fontUnderline
                  ? setFontUnderline(false)
                  : setFontUnderline(true);
              }}
              backgroundColor={fontUnderline ? "white" : ""}
              color={fontUnderline ? "black" : "white"}
              sx={{ cursor: "pointer" }}
            >
              <FormatUnderlined />
            </Box>
            <Box display="flex" width="40%" height="35px" alignItems="center">
              <TextField
                placeholder="size"
                type="number"
                inputProps={{ max: 40, min: 5 }}
                value={fontSize}
                onChange={(event) => {
                  setFontSize(event.target.value);
                }}
                sx={{
                  "& label.Mui-focused": {
                    color: "aqua",
                  },
                  "& .MuiInput-underline:after": {
                    borderBottomColor: "white",
                  },
                  "& .MuiOutlinedInput-root": {
                    "& fieldset": {
                      border: "none",
                    },
                    "&:hover fieldset": {
                      border: "none",
                    },
                    "&.Mui-focused fieldset": {
                      border: "none",
                    },
                    "& .MuiInputBase-input": {
                      color: "white",
                      fontSize: "15px",
                      border: "none",
                    },
                  },
                }}
              />
            </Box>
          </Box>

          <Box display="inline-flex" marginTop="20px">
            <Box display="inline-flex" border="2px solid black">
              <Box
                display="flex"
                justifyContent="center"
                alignItems="center"
                width="40px"
                height="35px"
                borderRight="2px solid black"
                onClick={() => {
                  setFontGravity("start");
                }}
                backgroundColor={fontGravity == "start" ? "white" : ""}
                color={fontGravity == "start" ? "black" : "white"}
                sx={{ cursor: "pointer" }}
              >
                <FormatAlignLeftRounded />
              </Box>

              <Box
                display="flex"
                justifyContent="center"
                alignItems="center"
                width="40px"
                height="35px"
                borderRight="2px solid black"
                onClick={() => {
                  setFontGravity("center");
                }}
                backgroundColor={fontGravity == "center" ? "white" : ""}
                color={fontGravity == "center" ? "black" : "white"}
                sx={{ cursor: "pointer" }}
              >
                <FormatAlignCenterRounded />
              </Box>

              <Box
                display="flex"
                justifyContent="center"
                alignItems="center"
                width="40px"
                height="35px"
                onClick={() => {
                  setFontGravity("end");
                }}
                backgroundColor={fontGravity == "end" ? "white" : ""}
                color={fontGravity == "end" ? "black" : "white"}
                sx={{ cursor: "pointer" }}
              >
                <FormatAlignRightRounded />
              </Box>
            </Box>
          </Box>

          <Box display="flex" marginTop="20px" width="100%">
            <TextField
              label="color"
              type="color"
              value={fontColor}
              onChange={handleFontColorChange}
              InputLabelProps={{
                shrink: true,
              }}
              sx={{ width: "50%", height: "50%" }}
            />
          </Box>

          <Box display="flex" marginTop="20px">
            <Box
              display="flex"
              backgroundColor="blue"
              padding="8px"
              borderRadius="18px"
              alignItems="center"
            >
              <Typography
                onClick={() => {
                  addTextToMain();
                }}
                color="white"
                sx={{ userSelect: "none" }}
                fontSize="15px"
              >
                {isEditText ? "Edit Text" : "Add Text"}
              </Typography>
            </Box>

            <Box
              display={isEditText ? "flex" : "none"}
              ml="10px"
              backgroundColor="blue"
              padding="8px"
              borderRadius="18px"
              alignItems="center"
            >
              <Typography
                onClick={() => {
                  deleteText();
                }}
                color="white"
                sx={{ userSelect: "none" }}
                fontSize="15px"
              >
                Delete
              </Typography>
            </Box>

            <Box display={isEditText ? "flex" : "none"} marginLeft="10px">
              <Box
                display="flex"
                backgroundColor="blue"
                padding="8px"
                borderRadius="18px"
                alignItems="center"
              >
                <Typography
                  onClick={() => {
                    resetEditText();
                  }}
                  color="white"
                  sx={{ userSelect: "none" }}
                  fontSize="15px"
                >
                  Reset
                </Typography>
              </Box>
            </Box>
          </Box>
        </Box>

        {/* #############   This is for add text inside ediitor ends #################### */}
      </Box>
    </Box>
  );
};

export default EditImageFromServer;
