import { Avatar, Box, Button, Grid, Input, Modal, TextField, Tooltip, Typography, useTheme } from "@mui/material";
import { Link, useLocation, useParams } from "react-router-dom";
import Header from "../../components/Header";
import { tokens } from "../../theme";
import { useEffect, useState } from "react";
import { useMemo } from "react";
import { onValue, ref } from "firebase/database";
import { database, storage, writedb } from "../../firebase-config";
import { AddCircleOutlineRounded, DeleteRounded, AbcRounded, CloseRounded, AddCircleOutline, CheckBox, CheckBoxOutlineBlank } from "@mui/icons-material";
import React from "react";


const AddImagesInFilter = () => {
    const theme = useTheme();
    const colors = tokens(theme.palette.mode);

    const location = useLocation().pathname;

    const [catCus, setCatCus] = useState("");
    const [filterType, setFilterTypw] = useState("");
    const [vegNonVeg, setVegNonVeg] = useState("veg");

    const categoryKey = useParams().catKey;
    const FType = useParams().type;
    const filterName = useParams().name;
    const filterKey = useParams().filterKey
    const [noImageMsg,setNoImagesMsg] = useState(false)

    useEffect(() => {
        if (location.includes("home/edit/category") || location.includes("filter/edit/addimages/category")) {
            setCatCus("category");
            loadData("category")
        } else if (location.includes("home/edit/custom")) {
            setCatCus("custom");
            loadData("custom")
        }

        if (FType == "nonveg") {
            setVegNonVeg("nonVeg")
        }
    }, [catCus.length])



    const [name, setName] = useState();  // this is the name of the category


    const [myKey, setMyKey] = useState();
    const [showaddImage, setShowAddimage] = useState(false);
    const [renameName, setRenameName] = useState('');
    const [showRename, setShowRename] = useState(false);
    const [catSnapshot, setCatSnapshot] = useState();
    const [vegCategory, setVegCategory] = useState([]);
    const [nonVegCategory, setNonVegCategory] = useState([]);
    const [renameRunning, setRenameRunning] = useState(false);
    const [showSubCatRename, setShowSubCatRename] = useState(false);
    const [subCatName, setSubCatName] = useState("");
    const [selectedSubCat, setSelectedSubCat] = useState();
    const [categoryFound, setCategoryFound] = useState(true);
    const [catDeleting, setCatDeleting] = useState(false)
    const [showAddMultipleImages, setShowAddMultipleImages] = useState(false);
    const [selectedImageIndexNumber, setSelectedImageIndexNumber] = useState(0); // selected image index number which we want to move;
    const [showMoveBox, setShowMoveBox] = useState(false); //this is used to get index where we want to move image;
    const [newFilterData, setNewFilterData] = useState(false);
    const [dropLocationOfImage, setDropLocationOfImage] = useState("");
    const [imageSnapshot, setImageSnapshot] = useState("");
    const [mainPath, setMainPath] = useState("");
    const [newFilterName, setNewFilterName] = useState("");
    const [selectedImages, setSelectedImages] = useState([]);

    const [imageCount, setImageCount] = useState("0");

    function loadData(address) {

        let type = "vegFilter"
        let catType = "veg"
        if (FType == "nonveg") {
            type = "nonVegFilter"
            catType = "nonVeg"
        }
        onValue(ref(database, `images/${address}`), (snapshotAllImages) => {
            setImageSnapshot(snapshotAllImages);
            onValue(ref(database, `home/${address}/${categoryKey}`), (snapshot) => {
                onValue(ref(database, `home/${address}/${categoryKey}/cover/filter/${type}/${filterKey}/${filterName}`), (snapshotFilter) => {
                    if (snapshot.exists() && snapshotFilter.exists()) {
                        setCategoryFound(true)
                        let categoryArr = [];
                        let filterArr = [];
                        let snapVal = snapshot.val();
                        let name = Object.keys(snapVal)[0];
                        setName(name)
                        snapVal = snapshot.child(name).child(catType).val()
                        let imageKeysForCategory = Object.keys(snapVal);
                        let imagePaths = Object.values(snapVal);
                        imagePaths.forEach((val, index) => {
                            let imageKeyInCategory = imageKeysForCategory[index]
                            let imageKeyForLink = val.image.slice(7).slice(catCus.length + 1);
                            if (snapshotAllImages.child(imageKeyForLink).exists()) {
                                let imageObj = snapshotAllImages.child(imageKeyForLink).val();
                                if (imageObj.hasOwnProperty("cover") && imageObj != null) {
                                    let image = imageObj.cover.image;
                                    var newItem = { image: image, key: imageKeyForLink, imageCatKey: imageKeyInCategory, selected: false, index: index, icon: <CheckBoxOutlineBlank sx={{ height: "30px", width: "30px" }} /> } // imagecatkey is the key for image path listed in home/category/"key"/   and the key:key is for the image data listed in images/category/"key"s
                                    categoryArr.push(newItem);
                                }
                            }
                        })

                        let imagePathFilter = Object.values(snapshotFilter.val());
                        imagePathFilter.forEach(val => {
                            let imagekey = val.image.slice(7).slice(catCus.length + 1);
                            categoryArr = categoryArr.filter(item => item.key !== imagekey);
                        })

                        if (FType == "nonveg") {
                            setNonVegCategory(categoryArr);
                        } else {
                            setVegCategory(categoryArr);
                        }
                        setImageCount(0)
                        setSelectedImages([])

                        if (categoryArr.length < 1){
                            setNoImagesMsg(true);
                        }else{
                            setNoImagesMsg(false);
                        }


                    }
                    else {
                        setCategoryFound(false)
                    }


                })
            })

        });

    }




    function selectImage(val, icon) {
        let check = val.selected;
        let arr = [...selectedImages];
        if (check) {
            val.icon = <CheckBoxOutlineBlank sx={{ height: "30px", width: "30px" }} />
            val.selected = false;
            arr.splice(val.index, 1)
            arr = arr.filter(item => item.key !== val.key);


        } else {
            val.icon = <CheckBox sx={{ height: "30px", width: "30px" }} />
            val.selected = true;
            let path = `images/${catCus}/${val.key}`
            let item = { path: path, key: val.key,thumbnailImage:val.image }

            val.newIndex = selectedImages.length;
            arr.push(item)

        }

        if (FType === "nonVeg") {
            let newArray = [...nonVegCategory];

            setNonVegCategory(newArray)
        } else {
            let newArray = [...vegCategory];

            setVegCategory(newArray)
        }





        setSelectedImages(arr);

        setImageCount(arr.length)

    }

    async function checkSnapshotValue() {
        let type = "vegFilter";
        if (FType == "nonveg") {
            type = "nonVegFilter"
        }
        let checkPath = `home/${catCus}/${categoryKey}/cover/filter/uniqueKeys/${type}/${filterName}`
        let check = await writedb.ref(checkPath).once("value").then(async (snapshot) => {
            if (snapshot.exists()) {

                let path = `home/${catCus}/${categoryKey}/cover/filter/${type}/${filterKey}/${filterName}`
            
                return path;
            } else {
                let path = `home/${catCus}/${categoryKey}/cover/filter/${type}`
                let key = writedb.ref(path).push().key
                await writedb.ref(checkPath).set({ key: key })
                path = `home/${catCus}/${categoryKey}/cover/filter/${type}/${key}/${filterName}`
                return path
            }
        });
        return check;
    }

    async function addFilter() {

        if (selectedImages.length > 0) {

            let check = await checkSnapshotValue();
            if (check === "already exist") {
                alert("This filter is already exist");
            } else {
                let path = check;
                selectedImages.forEach((val, index) => {
                    writedb.ref(path).push().set({ image: val.path,thumbnailImage:val.thumbnailImage }).then(() => {
                        if (index == selectImage.length - 1) {
                            alert("Filter Updated successfully");
                        }
                    })
                })

            }

            // let path = `home/${catCus}/${categoryKey}/cover/filter/${type}/${filterName}`
            // selectedImages.forEach(val => {
            //     writedb.ref(path).push().set({image:val.path})
            // })
        } else {
            alert("Please select images to Update filter")
        }




    }


    return (
        <Box>

            <Box display={categoryFound ? "flex" : "none"} flexDirection="column" m="20px" marginBottom="20px">
                {/* HEADER */}
                <Box display="flex" justifyContent="space-between" alignItems="center">
                    <Header title={filterName + " in " + name} subtitle="Brand Bite" />
                </Box>

                {/* Category Options starts here */}
                <Box display="flex" flexDirection="column">
                    <Box display="flex" >
                        <Box display="inline-flex" bgcolor="blue" borderRadius="20px" p="5px 10px" className="thisisbtn" onClick={addFilter} >
                            <Typography display="flex" justifyContent="center" alignItems="center" color="white">Update Filter</Typography>
                        </Box>

                        <Box display="inline-flex" bgcolor="blue" borderRadius="20px" ml="10px" p="5px 10px" className="thisisbtn" onClick={() => { { loadData("category") } { setSelectedImages([]) } { setImageCount(0) } }} >
                            <Typography display="flex" justifyContent="center" alignItems="center" color="white">Reset</Typography>
                        </Box>

                        <Box borderRadius="20px" ml="10px" p="5px 10px" className="thisisbtn" >
                            <Typography display="flex" justifyContent="center" alignItems="center" color="white">{`${imageCount} Images Selected`}</Typography>
                        </Box>



                    </Box>
                    <Box display="none" borderRadius="10px" mb="-15px" mt="5px">
                        <Button onClick={() => { setVegNonVeg("veg") }}><Typography textTransform="none" fontSize="20px" fontWeight="700" color={vegNonVeg === "veg" ? colors.greenAccent[500] : colors.grey[100]}>Veg</Typography> </Button>

                        <Button onClick={() => { setVegNonVeg("nonVeg") }}> <Typography textTransform="none" fontSize="20px" fontWeight="700" color={vegNonVeg === "nonVeg" ? colors.greenAccent[500] : colors.grey[100]}>Non-veg</Typography> </Button>
                    </Box>
                </Box>
                {/* Category Options ends here */}


                <Box display={vegNonVeg == "veg" ? "flex" : "none"} width="100%" flexWrap="wrap" mt="20px" >
                    <Grid container justify="center" spacing={1} wrap="wrap"  >
                        {vegCategory.map((val, index) => (


                            <Grid item key={index + "veg"} xs={12} sm={6} md={4} lg={3} xl={2.4} >

                                <Box display="flex" p="10px" flexDirection="column" key={val.key} width={200} position="relative" onClick={() => { selectImage(val, val.icon) }}>

                                    <Avatar src={val.image} variant="square" sx={{
                                        width: 200, height: 200, borderRadius: "10px", cursor: "pointer", '& > img': {
                                            objectFit: 'fill',
                                            width: '100%',
                                            height: '100%',
                                        }, ":hover": { bgcolor: "white" }
                                    }}></Avatar>

                                    <Typography display="flex" color="white" ml="10px" position="absolute" mt="170px">{index + 1}</Typography>

                                    <Box id={"checkbox" + index} display="flex" color="white" ml="165px" position="absolute" mt="165px">
                                        {val.icon}

                                    </Box>

                                </Box>

                            </Grid>


                        ))}
                    </Grid>
                </Box>



                <Box display={vegNonVeg == "nonVeg" ? "flex" : "none"} width="100%" flexWrap={true} flexDirection="column" mt="20px">
                    <Grid container justify="center" spacing={1} wrap="wrap"  >
                        {nonVegCategory.map((val, index) => (


                            <Grid item key={index + "veg"} xs={12} sm={6} md={4} lg={3} xl={2.4} >

                                <Box display="flex" p="10px" flexDirection="column" key={val.key} width={200} position="relative" onClick={() => { selectImage(val, val.icon) }}>

                                    <Avatar src={val.image} variant="square" sx={{
                                        width: 200, height: 200, borderRadius: "10px", cursor: "pointer", '& > img': {
                                            objectFit: 'fill',
                                            width: '100%',
                                            height: '100%',
                                        }, ":hover": { bgcolor: "white" }
                                    }}></Avatar>

                                    <Typography display="flex" color="white" ml="10px" position="absolute" mt="170px">{index + 1}</Typography>

                                    <Box id={"checkbox" + index} display="flex" color="white" ml="165px" position="absolute" mt="165px">
                                        {val.icon}

                                    </Box>

                                </Box>

                            </Grid>


                        ))}
                    </Grid>
                </Box>

            </Box>

            <Box width="100%" height="100vh" display={categoryFound ? "none" : "flex"} justifyContent="Center" alignItems="center" >

                <Typography fontSize="20px">
                    Category Not Found
                </Typography>
            </Box>


            <Box width="100%" height="50vh" display={noImageMsg ? "flex" : "none"} justifyContent="Center" alignItems="center" >

                <Typography fontSize="20px" textAlign="center">
                
                    No Images To Add
                    <br/>
                    All images of {name} are added in this filter
                    
                </Typography>
            </Box>

        </Box>


    );
}

export default AddImagesInFilter;