import React, { useEffect, useMemo, useState } from "react";
import {
  TextField,
  MenuItem,
  Select,
  InputLabel,
  FormControl,
  Grid,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Typography,
  Box,
  useMediaQuery,
  TablePagination,
} from "@mui/material";
import { writedb } from "../../firebase-config";
import { useTheme } from "@emotion/react";
import { tokens } from "../../theme";

const Transactions = () => {
  const theme = useTheme();
  const darkMode = theme.palette.mode === "dark";
  const colors = tokens(theme.palette.mode);
  const [transactions, setTransactions] = useState([]);
  const [filteredTransactions, setFilteredTransactions] = useState([]);
  const [status, setStatus] = useState("");
  const [month, setMonth] = useState("");
  const [year, setYear] = useState("");
  const [date, setDate] = useState("");
  const [plan, setPlan] = useState("");
  const isSmallScreen = useMediaQuery(theme.breakpoints.down("sm"));
  const [page, setPage] = useState(0);
  const [rowsPerPage] = useState(10);
  const path = "/analytics/paymentTransactions";
  useMemo(() => {
    const transactionRef = writedb.ref("/analytics/paymentTransactions");
    transactionRef.on("value", (snapshot) => {
      const data = snapshot.val();
      const loadedTransactions = [];
      for (let id in data) {
        loadedTransactions.push({ id, ...data[id], key: id.key });
      }
      loadedTransactions.sort(
        (a, b) => Date.parse(b.date) - Date.parse(a.date)
      );
      setTransactions(loadedTransactions);
      setFilteredTransactions(loadedTransactions); // Initial load
    });
  }, [setTransactions, path]);

  useEffect(() => {
    applyFilters();
  }, [date, status, month, year, plan]);

  const applyFilters = () => {
    let filtered = transactions;
    if (date)
      filtered = filtered.filter(
        (transaction) => formatDateToISO(transaction.date) === date.toString()
      );
    if (status)
      filtered = filtered.filter(
        (transaction) => transaction.status === status
      );
    if (month)
      filtered = filtered.filter((transaction) => {
        
        const transactionDate = new Date(transaction.date);
        const transactionMonth = transactionDate.toLocaleString("en-US", {
            month: "short",
          }).slice(0, 3);
        return transactionMonth === month;
      });
    if (year)
      filtered = filtered.filter(
        (transaction) => new Date(transaction.date).getFullYear() === year
      );
    if (plan)
      filtered = filtered.filter(
        (transaction) => transaction.selectedPlan === plan
      );

    filtered.sort((a, b) => Date.parse(b.date) - Date.parse(a.date));
    setFilteredTransactions(filtered);
  };

  const formatDateToISO = (inputDate) => {
    const [day, month, year] = inputDate.replace(",", "").split(" ");
    const monthMap = {
      Jan: "01",
      Feb: "02",
      Mar: "03",
      Apr: "04",
      May: "05",
      Jun: "06",
      Jul: "07",
      Aug: "08",
      Sep: "09",
      Oct: "10",
      Nov: "11",
      Dec: "12",
    };
    return `${year}-${monthMap[month]}-${day.padStart(2, "0")}`;
  };

  const isValidEmail = (email) => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(email);
  };

  // Pagination handler
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  return (
    <Box sx={{ padding: 4, borderRadius: "8px", boxShadow: 2 }}>
      <Typography variant="h4" component="h1" gutterBottom mt="5px">
        Payment Transactions
      </Typography>

      {/* Filters */}
      <Grid container spacing={2} mt="5px">
        <Grid item xs={12} sm={6} md={3}>
          <TextField
            type="date"
            label="Select Date"
            value={date}
            onChange={(e) => setDate(e.target.value)}
            fullWidth
            variant="outlined"
            InputLabelProps={{ shrink: true }}
          />
        </Grid>

        <Grid item xs={12} sm={6} md={3}>
          <FormControl fullWidth variant="outlined">
            <InputLabel>Status</InputLabel>
            <Select
              value={status}
              onChange={(e) => setStatus(e.target.value)}
              label="Status"
            >
              <MenuItem value="">
                <em>None</em>
              </MenuItem>
              <MenuItem value="success">Success</MenuItem>
              <MenuItem value="canceled">Canceled</MenuItem>
              <MenuItem value="Technical Error">Technical Error</MenuItem>
            </Select>
          </FormControl>
        </Grid>

        <Grid item xs={12} sm={6} md={3}>
          <FormControl fullWidth variant="outlined">
            <InputLabel>Month</InputLabel>
            <Select
              value={month}
              onChange={(e) => setMonth(e.target.value)}
              label="Month"
            >
              <MenuItem value="">
                <em>None</em>
              </MenuItem>
              {[
                "Jan",
                "Feb",
                "Mar",
                "Apr",
                "May",
                "Jun",
                "Jul",
                "Aug",
                "Sep",
                "Oct",
                "Nov",
                "Dec",
              ].map((m) => (
                <MenuItem key={m} value={m}>
                  {m}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Grid>

        <Grid item xs={12} sm={6} md={3}>
          <FormControl fullWidth variant="outlined">
            <InputLabel>Year</InputLabel>
            <Select
              value={year}
              onChange={(e) => setYear(e.target.value)}
              label="Year"
            >
              <MenuItem value="">
                <em>None</em>
              </MenuItem>
              <MenuItem value={2023}>2023</MenuItem>
              <MenuItem value={2024}>2024</MenuItem>
            </Select>
          </FormControl>
        </Grid>

        {/* New Plan Filter */}
        <Grid item xs={12} sm={6} md={3}>
          <FormControl fullWidth variant="outlined">
            <InputLabel>Plan</InputLabel>
            <Select
              value={plan}
              onChange={(e) => setPlan(e.target.value)}
              label="Plan"
            >
              <MenuItem value="">
                <em>None</em>
              </MenuItem>
              <MenuItem value="1 Month">1 Month</MenuItem>
              <MenuItem value="6 Month">6 Month</MenuItem>
              <MenuItem value="1 Year">1 Year</MenuItem>
            </Select>
          </FormControl>
        </Grid>
      </Grid>

      {/* Transaction List */}
      {isSmallScreen ? (
        <Box sx={{ marginTop: 3 }}>
          {filteredTransactions.length > 0 ? (
            filteredTransactions
              .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
              .map((transaction) => (
                <Box
                  key={transaction.id}
                  sx={{
                    padding: 2,
                    mt: 2,
                    mb: 2,
                    border: `1px solid ${
                      darkMode ? "#2D9CDB" : theme.palette.primary.main
                    }`, // Accent color for borders
                    borderRadius: "8px",
                    backgroundColor: darkMode ? "#1E1E2F" : "#FFFFFF", // Dark mode vs light mode
                    boxShadow: darkMode
                      ? "0 4px 8px rgba(0, 0, 0, 0.5)"
                      : "0 2px 4px rgba(0, 0, 0, 0.1)", // Soft shadow
                  }}
                >
                  <Typography
                    variant="h6"
                    sx={{ color: darkMode ? "#E0E0E0" : "#000000" }}
                  >
                    {" "}
                    {/* Text color */}
                    Date: {transaction.date}
                  </Typography>
                  <Typography sx={{ color: darkMode ? "#B0BEC5" : "#000000" }}>
                    Mobile:{" "}
                    {isValidEmail(transaction.mobile)
                      ? "--"
                      : transaction.mobile}
                  </Typography>
                  <Typography sx={{ color: darkMode ? "#B0BEC5" : "#000000" }}>
                    Email: {transaction.userMainEmail || "--"}
                  </Typography>
                  <Typography sx={{ color: darkMode ? "#B0BEC5" : "#000000" }}>
                    Price: {transaction.price}
                  </Typography>
                  <Typography sx={{ color: darkMode ? "#B0BEC5" : "#000000" }}>
                    Reference: {transaction.ref}
                  </Typography>
                  <Typography sx={{ color: darkMode ? "#B0BEC5" : "#000000" }}>
                    Plan: {transaction.selectedPlan}
                  </Typography>
                  <Typography sx={{ color: darkMode ? "#B0BEC5" : "#000000" }}>
                    Status: {transaction.status}
                  </Typography>
                </Box>
              ))
          ) : (
            <Typography>No transactions found.</Typography>
          )}
          <TablePagination
            rowsPerPageOptions={[]}
            component="div"
            count={filteredTransactions.length}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            sx={{
              backgroundColor: darkMode ? colors.blueAccent[700] : "#e0e0e0",
              color: darkMode ? "#ffffff" : "#000000",
            }}
          />
        </Box>
      ) : (
        <TableContainer
          component={Paper}
          style={{ marginTop: "30px",maxHeight:"65vh" }}
        >
          <Table stickyHeader>
            <TableHead
              sx={{
                backgroundColor: colors.blueAccent[700] ,
                color: darkMode ? "#ffffff" : "#000000",
              }}
            >
              <TableRow>
                <TableCell>Date</TableCell>
                <TableCell>Mobile</TableCell>
                <TableCell>Email</TableCell>
                <TableCell>Price-USD</TableCell>
                <TableCell>Reference</TableCell>
                <TableCell>Plan</TableCell>
                <TableCell>Status</TableCell>
              </TableRow>
            </TableHead>
            <TableBody
            >
              {filteredTransactions.length > 0 ? (
                filteredTransactions
                  .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                  .map((transaction, index) => (
                    <TableRow
                      key={transaction.id}
                      sx={{
                        backgroundColor: darkMode
                          ? index % 2 === 0
                            ? "#1E1E2F" // Darker shade for even rows
                            : "#2A2A3B" // Slightly lighter for odd rows
                          : index % 2 === 0
                          ? "#f9f9f9"
                          : "#e0e0e0",
                        "&:hover": {
                          backgroundColor: darkMode ? "#2B2B3D" : "#d3d3d3", // Slightly lighter on hover
                        },
                      }}
                    >
                      <TableCell
                        sx={{ color: darkMode ? "#E0E0E0" : "#000000" }}
                      >
                        {transaction.date}
                      </TableCell>
                      <TableCell
                        sx={{ color: darkMode ? "#E0E0E0" : "#000000" }}
                      >
                        {isValidEmail(transaction.mobile)
                          ? "--"
                          : transaction.mobile}
                      </TableCell>
                      <TableCell
                        sx={{ color: darkMode ? "#E0E0E0" : "#000000" }}
                      >
                        {transaction.userMainEmail
                          ? transaction.userMainEmail
                          : "--"}
                      </TableCell>
                      <TableCell
                        sx={{ color: darkMode ? "#E0E0E0" : "#000000" }}
                      >
                        {transaction.price}
                      </TableCell>
                      <TableCell
                        sx={{ color: darkMode ? "#E0E0E0" : "#000000" }}
                      >
                        {transaction.ref}
                      </TableCell>
                      <TableCell
                        sx={{ color: darkMode ? "#E0E0E0" : "#000000" }}
                      >
                        {transaction.selectedPlan}
                      </TableCell>
                      <TableCell
                        sx={{ color: darkMode ? "#E0E0E0" : "#000000" }}
                      >
                        {transaction.status}
                      </TableCell>
                    </TableRow>
                  ))
              ) : (
                <TableRow>
                  <TableCell colSpan={7} align="center">
                    No transactions found.
                  </TableCell>
                </TableRow>
              )}
            </TableBody>
          </Table>
          <TablePagination
            rowsPerPageOptions={[]}
            component="div"
            count={filteredTransactions.length}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            sx={{
                backgroundColor: colors.blueAccent[700] ,
                color: darkMode ? "#ffffff" : "#000000",
              }}
          />
        </TableContainer>
      )}
    </Box>
  );
};

export default Transactions;
