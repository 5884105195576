import {
  Avatar,
  Box,
  Button,
  Card,
  CardContent,
  CircularProgress,
  List,
  ListItem,
  ListItemText,
  MenuItem,
  Select,
  TextField,
  Typography,
  useTheme,
} from "@mui/material";
import React, { useRef, useState } from "react";
import { tokens } from "../../theme";
import {
  AddCircleOutline,
  CloseRounded,
  FormatAlignCenterRounded,
  FormatAlignLeftRounded,
  FormatAlignRightRounded,
  FormatBoldRounded,
  FormatItalicRounded,
  FormatUnderlined,
  Grid4x4Outlined,
} from "@mui/icons-material";

import NewText from "./newText";
import html2canvas from "html2canvas";
import { storage, writedb } from "../../firebase-config";
import domtoimage from "dom-to-image";
import { useEffect } from "react";
import * as htmlToImage from "html-to-image";
import fontFamilies from "../tools/fontFamilies";

const AddNewFrame = (props) => {
  const close = props.close;
  const path = props.path;
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);

  const [selectImage, setSeletImage] = useState(false);
  const fileInput = useRef(null);
  const [background, setBackground] = useState([]);
  const [imageList, setImageList] = useState([]);
  const [letsupload, setletsupload] = useState(false);
  const [mainBoxArray, setmainBoxArray] = useState([]); // This array is used to store movable images inside
  const [currenSelection, setCurrSelection] = useState({
    height: 50,
    width: 50,
    left: 1,
    top: 1,
  });
  const [height, setHeight] = useState(0);
  const [width, setWidth] = useState(0);
  const [top, setTop] = useState(0);
  const [left, setLeft] = useState(0);
  const [fontFamily, setFontFamily] = useState("Montserrat"); //This is the subpart of text data
  const [fontSize, setFontSize] = useState(16); // ||
  const [fontWeight, setFontWeight] = useState(300); // ||
  const [fontGravity, setFontGravity] = useState("start"); // ||
  const [fontItalic, setFontItalic] = useState(false); // ||
  const [fontUnderline, setFontUnderline] = useState(false); // ||
  const [fontColor, setFontColor] = useState("#ffffff"); // ||
  const [editTextIndex, setEditTextIndex] = useState(); // Index of array where this text is located
  const [addTextData, setAddTextData] = useState(""); // This is the text data we want to add in our main editing box
  const [isEditText, setIsEditText] = useState(false);
  const [showAddText, setShowAddText] = useState(false);
  const [textMainArray, setTextMainArray] = useState([]); // This array is used to store editable text in server...
  const bgInput = useRef(null);
  const [previewImage, setPreviewImage] = useState("");
  const [foodTypeSel, setFoodTypeSel] = useState(false);
  const [vegNonVeg, setVegNonVeg] = useState("");
  const [catList, setCatList] = useState([]);
  const [selectedCatList, setSelctedCatList] = useState([]);
  const [uploadImage, setUploadImage] = useState(false);
  const [searchLabels, setSearchLables] = useState(false);
  const [labelName, setLabelName] = useState("");
  const [searchAdding, setSearchAdding] = useState(false);
  const [imageKey, setImageKey] = useState("");
  const [showCategoryList, setShowCategoryList] = useState(false);
  const [generatingPreview, setGeneratingPreview] = useState(false);
  const [showGuide, setShowGuide] = useState(false);
  const [avatrHeight, setAvatarHeight] = useState(300);
  const [addressAdded, setAddressAdded] = useState(false);
  const [mobileAdded, setMobileAdded] = useState(false);
  const [nameAdded, setNameAdded] = useState(false);
  const [logoAdded, setLogoAdded] = useState(false);
  const [emailAdded, setEmailAdded] = useState(false);
  const [websiteAdded, setWebsiteAdded] = useState(false);
  const [cityAdded, setCityAdded] = useState(false);

  let mobileIndex;

  useEffect(() => {
    console.info(path);
    if (path === "images/framesVideo") {
      setAvatarHeight(300);
    }
  }, [path.length]);

  const generateImage = async (element) => {
    const dataUrl = await htmlToImage.toPng(element);
    setPreviewImage(dataUrl);
    uploadImageToServer(dataUrl);
    setletsupload(true);
  };

  const captureImage = () => {
    setGeneratingPreview(true);
    const element = document.getElementById("imageEditingBoxInAddCategory");
    generateImage(element);
  };

  const uploadImageToServer = async (image) => {
    try {
      setUploadImage(true);
      const dbref = writedb.ref(path);
      let storageRef = storage.ref(path);
      const dataUrl = image;
      const pushref = dbref.push();
      const uniqueKey = pushref.key;
      setImageKey(uniqueKey);
      storageRef = storageRef.child(uniqueKey);
      const task = storageRef.child("background").put(background);
      const blob = await fetch(dataUrl).then((res) => res.blob()); // This is for convert image generated from html to blob for upload in firebase
      task.then(() => {
        // complete function
        storageRef
          .child("background")
          .getDownloadURL()
          .then((url) => {
            const finalPath = dbref.child(uniqueKey);
            const addBg = finalPath.child("background");
            addBg.set({ image: url }).then(() => {
              try {
                storageRef
                  .child("cover")
                  .put(blob)
                  .then(() => {
                    storageRef
                      .child("cover")
                      .getDownloadURL()
                      .then((url) => {
                        const addCover = finalPath.child("cover");
                        addCover.set({ image: url }).then(async () => {
                          for (let i = 0; i < textMainArray.length; i++) {
                            const data = textMainArray[i];
                            let text = data.text;
                            if (text == "+916942069420") {
                              text = "Mobile";
                            } else if (
                              text ==
                              "23 Maple Street, Oakville Heights, Willow County,\nLakeview, NY 5431, United States America"
                            ) {
                              text = "Address";
                            }
                            const addText = finalPath.child(text);
                            await addText.set({
                              fontGravity: data.fontGravity,
                              text: text,
                              fontFamily: data.fontFamily.toString(),
                              fontWeight: parseInt(data.fontWeight),
                              fontItalic: data.fontItalic.toString(),
                              fontUnderline: data.fontUnderline.toString(),
                              fontSize: parseInt(data.fontSize),
                              fontColor: data.fontColor.toString(),
                              top: parseInt(data.top),
                              left: parseInt(data.left),
                            });
                          }
                          close();
                        });
                      });
                  });
              } catch (error) {
                console.error(error.toString());
              }
              // resetAll();

              // alert("All Graphics system uploaded")
            });
          });
      });
    } catch (error) {
      console.error(error);
    }
  };

  const inputStyle = {
    "& label.Mui-focused": {
      color: "aqua",
    },
    "& .MuiInput-underline:after": {
      borderBottomColor: "white",
    },
    "& .MuiOutlinedInput-root": {
      "& fieldset": {
        borderColor: "gray",
      },
      "&:hover fieldset": {
        borderColor: "white",
      },
      "&.Mui-focused fieldset": {
        borderColor: "rgb(155,155,255)",
      },
      "& .MuiInputBase-input": {
        color: "black",
        fontSize: "16px",
      },
    },
  };

  const handleFileSelect = (event) => {
    if (event.target.files[0]) {
      setSeletImage(true);

      const files = event.target.files;
      setImageList(Array.from(files));
      setBackground(Array.from(files)[0]);
    } else {
      alert("Image Not selected");
    }
  };

  const handleTextChangeForText = async (newText) => {
    await Promise.all([
      setCurrSelection(newText),
      // setHeight(newText.height),
      // setWidth(newText.width),
      // setTop(newText.top),
      // setLeft(newText.left)
    ]);

    setHeight(newText.height);
    setWidth(newText.width);
    setTop(newText.top);
    setLeft(newText.left);
  };

  const handleFontFamilyChange = (event) => {
    setFontFamily(event.target.value);
  };

  const editText = (data) => {
    setEditTextIndex(data.index);
    setCurrSelection(data);
    setAddTextData(data.text);
    setFontFamily(data.fontFamily);
    setFontSize(data.fontSize);
    setFontWeight(data.fontWeight);
    setFontItalic(data.fontItalic);
    setFontUnderline(data.fontUnderline);
    setFontColor(data.fontColor);
    setFontGravity(data.fontGravity);
    setIsEditText(true);
    setShowAddText(true);
  };

  const displayAddText = () => {
    if (showAddText) {
      setShowAddText(false);
    } else {
      setFontFamily("Montserrat, sans-serif");
      setFontSize(16);
      setFontWeight(300);
      setFontItalic(false);
      setFontUnderline(false);
      setFontColor("#ffffff");
      setShowAddText(true);
      setIsEditText(false);
    }
  };

  function resetText() {
    setAddTextData("");
    setFontFamily("Montserrat, sans-serif");
    setFontSize(16);
    setFontWeight(300);
    setFontItalic(false);
    setFontUnderline(false);
    setFontColor("#ffffff");
    setFontGravity("start");
  }

  const resetEditText = () => {
    resetText();
    setIsEditText(false);
  };
  const inputStyleForproperties = {
    width: "80%",
    marginBottom: "10px",
    color: "white",
    "& .MuiInputBase-input": { color: "white" },
    "& label": { color: "white" },
  };

  const bgSelect = (event) => {
    if (event.target.files[0]) {
      const files = event.target.files;
      setBackground(Array.from(files)[0]);
    } else {
      alert("Image Not selected");
    }
  };

  function deleteText() {
    const newItems = textMainArray.filter((item, i) => i !== editTextIndex);
    setTextMainArray(newItems);
    resetEditText();
  }

  function addTextToMain() {
    if (isEditText) {
      const data = textMainArray[editTextIndex];
      data.text = addTextData;
      data.fontFamily = fontFamily;
      data.fontSize = fontSize;
      data.fontWeight = fontWeight;
      data.fontItalic = fontItalic;
      data.fontUnderline = fontUnderline;
      data.fontColor = fontColor;
      data.fontGravity = fontGravity;
      setTimeout(() => {
        setShowAddText(true);
      }, 5);
      setShowAddText(false);
    } else {
      if (addTextData.trim().length > 0) {
        setTextMainArray([
          ...textMainArray,
          {
            fontGravity: fontGravity,
            text: addTextData,
            top: 150,
            left: 150,
            height: 50,
            width: 50,
            index: textMainArray.length,
            fontFamily: fontFamily,
            fontSize: fontSize,
            fontWeight: fontWeight,
            fontItalic: fontItalic,
            fontUnderline: fontUnderline,
            fontColor: fontColor,
          },
        ]);
        resetText();
      }

      if (addTextData === "Mobile") {
        setMobileAdded(true);
      } else if (addTextData === "Address") {
        setAddressAdded(true);
      } else if (addTextData === "Name") {
        setNameAdded(true);
      } else if (addTextData === "Logo") {
        setLogoAdded(true);
      } else if (addTextData === "Email") {
        setEmailAdded(true);
      } else if (addTextData === "Website") {
        setWebsiteAdded(true);
      } else if (addTextData === "City") {
        setCityAdded(true);
      }

      setShowAddText(false);
    }
  }

  // ********* made changes of image such as Height width top left using this functions (Starts)

  const topValue = (event) => {
    setTop(event.target.value);
    if (currenSelection.fontColor) {
      textMainArray[currenSelection.index].top = event.target.value;
    } else {
      mainBoxArray[currenSelection.index].top = event.target.value;
    }
  };

  const leftValue = (event) => {
    setLeft(event.target.value);
    if (currenSelection.fontColor) {
      textMainArray[currenSelection.index].left = event.target.value;
    } else {
      mainBoxArray[currenSelection.index].left = event.target.value;
    }
  };

  function customText(text) {
    function removeTheValue(data) {
      let arr = [];
      textMainArray.map((value) => {
        if (value.text != data) {
          arr.push(value);
        }
      });
      setTextMainArray(arr);
      setShowAddText(false);
    }

    if (text === "+916942069420" && mobileAdded) {
      removeTheValue(text);
      setMobileAdded(false);
    } else if (
      text ===
        "23 Maple Street, Oakville Heights, Willow County,\nLakeview, NY 5431, United States America" &&
      addressAdded
    ) {
      removeTheValue(text);
      setAddressAdded(false);
    } else if (text === "Name" && nameAdded) {
      removeTheValue(text);
      setNameAdded(false);
    } else if (text === "Logo" && logoAdded) {
      removeTheValue(text);
      setLogoAdded(false);
    } else if (text === "Email" && emailAdded) {
      removeTheValue(text);
      setEmailAdded(false);
    } else if (text === "Website" && websiteAdded) {
      removeTheValue(text);
      setWebsiteAdded(false);
    } else if (text === "City" && cityAdded) {
      removeTheValue(text);
      setCityAdded(false);
    } else {
      setAddTextData(text);
      displayAddText();
    }
  }

  return (
    <Box
      display="flex"
      width="100%"
      height="100%"
      justifyContent="center"
      mt="50px"
    >
      <Box
        display="flex"
        height={`${avatrHeight + 130}px`}
        width="700px"
        zIndex="20"
        backgroundColor="#727681"
        borderRadius="10px"
        justifyContent="center"
      >
        <Box
          display={selectImage ? "none" : "flex"}
          height={`${avatrHeight}px`}
          width="700px"
          alignItems="center"
          flexDirection="column"
        >
          <Typography
            fontSize="35px"
            fontWeight="700"
            marginTop="100px"
            marginBottom="20px"
          >
            Add Image
          </Typography>

          <input
            accept="image/*"
            type="file"
            onChange={handleFileSelect}
            multiple
            ref={fileInput}
            style={{ display: "none" }}
          />
          <Box display="flex" justifyContent="center" alignItems="center">
            <Button
              onClick={() => {
                fileInput.current.click();
              }}
              sx={{ backgroundColor: "blue", borderRadius: "20px" }}
            >
              <Typography
                fontSize="16px"
                textTransform="none"
                color="white"
                padding="5px"
                textAlign="center"
                justifyContent="center"
                display="flex"
                alignItems="center"
              >
                <AddCircleOutline sx={{ marginRight: "8px" }} />
                Select Images
              </Typography>
            </Button>
          </Box>
        </Box>

        {/* This box works after image selection complete starts */}

        <Box
          display={selectImage ? (letsupload ? "none" : "inline-flex") : "none"}
          flexDirection="column"
          alignItems="center"
        >
          <Box display={letsupload ? "none" : "inline-flex"} mt="20px">
            {/* When You add image inside 300 * 300 editor then this trigger  */}
            <Box
              display="flex"
              width="400px"
              height={`${avatrHeight}px`}
              backgroundColor={colors.primary[500]}
            >
              <Box width="390px" height={`${avatrHeight}px`}>
                {/* This is fixed background image of editor */}
                <Box
                  position="absolute"
                  width="400px"
                  height={`${avatrHeight}px`}
                  zIndex="0"
                >
                  <Box display="flex" position="absolute">
                    <Box
                      display="flex"
                      flexDirection="column"
                      position="absolute"
                      width="290px"
                      height={`${avatrHeight}px`}
                      bgcolor="gray"
                      zIndex="20"
                      overflow="hidden"
                    ></Box>
                    <Box
                      display="flex"
                      flexDirection="column"
                      position="absolute"
                      width="290px"
                      height={`${avatrHeight}px`}
                      color="white"
                      zIndex="20"
                      overflow="hidden"
                      id="imageEditingBoxInAddCategory"
                    >
                      <Avatar
                        id="backgroundImage"
                        src={
                          imageList.length > 0
                            ? URL.createObjectURL(background)
                            : ""
                        }
                        variant="square"
                        sx={{
                          width: "290px",
                          height: `${avatrHeight}px`,
                          position: "absolute",
                          "& > img": {
                            objectFit: "fill",
                            width: "100%",
                            height: "100%",
                          },
                        }}
                      />
                      {textMainArray.map((item, index) => (
                        <Box key={item.text + index}>
                          <NewText
                            key={item + index}
                            element={textMainArray}
                            onTextChange={handleTextChangeForText}
                            index={index}
                            editText={editText}
                          />
                        </Box>
                      ))}
                    </Box>
                    <Box display={showGuide ? "flex" : "none"}>
                      <Box
                        id="horizontalline1"
                        display="flex"
                        position="absolute"
                        top={`${avatrHeight / 2}px`}
                        width="290px"
                        height="1px"
                        zIndex="50"
                        bgcolor="red"
                      ></Box>
                      <Box
                        id="verticalline1"
                        display="flex"
                        position="absolute"
                        left="145px"
                        width="1px"
                        height="300px"
                        zIndex="50"
                        bgcolor="red"
                      ></Box>
                    </Box>
                  </Box>
                </Box>
                {/* Fixed background image of editor ends here */}
                <Box
                  display="flex"
                  flexDirection="column"
                  margin="20px 0"
                  marginLeft="300px"
                  justifyContent="center"
                  alignItems="center"
                >
                  <Box
                    display="flex"
                    flexDirection="column"
                    alignItems="center"
                  >
                    <TextField
                      autoComplete="off"
                      type="number"
                      variant="standard"
                      color="secondary"
                      label="Top"
                      value={top}
                      sx={inputStyleForproperties}
                      onChange={(e) => {
                        topValue(e);
                      }}
                    />
                    <TextField
                      autoComplete="off"
                      type="number"
                      variant="standard"
                      color="secondary"
                      label="Left"
                      value={left}
                      sx={inputStyleForproperties}
                      onChange={(e) => {
                        leftValue(e);
                      }}
                    />
                  </Box>
                </Box>
              </Box>
            </Box>
            {/* Add Image to Main Editing box Ends here   */}
          </Box>

          <Box
            display="inline-flex"
            borderRadius="10px"
            padding="5px"
            justifyContent="start"
            mt="10px"
          >
            <Box
              backgroundColor={colors.blueAccent[700]}
              marginRight="10px"
              display="flex"
              alignItems="center"
              padding="3px"
              borderRadius="5px"
            >
              <Button
                onClick={() => {
                  customText(
                    "23 Maple Street, Oakville Heights, Willow County,\nLakeview, NY 5431, United States America"
                  );
                }}
              >
                <Typography
                  color={addressAdded ? "orange" : "white"}
                  sx={{ userSelect: "none", cursor: "pointer" }}
                >
                  Address
                </Typography>
              </Button>
            </Box>
            <Box
              backgroundColor={colors.blueAccent[700]}
              marginRight="10px"
              display="flex"
              alignItems="center"
              padding="3px"
              borderRadius="5px"
            >
              <Button
                onClick={() => {
                  customText("+916942069420");
                }}
              >
                <Typography
                  color={mobileAdded ? "orange" : "white"}
                  sx={{ userSelect: "none", cursor: "pointer" }}
                >
                  Mobile
                </Typography>
              </Button>
            </Box>
            <Box
              backgroundColor={colors.blueAccent[700]}
              marginRight="10px"
              display="flex"
              alignItems="center"
              padding="3px"
              borderRadius="5px"
            >
              <Button
                onClick={() => {
                  customText("Name");
                }}
              >
                <Typography
                  color={nameAdded ? "orange" : "white"}
                  sx={{ userSelect: "none", cursor: "pointer" }}
                >
                  Name
                </Typography>
              </Button>
            </Box>
            <Box
              backgroundColor={colors.blueAccent[700]}
              marginRight="10px"
              display="flex"
              alignItems="center"
              padding="3px"
              borderRadius="5px"
            >
              <Button
                onClick={() => {
                  customText("Logo");
                }}
              >
                <Typography
                  color={logoAdded ? "orange" : "white"}
                  sx={{ userSelect: "none", cursor: "pointer" }}
                >
                  Logo
                </Typography>
              </Button>
            </Box>
            <Box
              backgroundColor={colors.blueAccent[700]}
              marginRight="10px"
              display="flex"
              alignItems="center"
              padding="3px"
              borderRadius="5px"
            >
              <Button
                onClick={() => {
                  customText("Email");
                }}
              >
                <Typography
                  color={emailAdded ? "orange" : "white"}
                  sx={{ userSelect: "none", cursor: "pointer" }}
                >
                  Email
                </Typography>
              </Button>
            </Box>
            <Box
              backgroundColor={colors.blueAccent[700]}
              marginRight="10px"
              display="flex"
              alignItems="center"
              padding="3px"
              borderRadius="5px"
            >
              <Button
                onClick={() => {
                  customText("Website");
                }}
              >
                <Typography
                  color={websiteAdded ? "orange" : "white"}
                  sx={{ userSelect: "none", cursor: "pointer" }}
                >
                  Website
                </Typography>
              </Button>
            </Box>
            <Box
              backgroundColor={colors.blueAccent[700]}
              marginRight="10px"
              display="flex"
              alignItems="center"
              padding="3px"
              borderRadius="5px"
            >
              <Button
                onClick={() => {
                  customText("City");
                }}
              >
                <Typography
                  color={cityAdded ? "orange" : "white"}
                  sx={{ userSelect: "none", cursor: "pointer" }}
                >
                  City
                </Typography>
              </Button>
            </Box>
          </Box>

          {/* For change background and Guidlines */}
          <Box
            display="flex"
            borderRadius="10px"
            padding="5px"
            justifyContent="center"
          >
            <input
              accept="image/*"
              type="file"
              onChange={bgSelect}
              ref={bgInput}
              style={{ display: "none" }}
            />
            <Box
              backgroundColor="yellow"
              marginRight="10px"
              display="flex"
              alignItems="center"
              padding="3px"
              borderRadius="5px"
            >
              <Typography
                color="black"
                sx={{ userSelect: "none", cursor: "pointer" }}
                onClick={() => {
                  bgInput.current.click();
                }}
              >
                Change Background
              </Typography>
            </Box>

            <Box
              backgroundColor="yellow"
              padding="2px"
              display="flex"
              justifyContent="center"
              alignItems="center"
              borderRadius="5px"
            >
              <Typography
                onClick={() => {
                  setShowGuide(!showGuide);
                }}
                color="black"
                sx={{ userSelect: "none", cursor: "pointer" }}
              >
                <Grid4x4Outlined sx={{ pt: "5px" }} />
              </Typography>
            </Box>

            <Box
              backgroundColor="yellow"
              padding="2px"
              display={generatingPreview ? "none" : "flex"}
              justifyContent="center"
              alignItems="center"
              borderRadius="5px"
              ml="10px"
            >
              <Button onClick={captureImage}>Upload Frame to server</Button>
            </Box>

            <Box
              backgroundColor="yellow"
              padding="2px"
              display={generatingPreview ? "flex" : "none"}
              justifyContent="center"
              alignItems="center"
              borderRadius="5px"
              ml="10px"
            >
              <CircularProgress
                style={{ height: "30px", width: "30px", padding: "5px" }}
              />
            </Box>
          </Box>
          {/* For change background and add Text */}
        </Box>

        {/* This box works after image selection complete ends*/}

        {/* #############   This is for add text inside ediitor #################### */}

        {/* <Box display="flex" width="250px" overflow="auto" height="100%" position="fixed" top="0" right={showAddText ? "0" : "-250px"} sx={{ background: colors.primary[400], transition: "0.3s all ease-in-out" }} justifyContent="start" flexDirection="column" padding="0px 20px" paddingTop="50px">
                <Box position="fixed" right="-10px" top="0" display={showAddText ? "flex" : "none"} zIndex="100">
                    <Button onClick={() => { showAddText ? setShowAddText(false) : setShowAddText(true) }} ><CloseRounded sx={{
                        color: colors.primary[100], '&:hover': {
                            color: 'blue'
                        }
                    }} /></Button>
                </Box>

                <Typography color={fontColor} sx={{ textAlign: fontGravity, fontFamily: fontFamily, marginBottom: "30px", fontSize: fontSize + "px", fontWeight: fontWeight, fontStyle: fontItalic ? "italic" : "", textDecoration: fontUnderline ? "underline" : "" }} >
                    {addTextData.split("\n").map((line, index) => {
                        return (
                            <React.Fragment key={index}>
                                {line}
                                <br />
                            </React.Fragment>
                        );
                    })}
                </Typography>

                <Select value={fontFamily} onChange={handleFontFamilyChange} >
                    {fontFamilies.map((option) => (
                        <MenuItem key={option.value} value={option.value}>
                            <Typography sx={{ fontFamily: option.value }}>{option.label}</Typography>

                        </MenuItem>
                    ))}
                </Select>

                <Box display="flex" marginTop="20px" border="2px solid black" width="100%">
                    <Box display="flex" justifyContent="center" alignItems="center" width="20%" height="35px" borderRight="2px solid black" onClick={() => { fontWeight == 700 ? setFontWeight(300) : setFontWeight(700) }} backgroundColor={fontWeight == 700 ? "white" : ""} color={fontWeight == 700 ? "black" : "white"} sx={{ cursor: "pointer" }} >
                        <FormatBoldRounded />
                    </Box>
                    <Box display="flex" justifyContent="center" alignItems="center" width="20%" height="35px" borderRight="2px solid black" onClick={() => { fontItalic ? setFontItalic(false) : setFontItalic(true) }} backgroundColor={fontItalic ? "white" : ""} color={fontItalic ? "black" : "white"} sx={{ cursor: "pointer" }} >
                        <FormatItalicRounded />
                    </Box>
                    <Box display="flex" justifyContent="center" alignItems="center" width="20%" height="35px" borderRight="2px solid black" onClick={() => { fontUnderline ? setFontUnderline(false) : setFontUnderline(true) }} backgroundColor={fontUnderline ? "white" : ""} color={fontUnderline ? "black" : "white"} sx={{ cursor: "pointer" }} >
                        <FormatUnderlined />
                    </Box>
                    <Box display="flex" width="40%" height="35px" alignItems="center">
                        <TextField placeholder="size" type="number" inputProps={{ max: 40, min: 5 }} value={fontSize} onChange={(event) => { setFontSize(event.target.value) }}
                            sx={{
                                '& label.Mui-focused': {
                                    color: 'aqua',
                                },
                                '& .MuiInput-underline:after': {
                                    borderBottomColor: 'white',
                                },
                                '& .MuiOutlinedInput-root': {
                                    '& fieldset': {
                                        border: "none",

                                    },
                                    '&:hover fieldset': {
                                        border: "none",

                                    },
                                    '&.Mui-focused fieldset': {
                                        border: "none",

                                    }, '& .MuiInputBase-input': {
                                        color: 'white',
                                        fontSize: "15px",
                                        border: "none"

                                    }
                                }
                            }} />
                    </Box>
                </Box>
                <Box display="inline-flex" marginTop="20px"  >
                    <Box display='inline-flex' border="2px solid black">
                        <Box display="flex" justifyContent="center" alignItems="center" width="40px" height="35px" borderRight="2px solid black" onClick={() => { setFontGravity("start") }} backgroundColor={fontGravity == "start" ? "white" : ""} color={fontGravity == "start" ? "black" : "white"} sx={{ cursor: "pointer" }} >
                            <FormatAlignLeftRounded />
                        </Box>
                        <Box display="flex" justifyContent="center" alignItems="center" width="40px" height="35px" borderRight="2px solid black" onClick={() => { setFontGravity("center") }} backgroundColor={fontGravity == "center" ? "white" : ""} color={fontGravity == "center" ? "black" : "white"} sx={{ cursor: "pointer" }} >
                            <FormatAlignCenterRounded />
                        </Box>
                        <Box display="flex" justifyContent="center" alignItems="center" width="40px" height="35px" onClick={() => { setFontGravity("end") }} backgroundColor={fontGravity == "end" ? "white" : ""} color={fontGravity == "end" ? "black" : "white"} sx={{ cursor: "pointer" }} >flexDirection
                            <FormatAlignRightRounded />
                        </Box>
                    </Box>
                </Box>
                <Box display="flex" marginTop="20px" width="100%" >
                    <TextField
                        label="color"
                        type="color"
                        value={fontColor}
                        onChange={(event) => { setFontColor(event.target.value) }}
                        InputLabelProps={{
                            shrink: true,
                        }}
                        sx={{ width: "50%", height: "50%" }}
                    />
                </Box>
                <Box display="flex" marginTop="20px">
                    <Box display="flex" backgroundColor="blue" padding="8px" borderRadius="18px" alignItems="center">
                        <Typography onClick={() => {
                            addTextToMain()
                        }} color="white" sx={{ userSelect: "none" }} fontSize="15px">{isEditText ? "Edit Text" : "Add Text"}</Typography>
                    </Box>
                </Box>
            </Box> */}

        <Box
          display="flex"
          width="250px"
          overflow="auto"
          height="100%"
          position="fixed"
          top="0"
          right={showAddText ? "0" : "-250px"}
          sx={{
            background: colors.primary[400],
            transition: "0.3s all ease-in-out",
          }}
          justifyContent="start"
          flexDirection="column"
          padding="0px 20px"
          paddingTop="100px"
          zIndex="105"
        >
          <Box
            position="fixed"
            right="-10px"
            top="0"
            display={showAddText ? "flex" : "none"}
            zIndex="100"
          >
            <Button
              onClick={() => {
                showAddText ? setShowAddText(false) : setShowAddText(true);
              }}
            >
              <CloseRounded
                sx={{
                  color: colors.primary[100],
                  "&:hover": {
                    color: "blue",
                  },
                }}
              />
            </Button>
          </Box>

          <Typography
            color={fontColor}
            sx={{
              textAlign: fontGravity,
              fontFamily: fontFamily,
              marginBottom: "30px",
              fontSize: fontSize + "px",
              fontWeight: fontWeight,
              fontStyle: fontItalic ? "italic" : "",
              textDecoration: fontUnderline ? "underline" : "",
            }}
          >
            {addTextData.split("\n").map((line, index) => {
              return (
                <React.Fragment key={index}>
                  {line}
                  <br />
                </React.Fragment>
              );
            })}
          </Typography>

          

          <Select value={fontFamily} onChange={handleFontFamilyChange}>
            {fontFamilies.map((option) => (
              <MenuItem key={option.value} value={option.value}>
                <Typography sx={{ fontFamily: option.value }}>
                  {option.label}
                </Typography>
              </MenuItem>
            ))}
          </Select>

          <Box
            display="flex"
            marginTop="20px"
            border="2px solid black"
            width="100%"
          >
            <Box
              display="flex"
              justifyContent="center"
              alignItems="center"
              width="20%"
              height="35px"
              borderRight="2px solid black"
              onClick={() => {
                fontWeight == 700 ? setFontWeight(300) : setFontWeight(700);
              }}
              backgroundColor={fontWeight == 700 ? "white" : ""}
              color={fontWeight == 700 ? "black" : "white"}
              sx={{ cursor: "pointer" }}
            >
              <FormatBoldRounded />
            </Box>
            <Box
              display="flex"
              justifyContent="center"
              alignItems="center"
              width="20%"
              height="35px"
              borderRight="2px solid black"
              onClick={() => {
                fontItalic ? setFontItalic(false) : setFontItalic(true);
              }}
              backgroundColor={fontItalic ? "white" : ""}
              color={fontItalic ? "black" : "white"}
              sx={{ cursor: "pointer" }}
            >
              <FormatItalicRounded />
            </Box>
            <Box
              display="flex"
              justifyContent="center"
              alignItems="center"
              width="20%"
              height="35px"
              borderRight="2px solid black"
              onClick={() => {
                fontUnderline
                  ? setFontUnderline(false)
                  : setFontUnderline(true);
              }}
              backgroundColor={fontUnderline ? "white" : ""}
              color={fontUnderline ? "black" : "white"}
              sx={{ cursor: "pointer" }}
            >
              <FormatUnderlined />
            </Box>
            <Box display="flex" width="40%" height="35px" alignItems="center">
              <TextField
                placeholder="size"
                type="number"
                inputProps={{ max: 40, min: 5 }}
                value={fontSize}
                onChange={(event) => {
                  setFontSize(event.target.value);
                }}
                sx={{
                  "& label.Mui-focused": {
                    color: "aqua",
                  },
                  "& .MuiInput-underline:after": {
                    borderBottomColor: "white",
                  },
                  "& .MuiOutlinedInput-root": {
                    "& fieldset": {
                      border: "none",
                    },
                    "&:hover fieldset": {
                      border: "none",
                    },
                    "&.Mui-focused fieldset": {
                      border: "none",
                    },
                    "& .MuiInputBase-input": {
                      color: "white",
                      fontSize: "15px",
                      border: "none",
                    },
                  },
                }}
              />
            </Box>
          </Box>

          <Box display="inline-flex" marginTop="20px">
            <Box display="inline-flex" border="2px solid black">
              <Box
                display="flex"
                justifyContent="center"
                alignItems="center"
                width="40px"
                height="35px"
                borderRight="2px solid black"
                onClick={() => {
                  setFontGravity("start");
                }}
                backgroundColor={fontGravity == "start" ? "white" : ""}
                color={fontGravity == "start" ? "black" : "white"}
                sx={{ cursor: "pointer" }}
              >
                <FormatAlignLeftRounded />
              </Box>

              <Box
                display="flex"
                justifyContent="center"
                alignItems="center"
                width="40px"
                height="35px"
                borderRight="2px solid black"
                onClick={() => {
                  setFontGravity("center");
                }}
                backgroundColor={fontGravity == "center" ? "white" : ""}
                color={fontGravity == "center" ? "black" : "white"}
                sx={{ cursor: "pointer" }}
              >
                <FormatAlignCenterRounded />
              </Box>

              <Box
                display="flex"
                justifyContent="center"
                alignItems="center"
                width="40px"
                height="35px"
                onClick={() => {
                  setFontGravity("end");
                }}
                backgroundColor={fontGravity == "end" ? "white" : ""}
                color={fontGravity == "end" ? "black" : "white"}
                sx={{ cursor: "pointer" }}
              >
                <FormatAlignRightRounded />
              </Box>
            </Box>
          </Box>

          <Box display="flex" marginTop="20px" width="100%">
            <TextField
              label="color"
              type="color"
              value={fontColor}
              onChange={(event) => { setFontColor(event.target.value) }}
              InputLabelProps={{
                shrink: true,
              }}
              sx={{ width: "50%", height: "50%" }}
            />
          </Box>

          <Box display="flex" marginTop="20px">
            <Box
              display="flex"
              backgroundColor="blue"
              padding="8px"
              borderRadius="18px"
              alignItems="center"
            >
              <Typography
                onClick={() => {
                  addTextToMain();
                }}
                color="white"
                sx={{ userSelect: "none" }}
                fontSize="15px"
              >
                {isEditText ? "Edit Text" : "Add Text"}
              </Typography>
            </Box>

            <Box
              display={isEditText ? "flex" : "none"}
              ml="10px"
              backgroundColor="blue"
              padding="8px"
              borderRadius="18px"
              alignItems="center"
            >
              <Typography
                onClick={() => {
                  deleteText();
                }}
                color="white"
                sx={{ userSelect: "none" }}
                fontSize="15px"
              >
                Delete
              </Typography>
            </Box>

            <Box display={isEditText ? "flex" : "none"} marginLeft="10px">
              <Box
                display="flex"
                backgroundColor="blue"
                padding="8px"
                borderRadius="18px"
                alignItems="center"
              >
                <Typography
                  onClick={() => {
                    resetEditText();
                  }}
                  color="white"
                  sx={{ userSelect: "none" }}
                  fontSize="15px"
                >
                  Reset
                </Typography>
              </Box>
            </Box>
          </Box>
        </Box>

        {/* #############   This is for add text inside ediitor ends #################### */}

        <Box
          display={uploadImage ? "flex" : "none"}
          justifyContent="center"
          flexDirection="column"
          alignItems="center"
        >
          <Typography>Frame Uploading</Typography>
          <CircularProgress sx={{ padding: "7px", mt: "10px" }} />
        </Box>
      </Box>
    </Box>
  );
};

export default AddNewFrame;
