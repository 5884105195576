import { Avatar, Box, Button, Grid, Input, Modal, Typography, useScrollTrigger, useTheme } from '@mui/material';
import React, { useEffect } from 'react'
import { tokens } from '../../theme';
import Header from '../../components/Header';
import { AddCircleOutline, Visibility } from '@mui/icons-material';
import { useState } from 'react';
import AddNewFrame from './AddFrame';
import { useMemo } from 'react';
import { database, storage, writedb } from '../../firebase-config';
import { onValue, ref } from 'firebase/database';
import MultipleImagesUpload from '../addMultipleImages';
import { useLocation } from 'react-router-dom';

const Frames = () => {
    const theme = useTheme();
    const colors = tokens(theme.palette.mode);
    const location = useLocation().pathname;
    const [showFrameSection, setShowFrameSection] = useState(true);
    const [frameList, setFrameList] = useState([]);
    const [staticFrameList, setStaticFrameList] = useState([]);
    const [framSnapshot, setFrameSnapshot] = useState({});
    const [staticFramSnapshot, setStaticFrameSnapshot] = useState({});
    const [selectedImageIndexNumber, setSelectedImageIndexNumber] = useState(0); // selected frame index number which we want to move;
    const [showMoveBox, setShowMoveBox] = useState(false); //this is used to get index where we want to move frame;
    const [dropLocationOfImage, setDropLocationOfImage] = useState("");
    const [showModal, setShowModal] = useState(false);
    const [showImageUpload, setShowImageUpload] = useState(false);
    const [showDynamicFrames,setShowDynamicFrames] = useState(true);
    const [frameType,setFrameType] = useState("");
    const [staticFrameType,setStaticFrameType] = useState("");
    const [swapDataPath,setSwapDataPath] = useState("");
    useEffect(() => {
        if (location == "/frame") {
          setFrameType("frames")
          setStaticFrameType("staticFrames")
          loadData("frames","staticFrames")
        } if (location == "/framevideo") {
            setFrameType("framesVideo")
            setStaticFrameType("staticFramesVideo")
            loadData("framesVideo","staticFramesVideo")
          }
      },[location.length])

    function loadData(path,path1){
        onValue(ref(database, `images/${path}`), (snapshot) => {
            if (snapshot.exists()) {
                setFrameSnapshot(snapshot.val());
                let keys = Object.keys(snapshot.val());
                let values = Object.values(snapshot.val());
                let arr = [];
                values.forEach((val, index) => {
                    let key = keys[index];

                    if (val.hasOwnProperty("cover")) {
                        let cover = val.cover;
                        if (cover.hasOwnProperty("image")) {

                            let image = val.cover.image;
                            let item = { key: key, image: image };
                            arr.push(item);
                        }

                    }
                })
                setFrameList(arr.reverse());
            }
        })

        onValue(ref(database, `images/${path1}`), (snapshot) => {
            if (snapshot.exists()) {
                setStaticFrameSnapshot(snapshot.val());
                let keys = Object.keys(snapshot.val());
                let values = Object.values(snapshot.val());
                let arr = [];
                values.forEach((val, index) => {
                    let key = keys[index];
                    if (val.hasOwnProperty("image")) {
                            let image = val.image;
                            let item = { key: key, image: image };
                            arr.push(item);
                    }
                })
                setStaticFrameList(arr.reverse());
            }
        })

    }

    function showFrames() {
        setShowFrameSection(true);
    }


    const deleteImageFromLink = async (link) => {
        await storage.refFromURL(link).delete();
    }

    const deleImage = async (data) => {
       
        if (framSnapshot.hasOwnProperty(data.key)) {
            await writedb.ref(`images/${frameType}`).child(data.key).once('value').then(async (data) => {
                let snap = data.val();

                try {
                    let image = snap.background.image;
                    await deleteImageFromLink(image);
                } catch (error) { }
                try {
                    let image = snap.cover.image;
                    await deleteImageFromLink(image);
                } catch (error) { console.info(error) }

                writedb.ref(`images/${frameType}/${data.key}`).remove();
            });

        }
    }

    const deleStaticFrameImage = async (data) => {
        
        if (staticFramSnapshot.hasOwnProperty(data.key)) {
            
            await writedb.ref(`images/${staticFrameType}`).child(data.key).once('value').then(async (data) => {
                let snap = data.val();

                try {
                    let image = snap.image;
                    await deleteImageFromLink(image);
                } catch (error) { console.info(error) }

                writedb.ref(`images/${staticFrameType}/${data.key}`).remove();
            });

        }
    }

    const setSelectedImageDataToBeMove = (index,path) => {
        setSelectedImageIndexNumber(index);
        setSwapDataPath(path);
        setShowMoveBox(true)
    }

    const handleCloseSubCatRename = () => {
        setShowModal(false)
    };


    const inputStyle = {
        '& label.Mui-focused': {
            color: 'aqua',
        },
        '& .MuiInput-underline:after': {
            borderBottomColor: 'white',
        },
        '& .MuiOutlinedInput-root': {
            '& fieldset': {
                borderColor: 'gray',
                height: '45px'
            },
            '&:hover fieldset': {
                borderColor: 'white',
            },
            '&.Mui-focused fieldset': {
                borderColor: 'rgb(155,155,255)',
            }, '& .MuiInputBase-input': {
                color: 'white',
                fontSize: "16px",
                height: "7px"

            },
        }
    }

    const swapData = async () => {
        if (dropLocationOfImage.length == 0 || dropLocationOfImage < 1) {
            alert("Invalid");
            return;
        }

        let arr;
        if (swapDataPath === "frames" || swapDataPath === "framesVideo"){
            arr = [...frameList]
        }else {
            arr = [...staticFrameList]
        }
        
        if (dropLocationOfImage > arr.length) {
            alert("Invalid");
            return;
        }

        let firstImagePath = `images/${swapDataPath}/${arr[selectedImageIndexNumber].key}`
        let secondImagePath = `images/${swapDataPath}/${arr[dropLocationOfImage - 1].key}`

        let firstImageData;
        let secondImageData;

        await writedb.ref(firstImagePath).once('value').then((snapshot) => {
            firstImageData = snapshot.val();
            writedb.ref(secondImagePath).once('value').then((snapshot) => {
                secondImageData = snapshot.val();
                writedb.ref(firstImagePath).set(secondImageData).then(() => {
                    writedb.ref(secondImagePath).set(firstImageData).then(() => {
                        setSelectedImageIndexNumber(dropLocationOfImage - 1);
                    })
                })
            })
        })
    }

    return (
        <Box display="flex" flexDirection="column" m="20px" marginBottom="20px" width="97%" height="95%">
            {/* HEADER */}
            <Box display="flex" justifyContent="space-between" alignItems="center">
                <Header title="Frames" subtitle="Welcome to Brand Bite" />
            </Box>

            <Box display="flex" borderRadius="10px" >
                <Box marginRight="10px" backgroundColor="blue" borderRadius="20px" paddingRight="5px">
                    <Button onClick={() => { setShowFrameSection(true) }}><Typography display="flex" textAlign="center" fontSize="18px" textTransform="none" color="white" alignItems="center"><Visibility sx={{ marginRight: "8px" }} />Show Frames</Typography></Button>
                </Box>
                <Box marginRight="10px" backgroundColor="blue" borderRadius="20px" paddingRight="5px">
                    <Button onClick={() => { setShowFrameSection(false) }}><Typography display="flex" textAlign="center" fontSize="18px" textTransform="none" color="white" alignItems="center"><AddCircleOutline sx={{ marginRight: "8px" }} />Add New Frame</Typography></Button>
                </Box>
                <Box marginRight="10px" backgroundColor="blue" borderRadius="20px" paddingRight="5px">
                    <Button onClick={() => { setShowImageUpload(!showImageUpload) }}><Typography display="flex" textAlign="center" fontSize="18px" textTransform="none" color="white" alignItems="center"><AddCircleOutline sx={{ marginRight: "8px" }} />Add Multiple Frames</Typography></Button>
                </Box>
            </Box>


            {showImageUpload ? <MultipleImagesUpload path={`images/${staticFrameType}`} boxDisplay={() => setShowImageUpload(!showImageUpload)} parent="images" /> : ""}


            {showFrameSection ? "" : <AddNewFrame close={showFrames} path={`images/${frameType}`} />}



            <Modal open={showMoveBox} onClose={handleCloseSubCatRename}>
                <Box
                    display="flex"
                    flexDirection="column"
                    justifyContent="center"
                    alignItems="center"
                    bgcolor="#727681"
                    p={3}
                >
                    <Box display="flex" flexDirection="column" height="45px" ml="10px">
                        <Input autoFocus autoComplete="off" type="number" label="Name" value={dropLocationOfImage} onChange={(event) => {
                            setDropLocationOfImage(parseInt(event.target.value))
                        }} sx={inputStyle} />
                    </Box>
                    <Box mt="10px">
                        <Button variant="contained" color="primary" sx={{ mr: "10px" }} onClick={swapData}>
                            Move
                        </Button>
                        <Button variant="contained" color="primary" onClick={() => { setShowMoveBox(!showMoveBox) }}>
                            Close
                        </Button>
                    </Box>
                </Box>
            </Modal>

            <Box display={showFrameSection  ? "flex" : "none"}  >
                <Box display="flex" borderRadius="10px" >
                    <Button onClick={() => { setShowDynamicFrames(true) }}><Typography textTransform="none" fontSize="20px" fontWeight="700" color={showDynamicFrames ? colors.greenAccent[500] : colors.grey[100]}>Dynamic</Typography> </Button>

                    <Button onClick={() => { setShowDynamicFrames(false) }}> <Typography textTransform="none" fontSize="20px" fontWeight="700" color={!showDynamicFrames ? colors.greenAccent[500] : colors.grey[100]}>Static</Typography> </Button>
                </Box>
            </Box>

            <Box display={showFrameSection && showDynamicFrames? "flex" : "none"} width="100%" flexWrap="wrap" mt="20px">
                <Grid container justify="center" spacing={1} wrap="wrap">
                    {frameList.map((val, index) => (
                        <Grid item key={index + "frame"} xs={12} sm={6} md={4} lg={3} xl={4}>
                            <Box
                                display="flex"
                                p="10px"
                                flexDirection="column"
                                key={val.key}
                                width="300px"
                                position="relative"
                                alignItems="center" // Align items centrally
                                margin="auto" // Allocate available margin
                            >
                                <Avatar
                                    src={val.image}
                                    variant="square"
                                    sx={{
                                        width: 300,
                                        height: 300,
                                        borderStartStartRadius: "10px",
                                        borderStartEndRadius: "10px",
                                        cursor: "pointer",
                                        '& > img': {
                                            objectFit: 'fill',
                                            width: '100%',
                                            height: '100%',
                                        },
                                        ":hover": { bgcolor: "white" },
                                    }}
                                ></Avatar>

                                <Typography
                                    display="flex"
                                    color="white"
                                    ml="280px"
                                    position="absolute"
                                    mt="270px"
                                >
                                    {index + 1}
                                </Typography>

                                <Box
                                    display="flex"
                                    bgcolor="white"
                                    sx={{ borderEndEndRadius: "10px", borderEndStartRadius: "10px" }}
                                    width="300px"
                                    justifyContent="center"
                                >
                                    <Button onClick={() => { deleImage(val) }}>Delete</Button>
                                    <Button onClick={() => { setSelectedImageDataToBeMove(index,frameType) }}>Move</Button>
                                </Box>
                            </Box>
                        </Grid>
                    ))}
                </Grid>
            </Box>


            <Box display={showFrameSection && !showDynamicFrames? "flex" : "none"} width="100%" flexWrap="wrap" mt="20px">
                <Grid container justify="center" spacing={1} wrap="wrap">
                    {staticFrameList.map((val, index) => (
                        <Grid item key={index + "frame"} xs={12} sm={6} md={4} lg={3} xl={4}>
                            <Box
                                display="flex"
                                p="10px"
                                flexDirection="column"
                                key={val.key}
                                width="300px"
                                position="relative"
                                alignItems="center" // Align items centrally
                                margin="auto" // Allocate available margin
                            >
                                <Avatar
                                    src={val.image}
                                    variant="square"
                                    sx={{
                                        width: 300,
                                        height: 300,
                                        borderStartStartRadius: "10px",
                                        borderStartEndRadius: "10px",
                                        cursor: "pointer",
                                        '& > img': {
                                            objectFit: 'fill',
                                            width: '100%',
                                            height: '100%',
                                        },
                                        ":hover": { bgcolor: "white" },
                                    }}
                                ></Avatar>

                                <Typography
                                    display="flex"
                                    color="white"
                                    ml="280px"
                                    position="absolute"
                                    mt="270px"
                                >
                                    {index + 1}
                                </Typography>

                                <Box
                                    display="flex"
                                    bgcolor="white"
                                    sx={{ borderEndEndRadius: "10px", borderEndStartRadius: "10px" }}
                                    width="300px"
                                    justifyContent="center"
                                >
                                    <Button onClick={() => { deleStaticFrameImage(val) }}>Delete</Button>
                                    <Button onClick={() => { setSelectedImageDataToBeMove(index,staticFrameType) }}>Move</Button>
                                </Box>
                            </Box>
                        </Grid>
                    ))}
                </Grid>
            </Box>



        </Box>
    )
}

export default Frames
