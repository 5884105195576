import {
  Avatar,
  Box,
  Button,
  Grid,
  Input,
  Modal,
  TextField,
  Tooltip,
  Typography,
  useTheme,
} from "@mui/material";
import { useLocation, useParams } from "react-router-dom";
import Header from "../../components/Header";
import { tokens } from "../../theme";
import { useEffect, useState } from "react";
import { useMemo } from "react";
import { onValue, ref } from "firebase/database";
import { database, storage, writedb } from "../../firebase-config";
import {
  AddCircleOutlineRounded,
  DeleteRounded,
  AbcRounded,
  CloseRounded,
  AddCircleOutline,
  Edit,
} from "@mui/icons-material";
import React from "react";
import AddNewImage from "../manageOutlet/addNewImage";
import AddMultipleImagesCombos from "../manageOutlet/addMultipleImages";
import AddMultipleImages from "./addMultipleImages";
import EditImageFromServer from "../editServerImage";

const ManageCategory = () => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);

  const location = useLocation().pathname;
  const [catCus, setCatCus] = useState("");
  const [parent, setParent] = useState("");
  useEffect(() => {
    if (
      location.includes("home/edit/category") ||
      location.includes("filter/category")
    ) {
      setCatCus("category");
      loadData("category");
      setParent("home");
    } else if (location.includes("home/edit/custom")) {
      setCatCus("custom");
      loadData("custom");
      setParent("home");
    } else if (location.includes("combos/edit/category")) {
      setCatCus("categoryCombos");
      loadData("combos");
      setParent("combos");
    }
  }, [catCus.length]);

  const categoryKey = useParams().name;

  const [name, setName] = useState(); // this is the name of the category

  const [vegNonVeg, setVegNonVeg] = useState("veg");
  const [myKey, setMyKey] = useState();
  const [showaddImage, setShowAddimage] = useState(false);
  const [renameName, setRenameName] = useState("");
  const [showRename, setShowRename] = useState(false);
  const [catSnapshot, setCatSnapshot] = useState();
  const [vegCategory, setVegCategory] = useState([]);
  const [nonVegCategory, setNonVegCategory] = useState([]);
  const [renameRunning, setRenameRunning] = useState(false);
  const [showSubCatRename, setShowSubCatRename] = useState(false);
  const [subCatName, setSubCatName] = useState("");
  const [selectedSubCat, setSelectedSubCat] = useState();
  const [categoryFound, setCategoryFound] = useState(true);
  const [catDeleting, setCatDeleting] = useState(false);
  const [showAddMultipleImages, setShowAddMultipleImages] = useState(false);
  const [selectedImageIndexNumber, setSelectedImageIndexNumber] = useState(0); // selected image index number which we want to move;
  const [showMoveBox, setShowMoveBox] = useState(false); //this is used to get index where we want to move image;
  const [dropLocationOfImage, setDropLocationOfImage] = useState("");
  const [imageSnapshot, setImageSnapshot] = useState("");
  const [mainPath, setMainPath] = useState("");
  let start = true;
  const [showEditImage,setShowEditImage] = useState(false);
  const [catPath,setCatPath] = useState("");
  const [imagePath,setImagePath] = useState("");
  // const addImageLink =  (array) => {
  //   // array.forEach(async (data,index) => {
  //   //    await writedb.ref(data.tempPath).update({thumbnailImage:data.image});
  //   //    console.info(index)
  //   // })
  // }

  function loadData(address) {
    // onValue(ref(database, `images/${address}`), (snapshotAllImages) => {
    //   setImageSnapshot(snapshotAllImages);

    // });
    let path = `home/${address}/`;
    if (address == "combos") {
      path = "combos/categoryCombos/";
    }
    onValue(ref(database, path + categoryKey), (snapshot) => {
      if (snapshot.exists() && !catDeleting) {
        let catName = Object.keys(snapshot.val())[0];
        setMyKey(categoryKey);
        setMyKey(categoryKey);
        setName(catName);
        setRenameName(catName);
        let basePath = path + categoryKey + "/" + catName;
        setMainPath(basePath);
        onValue(ref(database, basePath), async (snapshot) => {
          if (!catDeleting) {
            setCatSnapshot(snapshot);
            vegCategory.size = 0;
            var vegImages = [];
            var nonVegImages = [];
            var v3 = snapshot.val();
            if (v3 != null) {
              var keyinv3 = Object.keys(v3);
              keyinv3.forEach((key) => {
                if (key === "veg") {
                  var veg = Object.values(v3.veg);
                  var keysVeg = Object.keys(v3.veg);
                  veg.forEach((val, index) => {
                    let imageKeyInCategory = keysVeg[index];
                    if (val.hasOwnProperty("image")) {
                      let len = address == "combos" ? 14 : address.length;
                      let key = val.image.slice(8 + len);
                      if (val.hasOwnProperty("thumbnailImage")) {
                        var newItem = {
                          image: val.thumbnailImage,
                          key: key,
                          imageCatKey: imageKeyInCategory,
                        };
                        vegImages.push(newItem);
                      } else {
                        writedb
                          .ref(`images/${address}/${key}`)
                          .once("value")
                          .then(async (snapshot) => {
                            if (snapshot.exists()) {
                              let imageObj = snapshot.val();
                              if (
                                imageObj.hasOwnProperty("cover") &&
                                imageObj != null
                              ) {
                                let path = `${basePath}/veg/${imageKeyInCategory}`;
                                let image = imageObj.cover.image;

                                var newItem = {
                                  image: image,
                                  key: key,
                                  imageCatKey: imageKeyInCategory,
                                };
                                vegImages.push(newItem);
                              }
                            }
                          });
                      }

                      // if (snapshotAllImages.child(key).exists()) {
                      //   let imageObj = snapshotAllImages.child(key).val();
                      //   if (imageObj.hasOwnProperty("cover") && imageObj != null) {
                      //     let image = imageObj.cover.image;
                      //     var newItem = { image: image, key: key, imageCatKey: imageKeyInCategory }
                      //     vegImages.push(newItem);
                      //   }
                      // } important code
                    }
                  });
                } else if (key === "nonVeg") {
                  var nonVeg = Object.values(v3.nonVeg);
                  var keysNonVeg = Object.keys(v3.nonVeg);

                  nonVeg.forEach((val, index) => {
                    let imageKeyInCategory = keysNonVeg[index];
                    if (val.hasOwnProperty("image")) {
                      let len = address == "combos" ? 14 : address.length;
                      let key = val.image.slice(8 + len);

                      if (val.hasOwnProperty("thumbnailImage")) {
                        var newItem = {
                          image: val.thumbnailImage,
                          key: key,
                          imageCatKey: imageKeyInCategory,
                        };
                        nonVegImages.push(newItem);
                      } else {
                        writedb
                          .ref(`images/${address}/${key}`)
                          .once("value")
                          .then((snapshot) => {
                            if (snapshot.exists()) {
                              let imageObj = snapshot.val();
                              if (
                                imageObj.hasOwnProperty("cover") &&
                                imageObj != null
                              ) {
                                // let path = `${basePath}/nonVeg/${imageKeyInCategory}`
                                let image = imageObj.cover.image;
                                var newItem = {
                                  image: image,
                                  key: key,
                                  imageCatKey: imageKeyInCategory,
                                };
                                nonVegImages.push(newItem);
                              }
                            }
                          });
                      }
                    }

                    // let imageObj = snapshotAllImages.child(key).val();
                    // if (imageObj.hasOwnProperty("cover") && imageObj != null) {
                    //   let image = imageObj.cover.image;
                    //   var newItem = { image: image, key: key, imageCatKey: imageKeyInCategory }
                    //   nonVegImages.push(newItem);
                    // } important code
                  });
                }
              });
            }
            setVegCategory(vegImages.reverse());
            setNonVegCategory(nonVegImages.reverse());
          }
        });
      } else {
        setCategoryFound(false);
      }
    });
  }

  const catDisplay = () => {
    setShowAddimage(!showaddImage);
  };

  const multipleImagesDisplay = () => {
    setShowAddMultipleImages(!showAddMultipleImages);
  };

  const inputStyle = {
    "& label.Mui-focused": {
      color: "aqua",
    },
    "& .MuiInput-underline:after": {
      borderBottomColor: "white",
    },
    "& .MuiOutlinedInput-root": {
      "& fieldset": {
        borderColor: "gray",
        height: "45px",
      },
      "&:hover fieldset": {
        borderColor: "white",
      },
      "&.Mui-focused fieldset": {
        borderColor: "rgb(155,155,255)",
      },
      "& .MuiInputBase-input": {
        color: "white",
        fontSize: "16px",
        height: "7px",
      },
    },
  };

  const renameCat = async () => {
    if (!renameName.length > 0) {
      alert("Invalid Input");
      return;
    }

    if (renameName == name) {
      setShowRename(!showRename);
      return;
    }
    let parent = "home";
    if (catCus == "categoryCombos") {
      parent = "combos";
    }
    if (!renameRunning) {
      let snapshot = await writedb
        .ref(`${parent}Uniquekeys/${catCus}/` + renameName)
        .once("value");
      if (snapshot.exists()) {
        alert("Category already exist");
      } else {
        setRenameRunning(true);
        let oldName = JSON.parse(JSON.stringify(name));
        let keyOfCat = JSON.parse(JSON.stringify(myKey));
        let snapshot1 = JSON.parse(JSON.stringify(catSnapshot));
        writedb
          .ref(`${parent}Uniquekeys/${catCus}/` + oldName + "/key")
          .remove()
          .then(() => {
            writedb
              .ref(`${parent}Uniquekeys/${catCus}/${renameName}`)
              .set({ key: keyOfCat })
              .then(() => {
                writedb
                  .ref(`${parent}/${catCus}/${keyOfCat}/${name}`)
                  .remove()
                  .then(() => {
                    writedb
                      .ref(`${parent}/${catCus}/${keyOfCat}/${renameName}`)
                      .set(snapshot1)
                      .then(() => {
                        setShowRename(!showRename);

                        setRenameRunning(false);
                        setTimeout(() => {
                          loadData("combos");
                          setCategoryFound(true);
                        }, 100);
                        alert(`${catCus} renamed`);
                      });
                  });
              });
          });
      }
    }
  };

  const handleCloseSubCatRename = () => {
    setShowSubCatRename(false);
  };

  const renameSubCat = async () => {
    if (subCatName.length == 0) {
      alert("please enter category name");
      return;
    }
    let oldName = JSON.parse(JSON.stringify(selectedSubCat.name));
    if (oldName == subCatName) {
      setShowSubCatRename(false);
      return;
    }

    let checkAlreadyExist = await writedb
      .ref(`${selectedSubCat.path}/${subCatName}`)
      .once("value");
    if (checkAlreadyExist.exists()) {
      alert("sub category Already exist");
    } else {
      let oldpath = `${selectedSubCat.path}/${oldName}`;
      let getsnapshot = await writedb.ref(oldpath).once("value");
      let snapshot = JSON.parse(JSON.stringify(getsnapshot));

      writedb
        .ref(`${selectedSubCat.path}/${subCatName}`)
        .set(snapshot)
        .then(() => {
          writedb
            .ref(oldpath)
            .remove()
            .then(() => {
              handleCloseSubCatRename();
              alert("Name changed");
            });
        });
    }
  };

  const deleteImageFromLink = async (link) => {
    await storage.refFromURL(link).delete();
  };

  const deleteMainCat = async () => {
    const confirmed = window.confirm(
      `Are you sure you want to delete this ${catCus}?`
    );
    if (confirmed) {
      setCatDeleting(true);

      vegCategory.forEach((val) => {
        deleteImageFromCat(val);
      });

      nonVegCategory.forEach((val) => {
        deleteImageFromCat(val);
      });
      let parent = "home";
      if (catCus == "categoryCombos") {
        parent = "combos";
      }
      storage
        .ref(`${parent}/${catCus}/` + categoryKey + "/" + "coverPhoto")
        .delete();

      writedb
        .ref(`${parent}Uniquekeys/${catCus}/${name}`)
        .remove()
        .then(() => {
          writedb.ref(`${parent}/${catCus}/` + categoryKey).remove(() => {
            setCategoryFound(false);
            setCatDeleting(false);
            alert("Category deleted");
          });
        });
    }
  };

  const deleteImageFromCat = async (data) => {
    writedb
      .ref(`images/${catCus}/${data.key}`)
      .once("value")
      .then(async (snapshot) => {
        if (snapshot.exists()) {
          let paths = snapshot.val().paths;
          let labelObj = snapshot.val();
          let labels = [];
          if (labelObj.hasOwnProperty("labels")) {
            let labelObj = snapshot.val().labels;
            labels = Object.keys(labelObj);
          }

          let pathSize = Object.keys(paths);
          let key = JSON.parse(JSON.stringify(data.imageCatKey));
          let imageKey = JSON.parse(JSON.stringify(data.key));

          if (pathSize.length > 1) {
            writedb
              .ref(`${mainPath}/${vegNonVeg}/${key}`)
              .remove()
              .then(() => {
                writedb
                  .ref(`images/${catCus}/${imageKey}/paths/${categoryKey}`)
                  .remove();
              });
          } else {
            let check = await writedb
              .ref(`images/${catCus}/${data.key}`)
              .once("value");

            if (check.exists()) {
              let snapVal = check.val();
              let data = Object.values(snapVal);
              // console.info(data);
              data.forEach(async (val) => {
                try {
                  if (val.hasOwnProperty("image")) {
                    let image = val.image;
                    // console.info(image)
                    await deleteImageFromLink(image);
                  }
                } catch (error) {}
              });
              labels.forEach(async (txt) => {
                await writedb.ref(`search/${txt}/${imageKey}`).remove();
              });
              writedb
                .ref(`images/${catCus}/${imageKey}`)
                .remove()
                .then(() => {
                  writedb.ref(`${mainPath}/${vegNonVeg}/${key}`).remove();
                });
            }
          }
        }
      });
  };

  const setSelectedImageDataToBeMove = (index) => {
    setSelectedImageIndexNumber(index);
    setShowMoveBox(true);
  };

  const swapData = async () => {
    if (dropLocationOfImage.length == 0 || dropLocationOfImage < 1) {
      alert("Invalid");
      return;
    }

    let arr = null;
    if (vegNonVeg === "veg") {
      arr = [...vegCategory];
    } else {
      arr = [...nonVegCategory];
    }

    if (dropLocationOfImage > arr.length) {
      alert("Invalid");
      return;
    }

    let firstImagePath = `${mainPath}/${vegNonVeg}/${arr[selectedImageIndexNumber].imageCatKey}`;
    let secondImagePath = `${mainPath}/${vegNonVeg}/${
      arr[dropLocationOfImage - 1].imageCatKey
    }`;

    let firstImageData;
    let secondImageData;

    await writedb
      .ref(firstImagePath)
      .once("value")
      .then((snapshot) => {
        firstImageData = snapshot.val();
        writedb
          .ref(secondImagePath)
          .once("value")
          .then((snapshot) => {
            secondImageData = snapshot.val();
            writedb
              .ref(firstImagePath)
              .set(secondImageData)
              .then(() => {
                writedb
                  .ref(secondImagePath)
                  .set(firstImageData)
                  .then(() => {
                    setSelectedImageIndexNumber(dropLocationOfImage - 1);
                  });
              });
          });
      });
  };

  function handleEditImage(index, type) {
    let arr = vegCategory;
    if (vegNonVeg != "veg") {
      arr = nonVegCategory;
    }
    let item = arr[index];
    setImagePath(`images/${catCus}/${item.key}`);
    setCatPath(`${parent}/${catCus}/${myKey}/${name}/${vegNonVeg}/${item.imageCatKey}`);
    
    setShowEditImage(true)
  }

  return (
    <Box>
      <Box
        display={categoryFound ? "flex" : "none"}
        flexDirection="column"
        m="20px"
        marginBottom="20px"
      >
        {/* HEADER */}
        <Box display="flex" justifyContent="space-between" alignItems="center">
          <Header title={name} subtitle="Brand Bite" />
        </Box>

        {/* Category Options starts here */}
        <Box display="flex" flexDirection="column">
          <Box display="flex">
            <Box
              display="inline-flex"
              bgcolor="blue"
              borderRadius="20px"
              p="5px 10px"
              className="thisisbtn"
              onClick={catDisplay}
            >
              <Typography
                display="flex"
                justifyContent="center"
                alignItems="center"
                color="white"
              >
                <AddCircleOutlineRounded sx={{ mr: "5px" }} /> Add New Image
              </Typography>
            </Box>
            <Box
              ml="10px"
              display="inline-flex"
              bgcolor="blue"
              borderRadius="20px"
              p="5px 10px"
              className="thisisbtn"
              onClick={() => setShowAddMultipleImages(!showAddMultipleImages)}
            >
              <Typography
                display="flex"
                justifyContent="center"
                alignItems="center"
                color="white"
              >
                <AddCircleOutlineRounded sx={{ mr: "5px" }} />
                Add Multiple Images
              </Typography>
            </Box>
            <Box
              ml="10px"
              display={showRename ? "none" : "flex"}
              bgcolor="blue"
              borderRadius="20px"
              p="5px 10px"
              className="thisisbtn"
              onClick={() => {
                setShowRename(!showRename);
              }}
            >
              <Typography
                display="flex"
                justifyContent="center"
                alignItems="center"
                color="white"
              >
                Rename
              </Typography>
            </Box>

            <Box
              display={showRename ? "flex" : "none"}
              flexDirection="column"
              height="40px"
              ml="10px"
            >
              <TextField
                autoFocus
                autoComplete="off"
                label="Name"
                value={renameName}
                onChange={(event) => {
                  setRenameName(
                    event.target.value.charAt(0).toUpperCase() +
                      event.target.value.slice(1)
                  );
                }}
                sx={inputStyle}
              />
              <Box display="flex" justifyContent="end" mt="5px" zIndex="1">
                <Box
                  bgcolor="blue"
                  borderRadius="10px"
                  p="2px 5px"
                  className="thisisbtn"
                  onClick={renameCat}
                >
                  <Typography color="white">Rename</Typography>
                </Box>
                <Box
                  bgcolor="blue"
                  borderRadius="10px"
                  p="2px 5px"
                  ml="10px"
                  className="thisisbtn"
                  onClick={() => {
                    setShowRename(!showRename);
                  }}
                >
                  <Typography color="white">Cancel</Typography>
                </Box>
              </Box>
            </Box>

            <Box
              ml="10px"
              display="flex"
              bgcolor="blue"
              borderRadius="20px"
              p="5px 10px"
              className="thisisbtn"
              onClick={deleteMainCat}
            >
              <Typography
                display="flex"
                justifyContent="center"
                alignItems="center"
                color="white"
              >
                Delete
              </Typography>
            </Box>
          </Box>
          <Box display="flex" borderRadius="10px" mb="-15px" mt="5px">
            <Button
              onClick={() => {
                setVegNonVeg("veg");
              }}
            >
              <Typography
                textTransform="none"
                fontSize="20px"
                fontWeight="700"
                color={
                  vegNonVeg === "veg"
                    ? colors.greenAccent[500]
                    : colors.grey[100]
                }
              >
                Veg
              </Typography>{" "}
            </Button>

            <Button
              onClick={() => {
                setVegNonVeg("nonVeg");
              }}
            >
              {" "}
              <Typography
                textTransform="none"
                fontSize="20px"
                fontWeight="700"
                color={
                  vegNonVeg === "nonVeg"
                    ? colors.greenAccent[500]
                    : colors.grey[100]
                }
              >
                Non-veg
              </Typography>{" "}
            </Button>
          </Box>
        </Box>
        {/* Category Options ends here */}

        <Modal open={showSubCatRename} onClose={handleCloseSubCatRename}>
          <Box
            display="flex"
            flexDirection="column"
            justifyContent="center"
            alignItems="center"
            bgcolor="#727681"
            s
            p={3}
          >
            <Box display="flex" flexDirection="column" height="40px" ml="10px">
              <TextField
                autoFocus
                autoComplete="off"
                label="Name"
                value={subCatName}
                onChange={(event) => {
                  setSubCatName(
                    event.target.value.charAt(0).toUpperCase() +
                      event.target.value.slice(1)
                  );
                }}
                sx={inputStyle}
              />
            </Box>
            <Box mt="10px">
              <Button
                variant="contained"
                color="primary"
                sx={{ mr: "10px" }}
                onClick={renameSubCat}
              >
                Rename
              </Button>
              <Button
                variant="contained"
                color="primary"
                onClick={handleCloseSubCatRename}
              >
                Close
              </Button>
            </Box>
          </Box>
        </Modal>

        <Modal open={showMoveBox} onClose={handleCloseSubCatRename}>
          <Box
            display="flex"
            flexDirection="column"
            justifyContent="center"
            alignItems="center"
            bgcolor="#727681"
            p={3}
          >
            <Box display="flex" flexDirection="column" height="45px" ml="10px">
              <Input
                autoFocus
                autoComplete="off"
                type="number"
                label="Name"
                value={dropLocationOfImage}
                onChange={(event) => {
                  setDropLocationOfImage(parseInt(event.target.value));
                }}
                sx={inputStyle}
              />
            </Box>
            <Box mt="10px">
              <Button
                variant="contained"
                color="primary"
                sx={{ mr: "10px" }}
                onClick={swapData}
              >
                Move
              </Button>
              <Button
                variant="contained"
                color="primary"
                onClick={() => {
                  setShowMoveBox(!showMoveBox);
                }}
              >
                Close
              </Button>
            </Box>
          </Box>
        </Modal>

        {showaddImage ? (
          <AddNewImage
            method="withcat"
            thisisEdit={true}
            catDis={catDisplay}
            myName={name}
            myKey={myKey}
            address={catCus}
          />
        ) : (
          ""
        )}
        {showEditImage ? (
          <EditImageFromServer
            catDis={()=>{setShowEditImage(!showEditImage)}}
            catPath={catPath}
            imagePath={imagePath}
            myName={name}
          />
        ) : (
          ""
        )}
        {showAddMultipleImages ? (
          <AddMultipleImages
            parent={parent}
            catDis={multipleImagesDisplay}
            myName={name}
            myKey={myKey}
            address={catCus}
          />
        ) : (
          ""
        )}

        <Box
          display={vegNonVeg == "veg" ? "flex" : "none"}
          width="100%"
          flexWrap="wrap"
          mt="20px"
        >
          <Grid container justify="center" spacing={1} wrap="wrap">
            {vegCategory.map((val, index) => (
              <Grid
                item
                key={index + "veg"}
                xs={12}
                sm={6}
                md={4}
                lg={3}
                xl={2.4}
              >
                <Box
                  display="flex"
                  p="10px"
                  flexDirection="column"
                  key={val.key}
                  width={200}
                  position="relative"
                >
                  <Box
                    position="absolute"
                    zIndex="10"
                    right="0"
                    mr="3px"
                    mt="8px"
                    onClick={() => {
                      handleEditImage(index, "veg");
                    }}
                  >
                    <div
                      style={{
                        width: "30px", // Adjust the size of the circle as needed
                        height: "30px", // Adjust the size of the circle as needed
                        borderRadius: "50%",
                        backgroundColor: "rgba(255, 255, 255, 0.5)", // White color with 80% opacity
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        color: "black",
                        cursor: "pointer", // Add pointer cursor to indicate it's clickable
                      }}
                    >
                      <Edit />
                    </div>
                  </Box>

                  <Avatar
                    src={val.image}
                    variant="square"
                    sx={{
                      width: 200,
                      height: 200,
                      borderStartStartRadius: "10px",
                      borderStartEndRadius: "10px",
                      cursor: "pointer",
                      "& > img": {
                        objectFit: "fill",
                        width: "100%",
                        height: "100%",
                      },
                      ":hover": { bgcolor: "white" },
                    }}
                  ></Avatar>

                  <Typography
                    display="flex"
                    color="white"
                    ml="180px"
                    position="absolute"
                    mt="170px"
                  >
                    {index + 1}
                  </Typography>

                  <Box
                    display="flex"
                    bgcolor="white"
                    sx={{
                      borderEndEndRadius: "10px",
                      borderEndStartRadius: "10px",
                    }}
                    width="200px"
                    justifyContent="center"
                  >
                    <Button
                      onClick={() => {
                        deleteImageFromCat(val);
                      }}
                    >
                      Delete
                    </Button>
                    <Button
                      onClick={() => {
                        setSelectedImageDataToBeMove(index);
                      }}
                    >
                      Move
                    </Button>
                  </Box>
                </Box>
              </Grid>
            ))}
          </Grid>
        </Box>

        <Box
          display={vegNonVeg == "nonVeg" ? "flex" : "none"}
          width="100%"
          flexWrap={true}
          flexDirection="column"
          mt="20px"
        >
          <Grid container justify="center" spacing={1} wrap="wrap">
            {nonVegCategory.map((val, index) => (
              <Grid
                item
                key={index + "nonVeg"}
                xs={12}
                sm={6}
                md={4}
                lg={3}
                xl={2.4}
              >
                <Box
                  display="flex"
                  p="10px"
                  flexDirection="column"
                  key={val.key}
                  width={200}
                  position="relative"
                >
                  <Box
                    position="absolute"
                    zIndex="10"
                    right="0"
                    mr="3px"
                    mt="8px"
                    onClick={() => {
                      handleEditImage(index, "nonVeg");
                    }}
                  >
                    <div
                      style={{
                        width: "30px", // Adjust the size of the circle as needed
                        height: "30px", // Adjust the size of the circle as needed
                        borderRadius: "50%",
                        backgroundColor: "rgba(255, 255, 255, 0.5)", // White color with 80% opacity
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        color: "black",
                        cursor: "pointer", // Add pointer cursor to indicate it's clickable
                      }}
                    >
                      <Edit />
                    </div>
                  </Box>
                  <Avatar
                    src={val.image}
                    variant="square"
                    sx={{
                      width: 200,
                      height: 200,
                      borderStartStartRadius: "10px",
                      borderStartEndRadius: "10px",
                      cursor: "pointer",
                      "& > img": {
                        objectFit: "fill",
                        width: "100%",
                        height: "100%",
                      },
                      ":hover": { bgcolor: "white" },
                    }}
                  ></Avatar>

                  <Typography
                    display="flex"
                    color="white"
                    ml="180px"
                    position="absolute"
                    mt="170px"
                  >
                    {index + 1}
                  </Typography>

                  <Box
                    display="flex"
                    bgcolor="white"
                    sx={{
                      borderEndEndRadius: "10px",
                      borderEndStartRadius: "10px",
                    }}
                    width="200px"
                    justifyContent="center"
                  >
                    <Button
                      onClick={() => {
                        deleteImageFromCat(val);
                      }}
                    >
                      Delete
                    </Button>
                    <Button
                      onClick={() => {
                        setSelectedImageDataToBeMove(index);
                      }}
                    >
                      Move
                    </Button>
                  </Box>
                </Box>
              </Grid>
            ))}
          </Grid>
        </Box>
      </Box>

      <Box
        width="100%"
        height="100vh"
        display={categoryFound ? "none" : "flex"}
        justifyContent="Center"
        alignItems="center"
      >
        <Typography fontSize="20px">Category Not Found</Typography>
      </Box>
    </Box>
  );
};

export default ManageCategory;
