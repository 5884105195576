import React, { useEffect, useMemo, useRef, useState } from "react";
import { useDrag } from "react-use-gesture";
import { Box, Typography } from "@mui/material";

const NewText = (props) => {
  const textRef = useRef(null);
  const text = props.element[props.index]
  let xpos = props.left ? props.left : 150;
  let ypos = props.top ? props.top : 150;
  const myleft = props.left ? props.left : 150
  const myTop = props.top ? props.top : 150

  const bind = useDrag(
    ({ offset: [dx, dy], down }) => {
      if (down) {
        // Calculate new position
        const newLeft = Math.min(
          Math.max(myleft + dx, 0),
          290 - textRef.current.offsetWidth
        );
        const newTop = Math.min(
          Math.max(myTop + dy, 0),
          (props.myheight-2) - textRef.current.offsetHeight
        );

        textRef.current.style.left = `${newLeft}px`;
        textRef.current.style.top = `${newTop}px`;

        xpos = newLeft;
        ypos = newTop;
      }
    },
    { domTarget: textRef, eventOptions: { passive: false } }
  );

  const handleMouseUp = () => {
    try{
    if ((xpos = 0)) {
      text.left = 2;
    } else {
      text.left = xpos;
    }
    props.onTextChange(
      props.index,
      parseInt(textRef.current.style.top, 10),
      parseInt(textRef.current.style.left, 10)
    );
    text.top = ypos;
    }catch(e){}
  };

  useEffect(() => {
    textRef.current.style.left = `${text.left}px`;
    textRef.current.style.top = `${text.top}px`;
  }, [text.left, text.top]);

  return (
    <Box
      ref={textRef}
      key={text.text + text.index}
      onMouseUp={handleMouseUp}
      sx={{
        userSelect: "none",
        cursor: "grab",
        position: "absolute",
        left: text.left + "px",
        top: text.top + "px",
      }}
      {...bind()}
    >
      <Typography
        textAlign={text.fontGravity}
        color={text.fontColor}
        noWrap
        sx={{
          fontFamily: text.fontFamily,
          fontSize: text.fontSize + "px",
          fontWeight: text.fontWeight,
          textDecoration: text.fontUnderline ? "underline" : "",
          fontStyle: text.fontItalic ? "italic" : "none",
        }}
        onDoubleClick={() => {
          props.editText(text);
        }}
      >
        {text.text.split("\n").map((line, index) => (
          <React.Fragment key={index}>
            {line}
            <br />
          </React.Fragment>
        ))}
      </Typography>
    </Box>
  );
};

export default NewText;
