import { Box, Typography, useTheme } from "@mui/material";
import React, { useMemo } from "react";
import ReactApexChart from "react-apexcharts";
import { tokens } from "../../theme";
import { onValue, ref } from "firebase/database";
import { database, writedb } from "../../firebase-config";
import { useState } from "react";

const FreePremiumUserChart = () => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const Color = theme.palette.mode === "dark" ? "white" : "black";
  const darkMode = theme.palette.mode === "dark" ? true : false;
  const [plan1Users, setPlan1Users] = useState(0);
  const [plan2Users, setPlan2Users] = useState(0);
  const [plan3Users, setPlan3Users] = useState(0);
  const [_totalUsers, _setTotalUsers] = useState(0);
  const [_premiumAdmin, _setPremiumAdmin] = useState(0);

  function isWithinFiveMinutes(timestamp) {
    const fiveMinutesInMillis = 5 * 60 * 1000; // 5 minutes in milliseconds
    const currentTime = new Date().getTime(); // Get the current time in milliseconds
    return currentTime - timestamp >= fiveMinutesInMillis;
  }

  useMemo(() => {
    const usersRef = writedb.ref('users');
    const query1 = usersRef.orderByChild("premiumPlanDetail/selectedPlan").equalTo("1 Month");
    const query2 = usersRef.orderByChild("premiumPlanDetail/selectedPlan").equalTo("6 Month");
    const query3 = usersRef.orderByChild("premiumPlanDetail/selectedPlan").equalTo("1 Year");
    const query4 = usersRef.orderByChild("adminPremium/acess").equalTo("true");

    query1.on('value', (snapshot) => {
      if (snapshot?.exists()) {
        let count = Object.keys(snapshot.val()).length;
        setPlan1Users(count);
      } else {
        setPlan1Users(0);
      }
    });
    query2.on('value', (snapshot) => {
      if (snapshot?.exists()) {
        let count = Object.keys(snapshot.val()).length;
        setPlan2Users(count);
      } else {
        setPlan2Users(0);
      }
    });
    query3.on('value', (snapshot) => {
      if (snapshot?.exists()) {
        let count = Object.keys(snapshot.val()).length;
        setPlan3Users(count);
      } else {
        setPlan3Users(0);
      }
    });

    query4.on('value', (snapshot) => {
        if (snapshot?.exists()) {
          let count = Object.keys(snapshot.val()).length;
          _setPremiumAdmin(count);
        } else {
            _setPremiumAdmin(0);
        }
      });

    onValue(ref(database, "userUniqueKeys"), (snapshot) => {
      if (snapshot.exists()) {
        _setTotalUsers(snapshot.size);
      }
    });
    
  }, [plan3Users]);

const chartData = {
    series: [plan2Users, plan3Users, _premiumAdmin, _totalUsers - (plan1Users + plan2Users + plan3Users),plan1Users],
    options: {
      chart: {
        type: "pie",
        height: 310, // Increase the chart height
        width: 330, // Increase the chart width
      },
      labels: ["6 Months", "1 Year", "Premium - Admin", "Free users","1 Month"], 
      legend: {
        show:false,
        position: "right",
        offsetY: 60,
        height: "100%", // Set the legend height to fill the container
      },
      
      responsive: [
        {
          breakpoint: 480,
          options: {
            chart: {
              height: 300, // Adjust the height for smaller screens
              width: 400, // Adjust the width for smaller screens
            },
            legend: {
              position: "bottom",
            },
          },
        },
      ],
    },
    
  };

  return (
    <Box id="chart" sx={{ position: "relative" }}>
      <ReactApexChart options={chartData.options} series={chartData.series} type="donut" height={chartData.options.chart.height} />

      {/* Custom content in the center of the donut chart */}
      <Box
        sx={{
          position: "absolute",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          textAlign: "center",
        }}
      >
        <Typography fontSize="13px">Free : {_totalUsers - (plan1Users + plan2Users + plan3Users)}</Typography>
        <Typography fontSize="13px">1 Year : {plan3Users}</Typography>
        <Typography fontSize="13px">6 Month : {plan2Users}</Typography>
        <Typography fontSize="13px">1 Month : {plan1Users}</Typography>
        <Typography fontSize="13px">Admin : {_premiumAdmin}</Typography>
      </Box>
    </Box>
  );
};

export default FreePremiumUserChart;

// import React from "react";
// import ReactApexChart from "react-apexcharts";

// const PieChartExample = () => {
  
//   const chartData = {
//     series: [44, 55, 13, 43,10],
//     options: {
//       chart: {
//         type: "pie",
//         height: 310, // Increase the chart height
//         width: 330, // Increase the chart width
//       },
//       labels: ["6 Months", "1 Year", "Yellow", "Red","1 Month"], 
//       legend: {
//         show:false,
//         position: "right",
//         offsetY: 60,
//         height: "100%", // Set the legend height to fill the container
//       },

//       responsive: [
//         {
//           breakpoint: 480,
//           options: {
//             chart: {
//               height: 300, // Adjust the height for smaller screens
//               width: 400, // Adjust the width for smaller screens
//             },
//             legend: {
//               position: "bottom",
//             },
//           },
//         },
//       ],
//     },
    
//   };

//   return (
//     <div id="chart" className="my-pie-chart">
//       <ReactApexChart
//         options={chartData.options}
//         series={chartData.series}
//         type="pie"
//         height={chartData.options.chart.height}
//         width={chartData.options.chart.width}
//       />
//     </div>
//   );
// };

// export default PieChartExample;
