import { Avatar, Box, Button, Grid, Typography, useTheme } from "@mui/material";
import { tokens } from "../../theme";
import Header from "../../components/Header";
import React, { useMemo, useState } from "react";
import { database } from "../../firebase-config";
import { onValue, ref } from "firebase/database";
import { AddCircleOutline} from "@mui/icons-material";
import Category from "../addCategory";
import Outlet from "../addOutlet";
import { Link } from "react-router-dom";
import { useEffect } from "react";

import AddNewImage from "../manageOutlet/addNewImage";


const Home = () => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const [imageVideo, setImageVideo] = useState("Image")
  const [showCategory, setShowCategory] = useState(false);
  const [showAddImage, setShowAddImage] = useState(false);
  const [showCustom, setShowCustom] = useState(false);
  const [showOutlet, setShowOutlet] = useState(false);
  const [vegOrNonVeg, setVegOrNonVeg] = useState("veg");
  const [avatarWidth, setAvatarWidth] = useState(200);
  const [avatarHeight, setAvatarHeight] = useState(200);


  useEffect(() => {
    function handleResize() {
      if (window.innerWidth >= 1200) {
        setAvatarWidth(200);
        setAvatarHeight(200);
      } else if (window.innerWidth >= 900) {
        setAvatarWidth(200);
        setAvatarHeight(200);
      } else if (window.innerWidth >= 770) {
        setAvatarWidth(280);
        setAvatarHeight(280);
      }

      else if (window.innerWidth <= 770 && window.innerWidth >= 700) {
        setAvatarWidth(300);
        setAvatarHeight(300);
      }
      else if (window.innerWidth >= 620) {
        setAvatarWidth(280);
        setAvatarHeight(280);
      }
      else if (window.innerWidth >= 600) {
        setAvatarWidth(250);
        setAvatarHeight(250);
      }
      else if (window.innerWidth >= 550) {
        setAvatarWidth(230);
        setAvatarHeight(230);
      }
      else if (window.innerWidth >= 500) {
        setAvatarWidth(210);
        setAvatarHeight(210);
      }
      else if (window.innerWidth >= 450) {
        setAvatarWidth(180);
        setAvatarHeight(180);
      }
      else if (window.innerWidth >= 400) {
        setAvatarWidth(160);
        setAvatarHeight(160);
      }
      else if (window.innerWidth >= 350) {
        setAvatarWidth(140);
        setAvatarHeight(140);
      }

      else {
        setAvatarWidth(120);
        setAvatarHeight(120);
      }
    }
    window.addEventListener('resize', handleResize);
    handleResize();
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  // ======================     Server Database Management starts  ===================================

  const [vegCategory, setVegCategory] = useState([]);
  const [nonVegCategory, setNonVegCategory] = useState([]);

  const [vegCustom, setVegCustom] = useState([]);
  const [nonVegCustom, setNonVegCustom] = useState([]);

  const [outlet, setOutlet] = useState([]);

  useMemo(() => {
    // onValue(ref(database, "images"), (snapshotImages) => {
      // Category Data Fetch
      onValue(ref(database, "home/category"), (snapshot) => {
        vegCategory.size = 0;
        var vegImages = [];
        var nonVegImages = [];
        vegImages.length = 0;
        nonVegImages.length = 0;
        snapshot.forEach(childSnapshot => {
          var categoryKey = childSnapshot.key;
          var data = childSnapshot.val(); //All data of category is here
          var keys = Object.keys(data)[0]; // name of the category is the key value of childsnapshot
          var v3 = Object.values(data)[0]; // this is used to get images from specific category wrapped with unique id like -NZ...hux
          var keyinv3 = Object.keys(v3);
          keyinv3.forEach(key => {
            if (key == "veg") {
              var veg = Object.values(v3.veg)
              var arr = [];
              veg.forEach(val => {
                if (arr.length < 10) {
                  let value = Object.values(val)
                  if (val.hasOwnProperty("thumbnailImage")) {
                    // let imageKey = val.image.slice(16);
                    // if (snapshotImages.child("category").child(imageKey).exists()) {
                    //   let image = snapshotImages.child("category").child(imageKey).val();
                    //   if (image.hasOwnProperty("cover")) {

                        arr.push({ image: val.thumbnailImage });
                    //   }
                    // }
                  }
                }
             
              })

              var newItem = { key: categoryKey, value: arr, category: keys }
              vegImages.push(newItem);
            } else if (key == "nonVeg") {
              var nonVeg = Object.values(v3.nonVeg)
              var arr = [];
              nonVeg.forEach(val => {
                if (arr.length < 10) {
                  let value = Object.values(val)
                  if (val.hasOwnProperty("thumbnailImage")) {
                    // let imageKey = val.image.slice(16);
                    // if (snapshotImages.child("category").child(imageKey).exists()) {
                    //   let image = snapshotImages.child("category").child(imageKey).val();
                    //   if (image.hasOwnProperty("cover")) {
                        arr.push({ image: val.thumbnailImage });
                    //   }
                    // }
                  }
                }
              })
              var newItem = { key: categoryKey, value: arr, category: keys }
              nonVegImages.push(newItem);
            }
          })




        })


        setVegCategory(vegImages);
        setNonVegCategory(nonVegImages);
      })

      // custom data fetch
      onValue(ref(database, "home/custom"), (snapshot) => {

        var vegImages = [];
        var nonVegImages = [];
        vegCustom.length = 0;
        nonVegCustom.length = 0;
        snapshot.forEach(childSnapshot => {
          var categoryKey = childSnapshot.key;
          var data = childSnapshot.val(); //All data of category is here
          var keys = Object.keys(data)[0]; // name of the category is the key value of childsnapshot
          var v3 = Object.values(data)[0]; // this is used to get images from specific category wrapped with unique id like -NZ...hux
          var keyinv3 = Object.keys(v3);
          keyinv3.forEach(key => {
            if (key == "veg") {
              var veg = Object.values(v3.veg)
              var arr = [];
              veg.forEach(val => {
                if (arr.length < 10) {
                  let value = Object.values(val)

                  if (val.hasOwnProperty("thumbnailImage")) {
                    // let imageKey = val.image.slice(14);
                    // if (snapshotImages.child("custom").child(imageKey).exists()) {
                      // let image = snapshotImages.child("custom").child(imageKey).val();
                      // if (image.hasOwnProperty("cover")) {

                        // arr.push({ image: image.cover.image });
                        arr.push({ image: val.thumbnailImage });
                    //   }
                    // }
                  }
                }
              })

              var newItem = { key: categoryKey, value: arr, category: keys }
              vegImages.push(newItem);
            } else if (key == "nonVeg") {
              var nonVeg = Object.values(v3.nonVeg)
              var arr = [];
              nonVeg.forEach(val => {
                if (arr.length < 10) {
                  let value = Object.values(val)
                  if (val.hasOwnProperty("thumbnailImage")) {
                    // let imageKey = val.image.slice(14);
                    // if (snapshotImages.child("custom").child(imageKey).exists()) {
                    //   let image = snapshotImages.child("custom").child(imageKey).val();
                    //   if (image.hasOwnProperty("cover")) {

                        arr.push({ image: val.thumbnailImage });
                    //   }
                    // }
                  }
                }
              })
              var newItem = { key: categoryKey, value: arr, category: keys }
              nonVegImages.push(newItem);
            }
          })




        })
        vegImages.reverse()
        nonVegImages.reverse();

        setVegCustom(vegImages);
        setNonVegCustom(nonVegImages);
      })

      // outlet data fetch
      onValue(ref(database, "home/outlet"), (snapshot) => {
        outlet.size = 0;
        let urls = [];
        snapshot.forEach(childSnapshot => {
          var outletKey = childSnapshot.key;
          const data = childSnapshot.val(); //All data of outlet is here
          const keys = Object.keys(data)[0]; // name of the outlet is the key value of childsnapshot
          var v3 = Object.values(data)[0]; // this is used to get images from specific outlet wrapped with unique id like -NZ...hux
          var v4 = Object.values(v3).reverse(); // This is used to extract images from unique id of server
          var arr = [];
          v4.forEach(val => { // there is multiple images in our system but here we need cover image or thumbnail of that so this is used to get just cover imag...
            if (arr.length < 10) {

              if (val.hasOwnProperty("thumbnailImage")) {
                // let imageKey = val.image.slice(14);
                // let imageEx = snapshotImages.child("outlet").child(imageKey);
                // if (imageEx.exists()) {
                //   if (imageEx.child("cover").exists()) {
                //     let image = imageEx.child("cover").child("image").val();
                    arr.push({ image: val.thumbnailImage });
                //   }
                // }

              }
            }


          })

          const newItem = { key: outletKey, outlet: keys, value: arr }
          urls.push(newItem);

        })
        urls.reverse()
        setOutlet(urls);

      })
    // })

  }, [vegCategory.size])










  // ======================     Server Database Management Ends  ===================================

  const catDisplay = () => {
    setShowCategory(showCategory ? false : true);
  }
  const addImageDisplay = () => {
    setShowAddImage(showAddImage ? false : true);
  }
  const customDisplay = () => {
    setShowCustom(!showCustom);
  }
  const outDisplay = () => {
    setShowOutlet(showOutlet ? false : true);
  }

  const handleLoad = (lazyLoadRef) => {

    if (lazyLoadRef.current) {
      lazyLoadRef.current.load();
    }
  };

  return (
    <Box display="flex" flexDirection="column" m="20px" marginBottom="20px">
      {/* HEADER */}
      <Box display="flex" justifyContent="space-between" alignItems="center">
        <Header title="Home" subtitle="Welcome to Brand Bite" />
      </Box>
 
      <Box display="flex" marginBottom="20px" >
        <Box display="flex" borderRadius="10px" sx={{ background: colors.primary[400] }} padding="0 10px" flexDirection="column">
          <Box display="flex" justifyContent="center">
            <Button onClick={() => { setImageVideo("Image") }}> <Typography textTransform="none" fontSize="20px" fontWeight="700" color={imageVideo === "Image" ? colors.greenAccent[500] : colors.grey[100]}>Images</Typography> </Button>
          </Box>
        </Box>


        <Box display="flex" borderRadius="10px" sx={{ background: colors.primary[400] }} padding="0 10px" marginLeft="20px">
          <Button onClick={() => { <>{setImageVideo("Outlet")} </> }}> <Typography textTransform="none" fontSize="20px" fontWeight="700" color={imageVideo === "Outlet" ? colors.greenAccent[500] : colors.grey[100]}>Show Outlets</Typography> </Button>
        </Box>

        <Box display="flex" borderRadius="10px" sx={{ background: colors.primary[400] }} padding="0 10px" marginLeft="20px">
          <Button onClick={() => { setImageVideo("Custom") }}> <Typography textTransform="none" fontSize="20px" fontWeight="700" color={imageVideo === "Custom" ? colors.greenAccent[500] : colors.grey[100]}>Show Custom</Typography> </Button>
        </Box>
      </Box>

      {/* For add new category and outlet system is here */}
      <Box display="flex" borderRadius="10px" marginBottom={imageVideo === "Image" || imageVideo === "Video" ? "0px" : "20px"}>
        <Box marginRight="10px" backgroundColor="blue" borderRadius="20px" paddingRight="5px">
          <Button onClick={addImageDisplay}><Typography display="flex" textAlign="center" fontSize="18px" textTransform="none" color="white" alignItems="center"><AddCircleOutline sx={{ marginRight: "8px" }} />Add New Image</Typography></Button>
        </Box>
        <Box marginRight="10px" backgroundColor="blue" borderRadius="20px" paddingRight="5px">
          <Button onClick={catDisplay}><Typography display="flex" textAlign="center" fontSize="18px" textTransform="none" color="white" alignItems="center"><AddCircleOutline sx={{ marginRight: "8px" }} />Add New Category</Typography></Button>
        </Box>
        <Box backgroundColor="blue" borderRadius="20px" paddingRight="5px">
          <Button onClick={outDisplay}><Typography display="flex" textAlign="center" fontSize="18px" textTransform="none" color="white" alignItems="center"><AddCircleOutline sx={{ marginRight: "8px" }} />Add New Outlet</Typography></Button>
        </Box>
        <Box backgroundColor="blue" borderRadius="20px" paddingRight="5px" ml="10px">
          <Button onClick={customDisplay}><Typography display="flex" textAlign="center" fontSize="18px" textTransform="none" color="white" alignItems="center"><AddCircleOutline sx={{ marginRight: "8px" }} />Add New Custom</Typography></Button>
        </Box>
        {/* Category display box starts from here */}
        {showAddImage ? <AddNewImage catDis={addImageDisplay} catGen={"category"} parent={"home"} method="nocat"  myName=" " myKey=" "/> : ""}
        {showCategory ? <Category parent={"home"} catDis={catDisplay} catOut={"category"} /> : ""}
        {showCustom ? <Category parent={"home"} catDis={customDisplay} catOut={"custom"} /> : ""}
        {/* Category display box ends here */}

        {/* outlet display box starts from here */}
        {showOutlet ? <Outlet outDis={outDisplay} /> : ""} 
        {/* outlet display box ends here */}
      </Box>

      {/* Display all categories from server using below code */}


      <Box display={imageVideo === "Image" || imageVideo === "Custom" ? "flex" : "none"}  >
        <Box display="flex" borderRadius="10px" >
          <Button onClick={() => { setVegOrNonVeg("veg") }}><Typography textTransform="none" fontSize="20px" fontWeight="700" color={vegOrNonVeg === "veg" ? colors.greenAccent[500] : colors.grey[100]}>Veg</Typography> </Button>

          <Button onClick={() => { setVegOrNonVeg("nonVeg") }}> <Typography textTransform="none" fontSize="20px" fontWeight="700" color={vegOrNonVeg === "nonVeg" ? colors.greenAccent[500] : colors.grey[100]}>Non-veg</Typography> </Button>
        </Box>
      </Box>

      <Box display={imageVideo === "Image" ? vegOrNonVeg == "veg" ? "flex" : "none" : "none"} flexDirection="column">
        {vegCategory.map((imageUrl, index) => (

          <Box key={imageUrl.category + index} display="flex" flexDirection="column" width="100%" sx={{ background: colors.primary[400] }} marginBottom="20px" paddingLeft="10px" borderRadius="10px">
            <Typography fontSize="25px" fontWeight="700" margin="10px 0" >{imageUrl.category}</Typography>
            <Box display="flex" overflow="auto"  >
              <Grid container maxHeight="300px" overflow="auto">
                {
                  imageUrl.value.reverse().map((vinay, index) => (
                    <Grid item key={index + "nonVeg"} xs={6} sm={6} md={4} lg={3} xl={2.4} >
                      <Box key={vinay.image + index} >
                        <Link to={"edit/category/" + imageUrl.key}>

                          <Avatar src={vinay.image} sx={{
                            width: avatarWidth, height: avatarHeight, marginRight: "15px", marginBottom: "10px", borderRadius: "5%", cursor: "pointer", '& > img': {
                              objectFit: 'fill',
                              width: '100%',
                              height: '100%',
                            }, ":hover": { bgcolor: "white" }
                          }}>
                          </Avatar>
                        </Link>
                      </Box>
                    </Grid>
                  ))
                }
              </Grid>
            </Box>
          </Box>
        ))}
      </Box>


      <Box display={imageVideo === "Image" ? vegOrNonVeg == "nonVeg" ? "flex" : "none" : "none"} flexDirection="column">
        {nonVegCategory.map((imageUrl, index) => (

          <Box key={imageUrl.category + index} display="flex" flexDirection="column" width="100%" sx={{ background: colors.primary[400] }} marginBottom="20px" paddingLeft="10px" borderRadius="10px">
            <Typography fontSize="25px" fontWeight="700" margin="10px 0" >{imageUrl.category}</Typography>
            <Box display="flex" overflow="auto"  >
              <Grid container maxHeight="300px" overflow="auto">
                {
                  imageUrl.value.reverse().map((vinay, index) => (
                    <Grid item key={index + "nonVeg"} xs={6} sm={6} md={4} lg={3} xl={2.4} >
                      <Box key={vinay.image + index} >
                        <Link to={"edit/category/" + imageUrl.key}>

                          <Avatar src={vinay.image} sx={{
                            width: avatarWidth, height: avatarHeight, marginRight: "15px", marginBottom: "10px", borderRadius: "5%", cursor: "pointer", '& > img': {
                              objectFit: 'fill',
                              width: '100%',
                              height: '100%',
                            }, ":hover": { bgcolor: "white" }
                          }}></Avatar>

                        </Link>
                      </Box>
                    </Grid>
                  ))
                }
              </Grid>
            </Box>
          </Box>))}

      </Box>


      {/* Display all outlets from server using below code */}

      <Box display={imageVideo === "Outlet" ? "flex" : "none"} flexDirection="column">
        {outlet.map((imageUrl, index) => (

          <Box key={imageUrl.outlet + index} display="flex" flexDirection="column" sx={{ background: colors.primary[400] }} marginBottom="20px" paddingLeft="10px" borderRadius="10px">
            <Typography fontSize="25px" fontWeight="700" margin="10px 0" >{imageUrl.outlet}</Typography>
            <Box display="flex" overflow="auto"  >
              <Grid container maxHeight="300px" overflow="auto">
                {
                  imageUrl.value.reverse().map((vinay, index) => (
                    <Grid item key={index + "nonVeg"} xs={12} sm={6} md={4} lg={3} xl={3} >
                      <Box key={vinay.image + index} >

                        <Link to={"edit/outlet/" + imageUrl.key}><Avatar src={vinay.image} sx={{
                          width: avatarWidth, height: avatarHeight, marginRight: "15px", marginBottom: "10px", borderRadius: "5%", cursor: "pointer", '& > img': {
                            objectFit: 'fill',
                            width: '100%',
                            height: '100%',
                          }, ":hover": { bgcolor: "white" }
                        }}></Avatar></Link>
                      </Box>
                    </Grid>
                  ))
                }
              </Grid>
            </Box>
          </Box>))}
      </Box>



      <Box display={imageVideo === "Custom" ? vegOrNonVeg == "veg" ? "flex" : "none" : "none"} flexDirection="column">
        {vegCustom.map((imageUrl, index) => (

          <Box key={imageUrl.category + index} display="flex" flexDirection="column" width="100%" sx={{ background: colors.primary[400] }} marginBottom="20px" paddingLeft="10px" borderRadius="10px">
            <Typography fontSize="25px" fontWeight="700" margin="10px 0" >{imageUrl.category}</Typography>
            <Box display="flex" overflow="auto"  >
              <Grid container maxHeight="300px" overflow="auto">
                {
                  imageUrl.value.reverse().map((vinay, index) => (
                    <Grid item key={index + "VegCustom"} xs={6} sm={6} md={4} lg={3} xl={2.4} >
                      <Box key={vinay.image + index} >
                        <Link to={"edit/custom/" + imageUrl.key}>

                          <Avatar src={vinay.image} sx={{
                            width: avatarWidth, height: avatarHeight, marginRight: "15px", marginBottom: "10px", borderRadius: "5%", cursor: "pointer", '& > img': {
                              objectFit: 'fill',
                              width: '100%',
                              height: '100%',
                            }, ":hover": { bgcolor: "white" }
                          }}>
                          </Avatar>
                        </Link>
                      </Box>
                    </Grid>
                  ))
                }
              </Grid>
            </Box>
          </Box>
        ))}
      </Box>


      <Box display={imageVideo === "Custom" ? vegOrNonVeg == "nonVeg" ? "flex" : "none" : "none"} flexDirection="column">
        {nonVegCustom.map((imageUrl, index) => (

          <Box key={imageUrl.category + index} display="flex" flexDirection="column" width="100%" sx={{ background: colors.primary[400] }} marginBottom="20px" paddingLeft="10px" borderRadius="10px">
            <Typography fontSize="25px" fontWeight="700" margin="10px 0" >{imageUrl.category}</Typography>
            <Box display="flex" overflow="auto"  >
              <Grid container maxHeight="300px" overflow="auto">
                {
                  imageUrl.value.reverse().map((vinay, index) => (
                    <Grid item key={index + "nonVegCustom"} xs={6} sm={6} md={4} lg={3} xl={2.4} >
                      <Box key={vinay.image + index} >
                        <Link to={"edit/custom/" + imageUrl.key}>

                          <Avatar src={vinay.image} sx={{
                            width: avatarWidth, height: avatarHeight, marginRight: "15px", marginBottom: "10px", borderRadius: "5%", cursor: "pointer", '& > img': {
                              objectFit: 'fill',
                              width: '100%',
                              height: '100%',
                            }, ":hover": { bgcolor: "white" }
                          }}></Avatar>

                        </Link>
                      </Box>
                    </Grid>
                  ))
                }
              </Grid>
            </Box>
          </Box>))}

      </Box>


    </Box>

  );
};

export default Home;
