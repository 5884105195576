import { useTheme } from '@emotion/react'
import React, { useEffect, useMemo, useState } from 'react'
import { tokens } from '../../theme';
import { Avatar, Box, Button, Grid, Input, Modal, TextField, Typography } from '@mui/material';
import Header from "../../components/Header";
import { useLocation, useParams } from 'react-router-dom';
import { onValue, ref } from 'firebase/database';
import { database, storage, writedb } from '../../firebase-config';
import { AddCircleOutline } from '@mui/icons-material';
import MultipleImagesUpload from '../addMultipleImages';

const ManageAnyCategory = () => {
    const theme = useTheme();
    const colors = tokens(theme.palette.mode);
    const key = useParams().name;
    const [name, setName] = useState("");
    const [renameName, setRenameName] = useState("");
    const [avatarWidth, setAvatarWidth] = useState(200);
    const [avatarHeight, setAvatarHeight] = useState(200);
    const [stickers, setStickers] = useState([]);
    const [selectedImageIndexNumber, setSelectedImageIndexNumber] = useState(0); // selected image index number which we want to move;
    const [showMoveBox, setShowMoveBox] = useState(false); //this is used to get index where we want to move image;
    const [close, setClose] = useState(false);
    const [dropLocationOfImage, setDropLocationOfImage] = useState("");
    const [catDeleting, setCatDeleting] = useState(false)
    const location = useLocation().pathname;
    const [title, setTitle] = useState("");
    const [catFound, setCatFound] = useState(true);
    const [imageList, setImageList] = useState([]);
    const [showImageUpload, setShowImageUpload] = useState(false);
    const [showRename, setShowRename] = useState(false);
    const [renameRunning, setRenameRunning] = useState(false);
    const [categoryFound, setCategoryFound] = useState(true);
    useMemo(() => {
        if (location.includes("testimonials")) {
            setTitle("testimonials")
            loadData("testimonials", key)
        } else if (location.includes("wallposter")) {
            setTitle("wallPoster")
            setAvatarHeight(270)
            loadData("wallPoster",key)
        }else if (location.includes("combos")) {
            setTitle("combos")
            loadData("combos",key)
        }
        else if (location.includes("events")) {
            setTitle("events")
            loadData("events",key)
        }
        else if (location.includes("stickers")) {
            setTitle("stickers")
            loadData("stickers",key)
        }
        else if (location.includes("brands")) {
            setTitle("brands")
            loadData("brands",key)
        }
        else if (location.includes("background")) {
            setTitle("background")
            loadData("background",key)
        }
        else if (location.includes("foodImages")) {
            setTitle("foodImages")
            loadData("foodImages",key)
        }
    }, [location.length])

    function loadData(par, key) {

        onValue(ref(database, `${par}/${key}`), (snapshot) => {

            if (snapshot.exists() && !catDeleting) {
                let data = snapshot.val()

                let name = Object.keys(data)[0];
                setName(name);

                setRenameName(name)
                let values = Object.values(data);
                let keys = Object.keys(data);
                let arr = [];

                values.forEach((val, index) => {
                    let keys = Object.keys(val);
                    let valuesOfVal = Object.values(val);

                    valuesOfVal.forEach((val, index) => {
                        if (val.hasOwnProperty("image")) {
                            let imageKey = keys[index];
                            let imageLink = val.image;
                            let item = { image: imageLink, imagePath: `${par}/${key}/${name}/${imageKey}` }
                            arr.push(item);
                        }
                    })

                    setImageList(arr.reverse());
                })
            } else {
                setCatFound(false);

            }
        })


    }

    const setSelectedImageDataToBeMove = (index) => {

        setSelectedImageIndexNumber(index);
        setShowMoveBox(true)
    }

    const handleClose = () => {
        setClose(false)
    };


    const inputStyle = {
        '& label.Mui-focused': {
            color: 'aqua',
        },
        '& .MuiInput-underline:after': {
            borderBottomColor: 'white',
        },
        '& .MuiOutlinedInput-root': {
            '& fieldset': {
                borderColor: 'gray',
                height: '45px'
            },
            '&:hover fieldset': {
                borderColor: 'white',
            },
            '&.Mui-focused fieldset': {
                borderColor: 'rgb(155,155,255)',
            }, '& .MuiInputBase-input': {
                color: 'white',
                fontSize: "16px",
                height: "7px"

            },
        }
    }

    const swapData = async () => {
        if (dropLocationOfImage.length == 0 || dropLocationOfImage < 1) {
            alert("Invalid");
            return;
        }
        let arr = imageList;


        if (dropLocationOfImage > arr.length) {
            alert("Invalid");
            return;
        }

        let firstImagePath = arr[selectedImageIndexNumber].imagePath
        let secondImagePath = arr[dropLocationOfImage - 1].imagePath



        let firstImageData;
        let secondImageData;

        await writedb.ref(firstImagePath).once('value').then((snapshot) => {
            firstImageData = snapshot.val();
            writedb.ref(secondImagePath).once('value').then((snapshot) => {
                secondImageData = snapshot.val();
                writedb.ref(firstImagePath).set(secondImageData).then(() => {
                    writedb.ref(secondImagePath).set(firstImageData).then(() => {
                        setSelectedImageIndexNumber(dropLocationOfImage - 1);
                    })
                })
            })
        })
    }

    const renameCategory = async () => {
        if (!renameName.length > 0) {
            alert("Invalid Input");
            return;
        }

        if (renameName == name) {
            setShowRename(!showRename);
            return;
        }

        if (!renameRunning) {
            let snapshot = await writedb.ref(`${title}UniqueKeys/${renameName}/`).once('value');
            if (snapshot.exists()) {
                alert(`${renameName} already exist`);
            } else {
                  setRenameRunning(true)
                let oldName = JSON.parse(JSON.stringify(name));
                let keyOfCat = JSON.parse(JSON.stringify(key));
                let snapshotOfCategory;

                writedb.ref(`${title}/${key}/${name}`).once('value').then((snapshot) => {
                    snapshotOfCategory = snapshot.val();
                    writedb.ref(`${title}UniqueKeys/` + oldName + "/key").remove().then(() => {
                        writedb.ref(`${title}UniqueKeys/${renameName}`).set({ key: keyOfCat }).then(() => {
                            writedb.ref(`${title}/${key}/${name}`).remove().then(() => {

                                writedb.ref(`${title}/${key}/${renameName}`).set(snapshotOfCategory).then(() => {
                                    setShowRename(!showRename);
                                    alert(`${name} renamed to ${renameName}`);
                                    setRenameRunning(false)
                                })
                            })
                        })

                    })

                })

            }
        }
    }

    const deleteImageFromLink = async (link) => {
        await storage.refFromURL(link).delete();
    }

    const deleteMainOut = async () => {

        const confirmed = window.confirm(`Are you sure you want to delete ${name}?`);
        if (confirmed) {

            setCatDeleting(true)


            imageList.forEach(val => {
                deleteImageFromLink(val.image);
            })




            writedb.ref(`${title}UniqueKeys/${name}`).remove().then(() => {
                writedb.ref(`${title}/${key}/`).remove(() => {
                    setCategoryFound(false);
                    setCatDeleting(false)
                    alert(`${name} deleted`)
                })
            })


        }
    }

    function deleteImage(data){
        storage.refFromURL(data.image).delete().then(() => {
            writedb.ref(data.imagePath).remove()
        })
        if (imageList.length ==  1){
            writedb.ref(`${title}UniqueKeys/${name}`).remove().then(() => {
                writedb.ref(`${title}/${key}/`).remove(() => {
                    setCategoryFound(false);
                    setCatDeleting(false)
                    alert(`${name} deleted`)
                })
            })
        }
        
    }


    return (
        <Box>
        <Box display={categoryFound ? "flex" : "none"} flexDirection="column" m="20px" marginBottom="20px">
            {/* HEADER */}
            <Box display="flex" justifyContent="space-between" alignItems="center">
                <Header title={name} subtitle="Brand Bite" />
            </Box>

            <Box display="flex" borderRadius="10px" marginBottom="20px">
                <Box >
                    <Button onClick={() => setShowImageUpload(!showImageUpload)} sx={{ marginRight: "10px", backgroundColor: "blue", borderRadius: "20px", paddingRight: "10px" }}><Typography display="flex" textAlign="center" fontSize="18px" textTransform="none" color="white" alignItems="center"><AddCircleOutline sx={{ marginRight: "8px" }} />Add Images</Typography></Button>
                </Box>

                <Box mr="5px" display={showRename ? "none" : "flex"} >
                    <Button sx={{ bgcolor: "blue", borderRadius: "20px" }} onClick={() => { setShowRename(!showRename) }}>
                        <Typography display="flex" color="white" textTransform="none">Rename</Typography>
                    </Button>
                </Box>

                <Box display={showRename ? "flex" : "none"} flexDirection="column" height="40px" mr="5px" mb="15px">
                    <TextField autoComplete="off" label="Name" value={renameName} onChange={(event) => {
                        setRenameName(event.target.value.charAt(0).toUpperCase() + event.target.value.slice(1))
                    }} sx={inputStyle} />
                    <Box display="flex" justifyContent="center" mt="5px" zIndex="1">
                        <Box bgcolor="blue" borderRadius="10px" p="2px 5px" className="thisisbtn" onClick={renameCategory}>
                            <Button> <Typography color="white">Rename</Typography></Button>
                        </Box>
                        <Box bgcolor="blue" borderRadius="10px" p="2px 5px" ml="10px" className="thisisbtn" onClick={() => { setShowRename(!showRename) }}>
                            <Button> <Typography color="white">Cancel</Typography></Button>
                        </Box>
                    </Box>
                </Box>

                <Box mr="5px">
                    <Button sx={{ bgcolor: "blue", borderRadius: "20px", p:"10px" }} onClick={deleteMainOut}>
                        <Typography display="flex" color="white" textTransform="none">Delete</Typography>
                    </Button>
                </Box>

            </Box>

            {showImageUpload ? <MultipleImagesUpload path={`${key}/${name}`} boxDisplay={() => setShowImageUpload(!showImageUpload)} parent={title} /> : ""}


            <Modal open={showMoveBox} onClose={handleClose}>
                <Box
                    display="flex"
                    flexDirection="column"
                    justifyContent="center"
                    alignItems="center"
                    bgcolor="#727681"
                    p={3}
                >
                    <Box display="flex" flexDirection="column" height="45px" ml="10px">
                        <Input autoFocus autoComplete="off" type="number" label="Name" value={dropLocationOfImage} onChange={(event) => {
                            setDropLocationOfImage(parseInt(event.target.value))
                        }} sx={inputStyle} />
                    </Box>

                    <Box mt="10px">
                        <Button variant="contained" color="primary" sx={{ mr: "10px" }} onClick={swapData}>
                            Move
                        </Button>
                        <Button variant="contained" color="primary" onClick={() => { setShowMoveBox(!showMoveBox) }}>
                            Close
                        </Button>
                    </Box>
                </Box>
            </Modal>


            <Box display="flex" width="100%" flexWrap={true} flexDirection="column" >

                <React.Fragment key="listOfOutlet" >
                    <Box display="flex" alignItems="center"  >
                        <Grid container maxHeight="600px" overflow="auto" mt="30px">
                            {imageList.map((data, indexData) => (
                                <Grid item key={indexData + "outler"} xs={12} sm={6} md={4} lg={3} xl={3} >
                                    <Box display="flex" p="10px" flexDirection="column" key={data.image} width={200} position="relative">
                                        <Avatar src={data.image} variant="square" sx={{
                                            width: avatarWidth, height: avatarHeight, borderStartStartRadius: "10px", borderStartEndRadius: "10px", cursor: "pointer", '& > img': {
                                                objectFit: 'fill',
                                                width: '100%',
                                                height: '100%',
                                            }, ":hover": { bgcolor: "white" }
                                        }}></Avatar>

                                        <Typography display="flex" color="white" ml="180px" position="absolute" mt={`${avatarHeight - 30}px`}>{indexData + 1}</Typography>

                                        <Box display="flex" bgcolor="white" sx={{ borderEndEndRadius: "10px", borderEndStartRadius: "10px" }} width="200px" justifyContent="center">
                                            <Button onClick={() => {deleteImage(data)}}>

                                                Delete
                                            </Button>
                                            <Button onClick={() => { setSelectedImageDataToBeMove(indexData) }}>
                                                Move
                                            </Button>

                                        </Box>
                                    </Box>
                                </Grid>
                            ))}
                        </Grid>

                    </Box>

                </React.Fragment>


            </Box>

        </Box>

        <Box width="100%" height="100vh" display={categoryFound ? "none" : "flex"} justifyContent="Center" alignItems="center" >

                <Typography fontSize="20px">
                     Not Found
                </Typography>
            </Box>

        </Box>
    )
}

export default ManageAnyCategory;

