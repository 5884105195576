
const FontFamilies = [
  { label: "Alex Brush", value: "'Alex Brush', cursive" },
  { label: "Anton", value: "'Anton', sans-serif" },
  { label: "Bebas Neue", value: "'Bebas Neue', cursive" },
  { label: "Cookie", value: "'Cookie', cursive" },
  { label: "Dancing Script", value: "'Dancing Script', cursive" },
  { label: "Lato", value: "Lato" },
  { label: "Montserrat", value: "Montserrat" },
  { label: "Oswald", value: "Oswald" },
  { label: "Pacifico", value: "'Pacifico', cursive" },
  { label: "PT Sans", value: "PT Sans" },
  { label: "Raleway", value: "Raleway" },
  { label: "Rowdies", value: "'Rowdies', cursive" },
  { label: "Teko", value: "'Teko', sans-serif" },
  { label: "Titillium Web", value: "Titillium Web" },
  { label: "Ubuntu", value: "Ubuntu" },
  { label: "Yatra One", value: "'Yatra One', cursive" },
  { label: "aBlackLives", value: "'aBlackLives', cursive" },
  { label: "Academic M54", value: "'Academic M54', cursive" },
  { label: "Agatho_ Bold", value: "'Agatho_ Bold', sans-serif" },
  { label: "Agatho_ BoldCAPS", value: "'Agatho_ BoldCAPS', sans-serif" },
  { label: "Agatho_ Light", value: "'Agatho_ Light', sans-serif" },
  { label: "Agatho_ LightCAPS", value: "'Agatho_ LightCAPS', sans-serif" },
  { label: "Agatho_ Medium", value: "'Agatho_ Medium', sans-serif" },
  { label: "Agatho_ Narrow", value: "'Agatho_ Narrow', sans-serif" },
  { label: "Agatho_ RegularCAPS", value: "'Agatho_ RegularCAPS', sans-serif" },
  { label: "Agatho_Regular", value: "'Agatho_Regular', sans-serif" },
  { label: "Awake the Beauty", value: "'Awake the Beauty', cursive" },
  { label: "Batangas Bold 700", value: "'Batangas Bold 700', sans-serif" },
  { label: "Bebas Neue Cyrillic", value: "'Bebas Neue Cyrillic', cursive" },
  { label: "Bevan-Italic", value: "'Bevan-Italic', cursive" },
  { label: "Bevan-Regular", value: "'Bevan-Regular', cursive" },
  { label: "Billabong", value: "'Billabong', cursive" },
  { label: "Blastine", value: "'Blastine', sans-serif" },
  { label: "chunkfive.regular", value: "'chunkfive.regular', cursive" },
  { label: "Cooper Black", value: "'Cooper Black', sans-serif" },
  { label: "Dosis-Bold", value: "'Dosis-Bold', sans-serif" },
  { label: "Dosis-ExtraBold", value: "'Dosis-ExtraBold', sans-serif" },
  { label: "Dosis-ExtraLight", value: "'Dosis-ExtraLight', sans-serif" },
  { label: "Dosis-Light", value: "'Dosis-Light', sans-serif" },
  { label: "Dosis-Medium", value: "'Dosis-Medium', sans-serif" },
  { label: "Dosis-Regular", value: "'Dosis-Regular', sans-serif" },
  { label: "Dosis-SemiBold", value: "'Dosis-SemiBold', sans-serif" },
  { label: "Garet-Book", value: "'Garet-Book', sans-serif" },
  { label: "Garet-Heavy", value: "'Garet-Heavy', sans-serif" },
  { label: "KaushanScript-Regular", value: "'KaushanScript-Regular', cursive" },
  { label: "LeagueGothic-Regular", value: "'LeagueGothic-Regular', sans-serif" },
  { label: "LeagueGothic_Condensed-Regular", value: "'LeagueGothic_Condensed-Regular', sans-serif" }, 
  { label: "LeagueGothic_SemiCondensed-Regular", value: "'LeagueGothic_SemiCondensed-Regular', sans-serif" },
  { label: "Milky Coffee", value: "'Milky Coffee', cursive" },
  { label: "Peace Sans Webfont", value: "'Peace Sans Webfont', sans-serif" },
  { label: "Playlist Caps", value: "'Playlist Caps', sans-serif" },
  // { label: "Playlist Ornament", value: "'Playlist Ornament', sans-serif" },
  { label: "Playlist Script", value: "'Playlist Script', sans-serif" },
  // { label: "Sprite Graffiti Extras", value: "'Sprite Graffiti Extras', sans-serif" },
  { label: "Sprite Graffiti Shadow", value: "'Sprite Graffiti Shadow', sans-serif" },
  { label: "Sprite Graffiti", value: "'Sprite Graffiti', sans-serif" },
  { label: "Strawberry Days", value: "'Strawberry Days', cursive" },
  { label: "THEBOLDFONT", value: "'THEBOLDFONT', sans-serif" },
  { label: "Yellowtail-Regular", value: "'Yellowtail-Regular', cursive" },
]


export default FontFamilies;
