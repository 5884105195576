import {
  Avatar,
  Box,
  Button,
  Grid,
  Input,
  Modal,
  TextField,
  Tooltip,
  Typography,
  useTheme,
} from "@mui/material";
import { useLocation, useParams } from "react-router-dom";
import Header from "../../components/Header";
import { tokens } from "../../theme";
import React from "react";
import { useState } from "react";
import { useMemo } from "react";
import { onValue, ref } from "firebase/database";
import { database } from "../../firebase-config";
import axios from "axios";

const DownloadedImages = () => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);

  const [selected, setSelected] = useState("Category");

  const [category, setCategory] = useState([]);
  const [menu, setMenu] = useState([]);
  const [outlet, setOutlet] = useState([]);
  const [custom, setCustom] = useState([]);
  const [general, setGeneral] = useState([]);
  const [testimonials, setTestimonials] = useState([]);
  const [combos, setCombos] = useState([]);
  const [events, setEvents] = useState([]);
  const [wallPoster, setWallPoster] = useState([]);
  const [flyer, setFlyer] = useState([]);

  const [categoryCount, setCategoryCount] = useState(0);
  const [menuCount, setMenuCount] = useState(0);
  const [outletCount, setOutletCount] = useState(0);
  const [customCount, setCustomCount] = useState(0);
  const [generalCount, setGeneralCount] = useState(0);
  const [testimonialsCount, setTestimonialsCount] = useState(0);
  const [combosCount, setCombosCount] = useState(0);
  const [eventsCount, setEventsCount] = useState(0);
  const [wallPosterCount, setWallPosterCount] = useState(0);
  const [flyerCount, setFlyerCount] = useState(0);

  const checkImageExistence = async (url) => {
    try {
      await axios.get(url);
      // URL exists
      return { url, exists: true };
    } catch (error) {
      // URL does not exist
      return { url, exists: false };
    }
  };

  const handleDownloadedImages = (databaseRef, setCategory) => {
    onValue(ref(database, databaseRef), (snapshot) => {
      if (snapshot.exists()) {
        const val = snapshot.val();
        const keys = Object.keys(val);
        const values = Object.values(val);
        const arr = [];
  
        values.forEach((val, index) => {
          if (val.hasOwnProperty("image") && val.hasOwnProperty("total")) {
            const imageUrl = val.image;
            const item = {
              key: keys[index],
              image: imageUrl,
              total: val.total,
            };
            arr.push(item);
          }
        });
  
        // Sort the array in descending order based on the 'total' property
        arr.sort((a, b) => b.total - a.total);
  
        setCategory(arr);
      }
    });
  };
  

  useMemo(() => {
    onValue(ref(database, "analytics/imageDownloads"), (snapshot) => {
      if (snapshot.exists()) {
        let val = snapshot.val();
        if (val.hasOwnProperty("category")) {
          setCategoryCount(val.category);
        }
        if (val.hasOwnProperty("menu")) {
          setMenuCount(val.menu);
        }
        if (val.hasOwnProperty("outlet")) {
          setOutletCount(val.outlet);
        }
        if (val.hasOwnProperty("custom")) {
          setCustomCount(val.custom);
        }
        if (val.hasOwnProperty("general")) {
          setGeneralCount(val.general);
        }
        if (val.hasOwnProperty("testimonials")) {
          setTestimonialsCount(val.testimonials);
        }
        if (val.hasOwnProperty("combos")) {
          setCombosCount(val.combos);
        }
        if (val.hasOwnProperty("events")) {
          setEventsCount(val.events);
        }
        if (val.hasOwnProperty("wallPoster")) {
          setWallPosterCount(val.wallPoster);
        }
        if (val.hasOwnProperty("flyer")) {
          setFlyerCount(val.flyer);
        }
      }
    });
    handleDownloadedImages("analytics/downloadedImages/category", setCategory);
    handleDownloadedImages("analytics/downloadedImages/menu", setMenu);
    handleDownloadedImages("analytics/downloadedImages/outlet", setOutlet);
    handleDownloadedImages("analytics/downloadedImages/general", setGeneral);
    handleDownloadedImages("analytics/downloadedImages/custom", setCustom);
    handleDownloadedImages(
      "analytics/downloadedImages/testimonials",
      setTestimonials
    );
    handleDownloadedImages("analytics/downloadedImages/combos", setCombos);
    handleDownloadedImages("analytics/downloadedImages/events", setEvents);
    handleDownloadedImages(
      "analytics/downloadedImages/wallPoster",
      setWallPoster
    );
    handleDownloadedImages(
      "analytics/downloadedImages/flyer",
      setFlyer
    );
  }, [category.length > 0]);

  const textCard = (value, count, className) => {
    return (
      <Box
        display="flex"
        flexDirection="column"
        justifyContent="center"
        alignItems="center"
        sx={{
          background: colors.primary[400],
          padding: 2,
          cursor: "-webkit-grab",
        }}
        border={
          selected == value ? `2px solid ${colors.bwTextColor[100]}` : null
        }
        borderRadius="10px"
        className={className}
        onClick={() => setSelected(value)}
      >
        <Typography fontSize="20px" fontWeight={600}>
          {count}
        </Typography>
        <Typography fontSize="18px">
          {value}
        </Typography>
      </Box>
    );
  };

  const categoryGrid = (selectedVal, list, a4) => {
    return (
      <Box
        display={selected === selectedVal ? "flex" : "none"}
        width="100%"
        flexWrap="wrap"
        mt="20px"
        height={list.length > 0 ? null : "60vh"}
      >
        {list.length > 0 ? (
          <Grid container justify="center" spacing={1} wrap="wrap">
            {list.map((val, index) => (
              <Grid
                item
                key={`${index}Cat`}
                xs={12}
                sm={6}
                md={4}
                lg={3}
                xl={2.4}
              >
                <Box
                  display="flex"
                  p="10px"
                  flexDirection="column"
                  key={val.key}
                  width={200}
                  position="relative"
                >
                  <Avatar
                    src={val.image}
                    variant="square"
                    sx={{
                      width: 200,
                      height: a4 ? 250 : 200,
                      borderStartStartRadius: "10px",
                      borderStartEndRadius: "10px",
                      cursor: "pointer",
                      "& > img": {
                        objectFit: "fill",
                        width: "100%",
                        height: "100%",
                      },
                      ":hover": { bgcolor: "white" },
                    }}
                  ></Avatar>

                  <Box
                    display="flex"
                    flexDirection="column"
                    alignItems="center"
                    bgcolor={colors.primary[400]}
                    sx={{
                      borderEndEndRadius: "10px",
                      borderEndStartRadius: "10px",
                    }}
                    width="200px"
                    justifyContent="center"
                  >
                    <Typography
                      display="flex"
                      color={colors.bwTextColor[100]}
                      fontWeight={600}
                      fontSize="22px"
                    >
                      {val.total}
                    </Typography>
                  </Box>
                </Box>
              </Grid>
            ))}
          </Grid>
        ) : (
          <Box
            display="flex"
            justifyContent="center"
            alignItems="center"
            width="100%"
            height="100%"
          >
            <Typography fontSize="22px" fontWeight={700}>
              No Images Downloaded
            </Typography>
          </Box>
        )}
      </Box>
    );
  };

  return (
    <Box>
      <Box display={"flex"} flexDirection="column" m="20px" marginBottom="20px">
        {/* HEADER */}
        <Box display="flex" justifyContent="space-between" alignItems="center">
          <Header title="Downloads" subtitle="Brand Bite" />
        </Box>
        
        <Box className="flex-col-600px flex-row" width="100%">
          
          {textCard(
            "Wall Poster",
            wallPosterCount,
            "mb10-600px width50 setwidth100"
          )}
          {textCard(
            "Flyer",
            flyerCount,
            "mb10-600px width50 setwidth100 ml20 ml0"
          )}
          
          {textCard(
            "Testimonials",
            testimonialsCount,
            "mb10-600px width50 setwidth100 ml20 ml0"
          )}
          {textCard(
            "Combos",
            combosCount,
            "mb10-600px width50 setwidth100 ml20 ml0"
          )}
          {textCard(
            "Events",
            eventsCount,
            "mb10-600px width50 setwidth100 ml20 ml0"
          )}
          {textCard(
            "Custom",
            customCount,
            "mb10-600px width50 setwidth100 ml20 ml0"
          )}
          {textCard("Outlet", outletCount, "mb10-600px width50 setwidth100 ml20 ml0")}
          
        </Box>

        <Box className="flex-col-600px flex-row" width="100%" mt="20px">
          {textCard(
            "Category",
            categoryCount,
            "mb10-600px width50 setwidth100"
          )}
          {textCard(
            "Menu",
            menuCount,
            "mb10-600px width50 setwidth100 ml20 ml0"
          )}
          {textCard(
            "General",
            generalCount,
            "mb10-600px width50 setwidth100 ml20 ml0"
          )}
        </Box>

        {/* Category Options starts here */}

        {categoryGrid("Category", category, false)}
        {categoryGrid("Menu", menu, true)}
        {categoryGrid("Outlet", outlet, false)}
        {categoryGrid("General", general, false)}
        {categoryGrid("Custom", custom, false)}
        {categoryGrid("Testimonials", testimonials, false)}
        {categoryGrid("Combos", combos, false)}
        {categoryGrid("Events", events, false)}
        {categoryGrid("Wall Poster", wallPoster, true)}
        {categoryGrid("Flyer", flyer, true)}
      </Box>
    </Box>
  );
};

export default DownloadedImages;
