import {
  Avatar,
  Box,
  Button,
  Grid,
  Input,
  Modal,
  TextField,
  Tooltip,
  Typography,
  useTheme,
} from "@mui/material";
import { useLocation, useParams } from "react-router-dom";
import Header from "../../components/Header";
import { tokens } from "../../theme";
import React from "react";
import { useState } from "react";
import { useMemo } from "react";
import { onValue, ref } from "firebase/database";
import { database } from "../../firebase-config";
import axios from "axios";

const DownloadedImages = () => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);

  const [selected, setSelected] = useState("Category");

  const [list, setList] = useState([]);


  useMemo(() => {
    onValue(
      ref(database, "analytics/downloadedVideoCategories"),
      (snapshot) => {
        if (snapshot.exists()) {
          let val = snapshot.val();
          let keys = Object.keys(val);
          let values = Object.values(val);
          let arr = [];
          values.forEach((val, index) => {
            let name = keys[index];
            let count = val;
            let item = { name: name, total: count };
            arr.push(item);
          });
          arr.sort((a, b) => b.total - a.total);
          setList(arr);
        }
      }
    );
  }, [list.length > 0]);

  const textCard = (value, count, className) => {
    return (
      
        <Box
          display="flex"
          flexDirection="column"
          justifyContent="center"
          alignItems="center"
          
          sx={{
            background: colors.primary[400],
            padding: 2,
            cursor: "-webkit-grab",
          }}
          border={
            selected == value ? `2px solid ${colors.bwTextColor[100]}` : null
          }
          borderRadius="10px"
        //   className={className}
          onClick={() => setSelected(value)}
        >
          <Typography fontSize="20px" fontWeight={600}>
            {count}
          </Typography>
          <Typography fontSize="18px" mt="5px">{value}</Typography>
        </Box>
      
    );
  };

  return (
    <Box>
      <Box display={"flex"} flexDirection="column" m="20px" marginBottom="20px">
        {/* HEADER */}
        <Box display="flex" justifyContent="space-between" alignItems="center">
          <Header title="Video Downloads" subtitle="Brand Bite" />
        </Box>

        <Box display="flex" mt="20px" mb="20px">
          <Grid container spacing={4} justifyContent="start">
            {list.map((val, index) => (
                <Grid item key={`${index}`}> 
                {textCard(
                  val.name,
                  val.total,
                  "setwidth100"
                )}
              
              </Grid>
            ))}
            {/* {textCard(
              "Category",
              categoryCount,
              "mb10-600px width50 setwidth100"
            )}
            {textCard(
              "Menu",
              menuCount,
              "mb10-600px width50 setwidth100 ml20 ml0"
            )}
            {textCard(
              "General",
              generalCount,
              "mb10-600px width50 setwidth100 ml20 ml0"
            )} */}
          </Grid>
        </Box>
      </Box>
    </Box>
  );
};

export default DownloadedImages;
