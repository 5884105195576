import { Button, IconButton, Input, InputBase, TextField, Typography, useTheme } from '@mui/material'
import { Box } from '@mui/system'
import React from 'react'
import { tokens } from '../theme';
import background from "../images/budh.jpg";
import { auth } from '../firebase-config';
import { useState } from 'react';
import { signInWithEmailAndPassword } from 'firebase/auth';
import { GridSearchIcon } from '@mui/x-data-grid';

const Login = () => {
    const theme = useTheme();
    const colors = tokens(theme.palette.mode);
    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");

    const logIn = async (e) => {
        e.preventDefault();
        try {
            await signInWithEmailAndPassword(auth, email, password);
            alert("Login Okay");
        } catch (error) {
            alert("Login Failed" + error);
        }
    }


    return (
        <Box display="flex"
            width="100%" height="100%" justifyContent="center" alignItems="center" flexDirection="column" backgroundColor={colors.primary[700]} sx={{
                /* backgroundImage: `url(${background})`*/ backgroundSize: "cover",
                backgroundRepeat: 'no-repeat'
            }}>
            <Box display="flex" flexDirection="column" backgroundColor={colors.primary[400]} borderRadius="10px" justifyContent="center" alignItems="center" width="100%" padding="20px 20px" paddingBottom="5px" boxShadow="0 0 50px rgba(18, 18, 18, 0.15)">
                <Box display="flex" width="100%" flexDirection="column" justifyContent="center" alignItems="center" >
                    <Box display="flex" justifyContent="center" flexDirection="column" alignItems="center" >
                    <Box
                        display="flex"
                        backgroundColor={colors.primary[400]}
                        borderRadius="10px"
                        width="100%"
                        padding="5px 0"
                        marginBottom="15px"
                        justifyContent="center"
                        
                    >
                        <InputBase sx={{ ml: 2, fontSize: "17px" }} placeholder="Email" value={email} onChange={(e) => { setEmail(e.target.value) }} />
                    </Box>

                    <Box
                        display="flex"
                        backgroundColor={colors.primary[400]}
                        borderRadius="10px"
                        width="100%"
                        justifyContent="center"
                        padding="5px 0"
                    >
                        <InputBase sx={{ ml: 2, fontSize: "17px" }} placeholder="Password" type="password" value={password} onChange={(e) => { setPassword(e.target.value) }} />
                    </Box>


                    <Box
                        display="flex"
                        borderRadius="10px"
                        background="#f2f2f2"
                    >
                        <Button onClick={logIn} sx={{
                            color:'white',
                            margin: "10px 0", "&:hover": {
                                backgroundColor: 'white',
                                color:'black'
                            },borderRadius:"10px"
                        }}><Typography textTransform="none" fontSize="25px" fontWeight={700}  >Log In</Typography></Button>
                    </Box>
                    
                    </Box>


                    {/* <TextField id="filled-basic" label="Email" variant="filled" color="error" sx={{ width: "100%", marginBottom: "10px", color: "black" }} />
                    <TextField id="outlined-basic" label="Password" variant="filled" color="primary" sx={{ width: "100%", color:'black' }} /> */}
                </Box>

            </Box>



        </Box>
    )
}

export default Login;






