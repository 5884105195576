/*

This is multipurpose file used for homescreen banner, videoscreen banner, generalscreen banner

varibale used in this file not only applicable for homescreen banner

*/


import { AddCircleOutlineRounded } from '@mui/icons-material'
import { Avatar, Button, Typography, useTheme, TextField } from '@mui/material'
import { Box } from '@mui/system'
import { onValue, ref } from 'firebase/database'
import React, { useState } from 'react'
import { useMemo, useEffect } from 'react'
import Header from '../../components/Header'
import { database, storage, writedb } from '../../firebase-config'
import { tokens } from '../../theme'
import AddNewBanner from './addBanner'
import EditBanner from './editBanner'
import { useLocation } from 'react-router-dom'
const Banner = () => {
    const theme = useTheme();
    const colors = tokens(theme.palette.mode);
    const [path, setPath] = useState("");
    const [title, setTitle] = useState("");
    const [selectedBannerImage, setselectedBannerImage] = useState(""); 
    const location = useLocation().pathname;

    useMemo(() => {
        if (location === "/banner") {
            setPath("home/banner");
            setTitle("Banner")
        } else if (location === "/genbanner") {
            setPath("general/banner")
            setTitle("Banner - General");
        }else if (location === "/videobanner") {
            setPath("video/banner")
            setTitle("Banner - Video"); 
        }else if (location === "/menubanner") {
            setPath("menu/banner")
            setTitle("Banner - Menu"); 
        }
    }, [location.length])

    const [addNew, setAddnew] = useState(false);
    const [bannerList, setBannerList] = useState([]);
    const [positionNo, setPositionNo] = useState(0); // set position no of to move banner
    const [myPos, setMyPos] = useState(-1) // used to handle change position click event
    const [editBannerKey, setEditBannerKey] = useState("");
    const [editBann, setEditBann] = useState(false); // to display edit bann box
    const [editBannData, setEditBannData] = useState(); // to pass data of banner to be edit
    
    const bannerDisplay = () => {
        setAddnew(addNew ? false : true);
    }

    const editBannerDisplay = () => {
        setEditBann(!editBann)
    }


    function loadBannerFromServer() {
        if (path.length > 0) {
            myPos < 0 && (onValue(ref(database, path), (snapshot) => {
                if (myPos < 0 && positionNo === 0) {
                    bannerList.length = 0;
                    var arr = [];
                    snapshot.forEach(snapshot => {
                        const data = snapshot.val();
                        arr.push({ image: data.image, key: data.key, name: data.name, trigger: data.trigger, bannkey: snapshot.key, bannerName: data.name }); 
                    })

                    setBannerList(arr.reverse());
                }
            }))
        }
    }

    useEffect(() => {
        loadBannerFromServer();
    }, [bannerList.length])


    const inputStyle = {
        '& label.Mui-focused': {
            color: 'aqua',
        },
        '& .MuiInput-underline:after': {
            borderBottomColor: 'white',
        },
        '& .MuiOutlinedInput-root': {
            '& fieldset': {
                borderColor: 'gray',
            },
            '&:hover fieldset': {
                borderColor: 'white',
            },
            '&.Mui-focused fieldset': {
                borderColor: 'rgb(155,155,255)',
            }, '& .MuiInputBase-input': {
                color: 'white',
                fontSize: "16px",

            },
        }
    }





    const settingMyPos = (index) => {
        setMyPos(index)
    }




    const swapData = () => {  // This is used to change position of banner
        if (positionNo < 1 || positionNo > bannerList.length || positionNo - 1 === myPos) {
            alert("Invalid Value");
            return;
        }
        else {
            const secpos = positionNo - 1;
            const first = Object.assign({}, bannerList[myPos]);
            const second = Object.assign({}, bannerList[secpos]);
            delete first.index;
            delete second.index;

            const firstkey = JSON.parse(JSON.stringify(first.bannkey));
            const secondkey = JSON.parse(JSON.stringify(second.bannkey));

            delete first.bannkey;
            delete second.bannkey;



            writedb.ref(`${path}/${firstkey}`).update(second).then(() => {
                writedb.ref(`${path}/${secondkey}`).update(first).then(() => {
                    setMyPos(-1);
                    setPositionNo(0)
                    // loadBannerFromServer();
                    alert("Banner Moved Successfully");

                })
            })

        }
    }


    const deleteBanner = async (data) => {
        const key = JSON.parse(JSON.stringify(data.bannkey));
        try{
            let link = await storage.ref(`${path}/${key}/image`).getDownloadURL();
            await storage.refFromURL(link).delete();
        }catch(error){}
       
        writedb.ref(`${path}/${key}`).remove().then(() => {
            alert("Successfully deleted");
        })
        
    }


    const editBanner = (data,image) => {
        const key = JSON.parse(JSON.stringify(data.bannkey));
        const dataBann = Object.assign({}, data);
        setselectedBannerImage(image);
        setEditBannerKey(key);
        setEditBannData(dataBann)
        setEditBann(true)
    }



    return (
        <Box display="flex" flexDirection="column" m="20px" marginBottom="20px" maxWidth="100%" overflow="auto">
            <Box width="auto" marginBottom="15px">
                <Header title={title} subtitle="Brand Bite" />
            </Box>

            <Box marginBottom="15px" >
                <Box display="inline-block" className="thisisbtn" backgroundColor={colors.greenAccent[800]} borderRadius="20px" justifyContent="center" alignItems="center" onClick={() => { addNew ? setAddnew(false) : setAddnew(true) }} >
                    <Box display="flex" padding="7px" className='thisisbtn' justifyContent="center" alignItems="center">
                        <AddCircleOutlineRounded sx={{ marginRight: "5px", marginLeft: "3px" }} />
                        <Typography marginRight="5px" fontSize="20px">New Banner</Typography>
                    </Box>
                </Box>
            </Box>


            {addNew ? <AddNewBanner address={path} bannerDisp={bannerDisplay} /> : ""}
            {editBann ? <EditBanner address={path} image={selectedBannerImage} bannerDisp={editBannerDisplay} data={editBannData} /> : ""}


            <Box minWidth="0" marginBottom="20px" overflow="auto" maxHeight="85vh" sx={{ background: colors.primary[400] }} borderRadius="10px">

                <Box display="flex" marginTop="10px" flexDirection="column" maxWidth="100%" justifyContent="start" alignItems="center" overflow="auto">
                    {
                        bannerList.map((val, index) => (
                            <React.Fragment key={index} >
                                <Box display="flex" alignItems="center" >

                                    <Box marginRight="50px">
                                        <Typography fontSize="25px">{index + 1}</Typography>
                                    </Box>
                                    <Box marginRight="50px" marginTop="20px">
                                        <Avatar src={val.image} sx={{ width: 120, height: 120, marginRight: "15px", marginBottom: "10px", borderRadius: "5%", cursor: "pointer", '& > img': { objectFit: "contain", width: "100%", height: "100%" } }}>
                                        </Avatar>
                                    </Box>
                                    <Box display="flex" flexDirection="column" marginRight="50px" width="150px">
                                        <Box>
                                            <Typography fontSize="20px" noWrap>{"Trigger: " + val.trigger}</Typography>
                                        </Box>
                                        <Box display="flex" justifyContent="center">
                                            <Typography fontSize="20px" >{val.name}</Typography>
                                        </Box>
                                    </Box>
                                    <Box display={myPos === index ? "none" : "flex"} className="thisisbtn" borderRadius="8px" backgroundColor={colors.greenAccent[800]} padding="0 10px" marginRight="50px" nowrap="true" onClick={() => { settingMyPos(index) }} >
                                        <Typography fontSize="20px" noWrap>Change Position</Typography>
                                    </Box>
                                    <Box display={myPos === index ? "flex" : "none"} marginRight="50px" justifyContent="center" alignItems="center" flexDirection="column">
                                        {/* <Box className="flex-col aijc " mb="40px" ml="100px" position="absolute" justifyContent="start">
                                        <Typography  marginBottom="2%">Max</Typography>
                                        <Typography>{bannerList.length}</Typography>
                                    </Box> */}

                                        <TextField autoFocus autoComplete="off" type="number" label="Position" value={positionNo} onChange={(event) => {
                                            setPositionNo(event.target.value)
                                        }} sx={inputStyle} />
                                        <Box display="flex">
                                            <Box display="flex" className="thisisbtn" mt="10px" justifyContent="center" alignItems="center" onClick={() => { setMyPos(-1) }} backgroundColor={colors.greenAccent[800]} borderRadius="10px" padding="5px 10px" marginRight="10px">
                                                <Button><Typography textTransform="none" color="white">Cancel</Typography></Button>
                                            </Box>
                                            <Box display="flex" onClick={swapData} className="thisisbtn" mt="10px" justifyContent="center" alignItems="center" backgroundColor={colors.greenAccent[800]} borderRadius="10px" padding="5px 10px">
                                                <Button><Typography color="white" textTransform="none">Move</Typography></Button>
                                            </Box>
                                        </Box>

                                    </Box>



                                    <Box backgroundColor={colors.greenAccent[800]} className="thisisbtn" borderRadius="8px" padding="0 10px" marginRight="50px" onClick={() => { editBanner(val,val.image) }}>
                                        <Typography fontSize="20px" noWrap>Edit</Typography>
                                    </Box>
                                    <Box backgroundColor={colors.greenAccent[800]} className="thisisbtn" borderRadius="8px" padding="0 10px" marginRight="50px" onClick={() => { deleteBanner(val) }}>
                                        <Typography fontSize="20px" noWrap>Delete</Typography>
                                    </Box>
                                </Box>
                            </React.Fragment>
                        ))
                    }
                </Box>
            </Box>




        </Box>





    )
}

export default Banner;
