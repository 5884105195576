import {
  Avatar,
  Box,
  Button,
  FormControlLabel,
  Grid,
  IconButton,
  Radio,
  Tooltip,
  Typography,
  useTheme,
} from "@mui/material";
import { tokens } from "../../theme";
import Header from "../../components/Header";
import { useMemo, useState } from "react";
import { Link } from "react-router-dom";
import { database, writedb } from "../../firebase-config";
import { limitToLast, onValue, query, ref } from "firebase/database";
import axios from "axios";
import {
  ArrowBack,
  ArrowLeft,
  ArrowRight,
  Backpack,
  CropDinRounded,
  RestaurantMenuOutlined,
  SentimentSatisfiedAltOutlined,
  WallpaperRounded,
} from "@mui/icons-material";

const Dashboard = () => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const [users, setUsers] = useState(0);
  const [preUser, setPreUsers] = useState(0);
  const [freeUser, setFreeUsers] = useState();
  const [blockUser, setBlockUsers] = useState("0");
  const [appOnOff, setAppOnOff] = useState(true);
  const [suggestions, setSuggestions] = useState([]);
  const [year, setYear] = useState(new Date().getFullYear().toString());
  const [earningList, setEarningList] = useState([]);
  const [totalEarnings, setTotalEarning] = useState("");
  const [snapOfUser, setSnapOfUser] = useState("");

  // ======================     Server Database Management starts  ===================================

  function loadSuggestions(snapOfUser1) {
    onValue(ref(database, "suggestions"), (snapshot) => {
      suggestions.length = 0;
      let tempArr = [];
      snapshot.forEach((childSnapshot) => {
        let mobile = Object.keys(childSnapshot.val())[0]; // After getting child node accessing the object using index value and we get mobile number
        let suggestion = Object.values(childSnapshot.val())[0].suggestion; // After getting node accessing the object's object to get

        onValue(ref(database, `users/${mobile}/details`), (snapshot) => {
          let snapVal = snapshot.val();
          let name = snapVal.name;
          tempArr.push({
            name: name,
            mobile: mobile.slice(3),
            desc: suggestion,
            key: childSnapshot.key.toString(),
          });
        });
      });

      tempArr.reverse();
      setSuggestions(tempArr);
    });
  }

  // Getting user details from server
  const userRef = ref(database, "users");
  useMemo(() => {
    onValue(userRef, (snapshot) => {
      setSnapOfUser(snapshot);
      loadSuggestions(snapshot);
    });
    
  }, [users]);

  const userCountRef = ref(database, "userUniqueKeys");
  useMemo(() => {
    onValue(userCountRef, (snapshot) => {
      setUsers(snapshot.size);
    });
  }, [users]);

  useMemo(() => {
    // updateVersion("1.11","04-10-2023"); // dont use this code inside onvalue because it triggers twice and makes wrong history of versions
    onValue(ref(database, "premiumUsers"), (snapshot) => {
      setPreUsers(snapshot.size);
      setFreeUsers(users - snapshot.size);
    });
    writedb.ref("paymentMethod/which").once('value').then((snapshot) => {
      if (snapshot.exists()) {
        let val =  snapshot.val();
        setPaymentMethod(val);
      }
    })
  }, [preUser]);

  function updateVersion(version, date) {
    writedb.ref("appVersions/currentVersion").set({ version: version });
    writedb
      .ref("appVersions/verionHistory")
      .push()
      .set({ version: version, releaseDate: date });
  }

  // Fetch Trending Images From Server
  const [trendingImages, setTrendingImages] = useState([]);
  const [moreCuisines, setMoreCuisines] = useState([]);
  const [genMoreCuisines, setGenMoreCuisines] = useState([]);
  const trendingImagesRef = ref(database, "home/trending");
  const MoreCuisinesRef = ref(database, "home/moreCuisines");
  const GenMoreCuisinesRef = ref(database, "general/moreCuisines");
  useMemo(() => {
    onValue(trendingImagesRef, (snapshot) => {
      let urls = [];
      snapshot.forEach((childSnapshot) => {
        let key = childSnapshot.val();
        urls.push(key.image);
      });
      urls.reverse();
      setTrendingImages(urls);
    });

    onValue(MoreCuisinesRef, (snapshot) => {
      let urls = [];
      snapshot.forEach((childSnapshot) => {
        let key = childSnapshot.val();
        urls.push(key.image);
      });
      urls.reverse();
      setMoreCuisines(urls);
    });
    onValue(GenMoreCuisinesRef, (snapshot) => {
      let urls = [];
      snapshot.forEach((childSnapshot) => {
        let key = childSnapshot.val();
        urls.push(key.image);
      });
      urls.reverse();
      setGenMoreCuisines(urls);
    });
  }, [trendingImages.size]);

  const imageElements = trendingImages.map((imageUrl, index) => (
    <Link key={imageUrl + index} to="/trending">
      <Avatar
        src={imageUrl}
        sx={{
          width: 70,
          height: 70,
          margin: "0 10px",
          marginBottom: "10px",
          cursor: "pointer",
        }}
      ></Avatar>
    </Link>
  ));

  const imageElementsForGeneralCuisines = genMoreCuisines.map(
    (imageUrl, index) => (
      <Link key={imageUrl + index} to="/genmorecuisines">
        <Avatar
          src={imageUrl}
          sx={{
            width: 70,
            height: 70,
            margin: "0 10px",
            marginBottom: "10px",
            cursor: "pointer",
          }}
        ></Avatar>
      </Link>
    )
  );

  const imageElementsForHomeCuisines = moreCuisines.map((imageUrl, index) => (
    <Link key={imageUrl + index} to="/morecuisines">
      <Avatar
        src={imageUrl}
        sx={{
          width: 70,
          height: 70,
          margin: "0 10px",
          marginBottom: "10px",
          cursor: "pointer",
        }}
      ></Avatar>
    </Link>
  ));

  // Fetch Banner Images From Server
  const [bannerImages, setBannerImages] = useState([]);
  const [genBannerImages, setGenBannerImages] = useState([]);
  const [menuBannerImages, setMenuBannerImages] = useState([]);
  const [videoBannerImages, setVideoBannerImages] = useState([]);
  const [transactionList, setTransactionList] = useState([]);
  const bannerImagesRef = ref(database, "home/banner");
  const videoBannerImagesRef = ref(database, "video/banner");
  const genbannerImagesRef = ref(database, "general/banner");
  const menubannerImagesRef = ref(database, "menu/banner");
  const transactionRef = ref(database, "analytics/paymentTransactions");
  useMemo(() => {
    onValue(bannerImagesRef, (snapshot) => {
      let urls = [];
      snapshot.forEach((childSnapshot) => {
        let key = childSnapshot.val();
        urls.push(key.image);
      });
      urls.reverse();
      setBannerImages(urls);
    });
    onValue(videoBannerImagesRef, (snapshot) => {
      let urls = [];
      snapshot.forEach((childSnapshot) => {
        let key = childSnapshot.val();
        urls.push(key.image);
      });
      urls.reverse();
      setVideoBannerImages(urls);
    });
    onValue(genbannerImagesRef, (snapshot) => {
      let urls = [];
      snapshot.forEach((childSnapshot) => {
        let key = childSnapshot.val();
        urls.push(key.image);
      });
      urls.reverse();
      setGenBannerImages(urls);
    });

    onValue(menubannerImagesRef, (snapshot) => {
      let urls = [];
      snapshot.forEach((childSnapshot) => {
        let key = childSnapshot.val();
        urls.push(key.image);
      });
      urls.reverse();
      setMenuBannerImages(urls);
    });
    const limitedQuery = query(transactionRef, limitToLast(10));
    onValue(limitedQuery, (snapshot) => {
      let urls = [];
      if (snapshot.exists()) {
        let values = Object.values(snapshot.val());

        values.forEach(async (childSnapshot) => {
          let key = childSnapshot;
          
          let item = {
            mobile: key.mobile,
            date: key.date,
            price: key.price,
            ref: key.ref,
            status: key.status,
            plan: key.selectedPlan,
            time: new Date(key.deviceTime),
          };
          if (item.mobile == "Not Linked (Mobile)"){
            if (key.hasOwnProperty("userMainMobile")){
              item.mobile = key.userMainMobile;
            }else{
              item.mobile = key.userMainEmail;
            }
          }


          urls.push(item);
        });
      }
      // Sort the transactions by timestamp before setting the state
      // urls.sort((a, b) => a.date - b.date);
      urls.sort((a, b) => new Date(a.time) - new Date(b.time));
      urls.reverse();
      setTransactionList(urls);
    });
  }, [bannerImages.size]);

  const bannerimageElements = bannerImages.map((imageUrl, index) => (
    <Link key={imageUrl + index} to="/banner">
      <Avatar
        src={imageUrl}
        sx={{
          width: 130,
          height: 100,
          margin: "0 10px",
          marginBottom: "10px",
          borderRadius: "10%",
          cursor: "pointer",
        }}
      ></Avatar>
    </Link>
  ));

  const bannerimageElementsForVideo = videoBannerImages.map(
    (imageUrl, index) => (
      <Link key={imageUrl + index} to="/videobanner">
        <Avatar
          src={imageUrl}
          sx={{
            width: 130,
            height: 100,
            margin: "0 10px",
            marginBottom: "10px",
            borderRadius: "10%",
            cursor: "pointer",
          }}
        ></Avatar>
      </Link>
    )
  );

  const bannerimageElementsForGeneral = genBannerImages.map(
    (imageUrl, index) => (
      <Link key={imageUrl + index} to="/genbanner">
        <Avatar
          src={imageUrl}
          sx={{
            width: 130,
            height: 100,
            margin: "0 10px",
            marginBottom: "10px",
            borderRadius: "10%",
            cursor: "pointer",
          }}
        ></Avatar>
      </Link>
    )
  );

  async function getNameFromNumber(number) {
    let name = await writedb
      .ref(`users/${number}/details/name`)
      .once("value")
      .then((snapshot) => {
        if (snapshot.exists) {
          let d = snapshot.val();
          return d;
        } else {
          return "";
        }
      });
    return name;
  }

  const bannerimageElementsForMenu = menuBannerImages.map((imageUrl, index) => (
    <Link key={imageUrl + index} to="/menubanner">
      <Avatar
        src={imageUrl}
        sx={{
          width: 130,
          height: 100,
          margin: "0 10px",
          marginBottom: "10px",
          borderRadius: "10%",
          cursor: "pointer",
        }}
      ></Avatar>
    </Link>
  ));

  const transactionListUi = transactionList.map((data, index) => (
    <Box
      display="block"
      border={`2px solid ${colors.primary[100]}`}
      borderRadius="5px"
      width="100%"
      p="5px"
      m="5px 0"
    >
      <Box
        display="flex"
        flexDirection="row"
        justifyContent="space-between"
        width="100%"
      >
        <Typography>{data.mobile}</Typography>
        <Typography>{data.date}</Typography>
      </Box>
      <Box
        display="flex"
        flexDirection="row"
        justifyContent="space-between"
        width="100%"
      >
        <Typography>
          {data.plan} - {data.price} (Price)
        </Typography>
        <Typography color={data.status == "success" ? "green" : "red"}>
          {data.status}
        </Typography>
      </Box>
      <Box
        display="flex"
        flexDirection="row"
        justifyContent="space-between"
        width="100%"
      >
        <Typography>Reference ID</Typography>
        <Typography>{data.ref}</Typography>
      </Box>
    </Box>
  ));

  // This is for update database to turn app status on off
  useMemo(() => {
    onValue(ref(database, "appOnOff"), (snapshot) => {
      var status = snapshot.val();
      if (status.status === "on") {
        setAppOnOff(true);
      } else {
        setAppOnOff(false);
      }
    });
  }, [appOnOff]);

  function changeAppStatus() {
    if (appOnOff) {
      writedb
        .ref("appOnOff")
        .set({ status: "off" })
        .then(() => {
          setAppOnOff(false);
        });
    } else {
      writedb
        .ref("appOnOff")
        .set({ status: "on" })
        .then(() => {
          setAppOnOff(true);
        });
    }
  }

  // This is to get suggestions acrosss from users in firebase to dashboard

  // ======================     Server Database Management Ends  ===================================

  function moEarningsGraph(month, progress) {
    return (
      <Box
        display="flex"
        flexDirection="column"
        height="90%"
        justifyContent="center"
        alignItems={"center"}
        margin="0 10px"
      >
        <Box
          width="5px"
          height="80%"
          backgroundColor="white"
          marginBottom="10px"
          display="flex"
          alignItems="end"
          borderRadius="20px"
        >
          <Box
            height={progress + "%"}
            width="100%"
            backgroundColor="blue"
            borderRadius="20px"
          ></Box>
        </Box>
        <Typography>{month}</Typography>
      </Box>
    );
  }

  useMemo(() => {
    loadEarnings();
  }, [earningList.length, year]);

  function loadEarnings() {
    const months = [
      "Jan",
      "Feb",
      "Mar",
      "Apr",
      "May",
      "Jun",
      "Jul",
      "Aug",
      "Sep",
      "Oct",
      "Nov",
      "Dec",
    ];
    const earningsRef = `earnings/${year}`;
    onValue(ref(database, earningsRef), async (snapshotOfEarn) => {
      let arr = [];
      let total = 0;
      for (let i = 0; i < months.length; i++) {
        let month = months[i];
        let snapshot = snapshotOfEarn.child(month);
        let earnings = snapshot.val();
        if (earnings == null) {
          earnings = 0;
        }
        total += earnings;
        let progress = (earnings / 50000) * 100;
        arr.push({ month: month, progress: progress, earnings: earnings });
      }
      setTotalEarning(total);
      setEarningList(arr);
    });
  }

  const StickersButton = ({ to, label, icon }) => (
    <Grid item>
      <Link to={to}>
        <Button
          variant="contained"
          color="primary"
          sx={{ background: colors.primary[400] }}
        >
          <Box
            display="flex"
            flexDirection="column"
            height="100px"
            width="100px"
            justifyContent="center"
            alignItems="center"
          >
            <Typography>{label}</Typography>
            {icon}
          </Box>
        </Button>
      </Link>
    </Grid>
  );

  const handlePaymentMethodChange = (event) => {
    setPaymentMethod(event.target.value);
    writedb.ref("paymentMethod").set({which:event.target.value});
  };

  const [paymentMethod, setPaymentMethod] = useState("");

  return (
    <Box maxWidth="100vw" className="hide-overflow-600px" paddingRight="5px">
      <Box
        display="flex"
        flexDirection="column"
        className="m20"
        marginBottom="20px"
        maxWidth="100%"
      >
        {/* HEADER */}
        <Box className="hide show-600px">
          <Typography fontSize="30px" fontWeight="700">
            BrandBite
          </Typography>
        </Box>
        <Box
          className="flex-col hide-600px"
          justifyContent="space-between"
          alignItems="start"
        >
          {/* <Header title="DASHBOARD" subtitle="Welcome to your dashboard" /> */}
          <Header title="DASHBOARD" subtitle="Welcome to Brand Bite" />
          {/* Download Report BTn */}
        </Box>

        {/* GRID & CHARTS */}

        {/* Row 1 - Dashboard */}

        <Box
          display="flex"
          marginBottom="20px"
          flexDirection={{
            sm: "row",
            xl: "row",
            xs: "column",
            lg: "row",
            md: "row",
          }}
        >
          {/* Total users start */}
          <Box
            className="setwidth100  mr20inline flex-col flex-row-600px center600 mb10-600px"
            sx={{ background: colors.primary[400] }}
            borderRadius="10px"
            justifyContent="center"
            alignItems="center"
            p="0 20px"
          >
            <Box
              className="flex-col hide-600px"
              justifyContent="center"
              alignItems="center"
              height="100%"
              width="100%"
            >
              <Typography fontSize="25px" fontWeight="700">
                {users}
              </Typography>

              <Typography fontSize="17px" marginTop="-2px">
                Total Users
              </Typography>
            </Box>
            <Box
              className="show-600px flex-col hide"
              justifyContent="center"
              padding="10px"
            >
              <Typography fontSize="20px" fontWeight={700}>
                TOTAL USERS
              </Typography>
              <Box display="flex" justifyContent="center">
                <Typography fontSize="20px" fontWeight={700}>
                  {users}
                </Typography>
              </Box>
            </Box>
          </Box>

          {/* ================ Total users end  =================*/}

          {/* visual report of users start */}
          <Box
            className="flex-col setwidth100 mr20inline mb10-600px"
            width={{ xs: "100%", sm: "60%", md: "60%", lg: "60%", xl: "60%" }}
            sx={{ background: colors.primary[400] }}
            padding="5px 10px"
            borderRadius="10px"
            overflow="hidden"
            justifyContent="center"
          >
            <Typography>Out Of {users} Users</Typography>
            {/* Premium Box */}
            <Box display="flex" className="premium-box" alignItems="center">
              <Box marginRight="10px">
                <Typography>Premium</Typography>
              </Box>
              <Box
                backgroundColor="white"
                width="85%"
                borderRadius="20px"
                height="5px"
                overflow="hidden"
              >
                <Box
                  backgroundColor="rgb(35, 131, 35)"
                  width={((preUser / users) * 100).toFixed(2) + "%"}
                  height="100%"
                  borderRadius="20px"
                ></Box>
              </Box>
              <Typography marginLeft="5px" marginRight="5px">
                {(((preUser / users) * 100).toFixed(2) + "").substring(0, 5) +
                  "%"}
              </Typography>
            </Box>

            {/* Free Users */}
            <Box display="flex" className="free-box" alignItems="center">
              <Box marginRight="10px">
                <Typography>Free</Typography>
              </Box>
              <Box
                backgroundColor="white"
                width="85%"
                borderRadius="20px"
                height="5px"
                overflow="hidden"
              >
                <Box
                  backgroundColor="#ec1839;"
                  width={((freeUser / users) * 100).toFixed(2) + "%"}
                  height="100%"
                  borderRadius="20px"
                ></Box>
              </Box>
              <Typography marginLeft="5px" marginRight="5px">
                {(((freeUser / users) * 100).toFixed(2) + "").substring(0, 5) +
                  "%"}
              </Typography>
            </Box>
            {/* Blocker users */}
            <Box display="flex" className="block-box" alignItems="center">
              <Box marginRight="10px">
                <Typography>Blocked</Typography>
              </Box>
              <Box
                backgroundColor="white"
                width="85%"
                borderRadius="20px"
                height="5px"
                overflow="hidden"
              >
                <Box
                  backgroundColor="#ec1839;"
                  width={blockUser}
                  height="100%"
                  borderRadius="20px"
                ></Box>
              </Box>
              <Typography marginLeft="5px" marginRight="5px">
                {blockUser.substring(0, 5) + "%"}
              </Typography>
            </Box>
          </Box>
          {/* visual report of users end */}

          <Box
            className="setwidth100  mr20inline flex-col flex-row-600px center600 mb10-600px"
            sx={{ background: colors.primary[400] }}
            borderRadius="10px"
            justifyContent="center"
            alignItems="center"
            padding="0 20px"
          >
            <Button
              onClick={() => {
                {
                  changeAppStatus();
                }
              }}
            >
              <Typography
                textTransform="none"
                fontSize="25px"
                fontWeight={700}
                color={appOnOff ? "lightgreen" : "red"}
              >
                {appOnOff ? "On" : "Off"}
              </Typography>
            </Button>
          </Box>

          <Box
            className="setwidth100  mr20inline flex-col flex-row-600px center600 mb10-600px"
            sx={{ background: colors.primary[400] }}
            borderRadius="10px"
            justifyContent="center"
            alignItems="start"
            padding="0 20px"
          >
            <FormControlLabel
              value="google"
              control={<Radio sx={{ color: colors.secondary, '&.Mui-checked': { color: colors.primary[100] } }}/>}
              label="Google"
              labelPlacement="end"
              checked={paymentMethod === "google"}
              onChange={handlePaymentMethodChange}
              name="paymentmethod"
            />
            <FormControlLabel
              value="payu"
              control={<Radio sx={{ color: colors.secondary, '&.Mui-checked': { color: colors.primary[100] } }}/>}
              label="PayU"
              labelPlacement="end"
              checked={paymentMethod === "payu"}
              onChange={handlePaymentMethodChange}
              name="paymentmethod"
            />
            <FormControlLabel
              value="razorpay"
              control={<Radio sx={{ color: colors.secondary, '&.Mui-checked': { color: colors.primary[100] } }}/>}
              label="RazorPay"
              labelPlacement="end"
              checked={paymentMethod === "razorpay"}
              onChange={handlePaymentMethodChange}
              name="paymentmethod"
            />
            
          </Box>
        </Box>

        {/* Row 2 - Dashboard */}

        <Box className="flex-col-600px flex-row">
          {/* Home Trending starts here*/}

          <Box
            className="mb10-600px width50 setwidth100"
            display="flex"
            flexDirection="column"
            alignItems="center"
            overflow="auto"
            sx={{
              background: colors.primary[400],
              padding: 2,
              cursor: "pointer",
              maxWidth: "100%", // Set maximum width to 50%
              maxHeight: "242px",
            }}
            borderRadius="10px"
          >
            <Link
              to="/trending"
              overflow="auto"
              style={{ color: "inherit", textDecoration: "none" }}
            >
              <Box display="flex" flexDirection="column">
                <Typography fontSize="20px" fontWeight={700} textAlign="center">
                  Trending
                </Typography>
              </Box>
            </Link>

            {/* Image List */}
            {trendingImages.length > 0 ? (
              <Box
                display="flex"
                flexDirection="row"
                justifyContent="center"
                alignItems="center"
                flexWrap="wrap"
              >
                {imageElements}
              </Box>
            ) : (
              <Box
                display="flex"
                flexDirection="row"
                justifyContent="center"
                alignItems="center"
                height="100%"
              >
                <Link
                  to="/trending"
                  overflow="auto"
                  style={{ color: "inherit", textDecoration: "none" }}
                >
                  <Box display="flex" flexDirection="column">
                    <Typography
                      fontSize="20px"
                      fontWeight={700}
                      textAlign="center"
                    >
                      No images
                    </Typography>
                  </Box>
                </Link>
              </Box>
            )}
          </Box>
          {/* Home Trending ends here*/}
          {/* More Cuisines starts here*/}
          <Box
            className="mb10-600px width50 setwidth100 ml20 ml0"
            display="flex"
            flexDirection="column"
            alignItems="center"
            overflow="auto"
            sx={{
              background: colors.primary[400],
              padding: 2,
              cursor: "pointer",
              maxWidth: "100%", // Set maximum width to 50%
              maxHeight: "242px",
            }}
            borderRadius="10px"
          >
            <Link
              to="/morecuisines"
              overflow="auto"
              style={{ color: "inherit", textDecoration: "none" }}
            >
              <Box display="flex" flexDirection="column">
                <Typography fontSize="20px" fontWeight={700} textAlign="center">
                  Explore More Cuisines
                </Typography>
                <Typography
                  fontSize="20px"
                  fontWeight={700}
                  textAlign="center"
                  marginBottom="10px"
                >
                  Home
                </Typography>
              </Box>
            </Link>

            {/* Image List */}
            <Box
              display="flex"
              flexDirection="row"
              justifyContent="center"
              alignItems="center"
              flexWrap="wrap"
            >
              {imageElementsForHomeCuisines}
            </Box>
          </Box>
          {/* More Cuisines ends here*/}
          <Box
            className="mb10-600px width50 setwidth100 ml20 ml0"
            display="flex"
            flexDirection="column"
            alignItems="center"
            overflow="auto"
            sx={{
              background: colors.primary[400],
              padding: 2,
              maxWidth: "100%", // Set maximum width to 50%
              maxHeight: "242px",
            }}
            borderRadius="10px"
          >
            <Box display="flex" flexDirection="column">
              <Typography
                fontSize="20px"
                fontWeight={700}
                textAlign="center"
                marginBottom="10px"
              >
                Transactions
              </Typography>
            </Box>
            {transactionList.length > 0 ? (
              <Box
                display="flex"
                flexDirection="row"
                justifyContent="center"
                alignItems="center"
                flexWrap="wrap"
                width="100%"
              >
                {transactionListUi}
              </Box>
            ) : (
              <Box
                display="flex"
                flexDirection="row"
                justifyContent="center"
                alignItems="center"
                flexWrap="wrap"
                width="100%"
                height="100%"
              >
                <Typography fontSize="20px" fontWeight={700} textAlign="center">
                  No Transactions
                </Typography>
              </Box>
            )}
            {/* Image List */}
          </Box>
        </Box>

        <Box className="flex-col-600px flex-row" mt="20px">
          <Box
            className="mb10-600px width50 setwidth100"
            display="flex"
            flexDirection="column"
            alignItems="center"
            overflow="auto"
            sx={{
              background: colors.primary[400],
              padding: 2,
              cursor: "pointer",
              maxWidth: "100%", // Set maximum width to 50%
            }}
            borderRadius="10px"
          >
            <div
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between", // Adjusted to place content at start and end
                width: "100%", // Take full width
              }}
            >
              <Box>
                <IconButton
                  onClick={() => {
                    setYear(2023);
                  }}
                >
                  <ArrowLeft />
                </IconButton>
              </Box>
              <Typography variant="h4" fontWeight={700} textAlign="center">
                Earnings - {year}
              </Typography>
              <Box>
                <IconButton
                  onClick={() => {
                    setYear(2024);
                  }}
                >
                  <ArrowRight />
                </IconButton>
              </Box>
            </div>
            <Box display="flex" flexDirection="row">
              {earningList.map((val, index) => (
                <Box
                  display="flex"
                  key={index + "earnings"}
                  height="200px"
                  justifyContent="center"
                  alignItems="center"
                >
                  <Tooltip title={val.earnings + " / 50000"}>
                    {moEarningsGraph(val.month, val.progress)}
                  </Tooltip>
                </Box>
              ))}
            </Box>
            <Typography fontSize="20px" fontWeight={700} textAlign="center">
              Total - {totalEarnings}
            </Typography>
          </Box>

          <Box
            className="mb10-600px width50 setwidth100 ml20 ml0"
            display="flex"
            flexDirection="column"
            alignItems="center"
            height="350px"
            overflow="auto"
            sx={{
              background: colors.primary[400],
              padding: 2,
              cursor: "pointer",
              maxWidth: "100%", // Set maximum width to 50%
              maxHeight: "305px",
            }}
            borderRadius="10px"
          >
            <Typography
              fontSize="20px"
              fontWeight="700"
              marginTop="5px"
              textAlign="center"
            >
              Suggestions
            </Typography>
            <Box
              display="flex"
              flexDirection="column"
              overflow="scroll"
              height="100%"
              width="100%"
            >
              {suggestions.map((val, index) => (
                <Box
                  key={val.name + index}
                  display="flex"
                  flexDirection="column"
                >
                  <Box
                    display="flex"
                    justifyContent="space-between"
                    margin="0 10px"
                    marginTop="5px"
                  >
                    <Typography
                      backgroundColor="green"
                      display="flex"
                      alignItems="center"
                      height="80%"
                      borderRadius="5px"
                      padding="1px 10px"
                      fontWeight="600"
                      color="white"
                    >
                      {val.name}
                    </Typography>
                    <Box display="flex" flexDirection="column" alignItems="end">
                      <Typography>{val.mobile}</Typography>
                      <Tooltip title="Delete this suggestion">
                        <Typography
                          onClick={() => {
                            writedb.ref(`suggestions/${val.key}`).remove();
                          }}
                          sx={{ cursor: "pointer", userSelect: "none" }}
                        >
                          Delete
                        </Typography>
                      </Tooltip>
                    </Box>
                  </Box>
                  <Typography margin="3px 10px">{val.desc}</Typography>
                </Box>
              ))}
            </Box>
          </Box>
        </Box>

        <Box className="flex-col-600px flex-row" mt="20px" mb="100px">
          <Box
            className="mb10-600px width50 setwidth100"
            display="flex"
            flexDirection="column"
            alignItems="center"
            overflow="auto"
            maxHeight="200px"
            sx={{
              background: colors.primary[400],
              padding: 2,
              cursor: "pointer",
              maxWidth: "100%", // Set maximum width to 50%
            }}
            borderRadius="10px"
          >
            <Link
              to="/banner"
              style={{ color: "inherit", textDecoration: "none" }}
            >
              <Typography
                fontSize="30px"
                fontWeight={700}
                textAlign="center"
                marginBottom="10px"
              >
                Home
              </Typography>
            </Link>
            {/* Image List */}
            <Box
              display="flex"
              flexDirection="row"
              justifyContent="center"
              alignItems="center"
              flexWrap="wrap"
            >
              {bannerimageElements}
            </Box>
          </Box>

          <Box
            className="mb10-600px width50 setwidth100 ml20 ml0"
            display="flex"
            flexDirection="column"
            alignItems="center"
            overflow="auto"
            maxHeight="200px"
            sx={{
              background: colors.primary[400],
              padding: 2,
              cursor: "pointer",
              maxWidth: "100%", // Set maximum width to 50%
            }}
            borderRadius="10px"
          >
            <Link
              to="/videobanner"
              style={{ color: "inherit", textDecoration: "none" }}
            >
              <Typography
                fontSize="30px"
                fontWeight={700}
                textAlign="center"
                marginBottom="10px"
              >
                Video
              </Typography>
            </Link>
            {/* Image List */}
            <Box
              display="flex"
              flexDirection="row"
              justifyContent="center"
              alignItems="center"
              flexWrap="wrap"
            >
              {bannerimageElementsForVideo}
            </Box>
          </Box>

          <Box
            className="mb10-600px width50 setwidth100 ml20 ml0"
            display="flex"
            flexDirection="column"
            alignItems="center"
            overflow="auto"
            maxHeight="200px"
            sx={{
              background: colors.primary[400],
              padding: 2,
              cursor: "pointer",
              maxWidth: "100%", // Set maximum width to 50%
            }}
            borderRadius="10px"
          >
            <Link
              to="/genbanner"
              style={{ color: "inherit", textDecoration: "none" }}
            >
              <Typography
                fontSize="30px"
                fontWeight={700}
                textAlign="center"
                marginBottom="10px"
              >
                General
              </Typography>
            </Link>
            {/* Image List */}
            <Box
              display="flex"
              flexDirection="row"
              justifyContent="center"
              alignItems="center"
              flexWrap="wrap"
            >
              {bannerimageElementsForGeneral}
            </Box>
          </Box>

          <Box
            className="mb10-600px width50 setwidth100 ml20 ml0"
            display="flex"
            flexDirection="column"
            alignItems="center"
            overflow="auto"
            maxHeight="200px"
            sx={{
              background: colors.primary[400],
              padding: 2,
              cursor: "pointer",
              maxWidth: "100%", // Set maximum width to 50%
            }}
            borderRadius="10px"
          >
            <Link
              to="/menubanner"
              style={{ color: "inherit", textDecoration: "none" }}
            >
              <Typography
                fontSize="30px"
                fontWeight={700}
                textAlign="center"
                marginBottom="10px"
              >
                Menu
              </Typography>
            </Link>
            {/* Image List */}
            <Box
              display="flex"
              flexDirection="row"
              justifyContent="center"
              alignItems="center"
              flexWrap="wrap"
            >
              {bannerimageElementsForMenu}
            </Box>
          </Box>
        </Box>

        {/* Feedback */}
      </Box>
    </Box>
  );
};

export default Dashboard;
