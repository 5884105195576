import { AddCircleOutlineRounded, CloseRounded } from '@mui/icons-material';
import { Alert, Button, CircularProgress, List, ListItem, ListItemText, TextField, Typography } from '@mui/material';
import { Box, useTheme } from '@mui/system'
import { onValue, ref } from 'firebase/database';
import React from 'react'
import { useMemo } from 'react';
import { useRef } from 'react';
import { useEffect } from 'react';
import { useState } from 'react';
import { database, storage, writedb } from '../../firebase-config';
import { tokens } from '../../theme';

const MultipleImagesUpload = (props) => {
    const stickerDisplay = props.boxDisplay;
    const parent = props.parent;
    const theme = useTheme();
    const colors = tokens(theme.palette.mode);
    const pathAddress = props.path;
    const [stickerCatList, setStickerCatList] = useState([]);
    const [displayAddNew, setDisplayAddNew] = useState(false);
    const [stickerCatName, setStickerCatName] = useState("");
    const imageInput = useRef(null);
    const [pathSelected, setPathSelected] = useState(false);
    const [uploadSticker, setUploadSticker] = useState(false);
    const [imageCount,setImageCount] = useState(0);
    const [selectImages,setSelectImages] = useState(true);
    const [uniquekeyAddress,setUniquekeyAddress] = useState("");
    const [imageList,setImageList] = useState([]);
    async function loadList(address){

        var path = address
        let snap = await writedb.ref(path).once('value');
        
        if (snap.exists()) {
            let keys = Object.keys(snap.val());
            let values = Object.values(snap.val());
            let arr = [];
            keys.forEach((name, index) => {
                let key = Object.values(values[index])[0];
                arr.push({ key:key , name: name, color: "white" });
            })
     
            setStickerCatList(arr);
        }

    }


    function selectStickers() {
        setPathSelected(true);
    }

    const uploadStickers = async (loc,images) => {
        setUploadSticker(true);
        writedb.ref(parent + stickerCatName).once('value').then((snapshot) => {
            
            images.forEach(async (val, index) => {
                let key = writedb.ref().push().key;
                let path = `${parent}/${loc}` ;
                await storage.ref(path).child(key).put(val).then(() => {
                    storage.ref(path).child(key).getDownloadURL().then((url) => {
                        writedb.ref(path).child(key).set({image:url}).then(() => {
                            
                            if (images.length == index + 1) {
                                alert("All Images Uploaded")
                                setUploadSticker(false);
                                stickerDisplay();
                            }
                        });
                    })
                })
            })

        })
    }

    const uploadFrames = async (loc,images) => {
        setUploadSticker(true);
        images.forEach(async (val, index) => {
            let key = writedb.ref(loc).push().key;
            let path = loc ;
            await storage.ref(path).child(key).put(val).then(() => {
                storage.ref(path).child(key).getDownloadURL().then((url) => {
                    writedb.ref(path).child(key).set({image:url}).then(() => {
                        if (images.length == index + 1) {
                            alert("All Images Uploaded")
                            setUploadSticker(false);
                            stickerDisplay();
                        }
                    });
                })
            })
        })

        
    }

    const handleImageSelect = (event) => {
        if (event.target.files[0]) {
            const files = event.target.files;
            // uploadStickers(Array.from(files));
            setSelectImages(false);
            setImageList(Array.from(files));
            
            if (pathAddress.length > 0){
                if (pathAddress === "images/staticFrames" || pathAddress === "images/staticFramesVideo"){
                    uploadFrames(pathAddress,Array.from(files))
                }else{
                    uploadStickers(pathAddress,Array.from(files))
                }
            }else{
                if (parent === "testimonials"){
                    loadList("testimonialsUniqueKeys")
                    setUniquekeyAddress("testimonialsUniqueKeys");
                }
                else if (parent === "wallPoster"){
                    loadList("wallPosterUniqueKeys")
                    setUniquekeyAddress("wallPosterUniqueKeys");
                }
                else if (parent === "combos"){
                    loadList("combosUniqueKeys")
                    setUniquekeyAddress("combosUniqueKeys");
                }
                else if (parent === "events"){
                    loadList("eventsUniqueKeys")
                    setUniquekeyAddress("eventsUniqueKeys");
                }
                else if (parent === "brands"){
                    loadList("brandsUniqueKeys")
                    setUniquekeyAddress("brandsUniqueKeys");
                }
                else if (parent === "stickers"){
                    loadList("stickersUniqueKeys")
                    setUniquekeyAddress("stickersUniqueKeys");
                }
                else if (parent === "background"){
                    loadList("backgroundUniqueKeys")
                    setUniquekeyAddress("backgroundUniqueKeys");
                }
                else if (parent === "foodImages"){
                    loadList("foodImagesUniqueKeys")
                    setUniquekeyAddress("foodImagesUniqueKeys");
                }
            }
            

        } else {
            alert("Image not selected")
        }

    }


    const inputStyle = {
        '& label.Mui-focused': {
            color: 'aqua',
        },
        '& .MuiInput-underline:after': {
            borderBottomColor: 'white',
        },
        '& .MuiOutlinedInput-root': {
            '& fieldset': {
                borderColor: 'gray',
            },
            '&:hover fieldset': {
                borderColor: 'white',
            },
            '&.Mui-focused fieldset': {
                borderColor: 'rgb(155,155,255)',
            }, '& .MuiInputBase-input': {
                color: 'black',
                fontSize: "16px",

            },
        }
    }

    async function addNewCategory(){
        let name = stickerCatName;
        writedb.ref(`${uniquekeyAddress}/${name}`).once('value').then((snapshot) => {
            if (snapshot.exists()){
                alert("it is already exists")
            }else{
                let key = writedb.ref(parent).push().key;
                writedb.ref(`${uniquekeyAddress}/${name}`).set({key:key}).then(() => {
                    let path = `${key}/${name}`
                    uploadStickers(path,imageList);
                })
            }
        })
        
    }

    

    return (
        <Box display="flex" position="absolute" top="20%" bottom="20%" left="30%" right="30%" height="400px" width="700px" backgroundColor="#727681" zIndex="20" borderRadius='10px' justifyContent="center" >

            <Box position="absolute" right="-10px" top="0" display="flex" zIndex="100">
                <Button onClick={stickerDisplay} ><CloseRounded sx={{
                    color: colors.primary[100], '&:hover': {
                        color: 'lightgreen'
                    }
                }} /></Button>
            </Box>
            <input accept="image/*" type="file" onChange={handleImageSelect} multiple ref={imageInput} style={{ display: "none" }} />


            <Box display={selectImages? "flex" : "none"} >
                <Box display="inline-flex" alignItems="center" flexDirection="column" justifyContent="center">
                    <Button sx={{ bgcolor: "blue", color: "white", borderRadius: "20px" }} onClick={() => { imageInput.current.click() }}>
                        <Typography display="flex" alignItems="center" fontSize="15px"><AddCircleOutlineRounded sx={{ marginRight: "5px" }} />Select Images</Typography>
                    </Button>
                </Box>

            </Box>


            <Box display={selectImages? "flex" : "none"} position="absolute" bottom="0" marginBottom="20px" justifyContent="Center">
                <Typography fontSize="16px">Note: You Can Select Multiple Images At Once</Typography>
            </Box>




            <Box display={displayAddNew && !selectImages && !uploadSticker? "flex" : "none"} alignItems="center" flexDirection="column" justifyContent="center">
                <Box display="flex">
                    <TextField autoFocus autoComplete="off" label="Name" value={stickerCatName} onChange={(event) => {
                        setStickerCatName(event.target.value.charAt(0).toUpperCase() + event.target.value.slice(1))
                    }} sx={inputStyle} />
                </Box>
                <Box display="inline-flex" sx={{ backgroundColor: stickerCatName.length > 0 ? "blue" : "rgb(255,255,255,0.23)" }} borderRadius="20px" mt="20px">
                    <Button disabled={stickerCatName.length > 0 ? false : true} onClick={addNewCategory}>
                        <Typography textTransform="none" color="white" fontSize="16px">Next</Typography>
                    </Button>
                </Box>
            </Box>

            <Box display={!selectImages && !displayAddNew && !uploadSticker? "flex":"none"} flexDirection="column" alignItems="center">
                <Box display="flex" width="600px" height="80%" marginTop="6%" justifyContent="center" alignItems="center" flexDirection="column" backgroundColor={colors.primary[500]} borderRadius="20px">
                    <Box display="flex" marginBottom="20px">
                        <Typography color="white">Where?</Typography>
                    </Box>
                    <Box width="350px" height="250px" overflow="auto" >
                        <List >
                            {
                                stickerCatList.map((val, index) => (
                                    <ListItem key={val.key} sx={{ '&:hover': { background: "#3b9a8c", userSelect: "none" } }} onClick={() => { uploadStickers(`${val.key}/${val.name}`,imageList) }}>
                                        <ListItemText primary={val.name} sx={{ color: "white" }} />
                                    </ListItem>
                                ))
                            }
                        </List>
                    </Box>
                </Box>

                <Box display="flex" mt="5px" onClick={() => setDisplayAddNew(!displayAddNew)}>
                    <Button><Typography >Add New type</Typography></Button>
                </Box>
            </Box>

            <Box display={uploadSticker?"flex":"none"} justifyContent="center" flexDirection="column" alignItems="center">
                <Typography >Uploading Images...</Typography>
                <CircularProgress sx={{padding:"7px",mt:"10px"}}/>
            </Box>
        </Box>
    )
}
export default MultipleImagesUpload;