import { Box, useTheme } from "@mui/material";
import React, { useMemo } from "react";
import ReactApexChart from "react-apexcharts";
import { tokens } from "../../theme";
import { onValue, ref } from "firebase/database";
import { database, writedb } from "../../firebase-config";
import { useState } from "react";

const NotificationClicks = () => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const Color = theme.palette.mode === "dark" ? "white" : "black"
  const darkMode = theme.palette.mode === "dark" ? true : false
  const [clicks,setClicks] = useState(0);

  function isWithinFiveMinutes(timestamp) {
    const fiveMinutesInMillis = 5 * 60 * 1000; // 5 minutes in milliseconds
    const currentTime = new Date().getTime(); // Get the current time in milliseconds
    return currentTime - timestamp >= fiveMinutesInMillis;
  }

  useMemo(()=>{
    onValue(ref(database,"analytics/notificationClicks/total"), (snapshot) => {
      if (snapshot?.exists()) {
        
        setClicks(snapshot.val())
      }else{
        setClicks(0);
      }
    })
  },[clicks])

  const chartData = {
    series: [clicks],
    options: {
      chart: {
        height: 350,
        type: 'radialBar',
        toolbar: {
          show: false,
        },
      },
      plotOptions: {
        radialBar: {
          startAngle: -135,
          endAngle: 225,
          hollow: {
            margin: 0,
            size: '80%',
            background: darkMode ? "#fff" : colors.primary[400],
            image: undefined,
            imageOffsetX: 0,
            imageOffsetY: 0,
            position: 'front',
            dropShadow: {
              enabled: true,
              top: 3,
              left: 0,
              blur: 4,
              opacity: 0.24,
            },
          },
          track: {
            background: '#fff',
            strokeWidth: '67%',
            margin: 0,
            dropShadow: {
              enabled: true,
              top: -3,
              left: 0,
              blur: 4,
              opacity: 0.35,
            },
          },
          dataLabels: {
            show: true,
            name: {
              offsetY: -10,
              show: true,
              color: '#888',
              fontSize: '17px',
            },
            value: {
              formatter: function (val) {
                return parseInt(val);
              },
              color: '#111',
              fontSize: '36px',
              show: true,
            },
          },
        },
      },
      fill: {
        type: 'gradient',
        gradient: {
          shade: 'dark',
          type: 'horizontal',
          shadeIntensity: 0.5,
          gradientToColors: darkMode ? ['#ABE5A1'] : [colors.redAccent[400]],
          inverseColors: true,
          opacityFrom: 1,
          opacityTo: 1,
          stops: [0, 100],
        },
      },
      stroke: {
        lineCap: 'round',
      },
      labels: ['Notification Clicks'],
    },
  };

  return (
    <Box id="chart"  mt="-10px">
      <ReactApexChart options={chartData.options} series={chartData.series} type="radialBar" height={chartData.options.chart.height} />
    </Box>
  );
};

export default NotificationClicks;













