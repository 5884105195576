import { Box, Button, Typography, useTheme } from "@mui/material";
import React, { useState } from "react";
import { database, writedb } from "../../firebase-config";
import { onValue, ref } from "firebase/database";
import { useMemo } from "react";
import { tokens } from "../../theme";
import Header from "../../components/Header";
import CuisineChart from "../charts/cuisineChart";
import ActiveUserChart from "../charts/activeUserschart";
import FreePremiumUserChart from "../charts/freePremiumUsersChart";
import NotificationClicks from "../charts/notificationClicks";
import { Link } from "react-router-dom";
const Analytics = () => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const [_userWhoSelectedCuisineCounting, _setUserWhoSelectedCuisineCounting] =
    useState(0);
  const [
    _userWhoSelectedBusinessCounting,
    _setUserWhoSelectedBusinessCounting,
  ] = useState(0);
  const [_totalUsers, _setTotalUsers] = useState(0);
  const [_imageDownloads, _setImageDownloads] = useState(0);
  const [_videoDownloads, _setVideoDownloads] = useState(0);
  const [usersWhoUsedAppAfterTriral, setUsersWhoUsedAppAfterTriral] =
    useState(0);

  let totalCats = [];

  

  useMemo(() => {
    onValue(ref(database, "analytics/cuisineSelectedUsers"), (snapshot) => {
      if (snapshot.exists()) {
        _setUserWhoSelectedCuisineCounting(snapshot.val().total);
      }
    });
    onValue(ref(database, "analytics/businessAddedUsers"), (snapshot) => {
      if (snapshot.exists()) {
        _setUserWhoSelectedBusinessCounting(snapshot.val().total);
      }
    });
    onValue(ref(database, "userUniqueKeys"), (snapshot) => {
      if (snapshot.exists()) {
        _setTotalUsers(snapshot.size);
      }
    });

    onValue(ref(database, "analytics/imageDownloads/total"), (snapshot) => {
      if (snapshot.exists()) {
        _setImageDownloads(snapshot.val());
      }
    });
    onValue(ref(database, "analytics/videoDownloads/total"), (snapshot) => {
      if (snapshot.exists()) {
        _setVideoDownloads(snapshot.val());
      }
    });

    const trialPlanCheckQuery = writedb
      .ref("users")
      .orderByChild("trialPlanExpired/PlanDetails/selectedPlan")
      .equalTo("Brand Bite trial plan");
    trialPlanCheckQuery.on("value", (snapshot) => {
      if (snapshot.exists()) {
        let snapVal = snapshot.val();
        let count = Object.keys(snapVal).length;
        setUsersWhoUsedAppAfterTriral(count);
      } else {
        setUsersWhoUsedAppAfterTriral(0);
      }
    });
  }, [_userWhoSelectedCuisineCounting]);

  const analyticalTextCard = (total, outof, slogan, className) => {
    return (
      <Box
        display="flex"
        flexDirection="column"
        justifyContent="center"
        alignItems="center"
        sx={{ background: colors.primary[400], padding: 2 }}
        borderRadius="10px"
        className={className}
      >
        <Typography fontSize="20px">
          <span style={{ fontWeight: "bold" }}>{total}</span> out of{" "}
          <span style={{ fontWeight: "bold" }}>{outof}</span> users
        </Typography>
        <Typography fontSize="18px">{slogan}</Typography>
      </Box>
    );
  };

  const analyticalTextCard2 = (total, slogan, className) => {
    return (
      <Box
        className={`${className}`}
        display="flex"
        flexDirection="column"
        justifyContent="center"
        alignItems="center"
        sx={{ background: colors.primary[400], padding: 2, cursor: "pointer" }}
        borderRadius="10px"
      >
        <Typography fontSize="20px" textTransform="none">
          <span style={{ fontWeight: "bold" }}>{total}</span>{" "}
        </Typography>
        <Typography fontSize="18px" textTransform="none">
          {slogan}
        </Typography>
      </Box>
    );
  };
  const linkStyle = {
    textDecoration: 'none', // Remove underlines
    color: 'inherit', // Inherit the color from parent elements
  }

  return (
    <Box display="flex" flexDirection="column" m="20px" maxWidth="100vw">
      <Box display="flex" justifyContent="space-between" alignItems="center">
        <Header title="Analytics" subtitle="Welcome to Brand Bite" />
      </Box>
      <Box className="flex-col-600px flex-row" width="100%" >
        {analyticalTextCard(
          _userWhoSelectedCuisineCounting,
          _totalUsers,
          "Selected Cuisines",
          "mb10-600px width50 setwidth100"
        )}
        {analyticalTextCard(
          _userWhoSelectedBusinessCounting,
          _totalUsers,
          "Added Business",
          "mb10-600px width50 setwidth100 ml20 ml0"
        )}
        {analyticalTextCard(
          usersWhoUsedAppAfterTriral,
          _totalUsers,
          "Appeared after trial ends",
          "mb10-600px width50 setwidth100 ml20 ml0"
        )}
      </Box>
      <Box className="flex-col-600px flex-row mt20" width="100%">

        <Link to="/downloads" className="mb10-600px width50 setwidth100" style={linkStyle}>
          <Box
            display="flex"
            flexDirection="column"
            justifyContent="center"
            alignItems="center"
            
            sx={{
              background: colors.primary[400],
              padding: 2,
              cursor: "pointer",
              borderRadius: "10px",
            }}
          >
            <Typography fontSize="20px" textTransform="none">
              <span style={{ fontWeight: "bold" }}>{_imageDownloads}</span>
            </Typography>
            <Typography fontSize="18px" textTransform="none">
              Image Downloads
            </Typography>
          </Box>
        </Link>

        {/* {analyticalTextCard2(
          _videoDownloads,
          "Video Downloads",
          "mb10-600px width50 setwidth100 ml20 ml0"
        )} */}
        <Link to="/videodownloads" className="mb10-600px width50 setwidth100 ml20 m10" style={linkStyle}>
          <Box
            display="flex"
            flexDirection="column"
            justifyContent="center"
            alignItems="center"
            
            sx={{
              background: colors.primary[400],
              padding: 2,
              cursor: "pointer",
              borderRadius: "10px",
            }}
          >
            <Typography fontSize="20px" textTransform="none">
              <span style={{ fontWeight: "bold" }}>{_videoDownloads}</span>
            </Typography>
            <Typography fontSize="18px" textTransform="none">
              Video Downloads
            </Typography>
          </Box>
        </Link>
      </Box>

      <Box display="flex" justifyContent="end" mt="10px" mb="-10px" mr="10px">
        <Typography fontSize="20px">Cuisine Data</Typography>
      </Box>
      <CuisineChart />
      <Box className="flex-row flex-col-600px width100">
        <ActiveUserChart />
        <Box className="ml20 ml0">
          <NotificationClicks />
        </Box>
        <Box
          display="flex"
          justifyContent="center"
          alignItems="center"
          className="ml20 ml0"
        >
          <FreePremiumUserChart />
        </Box>
      </Box>
    </Box>
  );
};

export default Analytics;



// this block of codes is used to align user data and analytics dont delete it because it is used anytime in future
  // const usersWhoSelectedCUisinesScript = async () => {
  //   await writedb
  //     .ref("users")
  //     .once("value")
  //     .then((snapshot) => {
  //       let data = snapshot.val();
  //       let numbers = Object.keys(data);
  //       let values = Object.values(data);
  //       let count = 0;
  //       values.forEach((val, index) => {
  //         if (val.hasOwnProperty("preferences")) {
  //           count++;
  //         }
  //       });
  //       _setUserWhoSelectedCuisineCounting(count);
  //       writedb
  //         .ref("analytics")
  //         .child("cuisineSelectedUsers")
  //         .set({ total: count });
  //     });
      
  // };
  // const getPrefCount = async () => {
  //     await writedb
  //       .ref("users")
  //       .once("value")
  //       .then((snapshot) => {
  //         let data = snapshot.val();
  //         let numbers = Object.keys(data);
  //         let values = Object.values(data);
  //         values.forEach((val, index) => {
  //           if (val.hasOwnProperty("preferences")) {
  //             if (val.preferences.hasOwnProperty("cuisineTypes")){
  //               let arr = val.preferences.cuisineTypes;
  //               arr.forEach((catKey,index) => {

  //                 if (totalCats.length < 1){
  //                   let item = {key:catKey,count:1};
  //                   totalCats.push(item);
  //                 }else{
  //                  let av = false;
  //                   totalCats.forEach((val,index) => {
  //                     if (val.key === catKey){
  //                       totalCats[index].count++;
  //                       av = true;
  //                     }
  //                   })
  //                   if (!av){
  //                     let item = {key:catKey,count:1};
  //                     totalCats.push(item);
  //                   }
  //                 }
  //               })
  //             }
  //           }
  //         });
  //         totalCats.forEach(async (val,index) => {
  //           await writedb.ref("analytics").child("cuisineUsage").child(val.key).set({count:val.count})
  //         });
  //       });
  //       alert("okay");
  //   };
  // const businessCount = async () => {
  //   await writedb
  //     .ref("users")
  //     .once("value")
  //     .then((snapshot) => {
  //       let data = snapshot.val();
  //       let numbers = Object.keys(data);
  //       let values = Object.values(data);
  //       let count = 0;
  //       values.forEach((val, index) => {
  //         if (val.hasOwnProperty("business")) {
  //           count++;
  //         }
  //       });
  //       _setUserWhoSelectedBusinessCounting(count);
  //       writedb
  //         .ref("analytics")
  //         .child("businessAddedUsers")
  //         .set({ total: count });
  //     });
  //     alert("okay");
  // };
  // Ends
