import { Avatar, Box, Button, capitalize, ImageList, Input, List, ListItem, ListItemText, MenuItem, Select, Slide, Slider, TextField, Typography, useTheme, CircularProgress } from "@mui/material";
import { tokens } from "../../theme";
import React, { useMemo, useState } from "react";
import { AddCircleOutline, CloseRounded, FormatBoldRounded, FormatItalicRounded, FormatUnderlined } from "@mui/icons-material";
import { useRef } from "react";
import NewImage from "./newImage";
import { storage, writedb } from "../../firebase-config"
import { ref, uploadBytes } from "firebase/storage"
import { ref as dbref, set as dbset, child, push } from "firebase/database";
import * as htmlToImage from 'html-to-image';
import { toPng, toJpeg, toBlob, toPixelData, toSvg } from 'html-to-image';
import html2canvas from 'html2canvas';
import { async } from "@firebase/util";
import NewText from "./newText";
import { current } from "@reduxjs/toolkit";

const AddMultipleImages = (props) => { //Cat display is used to close add category window from close button
    const catDis = props.catDis;
    const myKey = props.myKey;
    const myName = props.myName;
    const parent = props.parent;
    const outGen = props.address;
    const theme = useTheme();
    const colors = tokens(theme.palette.mode);
    const [selectImage, setSelectImage] = useState(false);
    const fileInput = useRef(null);
    const [imageList, setImageList] = useState([]);
    const [mainBoxArray, setmainBoxArray] = useState([]); // This array is used to store movable images inside server
    const [textMainArray, setTextMainArray] = useState([]); // This array is used to store editable text in server...
    const [background, setBackground] = useState([]);
    const [categoryName, setCatName] = useState(props.myName);
    const [letsupload, setletsupload] = useState(false)
    const [vegNonVeg, setVegNonVeg] = useState("");
    const [foodTypeSel, setFoodTypeSel] = useState(false);
    const [subCatName, setSubCatName] = useState("");
    const [previewImage, setPreviewImage] = useState("");
    const [showNewSubcatName, setShowNewSubCatName] = useState(false)
    const [subCatList, setSubCatList] = useState([]);
    const [searchLabels, setSearchLables] = useState(false);
    const [searchList, setSearchList] = useState([]);
    const [labelName, setLabelName] = useState("");
    const [imagesUploading,setImagesUploading] = useState(false);


    const handleFileSelect = (event) => {
        if (event.target.files[0]) {

            const files = event.target.files;
            const list = Array.from(files);
            setImageList(Array.from(files));
            // uploadToServer(list)
            setSelectImage(true);
            setSearchLables(true);
        } else {
            alert("Image Not selected")
        }
    };


    const addImageToServer = async (dataUrl, dbref, storageRef1) => {
        try {
            // const checkCategoryExistence = writedb.ref(`${parent}UniqueKeys/${catCus}/${categoryName}`).once('value').then((snapshot) => {
            //     return snapshot.exists();
            // })
            // let dbref;
            // if (checkCategoryExistence){
            //     dbref = writedb.ref(`${parent}/${catCus}/${categoryName}`);
            // }else{

            // }

            let i = 1;
            imageList.forEach(async image => {
                dbref = writedb.ref(`images/${outGen}`);
                storageRef1 = storage.ref(`images/${outGen}`);
                const pushref = dbref.push();
                const uniqueKey = pushref.key;
                const storageRef = storageRef1.child(uniqueKey);
                let path = `${parent}/${outGen}/${myKey}/${categoryName}/`
                let pushRef = writedb.ref(path).push();
                let imageCatKey = pushRef.key;
                const task = await storageRef.child("cover").put(image).then(
                    () => {
                        // complete function
                        // storageRef.child("background").getDownloadURL().then((url) => {
                        storageRef.child("cover").getDownloadURL().then((url) => {
                            const finalPath = dbref.child(uniqueKey);
                            const addBg = finalPath.child("cover")
                            addBg.set({ image: url }).then(async () => {
                                try {
                                    // await storageRef.child("cover").put(image).then(() => {
                                    //     storageRef.child("cover").getDownloadURL().then((url) => {
                                    //         const addCover = finalPath.child("cover");
                                    //         addCover.set({ image: url }).then(() => {

                                    //         })
                                    //     })
                                    // })
                                    await writedb.ref(path).child(imageCatKey).set({image:`images/${outGen}/${uniqueKey}`,thumbnailImage:url}).then(()=>{
                                        finalPath.child("paths").child(myKey).set({key:myKey}).then(() => {
                                            searchList.forEach(async txt => {
                                               await writedb.ref(`search/${txt}/${uniqueKey}`).set({image:`images/${outGen}/${uniqueKey}`}).then(()=>{
                                                finalPath.child("labels").child(txt).set({name:txt});
                                               })
                                            })
                                        })
                                    })

                                } catch (error) {
                                    console.error(error.toString());
                                }
                                if (i == imageList.length) {
                                    // resetAll();
                                    alert("All Images uploaded")
                                    catDis();
                                }
                                i++;
                            })
                        })
                    }
                );

            })


        } catch (error) {
            console.error(error)
        }
    }


    


    const uploadToServer = async (list) => {
        setImagesUploading(true);
        const dbref = writedb.ref(`${parent}/${outGen}/${myKey}/${categoryName}`);
        const storageRef = storage.ref(`${parent}/${outGen}/${myKey}/${categoryName}`);
        addImageToServer(dbref, storageRef, list)

    }



    function resetAll() {
        setmainBoxArray([]);
        setImageList([]);
        setSelectImage(false);
        setletsupload(false)
        setFoodTypeSel(false)
        setTextMainArray([])
        setBackground("");
    }

    const inputStyle = {
        '& label.Mui-focused': {
            color: 'aqua',
        },
        '& .MuiInput-underline:after': {
            borderBottomColor: 'white',
        },
        '& .MuiOutlinedInput-root': {
            '& fieldset': {
                borderColor: 'gray',
            },
            '&:hover fieldset': {
                borderColor: 'white',
            },
            '&.Mui-focused fieldset': {
                borderColor: 'rgb(155,155,255)',
            }, '& .MuiInputBase-input': {
                color: 'black',
                fontSize: "16px",

            },
        }
    }







    const handleClickOfInput = (input) => {
        if (input.current) {
            input.current.click();
        }
    };

    const addSearch = () => {
        searchList.push(labelName);
        if (labelName.includes(",")){
            let arr = labelName.split(",");
            arr.forEach(val => {
                searchList.push(val.trim());
            })
            
        }else{
            searchList.push(labelName);
        }
        setLabelName("");
    }

    return (
        <Box display="flex" position="absolute" top="20%" bottom="20%" left="30%" right="30%" height="400px" width="700px" backgroundColor="#727681" zIndex="20" borderRadius='10px' justifyContent="center" >

            {/* ############## category setup Screen 1 starts here ################ */}
            {/* This is close button of add category */}
            <Box position="absolute" right="0" top="0" display="flex" zIndex="100">
                <Button onClick={catDis} ><CloseRounded sx={{
                    color: colors.primary[100], '&:hover': {
                        color: 'lightgreen'
                    }
                }} /></Button>
            </Box>
            {/* This is close button of add category */}

            {/* This is for select image from files */}
            <Box display={selectImage  ? "none" : "flex"} height="400px" width="700px" alignItems="center" flexDirection="column" >

                <Typography fontSize="35px" fontWeight="700" marginTop="100px" marginBottom="20px">Add Image</Typography>

                <input accept="image/*" type="file" onChange={handleFileSelect} multiple ref={fileInput} style={{ display: "none" }} />
                <Box display="flex" justifyContent="center" alignItems="center" >
                    <Button onClick={() => {
                        handleClickOfInput(fileInput);
                    }}
                        sx={{ backgroundColor: "blue", borderRadius: "20px" }}><Typography fontSize="16px" textTransform="none" color="white" padding="5px" textAlign="center" justifyContent="center" display="flex" alignItems="center"><AddCircleOutline sx={{ marginRight: "8px" }} />Select Images</Typography></Button>
                </Box>
            </Box>
            {/* File select ends here */}



            {/* <Box display={foodTypeSel ? "flex" : "none"} height="100%" width="100%" justifyContent="center" alignItems="Center">
                <Box display="flex" bgcolor="blue" borderRadius="10px" mr="40px">
                    <Button
                        onClick={() => { <> {setFoodTypeSel(false)} {setVegNonVeg("veg")} {loadList("veg")} {setletsupload(true)}</> }}>
                        <Typography textTransform="none" color="white">Veg</Typography>
                    </Button>
                </Box>
                <Box display="flex" bgcolor="blue" borderRadius="10px">
                    <Button
                        onClick={() => { <> {setFoodTypeSel(false)} {setVegNonVeg("nonVeg")} {loadList("nonVeg")} {setletsupload(true)}</> }}>
                        <Typography textTransform="none" color="white">Non-Veg</Typography>
                    </Button>
                </Box>
            </Box>

            <Box display={showNewSubcatName ? "none" : foodTypeSel ? "none" : letsupload ? "flex" : "none"} width="600px" height="80%" marginTop="6%" justifyContent="center" alignItems="center" flexDirection="column" backgroundColor={colors.primary[500]} borderRadius="20px">

                <Box width="350px" height="250px" overflow="auto" >
                    <Box display="Flex" justifyContent="center">
                        <Typography>
                            Select Name
                        </Typography>
                    </Box>
                    <List>
                        {
                            subCatList.map((val, index) => (
                                <ListItem key={val} sx={{ '&:hover': { background: "#3b9a8c", userSelect: "none" } }} onClick={() => setSubCat(val)}>
                                    <ListItemText primary={val} sx={{ color: "white" }} />
                                </ListItem>
                            ))
                        }
                    </List>
                </Box>
                <Box>
                    <Button sx={{ color: "white" }} onClick={() => { setShowNewSubCatName(true) }}>
                        Add new name
                    </Button>
                </Box>
            </Box>

            <Box display={!foodTypeSel && showNewSubcatName ? "flex" : "none"} height="100%" width="100%" justifyContent="center" alignItems="center" flexDirection='column'>
                <TextField autoFocus autoComplete="off" label="Graphic Name?" value={subCatName} onChange={(event) => {
                    setSubCatName(event.target.value.charAt(0).toUpperCase() + event.target.value.slice(1))
                }} sx={inputStyle} />
                <Box mt="20px" bgcolor={subCatName.length > 0 ? "blue" : "rgb(255,255,255,0.23)"} borderRadius="10px">
                    <Button disabled={subCatName.length > 0 ? false : true} onClick={() => { uploadToServer() }}>
                        <Typography textTransform="none" color="white">Ready to Upload</Typography>
                    </Button>
                </Box>
            </Box> */}


            <Box display={searchLabels && !imagesUploading ? "flex" : "none"} height="100%" width="100%" justifyContent="center" alignItems="center" flexDirection='column'>
                <TextField autoFocus autoComplete="off" label="Enter Label?" value={labelName} onChange={(event) => {
                    setLabelName(event.target.value.charAt(0).toUpperCase() + event.target.value.slice(1))
                }} sx={inputStyle} />
                <Box display="flex">
                    <Box display="flex" mt="20px" bgcolor={labelName.length > 0 ? "blue" : "rgb(255,255,255,0.23)"} borderRadius="10px">
                        <Button disabled={labelName.length > 0 ? false : true} onClick={() => { addSearch() }}>
                            <Typography textTransform="none" color="white">Add Search Label</Typography>
                        </Button>
                    </Box>

                    <Box display="flex" ml="10px" mt="20px" bgcolor={searchList.length > 0 ? "blue" : "rgb(255,255,255,0.23)"} borderRadius="10px">
                        <Button disabled={searchList.length > 0 ? false : true} onClick={() => { uploadToServer() }}>
                            <Typography textTransform="none" color="white">Upload Images</Typography>
                        </Button>
                    </Box>
                </Box>

                <Box display={searchList.length > 0 ? "none" : "flex"} position="absolute" bottom="0" mb="10px">Add one label to continue</Box>
            </Box>

            <Box display={imagesUploading ? "flex" : "none"} justifyContent="center" flexDirection="column" alignItems="center">
                <Typography >Keep Patience Your Images Is Uploading</Typography>
                <CircularProgress sx={{ padding: "7px", mt: "10px" }} />
            </Box>



        </Box>
    );



};

export default AddMultipleImages;
