import { Avatar, Box } from "@mui/material";
import React, { useEffect, useMemo, useRef } from "react";
import { useState } from "react";
import { useDrag } from "react-use-gesture";

const NewImage = (props) => {
  const boxRef = useRef(null);
  const [posX, setPosX] = useState(1);
  const [posY, setPosY] = useState(1);
  const [isDragging, setIsDragging] = useState(false);
  const element = props.element[props.index];
  const [height, setHeight] = useState(50);
  const [width, setWidth] = useState(50);
  const checkImage = JSON.stringify(element.file);
  var image = checkImage.startsWith("\"https") ? element.file : URL.createObjectURL(element.file);
  let xpos = props.left ? props.left : 0;
  let ypos = props.top ? props.top : 0;
  const myleft = props.left ? props.left : 0
  const myTop = props.top ? props.top : 0

  const updateHW = () => {
    setTimeout(() => {
      setHeight(element.height);
      setWidth(element.width);
    }, 1);
  };

  useEffect(() => {
    updateHW();
  }, [element.height, element.width]);


  useEffect(() => {
    boxRef.current.style.left = `${element.left}px`;
    boxRef.current.style.top = `${element.top}px`;
  }, [element.top, element.left]);

  async function update() {
    setTimeout(() => {
      props.topLeft(
        props.index,
        parseInt(boxRef.current.style.top, 10),
        parseInt(parseInt(boxRef.current.style.left, 10)),
      );
    }, 100);
  }

  

  const handleMouseDown = (event) => {
    update();
    event.preventDefault();
    setIsDragging(true);
    document.addEventListener("mouseup", handleMouseUp);
    document.addEventListener("mousemove", handleMouseMove);
    update();
  };

  const handleMouseMove = async (event) => {
    if (isDragging) {
      requestAnimationFrame(() => {
        const x = parseInt(posX + event.movementX * 1.6);
        const y = parseInt(posY + event.movementY * 1.6);
        if (x < 295 - width * 3 && x >= 0) {
          setPosX(x);
          element.left = x;
        }
        if (y < 399 - height * 3 && y >= 0) {
          setPosY(y);
          element.top = y;
        }
      });
    } else {
      document.activeElement.blur();
    }
  };

  const handleMouseUp = (event) => {
    try{
    xpos = parseInt(boxRef.current.style.left, 10);
    ypos = parseInt(boxRef.current.style.top, 10);
    update();
    event.preventDefault();
    setIsDragging(false);
    document.removeEventListener("mouseup", handleMouseUp);
    document.removeEventListener("mousemove", handleMouseMove);
    // document.removeEventListener("keydown", handleKeyDown);
    props.topLeft(
      props.index,
      parseInt(boxRef.current.style.top, 10),
      parseInt(parseInt(boxRef.current.style.left, 10)),
    );
    update();
    }catch(e){}
  };

  const handleKeyDown = (event) => {
    updateHW();
  };

  useEffect(() => {
    document.addEventListener("keydown", handleKeyDown);
    return () => {
      document.removeEventListener("keydown", handleKeyDown);
    };
  }, [height, width]);

  const bind = useDrag(
    ({ offset: [dx, dy], down, movement: [mx, my] }) => {
      if (down) {
        const newLeft = Math.round(
          Math.min(Math.max(myleft + dx, 0), 288 - boxRef.current.clientWidth)
        );
        const newTop = Math.round(
          Math.min(Math.max(myTop + dy, 0), (props.myheight-2) - boxRef.current.clientHeight)
        );

        boxRef.current.style.left = `${newLeft}px`;
        boxRef.current.style.top = `${newTop}px`;

      }
    },
    {
      domTarget: boxRef,
      eventOptions: { passive: false },
    }
  );

  return (
    <Avatar
      ref={boxRef}
      key={element.file.name + element.index}
      variant="square"
      src={image}
      onMouseDown={handleMouseDown}
      onMouseUp={handleMouseUp}
      
      sx={{
        width: width + "%",
        height: height + "%",
        position: "absolute",
        top: element.top + "px",
        left: element.left + "px",
        cursor: isDragging ? "grabbing" : "grab",
        border: isDragging ? "0.5px solid white" : "none",
        "& > img": {
          objectFit: "contain",
          width: "100%",
          height: "100%",
        },
      }}
    />
  );
};

export default NewImage;
