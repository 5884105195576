import { Box, useTheme } from '@mui/material'
import React from 'react'
import { tokens } from '../theme';

const Background = () => {
    const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  return (
    <Box display="flex" position="fixed" width="200vw" height="200vh" sx={{ background: colors.primary[999] }}>

    </Box>
  )
}

export default Background
