import { initializeApp } from "firebase/app";
import {getAuth} from "firebase/auth";
import { getDatabase } from "firebase/database";
import { getStorage } from "firebase/storage";
import firebase from 'firebase/compat/app';
import 'firebase/compat/storage';
import 'firebase/compat/database';

const firebaseConfig = {
    apiKey: "AIzaSyByzc845Ve5RAQICc6fiWVQbKF-Xhe8ngg",
    authDomain: "apnapost-df169.firebaseapp.com",
    databaseURL: "https://apnapost-df169-default-rtdb.firebaseio.com",
    projectId: "apnapost-df169",
    storageBucket: "apnapost-df169.appspot.com",
    messagingSenderId: "767081979093",
    appId: "1:767081979093:web:a85743d9d1bef39e03565c",
    measurementId: "G-Y31WPFKRB8"
  };

  firebase.initializeApp(firebaseConfig);

  export const app = initializeApp(firebaseConfig);
  export const auth = getAuth(app);
  export const database = getDatabase(app);
  export const writedb = firebase.database();
  export const storage = firebase.storage();        
  