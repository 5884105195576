import {
  Box,
  Button,
  CircularProgress,
  List,
  ListItem,
  ListItemText,
  MenuItem,
  Select,
  TextField,
  Typography,
  useTheme,
} from "@mui/material";
import { tokens } from "../../theme";
import React, { useRef, useState } from "react";
import { AddCircleOutline, CloseRounded } from "@mui/icons-material";
import { onValue, ref } from "firebase/database";
import { database, storage, writedb } from "../../firebase-config";

const Banner = (props) => {
  //Cat display is used to close add category window from close button
  const theme = useTheme();
  const bannerDisp = props.bannerDisp;
  const address = props.address;
  const colors = tokens(theme.palette.mode);

  const [uploading, setUploading] = useState(false);
  const [name, setName] = useState("");
  const [targetName, setTargetName] = useState(""); //This is the subpart of text data
  const targetList = [
    { label: "Category", value: "Category" },
    { label: "Outlet", value: "Outlet" },
    { label: "Custom", value: "Custom" },
    { label: "Video", value: "Video" },
    { label: "General", value: "General" },
    { label: "Menu", value: "Menu" },
    { label: "Premium Screen", value: "PremiumScreen" },
  ];
  const [image, setImage] = useState("");
  const [imageSelected, setImageSelected] = useState(false);
  const imageInput = useRef(null);
  const [targetSelected, setTargetSelected] = useState(false);
  const [listNames, setListNames] = useState([]);

  const handleImageSelect = (event) => {
    if (event.target.files[0]) {
      const files = event.target.files;
      setImage(Array.from(files)[0]);
      setImageSelected(true);
    } else {
      alert("Image not selected");
    }
  };

  const loadData = (name) => {
    var path;
    if (name === "Category") {
      path = "homeUniquekeys/category";
    } else if (name === "Outlet") {
      path = "homeUniquekeys/outlet";
    } else if (name === "Custom") {
      path = "homeUniquekeys/custom";
    } else if (name == "Video") {
      path = "videoUniquekeys/category";
    } else if (name === "General") {
      path = "generalUniquekeys/categoryGeneral";
    } else if (name === "Menu") {
      path = "menuUniquekeys/categoryMenu";
    } else if (name === "premiumScreen") {
      console.info("vinay soni");
      setUploading(true);
      const target = "premiumScreen";
      const path = address;
      const key = writedb.ref(path).push().key;
      const finalPath = path + "/" + key;

      storage
        .ref(finalPath + "/image")
        .put(image)
        .then(() => {
          storage
            .ref(finalPath + "/image")
            .getDownloadURL()
            .then((url) => {
              writedb
                .ref(finalPath)
                .set({
                  name: "",
                  key: "",
                  trigger: target,
                  image: url,
                  name: "",
                })
                .then(() => {
                  alert("Banner Added Successfully");
                  bannerDisp();
                });
            });
        });
      return;
    }
    onValue(ref(database, path), (snapshot) => {
      listNames.length = 0;
      let names = [];
      console.warn(snapshot.val());
      snapshot.forEach((snapshot) => {
        const data = snapshot.val();
        const name = snapshot.key;
        const key = Object.values(data)[0];
        names.push({ key: key, name: name });
      });

      setListNames(names);
      // console.warn(names);
    });
  };

  const handleTargetChange = (event) => {
    setTargetName(event.target.value);
    if (event.target.value == "PremiumScreen") {
      setTargetName(event.target.value);
      setTargetSelected(true);
      addToServer({ key: "premiumscreen", name: "premiumscreen" },true);
      bannerDisp();
    } else {
      setTargetSelected(true);
      loadData(event.target.value);
    }
  };

  const inputStyle = {
    "& label.Mui-focused": {
      color: "aqua",
    },
    "& .MuiInput-underline:after": {
      borderBottomColor: "white",
    },
    "& .MuiOutlinedInput-root": {
      "& fieldset": {
        borderColor: "gray",
      },
      "&:hover fieldset": {
        borderColor: "white",
      },
      "&.Mui-focused fieldset": {
        borderColor: "rgb(155,155,255)",
      },
      "& .MuiInputBase-input": {
        color: "black",
        fontSize: "16px",
      },
    },
  };

  const addToServer = (data,premium) => {
    setUploading(true);
    const target = targetName.toLowerCase();
    const path = address;
    const key = writedb.ref(path).push().key;
    const finalPath = path + "/" + key;

    storage
      .ref(finalPath + "/image")
      .put(image)
      .then(() => {
        storage
          .ref(finalPath + "/image")
          .getDownloadURL()
          .then((url) => {
            writedb
              .ref(finalPath)
              .set({
                name: data.name,
                key: data.key,
                trigger: premium ? "premiumscreen" : target,
                image: url,
              })
              .then(() => {
                alert("Banner Added Successfully");
                bannerDisp();
              });
          });
      });
  };

  return (
    <Box
      display="flex"
      position="fixed"
      top="20%"
      bottom="20%"
      left="30%"
      right="30%"
      height="400px"
      width="700px"
      backgroundColor="#727681"
      zIndex="20"
      borderRadius="10px"
      justifyContent="center"
    >
      <Box position="absolute" right="0" top="0" display="flex" zIndex="100">
        <Button onClick={bannerDisp}>
          <CloseRounded
            sx={{
              color: colors.primary[100],
              "&:hover": {
                color: "lightgreen",
              },
            }}
          />
        </Button>
      </Box>

      <Box
        display={imageSelected ? "none" : "flex"}
        flexDirection="column"
        width="350px"
        borderRadius="10px"
        justifyContent="center"
        alignItems="center"
      >
        {/* <Box>
                    <TextField autoFocus autoComplete="off" label="Name" value={name} onChange={(event) => {
                        setName(event.target.value.charAt(0).toUpperCase() + event.target.value.slice(1))
                    }} sx={inputStyle} />
                </Box> */}
        <input
          accept="image/*"
          type="file"
          onChange={handleImageSelect}
          ref={imageInput}
          style={{ display: "none" }}
        />
        <Box
          display="flex"
          justifyContent="center"
          alignItems="center"
          marginTop="20px"
        >
          <Button
            onClick={() => {
              imageInput.current.click();
            }}
            sx={{ backgroundColor: "blue", borderRadius: "20px" }}
          >
            <Typography
              fontSize="16px"
              textTransform="none"
              color="white"
              padding="5px"
              textAlign="center"
              justifyContent="center"
              display="flex"
              alignItems="center"
            >
              <AddCircleOutline sx={{ marginRight: "8px" }} />
              Image
            </Typography>
          </Button>
        </Box>
      </Box>

      <Box
        display={imageSelected ? (targetSelected ? "none" : "flex") : "none"}
        height="100%"
        width="350px"
        alignItems="center"
        flexDirection="column"
      >
        <Box
          display="flex"
          justifyContent="center"
          alignItems="center"
          height="100%"
          flexDirection="column"
        >
          <Typography fontSize="20px" fontWeight="700" marginBottom="20px">
            Target
          </Typography>
          <Box display="flex" justifyContent="center" alignItems="center">
            <Select value={targetName} onChange={handleTargetChange}>
              {targetList.map((option) => (
                <MenuItem key={option.value} value={option.value}>
                  {option.label}
                </MenuItem>
              ))}
            </Select>
          </Box>
        </Box>
      </Box>

      <Box
        display={targetSelected && !uploading ? "flex" : "none"}
        width="600px"
        height="80%"
        marginTop="6%"
        justifyContent="center"
        alignItems="center"
        flexDirection="column"
        backgroundColor={colors.primary[500]}
        borderRadius="20px"
      >
        <Box display="flex" marginBottom="20px">
          <Typography color="white">Trigger {targetName}</Typography>
        </Box>
        <Box width="350px" height="250px" overflow="auto">
          <List>
            {listNames.map((val, index) => (
              <ListItem
                key={val.key}
                sx={{
                  "&:hover": { background: "#3b9a8c", userSelect: "none" },
                }}
                onClick={() => addToServer(val,false)}
              >
                <ListItemText primary={val.name} sx={{ color: "white" }} />
              </ListItem>
            ))}
          </List>
        </Box>
      </Box>

      <Box
        display={uploading ? "flex" : "none"}
        justifyContent="center"
        flexDirection="column"
        alignItems="center"
      >
        <Typography>Uploading</Typography>
        <CircularProgress sx={{ padding: "3px", mt: "15px" }} />
      </Box>
    </Box>
  );
};

export default Banner;
