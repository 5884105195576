import {
  Avatar,
  Box,
  Button,
  capitalize,
  CircularProgress,
  debounce,
  ImageList,
  Input,
  List,
  ListItem,
  ListItemText,
  MenuItem,
  Select,
  Slide,
  Slider,
  TextField,
  Typography,
  useTheme,
} from "@mui/material";
import { tokens } from "../../theme";
import React, { useCallback, useMemo, useState } from "react";
import {
  AddCircleOutline,
  CloseRounded,
  FormatAlignCenterRounded,
  FormatAlignLeft,
  FormatAlignLeftRounded,
  FormatAlignRightRounded,
  FormatBoldRounded,
  FormatItalicRounded,
  FormatUnderlined,
  Grid4x4Outlined,
} from "@mui/icons-material";
import { useRef } from "react";
import NewImage from "./newImage";
import { storage, writedb } from "../../firebase-config";
import { ref, uploadBytes } from "firebase/storage";
import { ref as dbref, set as dbset, child, push } from "firebase/database";
import * as htmlToImage from "html-to-image";
import { toPng, toJpeg, toBlob, toPixelData, toSvg } from "html-to-image";
import html2canvas from "html2canvas";
import { async } from "@firebase/util";
import NewText from "./newText";
import { current } from "@reduxjs/toolkit";
import { useEffect } from "react";
import fontFamilies from "../tools/fontFamilies";
import { useDrag } from "react-use-gesture";

const AddNewImage = (props) => {
  //Cat display is used to close add outlet window from close button
  const catDis = props.catDis;
  const [catList, setCatList] = useState([]);
  const myKey = props.myKey;
  const myName = props.myName;
  const parent = props.parent;
  const outGen = props.address;
  const [method, setMethod] = useState("");
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const [heightAvatar, setHeightAvatar] = useState(300);
  const [selectImage, setSeletImage] = useState(false);
  const [coverSelect, setCoverSelect] = useState(true);
  const fileInput = useRef(null);
  const bgInput = useRef(null);
  const [imageList, setImageList] = useState([]);
  const [mainBoxArray, setmainBoxArray] = useState([]); // This array is used to store movable images inside server
  const [textMainArray, setTextMainArray] = useState([]); // This array is used to store editable text in server...
  const [background, setBackground] = useState([]);
  const [outletName, setOutName] = useState(props.myName);
  const [currenSelection, setCurrSelection] = useState({
    height: 50,
    width: 50,
    left: 1,
    top: 1,
    index: 0,
  });
  const [catGen, setCatGen] = useState(!props.catCus ? props.catGen : props.catCus);
  const [selectedCatList, setSelctedCatList] = useState([]);
  const [showCategoryList, setShowCategoryList] = useState(false);
  const [height, setHeight] = useState(0);
  const [width, setWidth] = useState(0);
  const [top, setTop] = useState(0);
  const [left, setLeft] = useState(0);
  const [thisisEdit,setThisisEdit] = useState(false);
  const [catNameSel, setCatNameSel] = useState(false);
  const [showAddText, setShowAddText] = useState(false);
  const [addTextData, setAddTextData] = useState(""); // This is the text data we want to add in our main editing box
  const [fontFamily, setFontFamily] = useState("Montserrat"); //This is the subpart of text data
  const [fontSize, setFontSize] = useState(16); // ||
  const [fontWeight, setFontWeight] = useState(300); // ||
  const [fontGravity, setFontGravity] = useState("start"); // ||
  const [fontItalic, setFontItalic] = useState(false); // ||
  const [fontUnderline, setFontUnderline] = useState(false); // ||
  const [fontColor, setFontColor] = useState("#ffffff"); // ||
  const [isEditText, setIsEditText] = useState(false);
  const [editTextIndex, setEditTextIndex] = useState(); // Index of array where this text is located
  const [letsupload, setletsupload] = useState(false);
  const [vegNonVeg, setVegNonVeg] = useState("");
  const [foodTypeSel, setFoodTypeSel] = useState(false);
  const [subCatName, setSubCatName] = useState("");
  const [previewImage, setPreviewImage] = useState("");
  const [subCatList, setSubCatList] = useState([]);
  const [uploadImage, setUploadImage] = useState(false);
  const [searchLabels, setSearchLables] = useState(false);
  const [labelName, setLabelName] = useState("");
  const [imageKey, setImageKey] = useState("");
  const [searchAdding, setSearchAdding] = useState(false);
  const [generatingPreview, setGeneratingPreview] = useState(false);
  const [showGuide, setShowGuide] = useState(true);
  const catCus = props.address ? props.address : props.catGen;
  const hLineRef = useRef(null);
  const vLineRef = useRef(null);
  const v1LineRef = useRef(null);
  const h1LineRef = useRef(null);
  let vLineY = 137;
  const [lockLines, setLockLines] = useState(false);
  const textInputRef = useRef(null);

  const generateImage = async (element) => {
    const dataUrl = await htmlToImage.toPng(element);
    setPreviewImage(dataUrl);
    if (method !== "notselected") {
      console.info(method);
      if (method === "nocat") {
        setletsupload(true);
        if (parent === "home" || parent === "combos") {
          setFoodTypeSel(true);
        } else if (
          parent === "general" ||
          parent === "testimonials" ||
          parent === "events"
        ) {
          loadListForNoCat();
          // setFoodTypeSel(true);
        } else if (
          parent === "menu" ||
          parent === "wallposter" ||
          parent === "flyer"
        ) {
          loadListForNoCat();
        }
      }else if (method === "withcat"){
        
        if (catCus == "category"){
          setFoodTypeSel(true);
        }else if (catCus == "categoryCombos"){
          setFoodTypeSel(true);
        }
      }
    } else {
      uploadImageToServer(dataUrl);
    }
  }

  function verifyVegNonVeg(val){
    if (thisisEdit){
      setFoodTypeSel(false);
      setVegNonVeg(val);
      if (catCus == "categoryCombos"){
        uploadImageToServerForCombos(val);
      }else{
        uploadImageToServerForCat(val);
      }
      
    }else{
      setFoodTypeSel(false);
      setVegNonVeg(val);
      loadListForNoCat(val)
    }
  }

  const captureImage = () => {
    setGeneratingPreview(true);
    const element = document.getElementById("imageEditingBoxInAddCategory");
    generateImage(element);
  };

  useMemo(() => {
    if (parent === "menu" || parent === "wallposter") {
      setHeightAvatar(400);
    }
    if (!props.method) {
      setMethod("notselected");
    } else {
      setMethod(props.method);
    }
    if (props.thisisEdit){
      setThisisEdit(true);
    }
    if (!props.catCus){
      
    }
  }, [parent]);

  const handleFileSelect = (event) => {
    if (selectImage) {
      const files = event.target.files;
      const ilist = Array.from(files);
      let arr = [...imageList, ...ilist];
      setImageList(arr);
    } else {
      if (event.target.files[0]) {
        setSeletImage(true);
        setCatNameSel(true);
        const files = event.target.files;
        setImageList(Array.from(files));
        setBackground(Array.from(files)[0]);
      } else {
        alert("Image Not selected");
      }
    }
  };

  const bgSelect = (event) => {
    if (event.target.files[0]) {
      const files = event.target.files;
      setBackground(Array.from(files)[0]);
    } else {
      alert("Image Not selected");
    }
  };

  function addImageToMain(index) {
    setmainBoxArray([
      ...mainBoxArray,
      {
        file: imageList[index],
        top: 1,
        left: 1,
        height: 50,
        width: 50,
        index: mainBoxArray.length,
      },
    ]);
  }

  function resetText() {
    setAddTextData("");
    setFontFamily("Montserrat, sans-serif");
    setFontSize(16);
    setFontWeight(300);
    setFontItalic(false);
    setFontUnderline(false);
    setFontColor("#ffffff");
    setFontGravity("start");
  }

  function deleteText() {
    const newItems = textMainArray.filter((item, i) => i !== editTextIndex);
    setTextMainArray(newItems);
    resetEditText();
  }

  function addTextToMain() {
    if (isEditText) {
      const data = textMainArray[editTextIndex];
      data.text = addTextData;
      data.fontFamily = fontFamily;
      data.fontSize = fontSize;
      data.fontWeight = fontWeight;
      data.fontItalic = fontItalic;
      data.fontUnderline = fontUnderline;
      data.fontColor = fontColor;
      data.fontGravity = fontGravity;
      setTimeout(() => {
        setShowAddText(true);
      }, 5);
      setShowAddText(false);
    } else {
      if (addTextData.trim().length > 0) {
        setTextMainArray([
          ...textMainArray,
          {
            fontGravity: fontGravity,
            text: addTextData,
            top: 150,
            left: 150,
            height: 0,
            width: 0,
            index: textMainArray.length,
            fontFamily: fontFamily,
            fontSize: fontSize,
            fontWeight: fontWeight,
            fontItalic: fontItalic,
            fontUnderline: fontUnderline,
            fontColor: fontColor,
          },
        ]);
        resetText();
      }
    }
  }

  const addImageToServer = async (dataUrl, dbref, storageRef) => {
    try {
      // const checkCategoryExistence = writedb.ref(`homeUniqueKeys/category/${outletName}`).once('value').then((snapshot) => {
      //     return snapshot.exists();
      // })
      // let dbref;
      // if (checkCategoryExistence){
      //     dbref = writedb.ref(`${parent}/${outGen}/${outletName}`);
      // }else{

      // }
      const pushref = dbref.push();
      const uniqueKey = pushref.key;
      storageRef = storageRef.child(uniqueKey);
      const task = storageRef.child("background").put(background);
      const blob = await fetch(dataUrl).then((res) => res.blob()); // This is for convert image generated from html to blob for upload in firebase
      task.then(() => {
        // complete function
        storageRef
          .child("background")
          .getDownloadURL()
          .then((url) => {
            const finalPath = dbref.child(uniqueKey);
            const addBg = finalPath.child("background");
            addBg.set({ image: url }).then(() => {
              try {
                storageRef
                  .child("cover")
                  .put(blob)
                  .then(() => {
                    storageRef
                      .child("cover")
                      .getDownloadURL()
                      .then((url) => {
                        const addCover = finalPath.child("cover");
                        addCover
                          .set({ image: url, category: outletName })
                          .then(() => {
                            for (let i = 0; i < mainBoxArray.length; i++) {
                              const data = mainBoxArray[i];
                              const imageRef = storageRef.child(`i${i}`);
                              const imageUpload = imageRef
                                .put(data.file)
                                .then(() => {
                                  imageRef.getDownloadURL().then((url) => {
                                    const addImage = finalPath.child(`/i${i}`);
                                    addImage.set({
                                      image: url,
                                      height: parseInt(data.height),
                                      width: parseInt(data.width),
                                      top: parseInt(data.top),
                                      left: parseInt(data.left),
                                    });
                                  });
                                });
                            }
                            for (let i = 0; i < textMainArray.length; i++) {
                              const data = textMainArray[i];
                              let text = "";
                              data.text
                                .toString()
                                .split("\n")
                                .map((line, index) => {
                                  text = text + line + "\\n";
                                });
                              const addText = finalPath.child(`t${i}`);
                              addText.set({
                                fontGravity: data.fontGravity.toString(),
                                text: text,
                                fontFamily: data.fontFamily.toString(),
                                fontWeight: parseInt(data.fontWeight),
                                fontItalic: data.fontItalic.toString(),
                                fontUnderline: data.fontUnderline.toString(),
                                fontSize: parseInt(data.fontSize),
                                fontColor: data.fontColor.toString(),
                                top: parseInt(data.top),
                                left: parseInt(data.left),
                              });
                            }
                          });
                      });
                  });
              } catch (error) {
                console.error(error.toString());
              }
              resetAll();

              alert("All Graphics system uploaded");
            });
          });
      });
    } catch (error) {
      console.error(error);
    }
  };

  // const uploadToServer = async (dataUrl) => {
  //   const dbref = writedb.ref(`${parent}/${outGen}/${myKey}/${outletName}`);
  //   const storageRef = storage.ref(
  //     `${parent}/${outGen}/${myKey}/${outletName}`
  //   );
  //   addImageToServer(dataUrl, dbref, storageRef);
  // };

  const uploadImageToServer = async (previewImage) => {
    try {
      setUploadImage(true);
      const dbref = writedb.ref(`images/${outGen}`);
      let storageRef = storage.ref(`images/${outGen}`);
      const dataUrl = previewImage;
      const pushref = dbref.push();
      const uniqueKey = pushref.key;
      setImageKey(uniqueKey);
      storageRef = storageRef.child(uniqueKey);
      const task = storageRef.child("background").put(background);
      const blob = await fetch(dataUrl).then((res) => res.blob()); // This is for convert image generated from html to blob for upload in firebase
      task.then(() => {
        // complete function
        storageRef
          .child("background")
          .getDownloadURL()
          .then((url) => {
            const finalPath = dbref.child(uniqueKey);
            const addBg = finalPath.child("background");
            addBg.set({ image: url }).then(() => {
              try {
                storageRef
                  .child("cover")
                  .put(blob)
                  .then(() => {
                    storageRef
                      .child("cover")
                      .getDownloadURL()
                      .then((url) => {
                        const addCover = finalPath.child("cover");
                        addCover.set({ image: url }).then(async () => {
                          for (let i = 0; i < mainBoxArray.length; i++) {
                            const data = mainBoxArray[i];
                            const imageRef = storageRef.child(`i${i}`);
                            const imageUpload = imageRef
                              .put(data.file)
                              .then(() => {
                                imageRef.getDownloadURL().then((url) => {
                                  const addImage = finalPath.child(`/i${i}`);
                                  addImage.set({
                                    image: url,
                                    height: parseInt(data.height),
                                    width: parseInt(data.width),
                                    top: parseInt(data.top),
                                    left: parseInt(data.left),
                                  });
                                });
                              });
                          }
                          for (let i = 0; i < textMainArray.length; i++) {
                            const data = textMainArray[i];
                            let text = "";
                            data.text
                              .toString()
                              .split("\n")
                              .map((line, index) => {
                                text = text + line + "\\n";
                              });
                            const addText = finalPath.child(`t${i}`);
                            addText.set({
                              fontGravity: data.fontGravity,
                              text: text,
                              fontFamily: data.fontFamily.toString(),
                              fontWeight: parseInt(data.fontWeight),
                              fontItalic: data.fontItalic.toString(),
                              fontUnderline: data.fontUnderline.toString(),
                              fontSize: parseInt(data.fontSize),
                              fontColor: data.fontColor.toString(),
                              top: parseInt(data.top),
                              left: parseInt(data.left),
                            });
                          }

                          let path = `${parent}/${outGen}/${myKey}/${myName}`;
                          await writedb
                            .ref(path)
                            .push()
                            .set({
                              image: `images/${outGen}/${uniqueKey}`,
                              thumbnailImage: url,
                            })
                            .then(async () => {
                              await writedb
                                .ref(finalPath)
                                .child("paths")
                                .child(myKey)
                                .set({ key: myKey })
                                .then(() => {
                                  setSearchLables(true);
                                });
                            });
                        });
                      });
                  });
              } catch (error) {
                console.error(error.toString());
              }
              // resetAll();

              // alert("All Graphics system uploaded")
            });
          });
      });
    } catch (error) {
      console.error(error);
    }
  };

  const uploadImageToServerForCat = async (vegOrNonVeg) => {
    try {
      setUploadImage(true);
      const dbref = writedb.ref(`images/${catCus}`);
      let storageRef = storage.ref(`images/${catCus}`);
      const dataUrl = previewImage;
      const pushref = dbref.push();
      const uniqueKey = pushref.key;
      setImageKey(uniqueKey);
      storageRef = storageRef.child(uniqueKey);
      const task = storageRef.child("background").put(background);
      const blob = await fetch(dataUrl).then((res) => res.blob()); // This is for convert image generated from html to blob for upload in firebase
      task.then(() => {
        // complete function
        storageRef
          .child("background")
          .getDownloadURL()
          .then((url) => {
            const finalPath = dbref.child(uniqueKey);
            const addBg = finalPath.child("background");
            addBg.set({ image: url }).then(() => {
              try {
                storageRef
                  .child("cover")
                  .put(blob)
                  .then(() => {
                    storageRef
                      .child("cover")
                      .getDownloadURL()
                      .then((url) => {
                        const addCover = finalPath.child("cover");
                        addCover.set({ image: url }).then(async () => {
                          for (let i = 0; i < mainBoxArray.length; i++) {
                            const data = mainBoxArray[i];
                            const imageRef = storageRef.child(`i${i}`);
                            const imageUpload = imageRef
                              .put(data.file)
                              .then(() => {
                                imageRef.getDownloadURL().then((url) => {
                                  const addImage = finalPath.child(`/i${i}`);
                                  addImage.set({
                                    image: url,
                                    height: parseInt(data.height),
                                    width: parseInt(data.width),
                                    top: parseInt(data.top),
                                    left: parseInt(data.left),
                                  });
                                });
                              });
                          }
                          for (let i = 0; i < textMainArray.length; i++) {
                            const data = textMainArray[i];
                            let text = "";
                            data.text
                              .toString()
                              .split("\n")
                              .map((line, index) => {
                                text = text + line + "\\n";
                              });
                            const addText = finalPath.child(`t${i}`);
                            addText.set({
                              fontGravity: data.fontGravity,
                              text: text,
                              fontFamily: data.fontFamily.toString(),
                              fontWeight: parseInt(data.fontWeight),
                              fontItalic: data.fontItalic.toString(),
                              fontUnderline: data.fontUnderline.toString(),
                              fontSize: parseInt(data.fontSize),
                              fontColor: data.fontColor.toString(),
                              top: parseInt(data.top),
                              left: parseInt(data.left),
                            });
                          }

                          let path = `home/${catCus}/${myKey}/${myName}/${vegOrNonVeg}`;
                          await writedb
                            .ref(path)
                            .push()
                            .set({
                              image: `images/${catCus}/${uniqueKey}`,
                              thumbnailImage: url,
                            })
                            .then(async () => {
                              await writedb
                                .ref(finalPath)
                                .child("paths")
                                .child(myKey)
                                .set({ key: myKey })
                                .then(() => {
                                  setSearchLables(true);
                                });
                            });
                        });
                      });
                  });
              } catch (error) {
                console.error(error.toString());
              }
              // resetAll();

              // alert("All Graphics system uploaded")
            });
          });
      });
    } catch (error) {
      console.error(error);
    }
  };


  const uploadImageToServerForCombos = async (vegOrNonVeg) => {
    try {
      setUploadImage(true);
      const dbref = writedb.ref(`images/${catCus}`);
      let storageRef = storage.ref(`images/${catCus}`);
      const dataUrl = previewImage;
      const pushref = dbref.push();
      const uniqueKey = pushref.key;
      setImageKey(uniqueKey);
      storageRef = storageRef.child(uniqueKey);
      const task = storageRef.child("background").put(background);
      const blob = await fetch(dataUrl).then((res) => res.blob()); // This is for convert image generated from html to blob for upload in firebase
      task.then(() => {
        // complete function
        storageRef
          .child("background")
          .getDownloadURL()
          .then((url) => {
            const finalPath = dbref.child(uniqueKey);
            const addBg = finalPath.child("background");
            addBg.set({ image: url }).then(() => {
              try {
                storageRef
                  .child("cover")
                  .put(blob)
                  .then(() => {
                    storageRef
                      .child("cover")
                      .getDownloadURL()
                      .then((url) => {
                        const addCover = finalPath.child("cover");
                        addCover.set({ image: url }).then(async () => {
                          for (let i = 0; i < mainBoxArray.length; i++) {
                            const data = mainBoxArray[i];
                            const imageRef = storageRef.child(`i${i}`);
                            const imageUpload = imageRef
                              .put(data.file)
                              .then(() => {
                                imageRef.getDownloadURL().then((url) => {
                                  const addImage = finalPath.child(`/i${i}`);
                                  addImage.set({
                                    image: url,
                                    height: parseInt(data.height),
                                    width: parseInt(data.width),
                                    top: parseInt(data.top),
                                    left: parseInt(data.left),
                                  });
                                });
                              });
                          }
                          for (let i = 0; i < textMainArray.length; i++) {
                            const data = textMainArray[i];
                            let text = "";
                            data.text
                              .toString()
                              .split("\n")
                              .map((line, index) => {
                                text = text + line + "\\n";
                              });
                            const addText = finalPath.child(`t${i}`);
                            addText.set({
                              fontGravity: data.fontGravity,
                              text: text,
                              fontFamily: data.fontFamily.toString(),
                              fontWeight: parseInt(data.fontWeight),
                              fontItalic: data.fontItalic.toString(),
                              fontUnderline: data.fontUnderline.toString(),
                              fontSize: parseInt(data.fontSize),
                              fontColor: data.fontColor.toString(),
                              top: parseInt(data.top),
                              left: parseInt(data.left),
                            });
                          }

                          let path = `combos/categoryCombos/${myKey}/${myName}/${vegOrNonVeg}`;
                          await writedb
                            .ref(path)
                            .push()
                            .set({
                              image: `images/${catCus}/${uniqueKey}`,
                              thumbnailImage: url,
                            })
                            .then(async () => {
                              await writedb
                                .ref(finalPath)
                                .child("paths")
                                .child(myKey)
                                .set({ key: myKey })
                                .then(() => {
                                  setSearchLables(true);
                                });
                            });
                        });
                      });
                  });
              } catch (error) {
                console.error(error.toString());
              }
              // resetAll();

              // alert("All Graphics system uploaded")
            });
          });
      });
    } catch (error) {
      console.error(error);
    }
  };

  const uploadImageToServerForNoCat = async () => {
    try {
      const dbref = writedb.ref(`images/${catGen}`);
      let storageRef = storage.ref(`images/${catGen}`);
      const dataUrl = previewImage;
      const pushref = dbref.push();
      const uniqueKey = pushref.key;
      setImageKey(uniqueKey);
      storageRef = storageRef.child(uniqueKey);
      const task = storageRef.child("background").put(background);
      const blob = await fetch(dataUrl).then((res) => res.blob()); // This is for convert image generated from html to blob for upload in firebase
      task.then(() => {
        // complete function
        storageRef
          .child("background")
          .getDownloadURL()
          .then((url) => {
            const finalPath = dbref.child(uniqueKey);
            const addBg = finalPath.child("background");
            addBg.set({ image: url }).then(() => {
              try {
                storageRef
                  .child("cover")
                  .put(blob)
                  .then(() => {
                    storageRef
                      .child("cover")
                      .getDownloadURL()
                      .then((url) => {
                        const addCover = finalPath.child("cover");
                        addCover.set({ image: url }).then(() => {
                          for (let i = 0; i < mainBoxArray.length; i++) {
                            const data = mainBoxArray[i];
                            const imageRef = storageRef.child(`i${i}`);
                            const imageUpload = imageRef
                              .put(data.file)
                              .then(() => {
                                imageRef.getDownloadURL().then((url) => {
                                  const addImage = finalPath.child(`/i${i}`);
                                  addImage.set({
                                    image: url,
                                    height: parseInt(data.height),
                                    width: parseInt(data.width),
                                    top: parseInt(data.top),
                                    left: parseInt(data.left),
                                  });
                                });
                              });
                          }
                          for (let i = 0; i < textMainArray.length; i++) {
                            const data = textMainArray[i];
                            let text = "";
                            data.text
                              .toString()
                              .split("\n")
                              .map((line, index) => {
                                text = text + line + "\\n";
                              });
                            const addText = finalPath.child(`t${i}`);
                            addText.set({
                              fontGravity: data.fontGravity,
                              text: text,
                              fontFamily: data.fontFamily.toString(),
                              fontWeight: parseInt(data.fontWeight),
                              fontItalic: data.fontItalic.toString(),
                              fontUnderline: data.fontUnderline.toString(),
                              fontSize: parseInt(data.fontSize),
                              fontColor: data.fontColor.toString(),
                              top: parseInt(data.top),
                              left: parseInt(data.left),
                            });
                          }
                          selectedCatList.forEach(async (val) => {
                            let key = val.key;
                            let name = val.name;
                            let path = `${parent}/${catGen}/${key}/${name}/${vegNonVeg}`;
                            await writedb
                              .ref(path)
                              .push()
                              .set({
                                image: `images/${catGen}/${uniqueKey}`,
                                thumbnailImage: url,
                              })
                              .then(async () => {
                                await writedb
                                  .ref(finalPath)
                                  .child("paths")
                                  .child(key)
                                  .set({ key: key })
                                  .then(() => {
                                    setSearchLables(true);
                                  });
                              });
                          });
                        });
                      });
                  });
              } catch (error) {
                console.error(error.toString());
              }
              // resetAll();

              // alert("All Graphics system uploaded")
            });
          });
      });
    } catch (error) {
      console.error(error);
    }
  };

  function resetAll() {
    setmainBoxArray([]);
    setImageList([]);
    setSeletImage(false);
    setletsupload(false);
    setFoodTypeSel(false);
    setTextMainArray([]);
    setBackground("");
  }

  const inputStyle = {
    "& label.Mui-focused": {
      color: "aqua",
    },
    "& .MuiInput-underline:after": {
      borderBottomColor: "white",
    },
    "& .MuiOutlinedInput-root": {
      "& fieldset": {
        borderColor: "gray",
      },
      "&:hover fieldset": {
        borderColor: "white",
      },
      "&.Mui-focused fieldset": {
        borderColor: "rgb(155,155,255)",
      },
      "& .MuiInputBase-input": {
        color: "black",
        fontSize: "16px",
      },
    },
  };

  const inputStyleForproperties = {
    width: "80%",
    marginBottom: "10px",
    color: "white",
    "& .MuiInputBase-input": { color: "white" },
    "& label": { color: "white" },
  };

  // ********* made changes of image such as Height width top left using this functions (Starts)

  const heightValue = (event) => {
    const inputValue = event.target.value;
    if (!isNaN(inputValue)) {
      setHeight(event.target.value);
      if (!currenSelection.fontColor) {
        currenSelection.height = inputValue;
      }
    }
  };

  const widthValue = (event) => {
    const inputValue = event.target.value;

    if (!isNaN(inputValue)) {
      setWidth(inputValue);

      if (!currenSelection.fontColor) {
        currenSelection.width = inputValue;
      }
    }
  };

  const topValue = (event) => {
    setTop(event.target.value);
    if (currenSelection.fontColor) {
      currenSelection.top = event.target.value;
    } else {
      currenSelection.top = event.target.value;
    }
  };

  const leftValue = (event) => {
    setLeft(event.target.value);
    if (currenSelection.fontColor) {
      currenSelection.left = event.target.value;
    } else {
      currenSelection.left = event.target.value;
    }
  };

  // ========== made changes of image such as Height width top left using this functions (Ends)

  // ********* This Function call when we drag image inside mainbox using mouse listener attached with that image Starts
  const handleChangeForImage = async (newText) => {
    await Promise.all([
      setCurrSelection(newText),
      setHeight(newText.height),
      setWidth(newText.width),
    ]);
  };
  // ========== This Function call when we drag image inside mainbox using mouse listener attached with that image End

  const handleTextChangeForText = async (index, top, left) => {
    await Promise.all([
      setCurrSelection(textMainArray[index]),
      (textMainArray[index].top = top),
      (textMainArray[index].left = left),
      setTop(top),
      setLeft(left),
    ]);
  };

  const handleFontFamilyChange = (event) => {
    setFontFamily(event.target.value);
  };

  const editText = (data) => {
    setEditTextIndex(data.index);
    setCurrSelection(data);
    setAddTextData(data.text);
    setFontFamily(data.fontFamily);
    setFontSize(data.fontSize);
    setFontWeight(data.fontWeight);
    setFontItalic(data.fontItalic);
    setFontUnderline(data.fontUnderline);
    setFontColor(data.fontColor);
    setFontGravity(data.fontGravity);
    setIsEditText(true);
    setShowAddText(true);
  };

  const TextDataForAddTextInMain = (event) => {
    setAddTextData(event.target.value);
  };

  const displayAddText = () => {
    if (showAddText) {
      setShowAddText(false);
    } else {
      setAddTextData("");
      setFontFamily("Montserrat, sans-serif");
      setFontSize(16);
      setFontWeight(300);
      setFontItalic(false);
      setFontUnderline(false);
      setFontColor("#ffffff");
      setShowAddText(true);
      setIsEditText(false);
    }
  };

  const resetEditText = () => {
    setCurrSelection({})
    resetText();
    setIsEditText(false);
  };

  const setSubCat = (data) => {
    setSubCatName(data);
    const dbref = writedb.ref(
      `${parent}/${outGen}/${myKey}/${outletName}/${vegNonVeg}/${data}`
    );
    const storageRef = storage.ref(
      `${parent}/${outGen}/${myKey}/${outletName}/${vegNonVeg}/${data}`
    );
    addImageToServer(previewImage, dbref, storageRef);
  };

  const loadList = async (check) => {
    let path = `${parent}/${outGen}/${myKey}/${myName}/${check}`;

    let snap = await writedb.ref(path).once("value");

    if (snap.exists()) {
      subCatList.length = 0;
      let snapVal = snap.val();
      let data = Object.keys(snapVal);
      let arr = [];
      data.forEach((key) => {
        arr.push(key);
      });

      setSubCatList(arr);
    }
  };

  const loadListForNoCat = async () => {
    setShowCategoryList(true);
    let path = `${parent}/${catGen}`;
    
    let snap = await writedb.ref(path).once("value");

    if (snap.exists()) {
      let keys = Object.keys(snap.val());
      let values = Object.values(snap.val());
      let arr = [];
      keys.forEach((key, index) => {
        let name = Object.keys(values[index])[0];
        arr.push({ key: key, name: name, color: "white" });
      });
      setCatList(arr);
    }
  };

  const handleClickOfInput = (input) => {
    if (input.current) {
      input.current.click();
    }
  };

  const addSearch = async () => {
    
    let res = catCus
    if (!props.catCus && !props.address) {res = props.catGen}
    
      if (res == "category"){
        addSearchForcat()
      }else if (res == "outlet"){
        addSearchForOut();  
      }else if (res == "categoryTestimonials"){
        addSearchForCatGen();
      }else if (res == "categoryWallPoster"){
        addSearchForCatGen();
      }else if (res == "categoryFlyer"){
        addSearchForCatGen();
      }else if (res == "categoryCombos"){
        addSearchForCatGen();
      }else if (res == "categoryEvents"){
        addSearchForCatGen();
      }
    
    else{
      addSearchForOut();
    }
  };


  const addSearchForCatGen = async () => {
    setSearchAdding(true);
    if (labelName.includes(",")) {
        let arr = labelName.split(",");
        arr.forEach(val => {
            let labelName = val.trim();
            writedb.ref(`search/${labelName}/${imageKey}`).set({ image: `images/${catGen}/${imageKey}` }).then(() => {
                writedb.ref(`images/${catGen}/${imageKey}/labels`).child(labelName).set({ name: labelName }).then(() => {
                    setLabelName("");
                    setSearchAdding(false);
                })
            });
        })
    } else {
        writedb.ref(`search/${labelName}/${imageKey}`).set({ image: `images/${catGen}/${imageKey}` }).then(() => {
            writedb.ref(`images/${catGen}/${imageKey}/labels`).child(labelName).set({ name: labelName }).then(() => {
                setLabelName("");
                setSearchAdding(false);
            })
        });
    }
}

  const addSearchForOut = async () => {
    setSearchAdding(true);

    if (labelName.includes(",")) {
      let arr = labelName.split(",");
      arr.forEach((val) => {
        let labelName = val.trim();
        writedb
          .ref(`search/${labelName}/${imageKey}`)
          .set({ image: `images/${catCus}/${imageKey}` })
          .then(() => {
            writedb
              .ref(`images/${catCus}/${imageKey}/labels`)
              .child(labelName)
              .set({ name: labelName })
              .then(() => {
                setLabelName("");
                setSearchAdding(false);
              });
          });
      });
    } else {
      writedb
        .ref(`search/${labelName}/${imageKey}`)
        .set({ image: `images/${catCus}/${imageKey}` })
        .then(() => {
          writedb
            .ref(`images/${catCus}/${imageKey}/labels`)
            .child(labelName)
            .set({ name: labelName })
            .then(() => {
              setLabelName("");
              setSearchAdding(false);
            });
        });
    }
  }


  const addSearchForcat = async () => {
    setSearchAdding(true);
    if (labelName.includes(",")) {
        let arr = labelName.split(",");
        arr.forEach(val => {
            let labelName = val.trim();
            writedb.ref(`search/${labelName}/${imageKey}`).set({ image: `images/${catCus}/${imageKey}` }).then(() => {
                writedb.ref(`images/${catCus}/${imageKey}/labels`).child(labelName).set({ name: labelName }).then(() => {
                    setLabelName("");
                    setSearchAdding(false);
                })
            });
        })
    } else {
        writedb.ref(`search/${labelName}/${imageKey}`).set({ image: `images/${catCus}/${imageKey}` }).then(() => {
            writedb.ref(`images/${catCus}/${imageKey}/labels`).child(labelName).set({ name: labelName }).then(() => {
                setLabelName("");
                setSearchAdding(false);
            })
        });
    }
}

  let isKeyDown = false;

  const handleKeyDown = (event) => {
    if (isKeyDown) {
      return; // Skip handling if a key is already down
    }
    isKeyDown = true;

    if (!currenSelection.fontColor) {
      if (event.key === "w") {
        currenSelection.height += 5;
        currenSelection.width += 5;
      } else if (event.key === "s") {
        currenSelection.height -= 5;
        currenSelection.width -= 5;
      } else if (event.key === "a") {
        currenSelection.height -= 5;
      } else if (event.key === "d") {
        currenSelection.width -= 5;
      } else if (event.key === "z") {
        currenSelection.height += 5;
      } else if (event.key === "x") {
        currenSelection.width += 5;
      }
    }
  };

  const handleKeyUp = (event) => {
    setHeight(currenSelection.height);
    setWidth(currenSelection.width);
    isKeyDown = false;
  };

  useEffect(() => {
    document.addEventListener("keydown", handleKeyDown);
    document.addEventListener("keyup", handleKeyUp);
    return () => {
      document.removeEventListener("keydown", handleKeyDown);
      document.removeEventListener("keyup", handleKeyUp);
    };
  }, [height]);

  const dragFunctionForHorizontalLine = useDrag(
    ({ offset: [dx, dy], down }) => {
      if (down && !lockLines) {
        // Calculate new position within the bounds of the box
        const newTop = Math.min(
          Math.max(150 + dy, 0),
          400 - hLineRef.current.clientHeight
        );
        hLineRef.current.style.top = `${newTop}px`;
        h1LineRef.current.style.top = `${newTop}px`;
      }
    },
    { domTarget: !lockLines ? hLineRef : "", eventOptions: { passive: false } }
  );

  const dragFunctionForVerticallLine = useDrag(
    ({ offset: [dx, dy], down }) => {
      if (down && !lockLines) {
        // Calculate new position
        const newLeft = Math.min(
          Math.max(150 + dx, 0),
          290 - vLineRef.current.clientWidth
        );
        vLineRef.current.style.left = `${newLeft}px`;
        v1LineRef.current.style.left = `${newLeft}px`;
      }
    },
    { domTarget: vLineRef, eventOptions: { passive: false } }
  );

  async function setTopLeft(index, top1, left1) {
    await Promise.all([
      setTop(parseInt(top1, 10)),
      setLeft(parseInt(left1, 10)),
    ]);
    mainBoxArray[index].top = top1;
    mainBoxArray[index].left = left1;
  }
  const debouncedSetFontColor = useCallback(
    debounce((color) => {
      setFontColor(color);
    }, 50), // Adjust the debounce delay as needed
    []
  );
  const handleFontColorChange = useCallback((event) => {
    const newColor = event.target.value;
    debouncedSetFontColor(newColor);
  }, []);

  const makeCategoryList = (index) => {
    let key = catList[index].key;
    let name = catList[index].name;
    let color = catList[index].color;
    if (color === "white") {
      selectedCatList.push({ key: key, name: name });
      catList[index].color = "black";
      setCatList([...catList]);
    } else {
      if (selectedCatList.length === 1) {
        setSelctedCatList([]);
        catList[index].color = "white";
        setCatList([...catList]);
      } else {
        let arr = [];
        selectedCatList.forEach((val) => {
          if (val.key != key) {
            arr.push(val);
          }
        });
        setSelctedCatList(arr);
        catList[index].color = "white";
        setCatList([...catList]);
      }
    }
  };

  

  return (
    <Box
      display="flex"
      position="absolute"
      top="20%"
      bottom="20%"
      left="30%"
      right="30%"
      height={`${heightAvatar + 100}px`}
      width="700px"
      backgroundColor="#727681"
      zIndex="20"
      borderRadius="10px"
      justifyContent="center"
    >
      {/* ############## category setup Screen 1 starts here ################ */}
      {/* This is close button of add category */}
      <Box position="absolute" right="0px" top="0" display="flex" zIndex="100">
        <Button onClick={catDis}>
          <CloseRounded
            sx={{
              color: colors.primary[100],
              "&:hover": {
                color: "lightgreen",
              },
            }}
          />
        </Button>
      </Box>
      {/* This is close button of add category */}

      {/* This is for select image from files */}
      <Box
        display={selectImage ? "none" : "flex"}
        height="400px"
        width="700px"
        alignItems="center"
        flexDirection="column"
      >
        <Typography
          fontSize="35px"
          fontWeight="700"
          marginTop="100px"
          marginBottom="20px"
        >
          Add Image
        </Typography>

        <input
          accept="image/*"
          type="file"
          onChange={handleFileSelect}
          multiple
          ref={fileInput}
          style={{ display: "none" }}
        />
        <Box display="flex" justifyContent="center" alignItems="center">
          <Button
            onClick={() => {
              handleClickOfInput(fileInput);
            }}
            sx={{ backgroundColor: "blue", borderRadius: "20px" }}
          >
            <Typography
              fontSize="16px"
              textTransform="none"
              color="white"
              padding="5px"
              textAlign="center"
              justifyContent="center"
              display="flex"
              alignItems="center"
            >
              <AddCircleOutline sx={{ marginRight: "8px" }} />
              Select Images
            </Typography>
          </Button>
        </Box>
      </Box>
      {/* File select ends here */}

      {/* ############## category setup  Screen 2 starts here ################ */}

      {/* ############## editor Screen 2 starts here ################ */}

      {/* This box works after image selection complete starts */}

      <Box
        display={
          selectImage && !uploadImage
            ? letsupload || uploadImage
              ? "none"
              : !foodTypeSel? "inline-flex" : "none"
            : "none"
        }
        height="100%"
        width="100%"
      >
        {/* Display Category name eneterd by user starts */}
        <Box
          display="flex"
          position="absolute"
          top="0"
          paddingLeft="20%"
          width="100%"
          justifyContent="center"
          marginTop="10px"
        >
          <Typography fontSize="20px" fontWeight="700" color="white">
            {outletName}
          </Typography>
        </Box>
        {/* Display Category name eneterd by user ends */}
        {/* This is for list selected images in editor */}
        <Box
          display={letsupload ? "none" : !foodTypeSel? "inline-flex" : "none"}
          height="100%"
          width="25%"
        >
          <Box
            display="flex"
            flexDirection="column"
            height="100%"
            width="100%"
            justifyContent="start"
            alignItems="center"
            overflow="auto"
            paddingTop="10px"
            borderRight="2px solid black"
            className="hideScroll"
            backgroundColor="#727681"
            borderRadius="10px"
          >
            {imageList.map((image, index) => (
              <Avatar
                key={image + index}
                src={URL.createObjectURL(image)}
                sx={{
                  width: "80%",
                  height: 80,
                  marginBottom: "10px",
                  borderRadius: "5%",
                  border: "2px solid white",
                }}
                onContextMenu={() => {
                  setBackground(image);
                }}
                onClick={() => {
                  addImageToMain(index);
                }}
              />
            ))}
          </Box>
        </Box>
        {/* This is for list selected images in editor ends */}
        <Box
          display={letsupload ? "none" : !foodTypeSel? "inline-flex" : "none"}
          width="100%"
          height="100%"
          justifyContent="center"
          alignItems="center"
        >
          {/* When You add image inside 300 * 300 editor then this trigger  */}
          <Box
            display="flex"
            width="400px"
            height={`${heightAvatar}px`}
            backgroundColor={colors.primary[500]}
          >
            <Box width="390px" height="300px">
              {/* This is fixed background image of editor */}
              <Box
                position="absolute"
                width="400px"
                height={`${heightAvatar}px`}
                zIndex="0"
              >
                <Box display="flex" position="absolute">
                  <Box
                    id="imageEditingBoxInAddCategory"
                    display="flex"
                    flexDirection="column"
                    position="absolute"
                    width="290px"
                    height={`${heightAvatar}px`}
                    color="white"
                    zIndex="20"
                    overflow="hidden"
                  >
                    <Avatar
                      id="backgroundImage"
                      src={
                        imageList.length > 0
                          ? URL.createObjectURL(background)
                          : ""
                      }
                      variant="square"
                      sx={{
                        width: "290px",
                        height: `${heightAvatar}px`,
                        position: "absolute",
                        "& > img": {
                          objectFit: "fill",
                          width: "100%",
                          height: "100%",
                        },
                      }}
                    />
                    {mainBoxArray.map((item, index) => (
                      <Box
                        key={item.file.name + index}
                        onMouseDown={() => {
                          handleChangeForImage(mainBoxArray[index]);
                        }}
                      >
                        <NewImage
                          element={mainBoxArray}
                          onTextChange={handleChangeForImage}
                          topLeft={setTopLeft}
                          index={index}
                          myheight={heightAvatar}
                          onMouseDown={() => {
                            setCurrSelection(mainBoxArray[index]);
                          }}
                        />
                      </Box>
                    ))}
                    {textMainArray.map((item, index) => (
                      <Box
                        key={item.text + index}
                        onMouseDown={() => {
                          setCurrSelection(textMainArray[index]);
                        }}
                      >
                        <NewText
                          key={item + index}
                          element={textMainArray}
                          onTextChange={handleTextChangeForText}
                          index={index}
                          myheight={heightAvatar}
                          editText={editText}
                        />
                      </Box>
                    ))}
                  </Box>

                  <Box display={showGuide && !lockLines ? "flex" : "none"}>
                    <Box
                      ref={hLineRef}
                      id="horizontalline1"
                      display="flex"
                      position="absolute"
                      top={`${heightAvatar / 2 - 8}px`}
                      width="290px"
                      height="15px"
                      zIndex="50"
                      {...dragFunctionForHorizontalLine}
                      alignItems="center"
                    >
                      <Box width="290px" bgcolor="red" height="1.5px"></Box>
                    </Box>

                    <Box
                      ref={vLineRef}
                      id="verticalline1"
                      display="flex"
                      position="absolute"
                      left="137px"
                      width="15px"
                      height={`${heightAvatar}px`}
                      zIndex="50"
                      justifyContent="center"
                      cursor={lockLines ? "default" : "row-resize"}
                      {...dragFunctionForVerticallLine}
                    >
                      <Box
                        width="1.5px"
                        bgcolor="red"
                        height={`${heightAvatar}px`}
                      ></Box>
                    </Box>
                  </Box>

                  <Box display={showGuide && lockLines ? "flex" : "none"}>
                    <Box
                      ref={h1LineRef}
                      display="flex"
                      position="absolute"
                      top={`${heightAvatar / 2 - 8}px`}
                      width="290px"
                      height="15px"
                      zIndex="50"
                      alignItems="center"
                    >
                      <Box width="290px" bgcolor="red" height="1.5px"></Box>
                    </Box>

                    <Box
                      ref={v1LineRef}
                      display="flex"
                      position="absolute"
                      left={`${vLineY}px`}
                      width="15px"
                      height={`${heightAvatar}px`}
                      zIndex="50"
                      justifyContent="center"
                      cursor={lockLines ? "default" : "row-resize"}
                    >
                      <Box
                        width="1.5px"
                        bgcolor="red"
                        height={`${heightAvatar}px`}
                      ></Box>
                    </Box>
                  </Box>
                </Box>
              </Box>

              {/* Fixed background image of editor ends here */}

              {/* Height and width of the selected image inside main box editor detais */}
              <Box
                display="flex"
                flexDirection="column"
                margin="20px 0"
                marginLeft="300px"
                justifyContent="center"
                alignItems="center"
                maxHeight={heightAvatar - 50}
                overflow="auto"
              >
                <Box
                  display="flex"
                  flexDirection="column"
                  alignItems="center"
                  mt={heightAvatar < 400 ? "110px" : "0px"}
                >
                  <TextField
                    autoComplete="off"
                    type="number"
                    variant="standard"
                    color="secondary"
                    label="Height"
                    value={height}
                    sx={inputStyleForproperties}
                    onChange={(e) => {
                      heightValue(e);
                    }}
                  />

                  <TextField
                    autoComplete="off"
                    type="number"
                    variant="standard"
                    color="secondary"
                    label="Width"
                    value={width}
                    sx={inputStyleForproperties}
                    onChange={(e) => {
                      widthValue(e);
                    }}
                  />

                  <TextField
                    autoComplete="off"
                    type="number"
                    variant="standard"
                    color="secondary"
                    label="Top"
                    value={top}
                    sx={inputStyleForproperties}
                    onChange={(e) => {
                      topValue(e);
                    }}
                  />

                  <TextField
                    autoComplete="off"
                    type="number"
                    variant="standard"
                    color="secondary"
                    label="Left"
                    value={left}
                    sx={inputStyleForproperties}
                    onChange={(e) => {
                      leftValue(e);
                    }}
                  />

                  <Box display="flex">
                    <Button
                      sx={{ color: "white" }}
                      onClick={() => {
                        hLineRef.current.style.top = `${
                          heightAvatar / 2 - 8
                        }px`;
                        h1LineRef.current.style.top = `${
                          heightAvatar / 2 - 8
                        }px`;
                      }}
                    >
                      Center H
                    </Button>
                  </Box>
                  <Box display="flex">
                    <Button
                      sx={{ color: "white" }}
                      onClick={() => {
                        vLineRef.current.style.left = "137px";
                        v1LineRef.current.style.left = "137px";
                      }}
                    >
                      Center V
                    </Button>
                  </Box>

                  <Box display="flex">
                    <Button
                      sx={{ color: "white" }}
                      onClick={() => {
                        setLockLines(!lockLines);
                      }}
                    >
                      {lockLines ? "Unlock" : "Lock"}
                    </Button>
                  </Box>
                  <Box display="flex">
                    <Button
                      sx={{ color: "white" }}
                      onClick={() => {
                        if (!currenSelection.fontColor) {
                          if (mainBoxArray.length == 1) {
                            setmainBoxArray([]);
                          } else {
                            mainBoxArray.splice(currenSelection.index, 1);
                            setmainBoxArray([...mainBoxArray]);
                          }
                        } else if (currenSelection.fontColor) {
                          if (textMainArray.length == 1) {
                            setTextMainArray([]);
                          } else {
                            const newItems = textMainArray.filter(
                              (item, i) => i !== currenSelection.index
                            );
                            setTextMainArray(newItems);
                          }

                          resetEditText();
                        }
                      }}
                    >
                      Delete
                    </Button>
                  </Box>
                </Box>
              </Box>
              {/* Height and width of the selected image inside main box editor detais ends */}
            </Box>
          </Box>

          {/* Add Image to Main Editing box Ends here   */}

          {/* For change background and add Text */}
          <Box
            display="flex"
            position="absolute"
            bottom="0"
            marginBottom="5px"
            left="0"
            borderRadius="10px"
            marginLeft="22%"
            padding="5px"
          >
            <input
              accept="image/*"
              type="file"
              onChange={bgSelect}
              ref={bgInput}
              style={{ display: "none" }}
            />
            <Box
              backgroundColor="yellow"
              marginRight="10px"
              display="flex"
              alignItems="center"
              padding="3px"
              borderRadius="5px"
              pl="5px"
              pr="5px"
            >
              <Typography
                color="black"
                sx={{ userSelect: "none", cursor: "pointer" }}
                onClick={() => {
                  //   handleClickOfInput(bgInput);
                  console.info(mainBoxArray);
                }}
              >
                Change Background
              </Typography>
            </Box>
            <Box
              backgroundColor="yellow"
              padding="3px"
              display="flex"
              alignItems="center"
              borderRadius="5px"
              pl="5px"
              pr="5px"
            >
              <Typography
                onClick={() => {
                  displayAddText();
                }}
                color="black"
                sx={{ userSelect: "none", cursor: "pointer" }}
              >
                Add Text
              </Typography>
            </Box>
            <Box
              backgroundColor="yellow"
              padding="2px"
              display="flex"
              justifyContent="center"
              alignItems="center"
              borderRadius="5px"
              ml="10px"
            >
              <Typography
                onClick={() => {
                  setShowGuide(!showGuide);
                }}
                color="black"
                sx={{ userSelect: "none", cursor: "pointer" }}
              >
                <Grid4x4Outlined sx={{ pt: "5px" }} />
              </Typography>
            </Box>
            <Box
              backgroundColor="yellow"
              padding="2px"
              display="flex"
              justifyContent="center"
              alignItems="center"
              borderRadius="5px"
              ml="10px"
              pl="5px"
              pr="5px"
            >
              <Typography
                onClick={() => {
                  handleClickOfInput(fileInput);
                }}
                color="black"
                sx={{ userSelect: "none", cursor: "pointer" }}
              >
                Add Images
              </Typography>
            </Box>
          </Box>
          {/* For change background and add Text */}

          {/* For upload imaege to server */}
          <Box
            display="flex"
            position="absolute"
            bottom="0"
            marginBottom="10px"
            backgroundColor="yellow"
            right="0"
            borderRadius="10px"
            marginRight="20px"
          >
            <Box display={generatingPreview ? "none" : "flex"}>
              <Button onClick={captureImage}>Upload Image to server</Button>
            </Box>

            <Box
              width="100px"
              display={generatingPreview ? "flex" : "none"}
              justifyContent="center"
            >
              <Box display="flex">
                <CircularProgress
                  style={{ height: "30px", width: "30px", padding: "5px" }}
                />
              </Box>
            </Box>

            {/* Here i use capture image first and then upload to server because i need to create final image of all editor and then uplod. */}
          </Box>
          {/* For upload imaege to server */}
        </Box>
      </Box>

      {/* This box works after image selection complete ends*/}

      {/* ############## editor Screen 2 ends here ################ */}

      {/* #############   This is for add text inside ediitor #################### */}
      <Box
        display={showCategoryList ? "flex" : "none"}
        width="600px"
        height="80%"
        marginTop="6%"
        justifyContent="center"
        alignItems="center"
        flexDirection="column"
        borderRadius="20px"
      >
        <Box width="350px" height="250px" overflow="auto">
          <Box display="Flex" justifyContent="center">
            <Typography>Select Category</Typography>
          </Box>
          <List>
            {catList.map((val, index) => (
              <ListItem
                key={val.key}
                sx={{
                  "&:hover": { background: "#3b9a8c", userSelect: "none" },
                }}
                onClick={() => {
                  makeCategoryList(index);
                }}
              >
                <ListItemText primary={val.name} sx={{ color: val.color }} />
              </ListItem>
            ))}
          </List>
        </Box>

        <Box
          mt="10px"
          borderRadius="15px"
          bgcolor={
            selectedCatList.length > 0 ? "blue" : "rgb(255,255,255,0.23)"
          }
        >
          <Button
            sx={{ borderRadius: "15px", color: "white" }}
            disabled={selectedCatList.length > 0 ? false : true}
            onClick={() => {
              <>
                {setUploadImage(true)} {setShowCategoryList(false)}{" "}
                {uploadImageToServerForNoCat()}
              </>;
            }}
          >
            Continue
          </Button>
        </Box>
      </Box>
      <Box
        display="flex"
        width="250px"
        overflow="auto"
        height="100%"
        position="fixed"
        top="0"
        right={showAddText ? "0" : "-250px"}
        sx={{
          background: colors.primary[400],
          transition: "0.3s all ease-in-out",
        }}
        justifyContent="start"
        flexDirection="column"
        padding="0px 20px"
        paddingTop="100px"
        zIndex="105"
      >
        <Box
          position="fixed"
          right="-10px"
          top="0"
          display={showAddText ? "flex" : "none"}
          zIndex="100"
        >
          <Button
            onClick={() => {
              showAddText ? setShowAddText(false) : setShowAddText(true);
            }}
          >
            <CloseRounded
              sx={{
                color: colors.primary[100],
                "&:hover": {
                  color: "blue",
                },
              }}
            />
          </Button>
        </Box>

        <TextField
          ref={textInputRef}
          autoComplete="off"
          label="Enter Text"
          multiline
          value={addTextData}
          onChange={(event) => {
            TextDataForAddTextInMain(event);
          }}
          onMouseDown={() => {
            setCurrSelection({});
          }}
          sx={{ inputStyle }}
        />

        <Typography
          color={fontColor}
          sx={{
            textAlign: fontGravity,
            fontFamily: fontFamily,
            margin: "20px 0",
            fontSize: fontSize + "px",
            fontWeight: fontWeight,
            fontStyle: fontItalic ? "italic" : "",
            textDecoration: fontUnderline ? "underline" : "",
          }}
        >
          {addTextData.split("\n").map((line, index) => {
            return (
              <React.Fragment key={index}>
                {line}
                <br />
              </React.Fragment>
            );
          })}
        </Typography>

        <Select value={fontFamily} onChange={handleFontFamilyChange}>
          {fontFamilies.map((option) => (
            <MenuItem key={option.value} value={option.value}>
              <Typography sx={{ fontFamily: option.value }}>
                {option.label}
              </Typography>
            </MenuItem>
          ))}
        </Select>

        <Box
          display="flex"
          marginTop="20px"
          border="2px solid black"
          width="100%"
        >
          <Box
            display="flex"
            justifyContent="center"
            alignItems="center"
            width="20%"
            height="35px"
            borderRight="2px solid black"
            onClick={() => {
              fontWeight == 700 ? setFontWeight(300) : setFontWeight(700);
            }}
            backgroundColor={fontWeight == 700 ? "white" : ""}
            color={fontWeight == 700 ? "black" : "white"}
            sx={{ cursor: "pointer" }}
          >
            <FormatBoldRounded />
          </Box>
          <Box
            display="flex"
            justifyContent="center"
            alignItems="center"
            width="20%"
            height="35px"
            borderRight="2px solid black"
            onClick={() => {
              fontItalic ? setFontItalic(false) : setFontItalic(true);
            }}
            backgroundColor={fontItalic ? "white" : ""}
            color={fontItalic ? "black" : "white"}
            sx={{ cursor: "pointer" }}
          >
            <FormatItalicRounded />
          </Box>
          <Box
            display="flex"
            justifyContent="center"
            alignItems="center"
            width="20%"
            height="35px"
            borderRight="2px solid black"
            onClick={() => {
              fontUnderline ? setFontUnderline(false) : setFontUnderline(true);
            }}
            backgroundColor={fontUnderline ? "white" : ""}
            color={fontUnderline ? "black" : "white"}
            sx={{ cursor: "pointer" }}
          >
            <FormatUnderlined />
          </Box>
          <Box display="flex" width="40%" height="35px" alignItems="center">
            <TextField
              placeholder="size"
              type="number"
              inputProps={{ max: 40, min: 5 }}
              value={fontSize}
              onChange={(event) => {
                setFontSize(event.target.value);
              }}
              sx={{
                "& label.Mui-focused": {
                  color: "aqua",
                },
                "& .MuiInput-underline:after": {
                  borderBottomColor: "white",
                },
                "& .MuiOutlinedInput-root": {
                  "& fieldset": {
                    border: "none",
                  },
                  "&:hover fieldset": {
                    border: "none",
                  },
                  "&.Mui-focused fieldset": {
                    border: "none",
                  },
                  "& .MuiInputBase-input": {
                    color: "white",
                    fontSize: "15px",
                    border: "none",
                  },
                },
              }}
            />
          </Box>
        </Box>

        {/* <Box display="flex" marginTop="20px" width="62%" border="2px solid black" height="35px">
                    <Box display="flex" borderRight="1px solid black" padding="5px" alignItems="center">
                        <Typography >Weight</Typography>
                    </Box>
                    <Box display="flex" width="100%" height="35px" alignItems="center" >
                        <TextField placeholder="size" type="number" inputProps={{ max: 700, min: 300, step: 100 }} value={fontWeight} onChange={(event) => { setFontWeight(event.target.value) }}
                            sx={{
                                '& label.Mui-focused': {
                                    color: 'aqua',
                                },
                                '& .MuiInput-underline:after': {
                                    borderBottomColor: 'white',
                                },
                                '& .MuiOutlinedInput-root': {
                                    '& fieldset': {
                                        border: "none",

                                    },
                                    '&:hover fieldset': {
                                        border: "none",

                                    },
                                    '&.Mui-focused fieldset': {
                                        border: "none",

                                    }, '& .MuiInputBase-input': {
                                        color: 'white',
                                        fontSize: "15px",
                                        border: "none"

                                    }
                                }
                            }} />
                    </Box>
                </Box> */}

        <Box display="inline-flex" marginTop="20px">
          <Box display="inline-flex" border="2px solid black">
            <Box
              display="flex"
              justifyContent="center"
              alignItems="center"
              width="40px"
              height="35px"
              borderRight="2px solid black"
              onClick={() => {
                setFontGravity("start");
              }}
              backgroundColor={fontGravity == "start" ? "white" : ""}
              color={fontGravity == "start" ? "black" : "white"}
              sx={{ cursor: "pointer" }}
            >
              <FormatAlignLeftRounded />
            </Box>

            <Box
              display="flex"
              justifyContent="center"
              alignItems="center"
              width="40px"
              height="35px"
              borderRight="2px solid black"
              onClick={() => {
                setFontGravity("center");
              }}
              backgroundColor={fontGravity == "center" ? "white" : ""}
              color={fontGravity == "center" ? "black" : "white"}
              sx={{ cursor: "pointer" }}
            >
              <FormatAlignCenterRounded />
            </Box>

            <Box
              display="flex"
              justifyContent="center"
              alignItems="center"
              width="40px"
              height="35px"
              onClick={() => {
                setFontGravity("end");
              }}
              backgroundColor={fontGravity == "end" ? "white" : ""}
              color={fontGravity == "end" ? "black" : "white"}
              sx={{ cursor: "pointer" }}
            >
              <FormatAlignRightRounded />
            </Box>
          </Box>
        </Box>

        <Box display="flex" marginTop="20px" width="100%">
          <TextField
            label="color"
            type="color"
            value={fontColor}
            onChange={handleFontColorChange}
            InputLabelProps={{
              shrink: true,
            }}
            sx={{ width: "50%", height: "50%" }}
          />
        </Box>

        <Box display="flex" marginTop="20px">
          <Box
            display="flex"
            backgroundColor="blue"
            padding="8px"
            borderRadius="18px"
            alignItems="center"
          >
            <Typography
              onClick={() => {
                addTextToMain();
              }}
              color="white"
              sx={{ userSelect: "none" }}
              fontSize="15px"
            >
              {isEditText ? "Edit Text" : "Add Text"}
            </Typography>
          </Box>

          <Box
            display={isEditText ? "flex" : "none"}
            ml="10px"
            backgroundColor="blue"
            padding="8px"
            borderRadius="18px"
            alignItems="center"
          >
            <Typography
              onClick={() => {
                deleteText();
              }}
              color="white"
              sx={{ userSelect: "none" }}
              fontSize="15px"
            >
              Delete
            </Typography>
          </Box>

          <Box display={isEditText ? "flex" : "none"} marginLeft="10px">
            <Box
              display="flex"
              backgroundColor="blue"
              padding="8px"
              borderRadius="18px"
              alignItems="center"
            >
              <Typography
                onClick={() => {
                  resetEditText();
                }}
                color="white"
                sx={{ userSelect: "none" }}
                fontSize="15px"
              >
                Reset
              </Typography>
            </Box>
          </Box>
        </Box>
      </Box>

      {/* #############   This is for add text inside ediitor ends #################### */}

      {/* This is for navigate user  */}
      <Box display="flex" position="absolute" bottom="0" marginBottom="20px">
        <Typography display={outletName.length > 0 ? "none" : "flex"}>
          Enter Category Name to continue
        </Typography>
        <Typography
          display={
            coverSelect ? "none" : outletName.length > 0 ? "flex" : "none"
          }
        >
          Select cover photo to continue
        </Typography>
        <Typography
          display={
            coverSelect > 0
              ? outletName.length > 0
                ? selectImage
                  ? "none"
                  : "flex"
                : "none"
              : "none"
          }
        >
          Select Images to continue
        </Typography>
        <Typography display={foodTypeSel && letsupload ? "flex" : "none"}>
          Select Where you want to add this image to continue
        </Typography>
      </Box>

      <Box
        display={foodTypeSel ? "flex" : "none"}
        height="100%"
        width="100%"
        justifyContent="center"
        alignItems="Center"
      >
        <Box display="flex" bgcolor="blue" borderRadius="10px" mr="40px">
          <Button
            onClick={() => {
              verifyVegNonVeg("veg")
              // <>
              //   {" "}
              //   {setFoodTypeSel(false)} {setVegNonVeg("veg")}{" "}
              //   {loadListForNoCat("veg")}
              // </>;
            }}
          >
            <Typography textTransform="none" color="white">
              Veg
            </Typography>
          </Button>
        </Box>
        <Box display="flex" bgcolor="blue" borderRadius="10px">
          <Button
            onClick={() => {
              verifyVegNonVeg("nonVeg")
              // <>
              //   {" "}
              //   {setFoodTypeSel(false)} {setVegNonVeg("nonVeg")}{" "}
              //   {loadListForNoCat("nonVeg")}
              // </>;
            }}
          >
            <Typography textTransform="none" color="white">
              Non-Veg
            </Typography>
          </Button>
        </Box>
      </Box>

      

      {/* <Box display={showNewSubcatName ? "none" : foodTypeSel ? "none" : letsupload ? "flex" : "none"} width="600px" height="80%" marginTop="6%" justifyContent="center" alignItems="center" flexDirection="column" backgroundColor={colors.primary[500]} borderRadius="20px">

                <Box width="350px" height="250px" overflow="auto" >
                    <Box display="Flex" justifyContent="center">
                        <Typography>
                            Select Name
                        </Typography>
                    </Box>
                    <List>
                        {
                            subCatList.map((val, index) => (
                                <ListItem key={val} sx={{ '&:hover': { background: "#3b9a8c", userSelect: "none" } }} onClick={() => setSubCat(val)}>
                                    <ListItemText primary={val} sx={{ color: "white" }} />
                                </ListItem>
                            ))
                        }
                    </List>
                </Box>
                <Box>
                    <Button sx={{ color: "white" }} onClick={() => { setShowNewSubCatName(true) }}>
                        Add new name
                    </Button>
                </Box>
            </Box>

            <Box display={!foodTypeSel && showNewSubcatName ? "flex" : "none"} height="100%" width="100%" justifyContent="center" alignItems="center" flexDirection='column'>
                <TextField autoFocus autoComplete="off" label="Graphic Name?" value={subCatName} onChange={(event) => {
                    setSubCatName(event.target.value.charAt(0).toUpperCase() + event.target.value.slice(1))
                }} sx={inputStyle} />
                <Box mt="20px" bgcolor={subCatName.length > 0 ? "blue" : "rgb(255,255,255,0.23)"} borderRadius="10px">
                    <Button disabled={subCatName.length > 0 ? false : true} onClick={() => { uploadToServer() }}>
                        <Typography textTransform="none" color="white">Ready to Upload</Typography>
                    </Button>
                </Box>
            </Box> */}

      <Box
        display={uploadImage && !searchLabels ? "flex" : "none"}
        justifyContent="center"
        flexDirection="column"
        alignItems="center"
      >
        <Typography>Image Uploading</Typography>
        <CircularProgress sx={{ padding: "7px", mt: "10px" }} />
      </Box>

      <Box
        display={searchLabels ? "flex" : "none"}
        height="100%"
        width="100%"
        justifyContent="center"
        alignItems="center"
        flexDirection="column"
      >
        <TextField
          autoFocus
          autoComplete="off"
          label="Enter Label?"
          value={labelName}
          onChange={(event) => {
            setLabelName(
              event.target.value.charAt(0).toUpperCase() +
                event.target.value.slice(1)
            );
          }}
          sx={inputStyle}
        />
        <Box
          display={searchAdding ? "none" : "flex"}
          mt="20px"
          bgcolor={labelName.length > 0 ? "blue" : "rgb(255,255,255,0.23)"}
          borderRadius="10px"
        >
          <Button
            disabled={labelName.length > 0 ? false : true}
            onClick={() => {
              addSearch();
            }}
          >
            <Typography textTransform="none" color="white">
              Add Search Label
            </Typography>
          </Button>
        </Box>
        <Box
          display={searchAdding ? "flex" : "none"}
          mt="20px"
          borderRadius="10px"
        >
          <CircularProgress />
        </Box>
        <Box display="flex" position="absolute" bottom="0" mb="10px">
          At least one label is required otherwise image not view in Search
        </Box>
      </Box>
    </Box>
  );
};

export default AddNewImage;
