import {
  Avatar,
  Box,
  Button,
  Grid,
  Input,
  Modal,
  TextField,
  Typography,
  useTheme,
} from "@mui/material";
import React from "react";
import { tokens } from "../../theme";
import Header from "../../components/Header";
import { useState } from "react";
import { useEffect } from "react";
import { database, storage, writedb } from "../../firebase-config";
import { onValue, ref } from "firebase/database";
import { useLocation, useParams } from "react-router-dom";
import { useMemo } from "react";
import {
  AddCircleOutlineRounded,
  CoPresentOutlined,
  Edit,
  Sync,
} from "@mui/icons-material";
import AddNewImage from "./addNewImage";
import AddMultipleImages from "./addMultipleImages";
import EditImageFromServer from "../editServerImage";

const OutletManage = () => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const [avatarWidth, setAvatarWidth] = useState(200);
  const [avatarHeight, setAvatarHeight] = useState(200);
  const location = useLocation().pathname;
  const [outGen, setOutGen] = useState("");
  const [parent, setParent] = useState("home");
  useEffect(() => {
    if (location.includes("home/edit/outlet")) {
      setOutGen("outlet");
      loadData("home", "outlet");
    } else if (location.includes("general/edit/category")) {
      setOutGen("categoryGeneral");
      loadData("general", "categoryGeneral");
      setParent("general");
    } else if (location.includes("combos/edit/category")) {
      setOutGen("categoryCombos");
      loadData("combos", "categoryCombos");
      setParent("combos");
    } else if (location.includes("testimonials/edit/category")) {
      setOutGen("categoryTestimonials");
      loadData("testimonials", "categoryTestimonials");
      setParent("testimonials");
    } else if (location.includes("events/edit/category")) {
      setOutGen("categoryEvents");
      loadData("events", "categoryEvents");
      setParent("events");
    } else if (location.includes("wallposter/edit/category")) {
      setOutGen("categoryWallPoster");
      loadData("wallposter", "categoryWallPoster");
      setParent("wallposter");
      setAvatarHeight(300);
    } else if (location.includes("menu/edit/category")) {
      setOutGen("categoryMenu");
      loadData("menu", "categoryMenu");
      setParent("menu");
      setAvatarHeight(300);
    } else if (location.includes("flyer/edit/category")) {
      setOutGen("categoryFlyer");
      loadData("flyer", "categoryFlyer");
      setParent("flyer");
      setAvatarHeight(300);
    }
  }, [outGen.length]);

  const title = parent.substring(0, 1).toUpperCase() + parent.substring(1);

  const [myKey, setMyKey] = useState("");
  const outletKey = useParams().name;
  const [outletFound, setOutletFound] = useState(true);
  const [name, setName] = useState(""); // this is the name of the category
  const [imageList, setImageList] = useState([]);
  const [showAddImage, setShowAddImage] = useState(false);
  const [showAddMultipleImages, setShowAddMultipleImages] = useState(false);
  const [renameName, setRenameName] = useState("");
  const [showMoveBox, setShowMoveBox] = useState(false);
  const [showRename, setShowRename] = useState(false);
  const [renameRunning, setRenameRunning] = useState(false);
  const [outDeleting, setOutDeleting] = useState(false);
  const [dropLocationOfImage, setDropLocationOfImage] = useState("");
  const [selectedImageIndexNumber, setSelectedImageIndexNumber] = useState(0);
  const [selectedImagepath, setSelectedImagPath] = useState("");
  const [imageSnapshot, setImageSnapshot] = useState("");
  const [catDeleting, setCatDeleting] = useState(false);
  const [categoryFound, setCategoryFound] = useState(true);
  const [catPath, setCatPath] = useState("");
  const [imagePath, setImagePath] = useState("");
  const [showEditImage, setShowEditImage] = useState(false);

  // const addImageLink = (array) => {

  //     array.forEach(async (data,index) => {
  //        await writedb.ref(data.tempPath).update({thumbnailImage:data.image});
  //        console.info(index)
  //     })
  // }

  function loadData(par, address) {
    // onValue(ref(database, `images/${address}`), (snapshotImages) => {
    //   setImageSnapshot(snapshotImages);
    onValue(ref(database, `${par}/${address}/${outletKey}`), (snapshot) => {
      if (snapshot.exists() && !outDeleting) {
        let data = snapshot.val();
        let name = Object.keys(data)[0];
        setName(name);
        setMyKey(outletKey);
        setRenameName(name);
        let values = Object.values(data);
        let arr = [];

        values.forEach((val, index) => {
          if (!val.hasOwnProperty("image")) {
            let imageKeys = Object.keys(val);
            let subVal = Object.values(val);
            subVal.forEach((val, index) => {
              let catImgeKey = imageKeys[index];
              let imagePath = val.image;
              // console.info(val.image);
              let imageKey = imagePath.slice(8 + address.length);
              // let path = `${parent}/${outGen}/${outletKey}/${name}/${catImgeKey}`
              // console.info(path)
              // let imageLink = snapshotImages
              //   .child(imageKey)
              //   .child("cover")
              //   .child("image")
              //   .val();
              let imageLink = val.thumbnailImage;
              let item = {
                image: imageLink,
                path: imagePath,
                key: imageKey,
                imageCatKey: catImgeKey,
              };
              arr.push(item);
              // let imageKey = imageKeys[index];
              // if (val.hasOwnProperty("cover")) {
              //     let imageLink = val.cover.image;
              //     let path = `${parent}/${outGen}/${outletKey}/${name}/${imageKey}`
              //     let item = { image: imageLink, path: path };
              //     arr.push(item);
              // }
            });

            setImageList(arr.reverse());
          }
        });
      } else {
        setOutletFound(false);
      }
    });
    // });
  }

  const catDisplay = () => {
    setShowAddImage(!showAddImage);
  };

  const multipleImagesShowHide = () => {
    setShowAddMultipleImages(!showAddMultipleImages);
  };

  const deleteImageFromLink = async (link) => {
    await storage.refFromURL(link).delete();
  };

  const deleteImage = (loc) => {
    let path = JSON.parse(JSON.stringify(loc));

    writedb
      .ref(path)
      .once("value")
      .then((snapshot) => {
        if (snapshot.exists()) {
          let snapVal = snapshot.val();
          let data = Object.values(snapVal);
          data.forEach(async (val) => {
            if (val.hasOwnProperty("image")) {
              await deleteImageFromLink(val.image);
            }
          });
        }
      });

    writedb.ref(path).remove();
  };

  const deleteMainOut = async () => {
    const confirmed = window.confirm(
      `Are you sure you want to delete this ${outGen}?`
    );
    if (confirmed) {
      setOutDeleting(false);

      // let veg = JSON.parse(JSON.stringify(vegCategory));
      // let nonVeg = JSON.parse(JSON.stringify(nonVegCategory));

      imageList.forEach((val) => {
        deleteImageFromOut(val);
      });

      storage.ref(`${parent}/${outGen}/` + myKey + "/" + "coverPhoto").delete();

      writedb
        .ref(`${[parent]}Uniquekeys/${outGen}/${name}`)
        .remove()
        .then(() => {
          writedb.ref(`${parent}/${outGen}/` + myKey).remove(() => {
            setOutletFound(false);
            setOutDeleting(false);
            alert(`${title} deleted`);
          });
        });
    }
  };

  const deleteImageFromOut = async (data) => {
    writedb.ref(data.path).once('value').then(async (snapshot) => {
        if (snapshot.exists()){
            let paths = snapshot.val().paths;
            let labelObj = snapshot.val();
            let labels = [];
            if (labelObj.hasOwnProperty("labels")) {
              let labelObj = snapshot.val().labels;
              labels = Object.keys(labelObj);
            }
        
            let pathSize = Object.keys(paths);
            let key = JSON.parse(JSON.stringify(data.imageCatKey));
            let imageKey = JSON.parse(JSON.stringify(data.key));
        
            if (pathSize.length > 1) {
              writedb
                .ref(`${parent}/${outGen}/${myKey}/${name}/${key}`)
                .remove()
                .then(() => {
                  writedb.ref(`images/${outGen}/${imageKey}/paths/${myKey}`).remove();
                });
            } else {
              let check = await writedb
                .ref(`images/${outGen}/${data.key}`)
                .once("value");
        
              if (check.exists()) {
                let snapVal = check.val();
                let data = Object.values(snapVal);
                // console.info(data);
                data.forEach(async (val) => {
                  try {
                    if (val.hasOwnProperty("image")) {
                      let image = val.image;
                      // console.info(image)
                      await deleteImageFromLink(image);
                    }
                  } catch (error) {}
                });
                // console.info(`images/category/${imageKey}`)
                // console.info(`${mainPath}/${vegNonVeg}/${key}`)
                labels.forEach(async (txt) => {
                  await writedb.ref(`search/${txt}/${imageKey}`).remove();
                });
        
                writedb
                  .ref(`images/${outGen}/${imageKey}`)
                  .remove()
                  .then(() => {
                    writedb.ref(`${parent}/${outGen}/${myKey}/${name}/${key}`).remove();
                  });
              }
            }
        }
    })
    

    // let path = JSON.parse(JSON.stringify(data.path))
    // let check = await writedb.ref(data.path).once('value');

    // if (check.exists()) {
    //   let snapVal = check.val();
    //   let data = Object.values(snapVal);
    //   data.forEach(async val => {
    //     try {
    //       if (val.hasOwnProperty("image")) {
    //         let image = val.image;
    //         await deleteImageFromLink(image);
    //       }
    //     } catch (error) { }
    //   })

    //   writedb.ref(path).remove();

    // }
  };

  const renameOutlet = async () => {
    if (!renameName.length > 0) {
      alert("Invalid Input");
      return;
    }

    if (renameName == name) {
      setShowRename(!showRename);
      return;
    }

    if (!renameRunning) {
      let snapshot = await writedb
        .ref(`${parent}Uniquekeys/${outGen}/` + renameName)
        .once("value");
      if (snapshot.exists()) {
        alert(`${title} already exist`);
      } else {
        //   setRenameRunning(true)
        let oldName = JSON.parse(JSON.stringify(name));
        let keyOfOut = JSON.parse(JSON.stringify(myKey));
        let snapshotOfOutlet;

        writedb
          .ref(`${parent}/${outGen}/${myKey}/${name}`)
          .once("value")
          .then((snapshot) => {
            snapshotOfOutlet = snapshot.val();
            writedb
              .ref(`${parent}Uniquekeys/${outGen}/` + oldName + "/key")
              .remove()
              .then(() => {
                writedb
                  .ref(`${parent}Uniquekeys/${outGen}/${renameName}`)
                  .set({ key: keyOfOut })
                  .then(() => {
                    writedb
                      .ref(`${parent}/${outGen}/${keyOfOut}/${name}`)
                      .remove()
                      .then(() => {
                        writedb
                          .ref(`${parent}/${outGen}/${keyOfOut}/${renameName}`)
                          .set(snapshotOfOutlet)
                          .then(() => {
                            setShowRename(!showRename);
                            alert(`${title} renamed`);
                            setRenameRunning(false);
                          });
                      });
                  });
              });
          });
      }
    }
  };

  const deleteMainOutlet = async () => {
    const permission = window.confirm(`Are you sure to delete this ${outGen}?`);
    if (permission) {
      const path = JSON.parse(
        JSON.stringify(`${parent}/${outGen}/${myKey}/${name}`)
      );
      setOutDeleting(true);

      let snapshot = await writedb.ref(path).once("value");
      if (snapshot.exists()) {
        let data = Object.values(snapshot.val());
        data.forEach((child) => {
          let image = Object.values(child);
          image.forEach((val) => {
            if (val.hasOwnProperty("image")) {
              let link = val.image;
              if (link.length > 0) {
                deleteImageFromLink(link);
              }
            }
          });
        });
        writedb
          .ref(path)
          .remove()
          .then(() => {
            storage
              .ref(`${parent}/${outGen}/` + outletKey + "/" + "coverPhoto")
              .delete()
              .then(() => {
                writedb
                  .ref(`${parent}Uniquekeys/${outGen}/${name}`)
                  .remove()
                  .then(() => {
                    writedb
                      .ref(`${parent}/${outGen}` + outletKey)
                      .remove(() => {
                        setOutletFound(false);
                        setOutDeleting(false);
                        alert(`${title} deleted`);
                      });
                  });
              });
          });
      }
    }
  };

  const inputStyle = {
    "& label.Mui-focused": {
      color: "aqua",
    },
    "& .MuiInput-underline:after": {
      borderBottomColor: "white",
    },
    "& .MuiOutlinedInput-root": {
      "& fieldset": {
        borderColor: "gray",
        height: "45px",
      },
      "&:hover fieldset": {
        borderColor: "white",
      },
      "&.Mui-focused fieldset": {
        borderColor: "rgb(155,155,255)",
      },
      "& .MuiInputBase-input": {
        color: "white",
        fontSize: "16px",
        height: "7px",
      },
    },
  };

  const handleCloseMoveBox = () => {
    setShowMoveBox(!showMoveBox);
  };

  const setSelectedImageDataToBeMove = (data, index) => {
    setSelectedImageIndexNumber(index);
    setSelectedImagPath(data);
    setShowMoveBox(true);
  };

  const swapData = async () => {
    if (dropLocationOfImage.length == 0 || dropLocationOfImage < 1) {
      alert("Invalid");
      return;
    }

    let arr = Array.from(imageList);
    if (dropLocationOfImage > arr.length) {
      alert("Invalid");
      return;
    }

    let firstImagePath = arr[selectedImageIndexNumber];
    let secondImagePath = arr[dropLocationOfImage - 1];

    let firstImageData;
    let secondImageData;
    await writedb
      .ref(`${parent}/${outGen}/${myKey}/${name}/${firstImagePath.imageCatKey}`)
      .once("value")
      .then((snapshot) => {
        firstImageData = snapshot.val();
        writedb
          .ref(
            `${parent}/${outGen}/${myKey}/${name}/${secondImagePath.imageCatKey}`
          )
          .once("value")
          .then((snapshot) => {
            secondImageData = snapshot.val();
            writedb
              .ref(
                `${parent}/${outGen}/${myKey}/${name}/${firstImagePath.imageCatKey}`
              )
              .set(secondImageData)
              .then(() => {
                writedb
                  .ref(
                    `${parent}/${outGen}/${myKey}/${name}/${secondImagePath.imageCatKey}`
                  )
                  .set(firstImageData)
                  .then(() => {
                    setShowMoveBox(false);
                    alert("Data Swapping success");
                  });
              });
          });
      });
  };

  function handleEditImage(index) {
    let ipath = imageList[index].path;
    let cpath = `${parent}/${outGen}/${outletKey}/${name}/${imageList[index].imageCatKey}`;
    setImagePath(ipath);
    setCatPath(cpath);  
    setShowEditImage(true);
  }

  return (
    <Box>
      <Box
        display={outletFound ? "flex" : "none"}
        flexDirection="column"
        m="20px"
        marginBottom="20px"
      >
        {/* HEADER */}
        <Box display="flex" justifyContent="space-between" alignItems="center">
          <Header title={name} subtitle={`Brand Bite ${title}`} />
        </Box>

        <Box display="flex">
          <Box mr="5px">
            <Button
              sx={{ bgcolor: "blue", borderRadius: "20px" }}
              onClick={() => {
                setShowAddImage(!showAddImage);
              }}
            >
              <Typography display="flex" color="white" textTransform="none">
                <AddCircleOutlineRounded sx={{ mr: "5px" }} />
                Add New Image
              </Typography>
            </Button>
          </Box>
          <Box mr="5px">
            <Button
              sx={{ bgcolor: "blue", borderRadius: "20px" }}
              onClick={multipleImagesShowHide}
            >
              <Typography display="flex" color="white" textTransform="none">
                <AddCircleOutlineRounded sx={{ mr: "5px" }} />
                Add Multiple Images
              </Typography>
            </Button>
          </Box>
          <Box mr="5px" display={showRename ? "none" : "flex"}>
            <Button
              sx={{ bgcolor: "blue", borderRadius: "20px" }}
              onClick={() => {
                setShowRename(!showRename);
              }}
            >
              <Typography display="flex" color="white" textTransform="none">
                Rename
              </Typography>
            </Button>
          </Box>

          <Box
            display={showRename ? "flex" : "none"}
            flexDirection="column"
            height="40px"
            mr="5px"
            mb="15px"
          >
            <TextField
              autoComplete="off"
              label="Name"
              value={renameName}
              onChange={(event) => {
                setRenameName(
                  event.target.value.charAt(0).toUpperCase() +
                    event.target.value.slice(1)
                );
              }}
              sx={inputStyle}
            />
            <Box display="flex" justifyContent="center" mt="5px" zIndex="1">
              <Box
                bgcolor="blue"
                borderRadius="10px"
                p="2px 5px"
                className="thisisbtn"
                onClick={renameOutlet}
              >
                <Button>
                  {" "}
                  <Typography color="white">Rename</Typography>
                </Button>
              </Box>
              <Box
                bgcolor="blue"
                borderRadius="10px"
                p="2px 5px"
                ml="10px"
                className="thisisbtn"
                onClick={() => {
                  setShowRename(!showRename);
                }}
              >
                <Button>
                  {" "}
                  <Typography color="white">Cancel</Typography>
                </Button>
              </Box>
            </Box>
          </Box>

          <Box mr="5px">
            <Button
              sx={{ bgcolor: "blue", borderRadius: "20px" }}
              onClick={deleteMainOut}
            >
              <Typography display="flex" color="white" textTransform="none">
                Delete
              </Typography>
            </Button>
          </Box>
        </Box>

        <Modal open={showMoveBox} onClose={handleCloseMoveBox}>
          <Box
            display="flex"
            flexDirection="column"
            justifyContent="center"
            alignItems="center"
            bgcolor="#727681"
            p={3}
          >
            <Box display="flex" flexDirection="column" height="45px" ml="10px">
              <Input
                autoFocus
                autoComplete="off"
                type="number"
                label="Name"
                value={dropLocationOfImage}
                onChange={(event) => {
                  setDropLocationOfImage(parseInt(event.target.value));
                }}
                sx={inputStyle}
              />
            </Box>
            <Box mt="10px">
              <Button
                variant="contained"
                color="primary"
                sx={{ mr: "10px" }}
                onClick={swapData}
              >
                Move
              </Button>
              <Button
                variant="contained"
                color="primary"
                onClick={() => {
                  setShowMoveBox(!showMoveBox);
                }}
              >
                Close
              </Button>
            </Box>
          </Box>
        </Modal>

        {showAddImage ? (
          <AddNewImage
            catDis={catDisplay}
            parent={parent}
            address={outGen}
            myName={name}
            myKey={myKey}
          />
        ) : (
          ""
        )}
        {showAddMultipleImages ? (
          <AddMultipleImages
            parent={parent}
            address={outGen}
            catDis={multipleImagesShowHide}
            myName={name}
            myKey={myKey}
          />
        ) : (
          ""
        )}

        {showEditImage ? (
          <EditImageFromServer
            catDis={() => {
              setShowEditImage(!showEditImage);
            }}
            parent={parent}
            catPath={catPath}
            myName={name}
            imagePath={imagePath}
          />
        ) : (
          ""
        )}

        <Box display="flex" width="100%" flexWrap={true} flexDirection="column">
          <React.Fragment key="listOfOutlet">
            <Box display="flex" alignItems="center">
              <Grid container maxHeight="600px" overflow="auto" mt="30px">
                {imageList.map((data, indexData) => (
                  <Grid
                    item
                    key={indexData + "outler"}
                    xs={12}
                    sm={6}
                    md={4}
                    lg={3}
                    xl={3}
                  >
                    <Box
                      display="flex"
                      p="10px"
                      flexDirection="column"
                      key={data.image}
                      width={200}
                      position="relative"
                    >
                      <Box
                        position="absolute"
                        zIndex="10"
                        right="0"
                        mr="3px"
                        mt="8px"
                        onClick={() => {
                          handleEditImage(indexData);
                        }}
                      >
                        <div
                          style={{
                            width: "30px", // Adjust the size of the circle as needed
                            height: "30px", // Adjust the size of the circle as needed
                            borderRadius: "50%",
                            backgroundColor: "rgba(255, 255, 255, 0.5)", // White color with 80% opacity
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                            color: "black",
                            cursor: "pointer", // Add pointer cursor to indicate it's clickable
                          }}
                        >
                          <Edit />
                        </div>
                      </Box>
                      <Avatar
                        src={data.image}
                        variant="square"
                        sx={{
                          width: avatarWidth,
                          height: avatarHeight,
                          borderStartStartRadius: "10px",
                          borderStartEndRadius: "10px",
                          cursor: "pointer",
                          "& > img": {
                            objectFit: "fill",
                            width: "100%",
                            height: "100%",
                          },
                          ":hover": { bgcolor: "white" },
                        }}
                      ></Avatar>

                      <Typography
                        display="flex"
                        color="white"
                        ml="180px"
                        position="absolute"
                        mt={`${avatarHeight - 30}px`}
                      >
                        {indexData + 1}
                      </Typography>

                      <Box
                        display="flex"
                        bgcolor="white"
                        sx={{
                          borderEndEndRadius: "10px",
                          borderEndStartRadius: "10px",
                        }}
                        width="200px"
                        justifyContent="center"
                      >
                        <Button
                          onClick={() => {
                            deleteImageFromOut(data);
                          }}
                        >
                          Delete
                        </Button>
                        <Button
                          onClick={() => {
                            setSelectedImageDataToBeMove(data.path, indexData);
                          }}
                        >
                          Move
                        </Button>
                      </Box>
                    </Box>
                  </Grid>
                ))}
              </Grid>
            </Box>
          </React.Fragment>
        </Box>
      </Box>

      <Box
        width="100%"
        height="100vh"
        display={outletFound ? "none" : "flex"}
        justifyContent="Center"
        alignItems="center"
      >
        <Typography fontSize="20px">{title} Not Found</Typography>
      </Box>
    </Box>
  );
};

export default OutletManage;
