import { Avatar, Box, Button, Grid, Input, Modal, TextField, Tooltip, Typography, useTheme } from "@mui/material";
import { useLocation, useParams } from "react-router-dom";
import Header from "../../components/Header";
import { tokens } from "../../theme";
import { useEffect, useState } from "react";
import { useMemo } from "react";
import { onValue, ref } from "firebase/database";
import { database, storage, writedb } from "../../firebase-config";
import { AddCircleOutlineRounded, DeleteRounded, AbcRounded, CloseRounded, AddCircleOutline } from "@mui/icons-material";
import React from "react";
import AddNewImage from "./addNewImage"
import AddMultipleImages from "./addMultipleImages";
import BulkUpload from "./bulkUpload";



const ManageVideoCategory = () => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);

  const location = useLocation().pathname;
  const [catCus, setCatCus] = useState("");
  useEffect(() => {
    if (location.includes("/category")) {
      setCatCus("category");
      loadData("category")
    } else if (location.includes("/custom")) {
      setCatCus("custom");
      loadData("custom")

    }
  }, [catCus.length])

  const categoryKey = useParams().name;

  const [name, setName] = useState();  // this is the name of the category

  const [vegNonVeg, setVegNonVeg] = useState("veg");
  const [myKey, setMyKey] = useState();
  const [showaddImage, setShowAddimage] = useState(false);
  const [renameName, setRenameName] = useState('');
  const [showRename, setShowRename] = useState(false);
  const [catSnapshot, setCatSnapshot] = useState();
  const [vegCategory, setVegCategory] = useState([]);
  const [nonVegCategory, setNonVegCategory] = useState([]);
  const [renameRunning, setRenameRunning] = useState(false);
  const [showSubCatRename, setShowSubCatRename] = useState(false);
  const [subCatName, setSubCatName] = useState("");
  const [selectedSubCat, setSelectedSubCat] = useState();
  const [categoryFound, setCategoryFound] = useState(true);
  const [catDeleting, setCatDeleting] = useState(false)
  const [multipleUpload, setMultipleUpload] = useState(false)
  const [showAddMultipleImages, setShowAddMultipleImages] = useState(false);
  const [selectedImageIndexNumber, setSelectedImageIndexNumber] = useState(0); // selected image index number which we want to move;
  const [showMoveBox, setShowMoveBox] = useState(false); //this is used to get index where we want to move image;
  const [selectedImageCat, setSelectedImageCat] = useState();  // in this we store the array of subcategory where selected image is stored
  const [dropLocationOfImage, setDropLocationOfImage] = useState("");
  const [subCatofSelectedImage, setsubCatofSelectedImage] = useState("");
  const [selectedImagepath, setSelectedImagPath] = useState("");
  const [imageSnapshot, setImageSnapshot] = useState("");
  const [mainPath, setMainPath] = useState("");

  function loadData(address) {


    onValue(ref(database, `videos/${address}`), (snapshotAllImages) => {
      setImageSnapshot(snapshotAllImages);
      onValue(ref(database, `video/${address}/` + categoryKey), (snapshot) => {

        if (snapshot.exists() && !catDeleting) {

          let catName = Object.keys(snapshot.val())[0]
          setMyKey(categoryKey)
          setName(catName)
          setRenameName(catName);
          let basePath = `video/${address}/` + categoryKey + "/" + catName;
          setMainPath(basePath);
          onValue(ref(database, basePath), (snapshot) => {

            if (!catDeleting) {
              setCatSnapshot(snapshot);
              vegCategory.size = 0;
              var vegImages = [];
              var nonVegImages = [];
              var v3 = snapshot.val();
              if (v3 != null) {
                var keyinv3 = Object.keys(v3);
                keyinv3.forEach(key => {
                  if (key === "veg") {
                    var veg = Object.values(v3.veg)


                    var keysVeg = Object.keys(v3.veg)
                    veg.forEach((val, index) => {
                      let imageKeyInCategory = keysVeg[index];
                      if (val.hasOwnProperty("video")) {
                        let key = val.video.slice(8 + address.length);
                        if (snapshotAllImages.child(key).exists()) {
                          let imageObj = snapshotAllImages.child(key).val();
                          if (imageObj.hasOwnProperty("cover") && imageObj != null) {
                            let image = imageObj.cover.image;
                            var newItem = { image: image, key: key, imageCatKey: imageKeyInCategory }
                            vegImages.push(newItem);
                          }
                        }
                      }
                    })
                  }

                  else if (key === "nonVeg") {
                    var nonVeg = Object.values(v3.nonVeg)
                    var keysNonVeg = Object.keys(v3.nonVeg)

                    nonVeg.forEach((val, index) => {
                      let imageKeyInCategory = keysNonVeg[index];
                      if (val.hasOwnProperty("video")) {
                        let key = val.video.slice(8 + address.length);
                        if (snapshotAllImages.child(key).exists()) {
                          let imageObj = snapshotAllImages.child(key).val();
                          if (imageObj.hasOwnProperty("cover") && imageObj != null) {
                            let image = imageObj.cover.image;
                            var newItem = { image: image, key: key, imageCatKey: imageKeyInCategory }
                            nonVegImages.push(newItem);
                          }
                        }
                      }
                    })

                  }

                })

              }

              setVegCategory(vegImages);
              setNonVegCategory(nonVegImages);
            }
          })
        } else {
          setCategoryFound(false)
        }

      })

    });

  }




  const catDisplay = () => {

    setShowAddimage(!showaddImage);
  }

  const mulDisplay = () => {

    setMultipleUpload(!multipleUpload);
  }

  const multipleImagesDisplay = () => {

    setShowAddMultipleImages(!showAddMultipleImages);
  }

  const inputStyle = {
    '& label.Mui-focused': {
      color: 'aqua',
    },
    '& .MuiInput-underline:after': {
      borderBottomColor: 'white',
    },
    '& .MuiOutlinedInput-root': {
      '& fieldset': {
        borderColor: 'gray',
        height: '45px'
      },
      '&:hover fieldset': {
        borderColor: 'white',
      },
      '&.Mui-focused fieldset': {
        borderColor: 'rgb(155,155,255)',
      }, '& .MuiInputBase-input': {
        color: 'white',
        fontSize: "16px",
        height: "7px"

      },
    }
  }


  const renameCat = async () => {
    if (!renameName.length > 0) {
      alert("Invalid Input");
      return;
    }

    if (renameName == name) {
      setShowRename(!showRename);
      return;
    }

    if (!renameRunning) {
      let snapshot = await writedb.ref(`videoUniquekeys/${catCus}/` + renameName).once('value');
      if (snapshot.exists()) {
        alert("Category already exist");
      } else {
        setRenameRunning(true)
        let oldName = JSON.parse(JSON.stringify(name));
        let keyOfCat = JSON.parse(JSON.stringify(myKey));
        let snapshot1 = JSON.parse(JSON.stringify(catSnapshot));
        writedb.ref(`videoUniquekeys/${catCus}/` + oldName + "/key").remove().then(() => {
          writedb.ref(`videoUniquekeys/${catCus}/${renameName}`).set({ key: keyOfCat }).then(() => {
            writedb.ref(`video/${catCus}/${keyOfCat}/${name}`).remove().then(() => {
              writedb.ref(`video/${catCus}/${keyOfCat}/${renameName}`).set(snapshot1).then(() => {
                setShowRename(!showRename);
                alert("category renamed");
                setRenameRunning(false)
                loadData("category")
              })
            })
          })

        })

      }
    }


    // onValue(ref(database, "homeUniquekeys/category/" + renameName), (snapshot) => {
    //   setTimeout(() => {
    //   if (!checkCatNameUpdateRunning && doRename){
    //     
    //   }
    // }, 1000);
    // })



  }

  const handleOpenSubCatRename = (data) => {
    setSelectedSubCat(data)
    setSubCatName(data.name);
    setShowSubCatRename(true)
  };

  const handleCloseSubCatRename = () => {
    setShowSubCatRename(false)
  };

  const renameSubCat = async () => {
    if (subCatName.length == 0) {
      alert("please enter category name");
      return;
    }
    let oldName = JSON.parse(JSON.stringify(selectedSubCat.name));
    if (oldName == subCatName) {
      setShowSubCatRename(false);
      return;
    }

    let checkAlreadyExist = await writedb.ref(`${selectedSubCat.path}/${subCatName}`).once('value');
    if (checkAlreadyExist.exists()) {
      alert("sub category Already exist")
    } else {
      let oldpath = `${selectedSubCat.path}/${oldName}`
      let getsnapshot = await writedb.ref(oldpath).once('value');
      let snapshot = JSON.parse(JSON.stringify(getsnapshot));

      writedb.ref(`${selectedSubCat.path}/${subCatName}`).set(snapshot).then(() => {
        writedb.ref(oldpath).remove().then(() => {
          handleCloseSubCatRename();
          alert("Name changed");
        })
      })
    }





  }




  const deleteImageFromLink = async (link) => {
    await storage.refFromURL(link).delete();
  }





  const deleteMainCat = async () => {
    const confirmed = window.confirm("Are you sure you want to delete this category?");
    if (confirmed) {
      setCatDeleting(true)


      // let veg = JSON.parse(JSON.stringify(vegCategory));
      // let nonVeg = JSON.parse(JSON.stringify(nonVegCategory));

      vegCategory.forEach(val => {
        deleteImageFromCat(val);
        // deletSubCategoryAuto(val.path)
      })

      nonVegCategory.forEach(val => {
        deleteImageFromCat(val);

      })



      // storage.ref(`video/${catCus}/` + categoryKey + "/" + "coverPhoto").delete();

      writedb.ref(`videoUniquekeys/${catCus}/${name}`).remove().then(() => {
        writedb.ref(`video/${catCus}/` + categoryKey).remove(() => {
          setCategoryFound(false);
          setCatDeleting(false)
          alert("Category deleted")
        })
      })


    }
  }


  const deleteImageFromCat = async (data) => {
    let paths = imageSnapshot.child(data.key).val().paths;
    let labelObj = imageSnapshot.child(data.key).val();
    let labels = [];
    if (labelObj.hasOwnProperty("labels")) {
      let labelObj = imageSnapshot.child(data.key).val().labels;
      labels = Object.keys(labelObj);
    }



    let pathSize = Object.keys(paths);
    let key = JSON.parse(JSON.stringify(data.imageCatKey))
    let imageKey = JSON.parse(JSON.stringify(data.key))

    if (pathSize.length > 1) {
      writedb.ref(`${mainPath}/${vegNonVeg}/${key}`).remove().then(() => {
        writedb.ref(`videos/${catCus}/${imageKey}/paths/${categoryKey}`).remove();
      })
    } else {
      let check = await writedb.ref(`videos/${catCus}/${data.key}`).once('value');

      if (check.exists()) {
        let snapVal = check.val();
        let data = Object.values(snapVal);
        // console.info(data);
        data.forEach(async val => {
          try {
            if (val.hasOwnProperty("image")) {
              let image = val.image;
              // console.info(image)
              await deleteImageFromLink(image);
            } else if (val.hasOwnProperty("video")) {
              let image = val.video;
              await deleteImageFromLink(image);
            }
          } catch (error) { }
        })
        // console.info(`images/category/${imageKey}`)
        // console.info(`${mainPath}/${vegNonVeg}/${key}`)
        labels.forEach(async txt => {
          await writedb.ref(`search/${txt}/${imageKey}`).remove();
        })
        writedb.ref(`videos/${catCus}/${imageKey}`).remove().then(() => {
          writedb.ref(`${mainPath}/${vegNonVeg}/${key}`).remove();
        });

      }
    }


    // let path = JSON.parse(JSON.stringify(data.path))
    // let check = await writedb.ref(data.path).once('value');


    // if (check.exists()) {
    //   let snapVal = check.val();
    //   let data = Object.values(snapVal);
    //   data.forEach(async val => {
    //     try {
    //       if (val.hasOwnProperty("image")) {
    //         let image = val.image;
    //         await deleteImageFromLink(image);
    //       }
    //     } catch (error) { }
    //   })

    //   writedb.ref(path).remove();

    // }
  }



  const setSelectedImageDataToBeMove = (index) => {
    setSelectedImageIndexNumber(index);
    // setSelectedImageCat(subCat);
    // setSelectedImagPath(data);
    setShowMoveBox(true)


  }

  const swapData = async () => {
    if (dropLocationOfImage.length == 0 || dropLocationOfImage < 1) {
      alert("Invalid");
      return;
    }


    let arr = null;
    if (vegNonVeg === "veg") {
      arr = [...vegCategory];
    }
    else {
      arr = [...nonVegCategory];
    }

    if (dropLocationOfImage > arr.length) {
      alert("Invalid");
      return;
    }

    let firstImagePath = `${mainPath}/${vegNonVeg}/${arr[selectedImageIndexNumber].imageCatKey}`
    let secondImagePath = `${mainPath}/${vegNonVeg}/${arr[dropLocationOfImage - 1].imageCatKey}`

    let firstImageData;
    let secondImageData;

    await writedb.ref(firstImagePath).once('value').then((snapshot) => {
      firstImageData = snapshot.val();
      writedb.ref(secondImagePath).once('value').then((snapshot) => {
        secondImageData = snapshot.val();
        writedb.ref(firstImagePath).set(secondImageData).then(() => {
          writedb.ref(secondImagePath).set(firstImageData).then(() => {
            setSelectedImageIndexNumber(dropLocationOfImage - 1);
          })
        })
      })
    })


  }




  return (
    <Box>

      <Box display={categoryFound ? "flex" : "none"} flexDirection="column" m="20px" marginBottom="20px">
        {/* HEADER */}
        <Box display="flex" justifyContent="space-between" alignItems="center">
          <Header title={name} subtitle="Brand Bite" />
        </Box>

        {/* Category Options starts here */}
        <Box display="flex" flexDirection="column">
          <Box display="flex" >
          <Box ml="10px" display="flex" bgcolor="blue" borderRadius="20px" p="5px 10px" className="thisisbtn" onClick={mulDisplay}>
              <Typography display="flex" justifyContent="center" alignItems="center" color="white">Bulk Upload</Typography>
            </Box>
            {/* <Box display="inline-flex" bgcolor="blue" borderRadius="20px" p="5px 10px" className="thisisbtn" onClick={catDisplay} >
              <Typography display="flex" justifyContent="center" alignItems="center" color="white"><AddCircleOutlineRounded sx={{ mr: "5px" }} /> Add New Video</Typography>
            </Box> */}
            {/* <Box ml="10px" display="inline-flex" bgcolor="blue" borderRadius="20px" p="5px 10px" className="thisisbtn" onClick={() => setShowAddMultipleImages(!showAddMultipleImages)}>
              <Typography display="flex" justifyContent="center" alignItems="center" color="white"><AddCircleOutlineRounded sx={{ mr: "5px" }} />Add Multiple Videos</Typography>
            </Box> */}
            <Box ml="10px" display={showRename ? "none" : "flex"} bgcolor="blue" borderRadius="20px" p="5px 10px" className="thisisbtn" onClick={() => { setShowRename(!showRename) }}>
              <Typography display="flex" justifyContent="center" alignItems="center" color="white">Rename</Typography>
            </Box>

            <Box display={showRename ? "flex" : "none"} flexDirection="column" height="40px" ml="10px">
              <TextField autoFocus autoComplete="off" label="Name" value={renameName} onChange={(event) => {
                setRenameName(event.target.value.charAt(0).toUpperCase() + event.target.value.slice(1))
              }} sx={inputStyle} />
              <Box display="flex" justifyContent="end" mt="5px" zIndex="1">
                <Box bgcolor="blue" borderRadius="10px" p="2px 5px" className="thisisbtn" onClick={renameCat}>
                  <Typography color="white">Rename</Typography>
                </Box>
                <Box bgcolor="blue" borderRadius="10px" p="2px 5px" ml="10px" className="thisisbtn" onClick={() => { setShowRename(!showRename) }}>
                  <Typography color="white">Cancel</Typography>
                </Box>
              </Box>
            </Box>

            <Box ml="10px" display="flex" bgcolor="blue" borderRadius="20px" p="5px 10px" className="thisisbtn" onClick={deleteMainCat}>
              <Typography display="flex" justifyContent="center" alignItems="center" color="white">Delete</Typography>
            </Box>
            
          </Box>

          
          <Box display="none" borderRadius="10px" mb="-15px" mt="5px">
            <Button onClick={() => { setVegNonVeg("veg") }}><Typography textTransform="none" fontSize="20px" fontWeight="700" color={vegNonVeg === "veg" ? colors.greenAccent[500] : colors.grey[100]}>Veg</Typography> </Button>

            <Button onClick={() => { setVegNonVeg("nonVeg") }}> <Typography textTransform="none" fontSize="20px" fontWeight="700" color={vegNonVeg === "nonVeg" ? colors.greenAccent[500] : colors.grey[100]}>Non-veg</Typography> </Button>
          </Box>
        </Box>
        {/* Category Options ends here */}


        <Modal open={showSubCatRename} onClose={handleCloseSubCatRename}>
          <Box
            display="flex"
            flexDirection="column"
            justifyContent="center"
            alignItems="center"
            bgcolor="#727681" s
            p={3}
          >
            <Box display="flex" flexDirection="column" height="40px" ml="10px">
              <TextField autoFocus autoComplete="off" label="Name" value={subCatName} onChange={(event) => {
                setSubCatName(event.target.value.charAt(0).toUpperCase() + event.target.value.slice(1))
              }} sx={inputStyle} />
            </Box>
            <Box mt="10px">
              <Button variant="contained" color="primary" sx={{ mr: "10px" }} onClick={renameSubCat}>
                Rename
              </Button>
              <Button variant="contained" color="primary" onClick={handleCloseSubCatRename}>
                Close
              </Button>
            </Box>
          </Box>
        </Modal>

        <Modal open={showMoveBox} onClose={handleCloseSubCatRename}>
          <Box
            display="flex"
            flexDirection="column"
            justifyContent="center"
            alignItems="center"
            bgcolor="#727681"
            p={3}
          >
            <Box display="flex" flexDirection="column" height="45px" ml="10px">
              <Input autoFocus autoComplete="off" type="number" label="Name" value={dropLocationOfImage} onChange={(event) => {
                setDropLocationOfImage(parseInt(event.target.value))
              }} sx={inputStyle} />
            </Box>
            <Box mt="10px">
              <Button variant="contained" color="primary" sx={{ mr: "10px" }} onClick={swapData}>
                Move
              </Button>
              <Button variant="contained" color="primary" onClick={() => { setShowMoveBox(!showMoveBox) }}>
                Close
              </Button>
            </Box>
          </Box>
        </Modal>

        {showaddImage ? <AddNewImage catDis={catDisplay} myName={name} myKey={myKey} address={catCus} /> : ""}
        {showAddMultipleImages ? <AddMultipleImages catDis={multipleImagesDisplay} myName={name} myKey={myKey} address={catCus} /> : ""}
        {multipleUpload ? <BulkUpload catDis={mulDisplay} myName={name} myKey={myKey} address={catCus} /> : ""}



        <Box display={vegNonVeg == "veg" ? "flex" : "none"} width="100%" flexWrap="wrap" mt="20px" >
          <Grid container justify="center" spacing={1} wrap="wrap"  >
            {vegCategory.map((val, index) => (


              <Grid item key={index + "veg"} xs={12} sm={6} md={4} lg={3} xl={2.4} >
                <Box display="flex" p="10px" flexDirection="column" key={val.key} width={200} position="relative">
                  <Avatar src={val.image} variant="square" sx={{
                    width: 200, height: 200, borderStartStartRadius: "10px", borderStartEndRadius: "10px", cursor: "pointer", '& > img': {
                      objectFit: 'fill',
                      width: '100%',
                      height: '100%',
                    }, ":hover": { bgcolor: "white" }
                  }}></Avatar>

                  <Typography display="flex" color="white" ml="180px" position="absolute" mt="170px">{index + 1}</Typography>

                  <Box display="flex" bgcolor="white" sx={{ borderEndEndRadius: "10px", borderEndStartRadius: "10px" }} width="200px" justifyContent="center">
                    <Button onClick={() => { deleteImageFromCat(val) }} >
                      Delete
                    </Button>
                    <Button onClick={() => { setSelectedImageDataToBeMove(index) }}>
                      Move
                    </Button>

                  </Box>
                </Box>
              </Grid>


            ))}
          </Grid>
        </Box>


      

        <Box display={vegNonVeg == "nonVeg" ? "flex" : "none"} width="100%" flexWrap={true} flexDirection="column" mt="20px">
        <Grid container justify="center" spacing={1} wrap="wrap"  >
            {nonVegCategory.map((val, index) => (


              <Grid item key={index + "nonVeg"} xs={12} sm={6} md={4} lg={3} xl={2.4} >
                <Box display="flex" p="10px" flexDirection="column" key={val.key} width={200} position="relative">
                  <Avatar src={val.image} variant="square" sx={{
                    width: 200, height: 200, borderStartStartRadius: "10px", borderStartEndRadius: "10px", cursor: "pointer", '& > img': {
                      objectFit: 'fill',
                      width: '100%',
                      height: '100%',
                    }, ":hover": { bgcolor: "white" }
                  }}></Avatar>

                  <Typography display="flex" color="white" ml="180px" position="absolute" mt="170px">{index + 1}</Typography>

                  <Box display="flex" bgcolor="white" sx={{ borderEndEndRadius: "10px", borderEndStartRadius: "10px" }} width="200px" justifyContent="center">
                    <Button onClick={() => { deleteImageFromCat(val) }} >
                      Delete
                    </Button>
                    <Button onClick={() => { setSelectedImageDataToBeMove(index) }}>
                      Move
                    </Button>

                  </Box>
                </Box>
              </Grid>


            ))}
          </Grid>
        </Box>

      </Box>





      <Box width="100%" height="100vh" display={categoryFound ? "none" : "flex"} justifyContent="Center" alignItems="center" >

        <Typography fontSize="20px">
          Category Not Found
        </Typography>
      </Box>

    </Box>


  );
}

export default ManageVideoCategory;